/* eslint-disable react/destructuring-assignment */
import './Loading.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { FC, HTMLAttributes } from 'react';

interface LoaderProps {
  isFullHeight?: boolean;
}

const Loader: FC<LoaderProps & HTMLAttributes<HTMLDivElement>> = props => {
  return (
    <CircularProgress
      style={{
        ...(props.style as any),
      }}
      size="small"
      className="defaultLoading"
    />
  );
};

export default Loader;
