import { FieldPolicy } from '@apollo/client';
import uniqBy from 'lodash/uniqBy';

/**
 * Pagination typePolicies
 */
export const fieldPaginationFn = (
  keyArgs: Array<string> | false = false,
  direction: 'up' | 'down' = 'down'
): FieldPolicy => {
  return {
    keyArgs,
    merge: (existing, incoming) => {
      if (!existing) {
        return incoming;
      }
      const oldResults = existing?.results || [];
      const newResults = incoming?.results || [];

      const results = uniqBy(
        direction === 'down' ? [...oldResults, ...newResults] : [...newResults, ...oldResults],
        '__ref'
      );

      return {
        ...incoming,
        results,
        total: incoming.total,
      };
    },
  };
};

/**
 * Exclude all removed in pagination
 */
export const withIsRemovedPaginationFn = (
  keyArgs: Array<string> | false = false,
  direction: 'up' | 'down' = 'down'
): FieldPolicy => {
  return {
    keyArgs,
    merge: (existing, incoming, { readField }) => {
      if (!existing) {
        return incoming;
      }
      const oldResults = existing?.results || [];
      const newResults = incoming?.results || [];
      const uniqResults = uniqBy(
        direction === 'down' ? [...oldResults, ...newResults] : [...newResults, ...oldResults],
        '__ref'
      );

      const results = uniqResults.slice(0).filter(res => !readField('isRemoved', res));

      return {
        ...incoming,
        results,
        total: incoming.total,
      };
    },
  };
};
