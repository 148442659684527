import './index.scss';
import './Theme/fonts/fonts.css';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import firebase from 'firebase/app';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import config from './config/config';
import MainApp from './MainApp';
import ApolloProvider from './provider/ApolloProvider';
import AuthProvider from './provider/AuthProvider';
import { SocketProvider } from './provider/SocketProvider';
import myTheme from './Theme/myTheme/myTheme';

// pick an adapter for your date library

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: config.firebase.apiKey,
    authDomain: config.firebase.authDomain,
  });
} else {
  firebase.app();
}
export { firebase };

const promise = loadStripe(config.stripe.apiKey);

function App() {
  return (
    <PayPalScriptProvider
      options={{
        'client-id':
          'ATb3t230TjFHcC9WDbJ88s3PkKXBWHDMMNdjTuyIagTLjtiPcM38nYDWcsSTSRYmxJm_HvMdoM8C_GuD',
      }}
    >
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <AuthProvider>
          <ApolloProvider>
            {/* <SocketProvider> */}
            <MuiThemeProvider theme={myTheme(false)}>
              <BrowserRouter>
                <div className="app">
                  <Elements stripe={promise}>
                    <MainApp />
                  </Elements>
                </div>
              </BrowserRouter>
            </MuiThemeProvider>
            {/* </SocketProvider> */}
          </ApolloProvider>
        </AuthProvider>
      </LocalizationProvider>
    </PayPalScriptProvider>
  );
}

export default App;
