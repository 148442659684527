import makeStyles from '@material-ui/core/styles/makeStyles';
import classnames from 'classnames';
import React, { FC } from 'react';
import { ReactComponent as LogoSVG } from '../../Assets/v3/logo_GG.svg';

const useStyles = makeStyles(() => ({
  go: {
    fontFamily: 'Noto Sans',
    fontWeight: 'bold',
    letterSpacing: 0,
  },
  go_small: {
    width: 22.49,
    color: '#FFFFFF',
    fontSize: 17.64,
  },
  go_medium: {
    width: 32.29,
    color: '#FFFFFF',
    fontSize: 25.2,
  },
  go_large: {
    width: 40.36,
    fontSize: 31.5,
    color: '#DC2800',
  },
  grind: {
    fontFamily: 'Noto Sans',
    fontWeight: 'bold',
    letterSpacing: 0,
    marginLeft: 2,
  },
  grind_small: {
    width: 54.46,
    color: '#949494',
    fontSize: 17.64,
  },
  grind_medium: {
    width: 78.2,
    color: '#949494',
    fontSize: 25.2,
  },
  grind_large: {
    width: 97.75,
    fontSize: 31.5,
    color: '#FFFFFF',
  },
  flex: {
    display: 'flex',
    marginLeft: 5,
  },
}));
interface LogoProps {
  size?: 'large' | 'medium' | 'small';
}
const Logo: FC<LogoProps> = ({ size }) => {
  const cls = useStyles();
  if (size === 'large') {
    return <LogoSVG />;
  }
  return (
    <span>
      <span className={classnames(cls.go, cls[`go_${size ?? 'large'}`])}>GO</span>
      <span className={classnames(cls.grind, cls[`grind_${size ?? 'large'}`])}>GRIND</span>
    </span>
  );
};

export default Logo;
