/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-use-before-define */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  EmailAddress: any;
  JSON: any;
};

export type Award_Type = 'cash';

export type ActiveCouponInput = {
  code: Scalars['String'];
};

export type ActivisionAuthPayload = {
  __typename?: 'ActivisionAuthPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type Actuality = {
  __typename?: 'Actuality';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  imagePreview?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ActualityFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type AddCouponInput = {
  totalDay: Scalars['Int'];
};

/** add gain from edit tournament */
export type AddGainInput = {
  tournamentId: Scalars['ID'];
  gain: GainInput;
};

export type AddGameInput = {
  name: Scalars['String'];
  logo: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  jacketImages: Array<Scalars['String']>;
  popularity: Scalars['Int'];
  releasedDate: Scalars['DateTime'];
  note: Scalars['Int'];
  platforms: Array<Scalars['ID']>;
};

export type AddGamePlatformInput = {
  name: Scalars['String'];
  logo: Scalars['String'];
};

export type AddMatchFileInput = {
  matchId: Scalars['ID'];
  url: Scalars['String'];
  size: Scalars['Float'];
  name: Scalars['String'];
};

export type AddMessageInput = {
  title?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  threadId?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
};

export type AddPostInput = {
  title: Scalars['String'];
  content: Scalars['String'];
};

export type AddProductCategoryInput = {
  name: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
};

export type AddProductInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  categoryId: Scalars['ID'];
  price: Scalars['Float'];
};

/** add Rule from edit tournament */
export type AddRuleInput = {
  tournamentId: Scalars['ID'];
  rule: RuleInput;
};

export type AddSubscriptionInput = {
  title: Scalars['String'];
  totalMonth: Scalars['Int'];
  pricePerMonth: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
};

export type AddTeamInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  members: Array<Scalars['ID']>;
};

export type AddThreadInput = {
  title: Scalars['String'];
  member?: Maybe<Array<Maybe<Scalars['ID']>>>;
  isPrivate?: Maybe<Scalars['Boolean']>;
};

export type AddTournamentFormatInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type AddTournamentInput = {
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  formatId: Scalars['ID'];
  gains: Array<GainInput>;
  gameId: Scalars['ID'];
  gamePlatformId: Scalars['ID'];
  maxParticipants: Scalars['Int'];
  minParticipants?: Maybe<Scalars['Int']>;
  withThirdRound?: Maybe<Scalars['Boolean']>;
  rules: Array<RuleInput>;
};

export type AddTournamentMessageInput = {
  tournamentId: Scalars['ID'];
  message: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
  sentAt: Scalars['DateTime'];
};

export type AddTrainingDetailInput = {
  trainingGameName?: Maybe<Scalars['String']>;
  advice?: Maybe<Scalars['String']>;
  about?: Maybe<TrainingDetailAboutInput>;
  system?: Maybe<TrainingDetailSystemInput>;
  logo?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  trainingProgramId: Scalars['ID'];
};

export type AddTrainingInput = {
  trainingGameName: Scalars['String'];
  logo: Scalars['String'];
  jacketImage: Scalars['String'];
};

export type AddTrainingProgramInput = {
  title: Scalars['String'];
  description: Scalars['String'];
  level: ProgramLevelValue;
  estimateDuration: Scalars['String'];
  trainingId: Scalars['ID'];
};

export type AddUserInTeamInput = {
  userId: Scalars['ID'];
  teamId: Scalars['ID'];
};

export type AddUserThreadInput = {
  threadId: Scalars['String'];
  member?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type AddUserToMyfriendsPayload = {
  __typename?: 'AddUserToMyfriendsPayload';
  user?: Maybe<User>;
  chat?: Maybe<DirectChat>;
};

export type AddUserTypingInput = {
  threadId: Scalars['String'];
  userId: Scalars['String'];
};

export type AddVideoCategoryInput = {
  name: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
};

export type AddVideoInput = {
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  categoryId: Scalars['ID'];
  path: Scalars['String'];
  imgPreview?: Maybe<Scalars['String']>;
  isStream?: Maybe<Scalars['Boolean']>;
};

export type AdminUserTeamFilterInput = {
  userIds?: Maybe<Array<Scalars['ID']>>;
};

export type AllThreadFilter = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ApexLegendMatchHistory = {
  __typename?: 'ApexLegendMatchHistory';
  matches?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  metadata?: Maybe<Scalars['JSON']>;
  stats?: Maybe<Scalars['JSON']>;
  playlists?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

export type ApexLegendStat = {
  __typename?: 'ApexLegendStat';
  platformInfo?: Maybe<Scalars['JSON']>;
  userInfo?: Maybe<Scalars['JSON']>;
  metadata?: Maybe<Scalars['JSON']>;
  segments?: Maybe<Scalars['JSON']>;
  availableSegments?: Maybe<Scalars['JSON']>;
  expiryDate?: Maybe<Scalars['Date']>;
};

export type AssignUserToRoleInput = {
  userId: Scalars['String'];
  roleId: Scalars['String'];
};

export type Assistancepage = {
  __typename?: 'Assistancepage';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  title_en?: Maybe<Scalars['String']>;
  content_en?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  category_en?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type AssistancepageFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type Audiochatplateform = {
  __typename?: 'Audiochatplateform';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type AudiochatplateformFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type Badge = {
  __typename?: 'Badge';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type BadgeFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type BanUserInput = {
  id: Scalars['ID'];
  isBanned: Scalars['Boolean'];
};

export type CodTrackerDotGgStats = {
  __typename?: 'CODTrackerDotGGStats';
  /** View codtrackergg to view example of response */
  profilStatOverView?: Maybe<Scalars['JSON']>;
  killstreaks?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  weaponStats?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

export type CategoryProductFilterInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type CategoryVideosFilterInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type Challenge = {
  __typename?: 'Challenge';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  title_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ChallengeFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ChangePasswordInput = {
  userId: Scalars['ID'];
  password: Scalars['String'];
};

export type Chat = {
  __typename?: 'Chat';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<User>;
  chatengineId?: Maybe<Scalars['String']>;
  chatEngineAccessKey?: Maybe<Scalars['String']>;
  removed?: Maybe<Scalars['Boolean']>;
  members?: Maybe<Array<Maybe<Chatmember>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ChatFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type Chatmember = {
  __typename?: 'Chatmember';
  id: Scalars['ID'];
  chat?: Maybe<Chat>;
  user?: Maybe<User>;
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ChatmemberFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type CheckExpiredTokenInput = {
  code: Scalars['Int'];
  email: Scalars['String'];
};

export type CodLifeTimeStatistic = {
  __typename?: 'CodLifeTimeStatistic';
  title?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  maxLevel?: Maybe<Scalars['Int']>;
  levelXpRemainder?: Maybe<Scalars['Int']>;
  levelXpGained?: Maybe<Scalars['Int']>;
  prestige?: Maybe<Scalars['Int']>;
  prestigeId?: Maybe<Scalars['Int']>;
  maxPrestige?: Maybe<Scalars['Int']>;
  totalXp?: Maybe<Scalars['Int']>;
  paragonRank?: Maybe<Scalars['Int']>;
  paragonId?: Maybe<Scalars['Int']>;
  s?: Maybe<Scalars['Int']>;
  p?: Maybe<Scalars['Int']>;
  lifetime?: Maybe<Scalars['JSON']>;
  weekly?: Maybe<Scalars['JSON']>;
  engagement?: Maybe<Scalars['String']>;
};

/** Call of duty modern warefare weekly statistics */
export type CodMwWeeklyStatistics = {
  __typename?: 'CodMWWeeklyStatistics';
  mp?: Maybe<Scalars['JSON']>;
  wz?: Maybe<Scalars['JSON']>;
};

export type CodStat = {
  __typename?: 'CodStat';
  lifetimeMultiplayerStat?: Maybe<CodLifeTimeStatistic>;
  lifetimeWareZoneStat?: Maybe<CodLifeTimeStatistic>;
  recentWareZoneMatches?: Maybe<RecentCodMwMatchDetails>;
  recentMultiplayerMatches?: Maybe<RecentCodMwMatchDetails>;
  weeklyStatistics?: Maybe<CodMwWeeklyStatistics>;
};

export type CodWarezoneTrackerDotGgLeaderboardItem = {
  __typename?: 'CodWarezoneTrackerDotGGLeaderboardItem';
  id?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['JSON']>;
  value?: Maybe<Scalars['Float']>;
  displayValue?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['Int']>;
  percentile?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  author?: Maybe<User>;
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type CountryFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type CountsResult = {
  __typename?: 'CountsResult';
  nbVideo: Scalars['Int'];
  nbUsers: Scalars['Int'];
  nbBanned: Scalars['Int'];
  nbTeam: Scalars['Int'];
  lastVideoCreationDate?: Maybe<Scalars['DateTime']>;
  lastTeamCreationDate?: Maybe<Scalars['DateTime']>;
  lastUserCreationDate?: Maybe<Scalars['DateTime']>;
  userCreationPerMonths: Array<UserCreationPerMonths>;
  nbTournaments: Scalars['Int'];
  nbStartedTournaments: Scalars['Int'];
};

export type Coupon = {
  __typename?: 'Coupon';
  id: Scalars['ID'];
  code: Scalars['String'];
  totalDay: Scalars['Int'];
};

export type CreateGuildRoleInput = {
  guildId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['Int']>;
  hoist?: Maybe<Scalars['Boolean']>;
  unicode_emoji?: Maybe<Scalars['String']>;
  mentionable?: Maybe<Scalars['Boolean']>;
};

export type CreateMessageInput = {
  content: Scalars['String'];
  channelId: Scalars['String'];
  messageReferenceId?: Maybe<Scalars['String']>;
};

export type CreateMessageWithWebHookInput = {
  content: Scalars['String'];
  webhookId: Scalars['String'];
};

export type CreateVideoThumbnailInput = {
  videoId: Scalars['ID'];
  thumbnailUrl: Scalars['String'];
};

/**
 * type DiscordClientUser {
 *   id: String
 *   bot: Boolean
 *   system: Boolean
 *   username: String
 *   discriminator: String
 *   avatar: String
 *   banner: String
 *   accentColor: String
 *   verified: Boolean
 *   mfaEnabled: Boolean
 * }
 */
export type CreateWebhookInput = {
  name: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  channelId: Scalars['String'];
};

/** delete gain from himself */
export type DeleteGainInput = {
  id: Scalars['ID'];
};

export type DeleteMessageInput = {
  messageId: Scalars['String'];
};

/** delete Rule from himself */
export type DeleteRuleInput = {
  id: Scalars['ID'];
};

export type DeleteTeamInput = {
  id: Scalars['ID'];
};

export type DeleteThreadInput = {
  threadId: Scalars['String'];
};

export type DeleteTournamentInput = {
  id: Scalars['ID'];
};

export type DeleteVideoInput = {
  id: Scalars['ID'];
};

export type Deleted = {
  __typename?: 'Deleted';
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  n?: Maybe<Scalars['Int']>;
};

export type DeletedActuality = {
  __typename?: 'DeletedActuality';
  n?: Maybe<Scalars['Int']>;
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
};

export type DeletedAudiochatplateform = {
  __typename?: 'DeletedAudiochatplateform';
  n?: Maybe<Scalars['Int']>;
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
};

export type DeletedBadge = {
  __typename?: 'DeletedBadge';
  n?: Maybe<Scalars['Int']>;
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
};

export type DeletedFile = {
  __typename?: 'DeletedFile';
  n?: Maybe<Scalars['Int']>;
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
};

export type DeletedGame = {
  __typename?: 'DeletedGame';
  n?: Maybe<Scalars['Int']>;
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
};

export type DeletedLeague = {
  __typename?: 'DeletedLeague';
  n?: Maybe<Scalars['Int']>;
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
};

export type DeletedMap = {
  __typename?: 'DeletedMap';
  n?: Maybe<Scalars['Int']>;
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
};

export type DeletedPage = {
  __typename?: 'DeletedPage';
  n?: Maybe<Scalars['Int']>;
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
};

export type DeletedPageContent = {
  __typename?: 'DeletedPageContent';
  n?: Maybe<Scalars['Int']>;
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
};

export type DeletedTeam = {
  __typename?: 'DeletedTeam';
  n?: Maybe<Scalars['Int']>;
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
};

export type DeletedWager = {
  __typename?: 'DeletedWager';
  n?: Maybe<Scalars['Int']>;
  ok?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
};

export type DeletedWebhookInput = {
  id: Scalars['String'];
};

export type DirectChat = {
  __typename?: 'DirectChat';
  chatId?: Maybe<Scalars['String']>;
  accessKey?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type DiscordAuthorMessage = {
  __typename?: 'DiscordAuthorMessage';
  id?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  avatar_decoration?: Maybe<Scalars['String']>;
  discriminator?: Maybe<Scalars['String']>;
  public_flags?: Maybe<Scalars['Int']>;
  bot?: Maybe<Scalars['Boolean']>;
};

export type DiscordChannel = {
  __typename?: 'DiscordChannel';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rawPosition?: Maybe<Scalars['Int']>;
  lastMessageId?: Maybe<Scalars['String']>;
  rateLimitPerUser?: Maybe<Scalars['Int']>;
  nsfw?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['String']>;
  guildId?: Maybe<Scalars['String']>;
  messages?: Maybe<Array<Maybe<DiscordChannelMessage>>>;
  last_message_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  flags?: Maybe<Scalars['Int']>;
  parent_id?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  guild_id?: Maybe<Scalars['String']>;
  rate_limit_per_user?: Maybe<Scalars['Int']>;
};

export type DiscordChannelMessage = {
  __typename?: 'DiscordChannelMessage';
  id?: Maybe<Scalars['String']>;
  guildId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  createdTimestamp?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  system?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['String']>;
  author?: Maybe<DiscordAuthorMessage>;
  pinned?: Maybe<Scalars['Boolean']>;
  tts?: Maybe<Scalars['Boolean']>;
  guild_id?: Maybe<Scalars['String']>;
  channel_id?: Maybe<Scalars['String']>;
  mention_everyone?: Maybe<Scalars['Boolean']>;
  mentionEveryone?: Maybe<Scalars['Boolean']>;
  flags?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  edited_timestamp?: Maybe<Scalars['DateTime']>;
  embeds?: Maybe<Array<Maybe<Embed>>>;
  message_reference?: Maybe<Scalars['JSON']>;
  messageReference?: Maybe<DiscordMessageReference>;
  referenced_message?: Maybe<Scalars['JSON']>;
  referencedMessage?: Maybe<DiscordChannelMessage>;
};

export type DiscordMessageReference = {
  __typename?: 'DiscordMessageReference';
  channelId?: Maybe<Scalars['String']>;
  channel_id?: Maybe<Scalars['String']>;
  guildId?: Maybe<Scalars['String']>;
  guild_id?: Maybe<Scalars['String']>;
  message_id?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
};

export type DiscordWebhook = {
  __typename?: 'DiscordWebhook';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  guildId?: Maybe<Scalars['String']>;
  applicationId?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<DiscordAuthorMessage>;
};

export type EditCouponInput = {
  id: Scalars['ID'];
  totalDay: Scalars['Int'];
};

/** edit gain from himself */
export type EditGainInput = {
  id: Scalars['ID'];
  label: Scalars['String'];
  price: Scalars['Float'];
  unity?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type EditGameInput = {
  name: Scalars['String'];
  logo: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  jacketImages: Array<Scalars['String']>;
  popularity: Scalars['Int'];
  note: Scalars['Int'];
  platforms: Array<Scalars['ID']>;
};

export type EditMessageInput = {
  messageId: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type EditPasswordInput = {
  newPassword: Scalars['String'];
};

export type EditProductCategoryInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
};

export type EditProductInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  categoryId: Scalars['ID'];
  price: Scalars['Float'];
};

export type EditProfilInput = {
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  password?: Maybe<EditPasswordInput>;
  birthDate?: Maybe<Scalars['Date']>;
  avatar?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  devise?: Maybe<Scalars['String']>;
  receiveCommunication?: Maybe<Scalars['Boolean']>;
};

/** edit Rule from himself */
export type EditRuleInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
};

export type EditSubscriptionInput = {
  id: Scalars['ID'];
  title: Scalars['String'];
  totalMonth: Scalars['Int'];
  pricePerMonth: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
};

export type EditTeamInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  members: Array<Scalars['ID']>;
};

export type EditThreadInput = {
  threadId: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  member?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type EditTournamentFormatInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type EditTournamentInput = {
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  formatId: Scalars['ID'];
  maxParticipants?: Maybe<Scalars['Int']>;
};

export type EditTrainingInput = {
  id: Scalars['ID'];
  trainingGameName: Scalars['String'];
  logo: Scalars['String'];
  jacketImage: Scalars['String'];
};

export type EditVideoCategoryInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
};

export type EditVideoInput = {
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  categoryId: Scalars['ID'];
  imgPreview?: Maybe<Scalars['String']>;
};

export type Embed = {
  __typename?: 'Embed';
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  provider?: Maybe<EmbedProvider>;
  thumbnail?: Maybe<EmbedThumbnail>;
  video?: Maybe<EmbedVideo>;
};

export type EmbedProvider = {
  __typename?: 'EmbedProvider';
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type EmbedThumbnail = {
  __typename?: 'EmbedThumbnail';
  url?: Maybe<Scalars['String']>;
  proxy_url?: Maybe<Scalars['String']>;
  proxyUrl?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type EmbedVideo = {
  __typename?: 'EmbedVideo';
  url?: Maybe<Scalars['String']>;
  proxy_url?: Maybe<Scalars['String']>;
  proxyUrl?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type Fortine_Plateform = 'pc' | 'console' | 'mobile';

export type File = {
  __typename?: 'File';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type FileFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type Filter = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type FilterTournamentMessageInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type FindDiscordMessageInput = {
  id: Scalars['String'];
  channelId: Scalars['String'];
  params?: Maybe<Scalars['JSON']>;
};

export type FindGuildMemberInput = {
  guildId: Scalars['String'];
};

export type FindGuildRoleInput = {
  guildId?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['String']>;
};

export type FindPinnedMessageInput = {
  channelId: Scalars['String'];
};

export type FindWebhookInput = {
  channelId: Scalars['String'];
  userId: Scalars['String'];
};

export type FindWebhookUserInput = {
  userId: Scalars['String'];
};

export type FinishMatchInput = {
  matchId: Scalars['ID'];
  winner: MatchWinnerValue;
};

export type FinishMatchReturn = {
  __typename?: 'FinishMatchReturn';
  match: Match;
  nextMatch?: Maybe<Match>;
  thirdOperatedMatch?: Maybe<Match>;
};

export type FinishVideoStreamInput = {
  videoId: Scalars['ID'];
};

export type ForgotPasswordInput = {
  email: Scalars['String'];
};

export type ForniteBrMatchItem = {
  __typename?: 'ForniteBRMatchItem';
  platform?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  mode?: Maybe<Scalars['String']>;
  readable_name?: Maybe<Scalars['String']>;
  kills?: Maybe<Scalars['Int']>;
  matchesplayed?: Maybe<Scalars['Int']>;
  minutesplayed?: Maybe<Scalars['Int']>;
  playersoutlived?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  placetop1?: Maybe<Scalars['Int']>;
  placetop3?: Maybe<Scalars['Int']>;
  placetop5?: Maybe<Scalars['Int']>;
  placetop6?: Maybe<Scalars['Int']>;
  placetop10?: Maybe<Scalars['Int']>;
  placetop12?: Maybe<Scalars['Int']>;
  placetop25?: Maybe<Scalars['Int']>;
};

/** https://dash.fortnite-api.com/ */
export type FortniteBrPlayerStat = {
  __typename?: 'FortniteBRPlayerStat';
  account?: Maybe<Scalars['JSON']>;
  battlePass?: Maybe<Scalars['JSON']>;
  image?: Maybe<Scalars['JSON']>;
  stats?: Maybe<Scalars['JSON']>;
};

export type FortnitePlayerRecentMatches = {
  __typename?: 'FortnitePlayerRecentMatches';
  account?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  max_results?: Maybe<Scalars['String']>;
  matches?: Maybe<Array<Maybe<ForniteBrMatchItem>>>;
};

export type FutPlayerInfo = {
  __typename?: 'FutPlayerInfo';
  commonName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  league?: Maybe<Scalars['JSON']>;
  nation?: Maybe<Scalars['JSON']>;
  club?: Maybe<Scalars['JSON']>;
  headshot?: Maybe<Scalars['JSON']>;
  position?: Maybe<Scalars['String']>;
  composure?: Maybe<Scalars['Int']>;
  playStyle?: Maybe<Scalars['String']>;
  playStyleId?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
  birthdate?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['Int']>;
  acceleration?: Maybe<Scalars['Int']>;
  aggression?: Maybe<Scalars['Int']>;
  agility?: Maybe<Scalars['Int']>;
  balance?: Maybe<Scalars['Int']>;
  ballcontrol?: Maybe<Scalars['Int']>;
  foot?: Maybe<Scalars['String']>;
  skillMoves?: Maybe<Scalars['Int']>;
  crossing?: Maybe<Scalars['Int']>;
  curve?: Maybe<Scalars['Int']>;
  dribbling?: Maybe<Scalars['Int']>;
  finishing?: Maybe<Scalars['Int']>;
  freekickaccuracy?: Maybe<Scalars['Int']>;
  gkdiving?: Maybe<Scalars['Int']>;
  gkhandling?: Maybe<Scalars['Int']>;
  gkkicking?: Maybe<Scalars['Int']>;
  gkpositioning?: Maybe<Scalars['Int']>;
  gkreflexes?: Maybe<Scalars['Int']>;
  headingaccuracy?: Maybe<Scalars['Int']>;
  interceptions?: Maybe<Scalars['Int']>;
  jumping?: Maybe<Scalars['Int']>;
  longpassing?: Maybe<Scalars['Int']>;
  longshots?: Maybe<Scalars['Int']>;
  marking?: Maybe<Scalars['Int']>;
  penalties?: Maybe<Scalars['Int']>;
  positioning?: Maybe<Scalars['Int']>;
  potential?: Maybe<Scalars['Int']>;
  reactions?: Maybe<Scalars['Int']>;
  shortpassing?: Maybe<Scalars['Int']>;
  shotpower?: Maybe<Scalars['Int']>;
  slidingtackle?: Maybe<Scalars['Int']>;
  sprintspeed?: Maybe<Scalars['Int']>;
  standingtackle?: Maybe<Scalars['Int']>;
  stamina?: Maybe<Scalars['Int']>;
  strength?: Maybe<Scalars['Int']>;
  vision?: Maybe<Scalars['Int']>;
  volleys?: Maybe<Scalars['Int']>;
  weakFoot?: Maybe<Scalars['Int']>;
};

export type Game_Mode_Type = 'TEAM' | 'SINGLEPLAYER';

export type Game_Recent_Result = 'WIN' | 'LOSE';

/** add gain from add tournament */
export type GainInput = {
  label: Scalars['String'];
  price: Scalars['Float'];
  unity?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type Game = {
  __typename?: 'Game';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jacketImages?: Maybe<Array<Scalars['String']>>;
  popularity?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  author?: Maybe<User>;
  authorId?: Maybe<Scalars['ID']>;
  platforms?: Maybe<Array<Maybe<GamePlatform>>>;
  slug?: Maybe<Scalars['String']>;
  mainJacket?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  twitchGameName?: Maybe<Scalars['String']>;
  twitchGameId?: Maybe<Scalars['String']>;
  /** platforms: [GamePlatform!] */
  plateformIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  releasedDate?: Maybe<Scalars['DateTime']>;
  isInCurrentUserFavorite?: Maybe<Scalars['Boolean']>;
  discordChannelId?: Maybe<Scalars['String']>;
  landingPageJacket?: Maybe<Scalars['String']>;
  landingPageLogo?: Maybe<Scalars['String']>;
  tournaments?: Maybe<Array<Maybe<Tournament>>>;
  gameAccountIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  leagues?: Maybe<Array<Maybe<League>>>;
  wagers?: Maybe<Array<Maybe<Wager>>>;
};

export type GameAccount = {
  __typename?: 'GameAccount';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  games?: Maybe<Array<Maybe<Game>>>;
  gameIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GameAccountFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type GameFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type GamePartyInvitation = {
  __typename?: 'GamePartyInvitation';
  user?: Maybe<User>;
  gameParty?: Maybe<Gameparty>;
};

export type GamePlatform = {
  __typename?: 'GamePlatform';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['ID']>;
  author?: Maybe<User>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tournaments?: Maybe<Array<Maybe<Tournament>>>;
  leagues?: Maybe<Array<Maybe<League>>>;
  wagers?: Maybe<Array<Maybe<Wager>>>;
};

export type GamePlatformFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type Gameformat = {
  __typename?: 'Gameformat';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['ID']>;
  author?: Maybe<User>;
  /** Unique identifiant */
  label?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type GameformatFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type Gamemode = {
  __typename?: 'Gamemode';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  teamSize?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  title_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
  type?: Maybe<Game_Mode_Type>;
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type GamemodeFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type Gameparty = {
  __typename?: 'Gameparty';
  id: Scalars['ID'];
  chat?: Maybe<Chat>;
  name?: Maybe<Scalars['String']>;
  removed?: Maybe<Scalars['Boolean']>;
  members?: Maybe<Array<Maybe<Gamepartymember>>>;
  memberIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type GamepartyFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type Gamepartymember = {
  __typename?: 'Gamepartymember';
  id: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type GamepartymemberFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type GamesFilterInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<GamesFilterSort>;
};

export type GamesFilterSort = {
  popularity?: Maybe<SortEnum>;
  name?: Maybe<SortEnum>;
};

export type GamesResult = {
  __typename?: 'GamesResult';
  results: Array<Game>;
  total: Scalars['Int'];
};

export type Gameserver = {
  __typename?: 'Gameserver';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  countryId?: Maybe<Scalars['ID']>;
  country?: Maybe<Country>;
};

export type GameserverFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type GetPrivateThreadUserInput = {
  userId: Scalars['ID'];
};

export type GuildRole = {
  __typename?: 'GuildRole';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  color?: Maybe<Scalars['Int']>;
  hoist?: Maybe<Scalars['Boolean']>;
  managed?: Maybe<Scalars['Boolean']>;
  mentionable?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Scalars['String']>;
  unicode_emoji?: Maybe<Scalars['String']>;
  flags?: Maybe<Scalars['Int']>;
  permissions_new?: Maybe<Scalars['String']>;
  tags?: Maybe<RoleTags>;
};

export type InvitationGamepartyPayload = {
  __typename?: 'InvitationGamepartyPayload';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type Leaderboard_Type = 'WEEKLY' | 'SAISON' | 'GENERAL';

export type League_Format = 'oneVsOne' | 'teamVsTeam';

export type Leaderboardpoint = {
  __typename?: 'Leaderboardpoint';
  id: Scalars['ID'];
  point?: Maybe<Scalars['Int']>;
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type LeaderboardpointFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type League = {
  __typename?: 'League';
  id: Scalars['ID'];
  participants?: Maybe<Array<Maybe<TournamentParticipant>>>;
  isMeAlreadyParticipant?: Maybe<Scalars['Boolean']>;
  participantIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  matcheIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  matches?: Maybe<Array<Maybe<TournamentBracket>>>;
  title_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  maxParticipants?: Maybe<Scalars['Int']>;
  minParticipants?: Maybe<Scalars['Int']>;
  format?: Maybe<League_Format>;
  /** Game id */
  gameId?: Maybe<Scalars['String']>;
  /** Game plateform id */
  gamePlateformId?: Maybe<Scalars['String']>;
  game?: Maybe<Game>;
  gamePlateform?: Maybe<GamePlatform>;
  roundNumber?: Maybe<Scalars['Int']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organiserId?: Maybe<Scalars['ID']>;
  awards?: Maybe<Array<Maybe<LeagueAward>>>;
  awardIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  rules?: Maybe<Array<Maybe<LeagueRule>>>;
  rulesId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  organiser?: Maybe<Organiser>;
  winnerMatchPoint?: Maybe<Scalars['Int']>;
  looserMatchPoint?: Maybe<Scalars['Int']>;
  drawMatchPoint?: Maybe<Scalars['Int']>;
  entryCost?: Maybe<Scalars['Float']>;
};

export type LeagueAward = {
  __typename?: 'LeagueAward';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  title_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
  startRank?: Maybe<Scalars['Int']>;
  endRank?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type LeagueAwardFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type LeagueAwardInput = {
  title?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
  startRank?: Maybe<Scalars['Int']>;
  endRank?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  sponsorName?: Maybe<Scalars['String']>;
  sponsorImage?: Maybe<Scalars['String']>;
  title_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
};

export type LeagueFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type LeaguePoint = {
  __typename?: 'LeaguePoint';
  id: Scalars['ID'];
  user?: Maybe<User>;
  team?: Maybe<Team>;
  game: Game;
  platform: GamePlatform;
  rankName: RankNameValue;
  rankPosition: RankPositionValue;
  point: Scalars['Int'];
};

export type LeaguePointInput = {
  userId?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  gameId: Scalars['String'];
  gamePlatformId: Scalars['String'];
};

export type LeagueRule = {
  __typename?: 'LeagueRule';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type LeagueRuleFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type LeagueRuleInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  name_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
};

export type LikePostInput = {
  id: Scalars['ID'];
  isLike: Scalars['Boolean'];
};

export type ListMetadata = {
  __typename?: 'ListMetadata';
  count: Scalars['Int'];
};

export type ListTeam = {
  __typename?: 'ListTeam';
  results: Array<Team>;
  isTeam: Scalars['Boolean'];
};

export type ListUser = {
  __typename?: 'ListUser';
  results: Array<User>;
  isTeam: Scalars['Boolean'];
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  isAdmin?: Maybe<Scalars['Boolean']>;
};

export type LoginOauth2Input = {
  email: Scalars['String'];
};

export type LoginSocial = {
  __typename?: 'LoginSocial';
  token: Scalars['String'];
  authName: Scalars['String'];
  userName: Scalars['String'];
};

export type LoginSocialInput = {
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  uid: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  socialApp?: Maybe<Social_App>;
  /** Auth provider uid */
  providerUid?: Maybe<Scalars['String']>;
};

export type MakePaymentPayload = {
  __typename?: 'MakePaymentPayload';
  clientSecret?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  paymentMethod?: Maybe<Payment_Method>;
};

export type MakeWalletPaymentPayload = {
  __typename?: 'MakeWalletPaymentPayload';
  clientSecret?: Maybe<Scalars['String']>;
  order?: Maybe<WalletOrder>;
};

export type Map = {
  __typename?: 'Map';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type MapFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type Match = {
  __typename?: 'Match';
  id: Scalars['ID'];
  tournamentId: Scalars['ID'];
  opponents?: Maybe<MatchOpponent>;
  isLive?: Maybe<Scalars['Boolean']>;
  roundNumber: Scalars['Int'];
  matchNumber: Scalars['Int'];
  dateBegin?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  result?: Maybe<MatchResult>;
  parents?: Maybe<MatchParent>;
  isFinished?: Maybe<Scalars['Boolean']>;
  files: Array<Maybe<MatchFile>>;
};

export type MatchFile = {
  __typename?: 'MatchFile';
  id: Scalars['ID'];
  url: Scalars['String'];
  size: Scalars['Float'];
  name: Scalars['String'];
  matchId: Scalars['ID'];
  author: User;
  authorId: Scalars['ID'];
};

export type MatchOpponent = MatchUserOpponent | MatchTeamOpponent;

export type MatchParent = {
  __typename?: 'MatchParent';
  parentAId?: Maybe<Scalars['ID']>;
  parentA?: Maybe<Match>;
  parentBId?: Maybe<Scalars['ID']>;
  parentB?: Maybe<Match>;
};

export type MatchResult = {
  __typename?: 'MatchResult';
  pointA?: Maybe<Scalars['String']>;
  pointB?: Maybe<Scalars['String']>;
  winner?: Maybe<MatchWinnerValue>;
};

export type MatchTeamOpponent = {
  __typename?: 'MatchTeamOpponent';
  isTeam: Scalars['Boolean'];
  teamA?: Maybe<Team>;
  teamB?: Maybe<Team>;
};

export type MatchUserOpponent = {
  __typename?: 'MatchUserOpponent';
  isTeam: Scalars['Boolean'];
  userA?: Maybe<User>;
  userB?: Maybe<User>;
};

export type MatchWinnerValue = 'A' | 'B';

export type Message = {
  __typename?: 'Message';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  author: User;
  authorId: Scalars['String'];
  threadId: Scalars['String'];
  isRemoved?: Maybe<Scalars['Boolean']>;
  isMine?: Maybe<Scalars['Boolean']>;
};

export type MessageFile = {
  __typename?: 'MessageFile';
  id: Scalars['ID'];
  url: Scalars['String'];
  size: Scalars['Float'];
  name: Scalars['String'];
};

export type MessageText = {
  __typename?: 'MessageText';
  text: Scalars['String'];
};

export type MessageType = 'file' | 'text';

export type Mutation = {
  __typename?: 'Mutation';
  /** User */
  signup: Scalars['String'];
  login: Scalars['String'];
  checkUserUidExists: Scalars['Boolean'];
  editProfil: User;
  /** Team */
  addTeam?: Maybe<Team>;
  deleteTeam?: Maybe<Team>;
  editTeam: Team;
  removeUserInTeam: Team;
  addUserInTeam: Team;
  addPost?: Maybe<Post>;
  likePost?: Maybe<Post>;
  banUser: User;
  addGame?: Maybe<Game>;
  editGame?: Maybe<Game>;
  removeGame?: Maybe<Game>;
  addGamePlatform?: Maybe<GamePlatform>;
  /** Video */
  addVideo?: Maybe<Video>;
  editVideo?: Maybe<Video>;
  deleteVideo?: Maybe<Video>;
  addVideoCategory?: Maybe<VideoCategory>;
  editVideoCategory?: Maybe<VideoCategory>;
  finishVideoStream?: Maybe<Video>;
  startVideoStream?: Maybe<Video>;
  createVideoThumbnail?: Maybe<Video>;
  /** Training */
  addTraining?: Maybe<Training>;
  editTraining?: Maybe<Training>;
  deleteTraining?: Maybe<Scalars['ID']>;
  addTrainingProgram?: Maybe<TrainingProgram>;
  addTrainingDetail?: Maybe<TrainingDetail>;
  addProductCategory?: Maybe<ProductCategory>;
  editProductCategory?: Maybe<ProductCategory>;
  addProduct?: Maybe<Product>;
  editProduct?: Maybe<Product>;
  /** Tournament */
  addTournament?: Maybe<Tournament>;
  editTournament?: Maybe<Tournament>;
  /** deleteTournament(input: DeleteTournamentInput!): Tournament */
  userSubscriptionToTournament?: Maybe<UserSubscriptionToTournamentReturn>;
  teamSubscriptionToTournament?: Maybe<TeamSubscriptionToTournamentReturn>;
  startTournament?: Maybe<Tournament>;
  reorganizeUserInTournament: Array<Match>;
  inviteUserSubscriptionToTournament?: Maybe<UserSubscriptionToTournamentReturn>;
  /** Match */
  setMatchPoint?: Maybe<Match>;
  finishMatch?: Maybe<FinishMatchReturn>;
  addMatchFile?: Maybe<Match>;
  removeMatchFile?: Maybe<Match>;
  /** Gain */
  addTournamentGain?: Maybe<Tournament>;
  editTournamentGain?: Maybe<TournamentGain>;
  deleteTournamentGain?: Maybe<TournamentGain>;
  /** Rule */
  addTournamentRule?: Maybe<Tournament>;
  editTournamentRule?: Maybe<TournamentRule>;
  deleteTournamentRule?: Maybe<TournamentRule>;
  /** Tournament chat */
  addTournamentMessage?: Maybe<TournamentMessage>;
  removeTournamentMessage?: Maybe<TournamentMessage>;
  /** Subscription */
  subscribeToPremium?: Maybe<User>;
  activeCoupon?: Maybe<User>;
  /** Admin */
  addSubscription: SubscriptionType;
  removeSubscription: SubscriptionType;
  editSubscription: SubscriptionType;
  addCoupon: Coupon;
  removeCoupon: Coupon;
  editCoupon: Coupon;
  /** Report user */
  reportUserAction?: Maybe<Report>;
  /** Chat */
  addMessage: Message;
  editMessage: Message;
  deleteMessage: Message;
  addThread: Thread;
  editThread: Thread;
  deleteThread: Thread;
  addUserThread: Thread;
  addUserTyping: User;
  removeUserTyping: User;
  updateUser?: Maybe<User>;
  addUserToMyfriends?: Maybe<AddUserToMyfriendsPayload>;
  forgotPassword: Scalars['Boolean'];
  loginOauth2: Scalars['Boolean'];
  checkExpiredToken: Scalars['ID'];
  changePassword: Scalars['Boolean'];
  deleteUser?: Maybe<User>;
  authorizeTwichUser?: Maybe<User>;
  loginSocial: LoginSocial;
  loginSocialWithoutUpdatingUserInfo: LoginSocial;
  /** Check if user has already authenticate with the social media */
  checkSocialAuth?: Maybe<Scalars['String']>;
  loginTwitch: Scalars['String'];
  loginDiscord?: Maybe<Scalars['String']>;
  loginTwitter?: Maybe<Scalars['String']>;
  linkDiscord?: Maybe<Scalars['String']>;
  loginXbox?: Maybe<Scalars['String']>;
  linkMicrosoft?: Maybe<Scalars['String']>;
  loginBattlenet: Scalars['String'];
  checkIfEmailIsAlreadyTaken: Scalars['Boolean'];
  /** social app */
  checkIfUserMatchInSocialApp: Scalars['Boolean'];
  sendInvitationMobile: Scalars['Boolean'];
  checkIfUsernameIsAlreadyTaken: Scalars['Boolean'];
  sendWelcomeEmail: Scalars['Boolean'];
  /** oauth2 */
  enableoAuth2: User;
  disableoAuth2: User;
  checkIfEmailAndPasswordMatched: Scalars['Boolean'];
  checkTokenOauth2: Scalars['Boolean'];
  createAuthToken: Scalars['String'];
  getAccessToken?: Maybe<TwitterOauthAccessToken>;
  createGamePlatform?: Maybe<GamePlatform>;
  deleteGamePlatform?: Maybe<GamePlatform>;
  updateGamePlatform?: Maybe<GamePlatform>;
  updateUserMainGamePlateform?: Maybe<User>;
  createGame?: Maybe<Game>;
  deleteGame?: Maybe<DeletedGame>;
  updateGame?: Maybe<Game>;
  addGameToMyFavorite?: Maybe<Game>;
  removeGameFromMyFavorite?: Maybe<Game>;
  createTournament?: Maybe<Tournament>;
  deleteTournament?: Maybe<Deleted>;
  updateTournament: Tournament;
  /** Tournament round */
  deleteTournamentround?: Maybe<Tournamentround>;
  updateTournamentround?: Maybe<Tournamentround>;
  createTournamentranking?: Maybe<Tournamentranking>;
  updateTournamentranking?: Maybe<Tournamentranking>;
  deleteTournamentranking?: Maybe<Tournamentranking>;
  createTournamentFormat?: Maybe<TournamentFormat>;
  deleteTournamentFormat?: Maybe<TournamentFormat>;
  updateTournamentFormat?: Maybe<TournamentFormat>;
  createTournamentAward?: Maybe<TournamentAward>;
  deleteTournamentAward?: Maybe<TournamentAward>;
  updateTournamentAward?: Maybe<TournamentAward>;
  createTournamentRule?: Maybe<TournamentRule>;
  updateTournamentRule?: Maybe<TournamentRule>;
  createTournamentParticipant?: Maybe<TournamentParticipant>;
  deleteTournamentParticipant?: Maybe<TournamentParticipant>;
  updateTournamentParticipant?: Maybe<TournamentParticipant>;
  joinUserToTournament?: Maybe<TournamentParticipant>;
  joinTeamToTournament?: Maybe<TournamentParticipant>;
  joinMeToWager?: Maybe<TournamentParticipant>;
  joinTeamToWager?: Maybe<TournamentParticipant>;
  joinMeToLeague?: Maybe<TournamentParticipant>;
  joinTeamToLeague?: Maybe<TournamentParticipant>;
  createTeam?: Maybe<Team>;
  updateTeam?: Maybe<Team>;
  createTournamentBracket?: Maybe<TournamentBracket>;
  deleteTournamentBracket?: Maybe<TournamentBracket>;
  updateTournamentBracket?: Maybe<TournamentBracket>;
  startTournamentBracket?: Maybe<TournamentBracket>;
  createGameAccount?: Maybe<GameAccount>;
  deleteGameAccount?: Maybe<GameAccount>;
  updateGameAccount?: Maybe<GameAccount>;
  linkAGameAccount?: Maybe<UserGameAccount>;
  checkGameAccountCredential?: Maybe<Scalars['Boolean']>;
  createOrganiser?: Maybe<Organiser>;
  deleteOrganiser?: Maybe<Organiser>;
  updateOrganiser?: Maybe<Organiser>;
  inviteUserToTeam?: Maybe<TeamMember>;
  deleteTeamMember?: Maybe<DeletedTeam>;
  updateTeamMember?: Maybe<TeamMember>;
  createFile?: Maybe<File>;
  deleteFile?: Maybe<DeletedFile>;
  updateFile?: Maybe<File>;
  createPage?: Maybe<Page>;
  deletePage?: Maybe<DeletedPage>;
  updatePage?: Maybe<Page>;
  deletePageContent?: Maybe<DeletedPageContent>;
  updatePageContent?: Maybe<PageContent>;
  createLeague?: Maybe<League>;
  deleteLeague?: Maybe<DeletedLeague>;
  updateLeague?: Maybe<League>;
  createLeagueRule?: Maybe<LeagueRule>;
  deleteLeagueRule?: Maybe<LeagueRule>;
  updateLeagueRule?: Maybe<LeagueRule>;
  createLeagueAward?: Maybe<LeagueAward>;
  deleteLeagueAward?: Maybe<LeagueAward>;
  updateLeagueAward?: Maybe<LeagueAward>;
  createActuality?: Maybe<Actuality>;
  deleteActuality?: Maybe<DeletedActuality>;
  updateActuality?: Maybe<Actuality>;
  createWager?: Maybe<Wager>;
  deleteWager?: Maybe<DeletedWager>;
  updateWager?: Maybe<Wager>;
  startWager?: Maybe<Wager>;
  createNotification?: Maybe<Notification>;
  deleteNotification?: Maybe<Notification>;
  createMap?: Maybe<Map>;
  deleteMap?: Maybe<DeletedMap>;
  updateMap?: Maybe<Map>;
  createGameserver?: Maybe<Gameserver>;
  deleteGameserver?: Maybe<Gameserver>;
  updateGameserver?: Maybe<Gameserver>;
  createRegion?: Maybe<Region>;
  deleteRegion?: Maybe<Region>;
  updateRegion?: Maybe<Region>;
  createAudiochatplateform?: Maybe<Audiochatplateform>;
  deleteAudiochatplateform?: Maybe<DeletedAudiochatplateform>;
  updateAudiochatplateform?: Maybe<Audiochatplateform>;
  createGamemode?: Maybe<Gamemode>;
  deleteGamemode?: Maybe<Gamemode>;
  updateGamemode?: Maybe<Gamemode>;
  createGameformat?: Maybe<Gameformat>;
  deleteGameformat?: Maybe<Gameformat>;
  updateGameformat?: Maybe<Gameformat>;
  createCountry?: Maybe<Country>;
  deleteCountry?: Maybe<Country>;
  updateCountry?: Maybe<Country>;
  addAmountToMyWallet?: Maybe<Wallet>;
  createWallet?: Maybe<Wallet>;
  deleteWallet?: Maybe<Wallet>;
  updateWallet?: Maybe<Wallet>;
  createWallettransaction?: Maybe<Wallettransaction>;
  deleteWallettransaction?: Maybe<Wallettransaction>;
  updateWallettransaction?: Maybe<Wallettransaction>;
  createWalletOrder?: Maybe<WalletOrder>;
  deleteWalletOrder?: Maybe<WalletOrder>;
  updateWalletOrder?: Maybe<WalletOrder>;
  makeWalletPayment?: Maybe<MakeWalletPaymentPayload>;
  confirmWalletOrderPayment?: Maybe<WalletOrder>;
  createOrder?: Maybe<Order>;
  deleteOrder?: Maybe<Order>;
  updateOrder?: Maybe<Order>;
  makePayment?: Maybe<MakePaymentPayload>;
  confirmOrderPayment?: Maybe<Order>;
  createTournamentWinner?: Maybe<TournamentWinner>;
  deleteTournamentWinner?: Maybe<TournamentWinner>;
  updateTournamentWinner?: Maybe<TournamentWinner>;
  createChallenge?: Maybe<Challenge>;
  deleteChallenge?: Maybe<Challenge>;
  updateChallenge?: Maybe<Challenge>;
  createChat?: Maybe<Chat>;
  deleteChat?: Maybe<Chat>;
  updateChat?: Maybe<Chat>;
  createChatmember?: Maybe<Chatmember>;
  deleteChatmember?: Maybe<Chatmember>;
  updateChatmember?: Maybe<Chatmember>;
  createLeaderboardpoint?: Maybe<Leaderboardpoint>;
  deleteLeaderboardpoint?: Maybe<Leaderboardpoint>;
  updateLeaderboardpoint?: Maybe<Leaderboardpoint>;
  createBadge?: Maybe<Badge>;
  deleteBadge?: Maybe<DeletedBadge>;
  updateBadge?: Maybe<Badge>;
  createGameparty?: Maybe<Gameparty>;
  deleteGameparty?: Maybe<Gameparty>;
  updateGameparty?: Maybe<Gameparty>;
  createGamepartymember?: Maybe<Gamepartymember>;
  deleteGamepartymember?: Maybe<Gamepartymember>;
  updateGamepartymember?: Maybe<Gamepartymember>;
  inviteUserToGameParty?: Maybe<InvitationGamepartyPayload>;
  createSocialaccount?: Maybe<Socialaccount>;
  deleteSocialaccount?: Maybe<Socialaccount>;
  updateSocialaccount?: Maybe<Socialaccount>;
  createUsersocialaccount?: Maybe<Usersocialaccount>;
  deleteUsersocialaccount?: Maybe<Usersocialaccount>;
  updateUsersocialaccount?: Maybe<Usersocialaccount>;
  createSaison?: Maybe<Saison>;
  deleteSaison?: Maybe<Saison>;
  updateSaison?: Maybe<Saison>;
  createTextContent?: Maybe<TextContent>;
  updateTextContent?: Maybe<TextContent>;
  deleteTextContent?: Maybe<TextContent>;
  createAssistancepage?: Maybe<Assistancepage>;
  deleteAssistancepage?: Maybe<Assistancepage>;
  updateAssistancepage?: Maybe<Assistancepage>;
  sendMessageChannelDiscord?: Maybe<DiscordChannelMessage>;
  updateMessageChannelDiscord?: Maybe<DiscordChannelMessage>;
  createMessage?: Maybe<DiscordChannelMessage>;
  createMessageWithWebHook?: Maybe<DiscordChannelMessage>;
  createWehbook?: Maybe<DiscordWebhook>;
  checkWebhookUserInChannel?: Maybe<Scalars['Boolean']>;
  createGuildRole?: Maybe<GuildRole>;
  updatePositionGuildRole?: Maybe<GuildRole>;
  deleteWebhook?: Maybe<DiscordWebhook>;
  createPrivateChannel?: Maybe<DiscordChannel>;
  assignUserToRole: Scalars['Boolean'];
};

export type MutationSignupArgs = {
  input: SignupInput;
};

export type MutationLoginArgs = {
  input: LoginInput;
};

export type MutationCheckUserUidExistsArgs = {
  uid: Scalars['String'];
};

export type MutationEditProfilArgs = {
  input: EditProfilInput;
};

export type MutationAddTeamArgs = {
  input: AddTeamInput;
};

export type MutationDeleteTeamArgs = {
  id: Scalars['ID'];
};

export type MutationEditTeamArgs = {
  input: EditTeamInput;
};

export type MutationRemoveUserInTeamArgs = {
  input: RemoveUserInTeamInput;
};

export type MutationAddUserInTeamArgs = {
  input: AddUserInTeamInput;
};

export type MutationAddPostArgs = {
  input: AddPostInput;
};

export type MutationLikePostArgs = {
  input: LikePostInput;
};

export type MutationBanUserArgs = {
  input: BanUserInput;
};

export type MutationAddGameArgs = {
  input: AddGameInput;
};

export type MutationEditGameArgs = {
  input: EditGameInput;
};

export type MutationRemoveGameArgs = {
  input: RemoveGameInput;
};

export type MutationAddGamePlatformArgs = {
  input: AddGamePlatformInput;
};

export type MutationAddVideoArgs = {
  input: AddVideoInput;
};

export type MutationEditVideoArgs = {
  input: EditVideoInput;
};

export type MutationDeleteVideoArgs = {
  input: DeleteVideoInput;
};

export type MutationAddVideoCategoryArgs = {
  input: AddVideoCategoryInput;
};

export type MutationEditVideoCategoryArgs = {
  input: EditVideoCategoryInput;
};

export type MutationFinishVideoStreamArgs = {
  input: FinishVideoStreamInput;
};

export type MutationStartVideoStreamArgs = {
  input: StartVideoStreamInput;
};

export type MutationCreateVideoThumbnailArgs = {
  input: CreateVideoThumbnailInput;
};

export type MutationAddTrainingArgs = {
  input: AddTrainingInput;
};

export type MutationEditTrainingArgs = {
  input: EditTrainingInput;
};

export type MutationDeleteTrainingArgs = {
  id: Scalars['ID'];
};

export type MutationAddTrainingProgramArgs = {
  input: AddTrainingProgramInput;
};

export type MutationAddTrainingDetailArgs = {
  input: AddTrainingDetailInput;
};

export type MutationAddProductCategoryArgs = {
  input: AddProductCategoryInput;
};

export type MutationEditProductCategoryArgs = {
  input: EditProductCategoryInput;
};

export type MutationAddProductArgs = {
  input: AddProductInput;
};

export type MutationEditProductArgs = {
  input: EditProductInput;
};

export type MutationAddTournamentArgs = {
  input: AddTournamentInput;
};

export type MutationEditTournamentArgs = {
  input: EditTournamentInput;
};

export type MutationUserSubscriptionToTournamentArgs = {
  tournamentId: Scalars['ID'];
};

export type MutationTeamSubscriptionToTournamentArgs = {
  input: TeamSubscriptionToTournamentInput;
};

export type MutationStartTournamentArgs = {
  tournamentId: Scalars['ID'];
};

export type MutationReorganizeUserInTournamentArgs = {
  input: ReorganizeUserInTournamentInput;
};

export type MutationInviteUserSubscriptionToTournamentArgs = {
  tournamentId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationSetMatchPointArgs = {
  input: SetMatchPointInput;
};

export type MutationFinishMatchArgs = {
  input: FinishMatchInput;
};

export type MutationAddMatchFileArgs = {
  input: AddMatchFileInput;
};

export type MutationRemoveMatchFileArgs = {
  id: Scalars['ID'];
};

export type MutationAddTournamentGainArgs = {
  input: AddGainInput;
};

export type MutationEditTournamentGainArgs = {
  input: EditGainInput;
};

export type MutationDeleteTournamentGainArgs = {
  input: DeleteGainInput;
};

export type MutationAddTournamentRuleArgs = {
  input: AddRuleInput;
};

export type MutationEditTournamentRuleArgs = {
  input: EditRuleInput;
};

export type MutationDeleteTournamentRuleArgs = {
  id: Scalars['ID'];
};

export type MutationAddTournamentMessageArgs = {
  input: AddTournamentMessageInput;
};

export type MutationRemoveTournamentMessageArgs = {
  id: Scalars['ID'];
};

export type MutationSubscribeToPremiumArgs = {
  input: SubscribeToPremiumInput;
};

export type MutationActiveCouponArgs = {
  input: ActiveCouponInput;
};

export type MutationAddSubscriptionArgs = {
  input: AddSubscriptionInput;
};

export type MutationRemoveSubscriptionArgs = {
  input: RemoveSubscriptionInput;
};

export type MutationEditSubscriptionArgs = {
  input: EditSubscriptionInput;
};

export type MutationAddCouponArgs = {
  input: AddCouponInput;
};

export type MutationRemoveCouponArgs = {
  input: RemoveCouponInput;
};

export type MutationEditCouponArgs = {
  input: EditCouponInput;
};

export type MutationReportUserActionArgs = {
  input: ReportUserActionInput;
};

export type MutationAddMessageArgs = {
  input: AddMessageInput;
};

export type MutationEditMessageArgs = {
  input: EditMessageInput;
};

export type MutationDeleteMessageArgs = {
  input: DeleteMessageInput;
};

export type MutationAddThreadArgs = {
  input: AddThreadInput;
};

export type MutationEditThreadArgs = {
  input: EditThreadInput;
};

export type MutationDeleteThreadArgs = {
  input: DeleteThreadInput;
};

export type MutationAddUserThreadArgs = {
  input: AddUserThreadInput;
};

export type MutationAddUserTypingArgs = {
  input: AddUserTypingInput;
};

export type MutationRemoveUserTypingArgs = {
  input: RemoveUserTypingInput;
};

export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  isBanned?: Maybe<Scalars['Boolean']>;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  avatar?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  devise?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  receiveCommunication?: Maybe<Scalars['Boolean']>;
  role?: Maybe<User_Role>;
  isSignupFinish?: Maybe<Scalars['Boolean']>;
};

export type MutationAddUserToMyfriendsArgs = {
  userId: Scalars['ID'];
};

export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};

export type MutationLoginOauth2Args = {
  input: LoginOauth2Input;
};

export type MutationCheckExpiredTokenArgs = {
  input: CheckExpiredTokenInput;
};

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};

export type MutationAuthorizeTwichUserArgs = {
  code: Scalars['String'];
};

export type MutationLoginSocialArgs = {
  input: LoginSocialInput;
};

export type MutationLoginSocialWithoutUpdatingUserInfoArgs = {
  input: LoginSocialInput;
};

export type MutationCheckSocialAuthArgs = {
  socialApp?: Maybe<Social_App>;
  uid: Scalars['String'];
};

export type MutationLoginTwitchArgs = {
  code: Scalars['String'];
};

export type MutationLoginDiscordArgs = {
  code: Scalars['String'];
};

export type MutationLoginTwitterArgs = {
  code: Scalars['String'];
};

export type MutationLinkDiscordArgs = {
  code: Scalars['String'];
};

export type MutationLoginXboxArgs = {
  code: Scalars['String'];
};

export type MutationLinkMicrosoftArgs = {
  code: Scalars['String'];
};

export type MutationLoginBattlenetArgs = {
  code: Scalars['String'];
};

export type MutationCheckIfEmailIsAlreadyTakenArgs = {
  email: Scalars['String'];
};

export type MutationCheckIfUserMatchInSocialAppArgs = {
  socialApp: Social_App;
  email: Scalars['String'];
  providerUid: Scalars['String'];
};

export type MutationSendInvitationMobileArgs = {
  email: Array<Maybe<Scalars['String']>>;
};

export type MutationCheckIfUsernameIsAlreadyTakenArgs = {
  username: Scalars['String'];
};

export type MutationSendWelcomeEmailArgs = {
  email: Scalars['String'];
  username: Scalars['String'];
};

export type MutationEnableoAuth2Args = {
  id?: Maybe<Scalars['ID']>;
};

export type MutationDisableoAuth2Args = {
  id?: Maybe<Scalars['ID']>;
};

export type MutationCheckIfEmailAndPasswordMatchedArgs = {
  input: LoginInput;
};

export type MutationCheckTokenOauth2Args = {
  input: CheckExpiredTokenInput;
};

export type MutationCreateAuthTokenArgs = {
  input: LoginInput;
};

export type MutationGetAccessTokenArgs = {
  oauth_token?: Maybe<Scalars['String']>;
  oauth_verifier?: Maybe<Scalars['String']>;
};

export type MutationCreateGamePlatformArgs = {
  name: Scalars['String'];
  logo: Scalars['String'];
};

export type MutationDeleteGamePlatformArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateGamePlatformArgs = {
  id: Scalars['ID'];
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MutationUpdateUserMainGamePlateformArgs = {
  gamePlateformId: Scalars['ID'];
};

export type MutationCreateGameArgs = {
  name: Scalars['String'];
  plateformIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  description: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['Int']>;
  coverImage?: Maybe<Scalars['String']>;
  releasedDate?: Maybe<Scalars['DateTime']>;
  mainJacket?: Maybe<Scalars['String']>;
  twitchGameName?: Maybe<Scalars['String']>;
  twitchGameId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  landingPageJacket?: Maybe<Scalars['String']>;
  landingPageLogo?: Maybe<Scalars['String']>;
};

export type MutationDeleteGameArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateGameArgs = {
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  plateformIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  gameAccountIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  logo?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Int']>;
  coverImage?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['Int']>;
  releasedDate?: Maybe<Scalars['DateTime']>;
  mainJacket?: Maybe<Scalars['String']>;
  twitchGameName?: Maybe<Scalars['String']>;
  twitchGameId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  landingPageJacket?: Maybe<Scalars['String']>;
  landingPageLogo?: Maybe<Scalars['String']>;
};

export type MutationAddGameToMyFavoriteArgs = {
  gameId: Scalars['ID'];
};

export type MutationRemoveGameFromMyFavoriteArgs = {
  gameId: Scalars['ID'];
};

export type MutationCreateTournamentArgs = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  title_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  maxParticipants?: Maybe<Scalars['Int']>;
  minParticipants?: Maybe<Scalars['Int']>;
  gameId?: Maybe<Scalars['ID']>;
  gamePlateformId?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  roundNumber?: Maybe<Scalars['Int']>;
  formatId?: Maybe<Scalars['ID']>;
  organiserId?: Maybe<Scalars['ID']>;
  entryCost?: Maybe<Scalars['Float']>;
  signupStartedAt?: Maybe<Scalars['DateTime']>;
  signupEndAt?: Maybe<Scalars['DateTime']>;
  mapId?: Maybe<Scalars['ID']>;
  gameServerId?: Maybe<Scalars['ID']>;
  regionId?: Maybe<Scalars['ID']>;
  audiochatId?: Maybe<Scalars['ID']>;
  descordUrl?: Maybe<Scalars['String']>;
  entryType?: Maybe<TournamentEntryType>;
  gameformatId?: Maybe<Scalars['ID']>;
  gameModeId?: Maybe<Scalars['ID']>;
  gameSlug?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['DateTime']>;
  saisonId?: Maybe<Scalars['ID']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  welcomeMessage_en?: Maybe<Scalars['String']>;
};

export type MutationDeleteTournamentArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateTournamentArgs = {
  input: UpdateTournamentInput;
};

export type MutationDeleteTournamentroundArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateTournamentroundArgs = {
  id: Scalars['ID'];
  roundNumber?: Maybe<Scalars['Int']>;
  roundDate?: Maybe<Scalars['DateTime']>;
};

export type MutationCreateTournamentrankingArgs = {
  participantId: Scalars['ID'];
  tournamentId: Scalars['ID'];
  rank?: Maybe<Scalars['Int']>;
};

export type MutationUpdateTournamentrankingArgs = {
  id: Scalars['ID'];
  participantId: Scalars['ID'];
  tournamentId: Scalars['ID'];
  rank?: Maybe<Scalars['Int']>;
};

export type MutationDeleteTournamentrankingArgs = {
  id: Scalars['ID'];
};

export type MutationCreateTournamentFormatArgs = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  title_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
  type?: Maybe<Tournament_Format_Type>;
};

export type MutationDeleteTournamentFormatArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateTournamentFormatArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  title_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
  type?: Maybe<Tournament_Format_Type>;
};

export type MutationCreateTournamentAwardArgs = {
  title?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
  startRank?: Maybe<Scalars['Int']>;
  endRank?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  sponsorName?: Maybe<Scalars['String']>;
  sponsorImage?: Maybe<Scalars['String']>;
  tournamentId?: Maybe<Scalars['ID']>;
  type?: Maybe<Award_Type>;
  title_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
};

export type MutationDeleteTournamentAwardArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateTournamentAwardArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
  startRank?: Maybe<Scalars['Int']>;
  endRank?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  sponsorName?: Maybe<Scalars['String']>;
  sponsorImage?: Maybe<Scalars['String']>;
  type?: Maybe<Award_Type>;
  winnerParticipantIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  title_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
};

export type MutationCreateTournamentRuleArgs = {
  name: Scalars['String'];
  description: Scalars['String'];
  tournamentId?: Maybe<Scalars['ID']>;
  name_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
};

export type MutationUpdateTournamentRuleArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
};

export type MutationCreateTournamentParticipantArgs = {
  league?: Maybe<Scalars['ID']>;
  team?: Maybe<Scalars['ID']>;
  user?: Maybe<Scalars['ID']>;
  wager?: Maybe<Scalars['ID']>;
  tournament?: Maybe<Scalars['ID']>;
  signupBy?: Maybe<Scalars['ID']>;
};

export type MutationDeleteTournamentParticipantArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateTournamentParticipantArgs = {
  id: Scalars['ID'];
};

export type MutationJoinUserToTournamentArgs = {
  tournamentId: Scalars['ID'];
};

export type MutationJoinTeamToTournamentArgs = {
  tournamentId: Scalars['ID'];
  teamId: Scalars['ID'];
};

export type MutationJoinMeToWagerArgs = {
  wagerId: Scalars['ID'];
};

export type MutationJoinTeamToWagerArgs = {
  wagerId: Scalars['ID'];
  teamId: Scalars['ID'];
};

export type MutationJoinMeToLeagueArgs = {
  leagueId: Scalars['ID'];
};

export type MutationJoinTeamToLeagueArgs = {
  leagueId: Scalars['ID'];
  teamId: Scalars['ID'];
};

export type MutationCreateTeamArgs = {
  name: Scalars['String'];
  tag?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  gamePlateformId?: Maybe<Scalars['ID']>;
  country?: Maybe<Scalars['String']>;
};

export type MutationUpdateTeamArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  gamePlateformId?: Maybe<Scalars['ID']>;
  country?: Maybe<Scalars['String']>;
};

export type MutationCreateTournamentBracketArgs = {
  participant1?: Maybe<Scalars['ID']>;
  participant2?: Maybe<Scalars['ID']>;
  league?: Maybe<Scalars['ID']>;
  tournament?: Maybe<Scalars['ID']>;
  wager?: Maybe<Scalars['ID']>;
};

export type MutationDeleteTournamentBracketArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateTournamentBracketArgs = {
  id: Scalars['ID'];
  startedAt?: Maybe<Scalars['DateTime']>;
  winnerId?: Maybe<Scalars['ID']>;
  pointParticipant1?: Maybe<Scalars['Int']>;
  pointParticipant2?: Maybe<Scalars['Int']>;
  isLive?: Maybe<Scalars['Boolean']>;
  isFinished?: Maybe<Scalars['Boolean']>;
  endedAt?: Maybe<Scalars['DateTime']>;
  chatEngineId?: Maybe<Scalars['String']>;
  chatEngineAccessKey?: Maybe<Scalars['String']>;
  participant1Id?: Maybe<Scalars['ID']>;
  participant2Id?: Maybe<Scalars['ID']>;
};

export type MutationStartTournamentBracketArgs = {
  id: Scalars['ID'];
};

export type MutationCreateGameAccountArgs = {
  name: Scalars['String'];
  logo: Scalars['String'];
  label?: Maybe<Scalars['String']>;
};

export type MutationDeleteGameAccountArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateGameAccountArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  logo: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  gameIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type MutationLinkAGameAccountArgs = {
  accountId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  gamertag?: Maybe<Scalars['String']>;
  gameAccountId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
};

export type MutationCheckGameAccountCredentialArgs = {
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  gameAccountId: Scalars['ID'];
};

export type MutationCreateOrganiserArgs = {
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  moreInfo?: Maybe<Scalars['String']>;
};

export type MutationDeleteOrganiserArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateOrganiserArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  moreInfo?: Maybe<Scalars['String']>;
};

export type MutationInviteUserToTeamArgs = {
  teamId: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
};

export type MutationDeleteTeamMemberArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateTeamMemberArgs = {
  id: Scalars['ID'];
  role?: Maybe<Team_Role>;
};

export type MutationCreateFileArgs = {
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  tournamentBracketId?: Maybe<Scalars['ID']>;
};

export type MutationDeleteFileArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateFileArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type MutationCreatePageArgs = {
  slug: Scalars['String'];
  title: Scalars['String'];
  value_en?: Maybe<Scalars['String']>;
};

export type MutationDeletePageArgs = {
  id: Scalars['ID'];
};

export type MutationUpdatePageArgs = {
  id: Scalars['ID'];
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contents?: Maybe<Array<Maybe<PageContentInput>>>;
};

export type MutationDeletePageContentArgs = {
  id: Scalars['ID'];
};

export type MutationUpdatePageContentArgs = {
  id: Scalars['ID'];
  value?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value_en?: Maybe<Scalars['String']>;
};

export type MutationCreateLeagueArgs = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  title_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  maxParticipants?: Maybe<Scalars['Int']>;
  minParticipants?: Maybe<Scalars['Int']>;
  gameId?: Maybe<Scalars['ID']>;
  gamePlateformId?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  roundNumber?: Maybe<Scalars['Int']>;
  organiserId?: Maybe<Scalars['ID']>;
  format?: Maybe<League_Format>;
  winnerMatchPoint?: Maybe<Scalars['Int']>;
  looserMatchPoint?: Maybe<Scalars['Int']>;
  drawMatchPoint?: Maybe<Scalars['Int']>;
  entryCost?: Maybe<Scalars['Float']>;
};

export type MutationDeleteLeagueArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateLeagueArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  title_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  maxParticipants?: Maybe<Scalars['Int']>;
  minParticipants?: Maybe<Scalars['Int']>;
  gameId?: Maybe<Scalars['ID']>;
  gamePlateformId?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  roundNumber?: Maybe<Scalars['Int']>;
  organiserId?: Maybe<Scalars['ID']>;
  winnerMatchPoint?: Maybe<Scalars['Int']>;
  looserMatchPoint?: Maybe<Scalars['Int']>;
  drawMatchPoint?: Maybe<Scalars['Int']>;
  newAwards?: Maybe<Array<Maybe<LeagueAwardInput>>>;
  newRules?: Maybe<Array<Maybe<LeagueRuleInput>>>;
  entryCost?: Maybe<Scalars['Float']>;
};

export type MutationCreateLeagueRuleArgs = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  league: Scalars['ID'];
  name_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
};

export type MutationDeleteLeagueRuleArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateLeagueRuleArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
};

export type MutationCreateLeagueAwardArgs = {
  title?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
  startRank?: Maybe<Scalars['Int']>;
  endRank?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  league: Scalars['ID'];
  title_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
};

export type MutationDeleteLeagueAwardArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateLeagueAwardArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
  startRank?: Maybe<Scalars['Int']>;
  endRank?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  title_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
};

export type MutationCreateActualityArgs = {
  title?: Maybe<Scalars['String']>;
  imagePreview?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type MutationDeleteActualityArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateActualityArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  imagePreview?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type MutationCreateWagerArgs = {
  gameId?: Maybe<Scalars['ID']>;
  gamePlateformId?: Maybe<Scalars['ID']>;
  format?: Maybe<Wager_Format>;
  title?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  cashPrice?: Maybe<Scalars['Float']>;
  rule?: Maybe<Scalars['String']>;
  entryCost?: Maybe<Scalars['Float']>;
  gameformatId?: Maybe<Scalars['ID']>;
  gameModeId?: Maybe<Scalars['ID']>;
  entryType?: Maybe<TournamentEntryType>;
  mapId?: Maybe<Scalars['ID']>;
  gameServerId?: Maybe<Scalars['ID']>;
  regionId?: Maybe<Scalars['ID']>;
  audiochatId?: Maybe<Scalars['ID']>;
  discordUrl?: Maybe<Scalars['String']>;
  title_en?: Maybe<Scalars['String']>;
  rule_en?: Maybe<Scalars['String']>;
};

export type MutationDeleteWagerArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateWagerArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  cashPrice?: Maybe<Scalars['Float']>;
  rule?: Maybe<Scalars['String']>;
  entryCost?: Maybe<Scalars['Float']>;
  gameformatId?: Maybe<Scalars['ID']>;
  gameModeId?: Maybe<Scalars['ID']>;
  entryType?: Maybe<TournamentEntryType>;
  mapId?: Maybe<Scalars['ID']>;
  gameServerId?: Maybe<Scalars['ID']>;
  regionId?: Maybe<Scalars['ID']>;
  audiochatId?: Maybe<Scalars['ID']>;
  discordUrl?: Maybe<Scalars['String']>;
  winnerId?: Maybe<Scalars['ID']>;
  title_en?: Maybe<Scalars['String']>;
  rule_en?: Maybe<Scalars['String']>;
  chatEngineId?: Maybe<Scalars['String']>;
  chatEngineAccessKey?: Maybe<Scalars['String']>;
};

export type MutationStartWagerArgs = {
  wagerId: Scalars['ID'];
};

export type MutationCreateNotificationArgs = {
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  type?: Maybe<Notificaiton_Type>;
  userId?: Maybe<Scalars['ID']>;
};

export type MutationDeleteNotificationArgs = {
  id: Scalars['ID'];
};

export type MutationCreateMapArgs = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
};

export type MutationDeleteMapArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateMapArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
};

export type MutationCreateGameserverArgs = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['ID']>;
};

export type MutationDeleteGameserverArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateGameserverArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['ID']>;
};

export type MutationCreateRegionArgs = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type MutationDeleteRegionArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateRegionArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type MutationCreateAudiochatplateformArgs = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
};

export type MutationDeleteAudiochatplateformArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateAudiochatplateformArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
};

export type MutationCreateGamemodeArgs = {
  title?: Maybe<Scalars['String']>;
  teamSize?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Game_Mode_Type>;
  title_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
};

export type MutationDeleteGamemodeArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateGamemodeArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  teamSize?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Game_Mode_Type>;
  title_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
};

export type MutationCreateGameformatArgs = {
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
};

export type MutationDeleteGameformatArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateGameformatArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
};

export type MutationCreateCountryArgs = {
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
};

export type MutationDeleteCountryArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateCountryArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
};

export type MutationAddAmountToMyWalletArgs = {
  value?: Maybe<Scalars['Float']>;
};

export type MutationCreateWalletArgs = {
  field?: Maybe<Scalars['String']>;
};

export type MutationDeleteWalletArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateWalletArgs = {
  id: Scalars['ID'];
};

export type MutationCreateWallettransactionArgs = {
  amount?: Maybe<Scalars['Float']>;
  walletId?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
  tournamentId?: Maybe<Scalars['ID']>;
  wagerId?: Maybe<Scalars['ID']>;
  leagueId?: Maybe<Scalars['ID']>;
};

export type MutationDeleteWallettransactionArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateWallettransactionArgs = {
  id: Scalars['ID'];
  mount?: Maybe<Scalars['Float']>;
};

export type MutationCreateWalletOrderArgs = {
  amount?: Maybe<Scalars['Float']>;
  jetton?: Maybe<Scalars['Float']>;
};

export type MutationDeleteWalletOrderArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateWalletOrderArgs = {
  id: Scalars['ID'];
};

export type MutationMakeWalletPaymentArgs = {
  amount?: Maybe<Scalars['Float']>;
  jetton?: Maybe<Scalars['Float']>;
};

export type MutationConfirmWalletOrderPaymentArgs = {
  walletOrderId: Scalars['ID'];
  meta?: Maybe<Scalars['JSON']>;
};

export type MutationCreateOrderArgs = {
  paymentMethod?: Maybe<Payment_Method>;
  amount?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['ID']>;
  tournamentId?: Maybe<Scalars['ID']>;
  wagerId?: Maybe<Scalars['ID']>;
  leagueId?: Maybe<Scalars['ID']>;
};

export type MutationDeleteOrderArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateOrderArgs = {
  id: Scalars['ID'];
};

export type MutationMakePaymentArgs = {
  paymentMethod?: Maybe<Payment_Method>;
  tournamentId?: Maybe<Scalars['ID']>;
  wagerId?: Maybe<Scalars['ID']>;
  leagueId?: Maybe<Scalars['ID']>;
  object?: Maybe<Payment_Object>;
};

export type MutationConfirmOrderPaymentArgs = {
  orderId: Scalars['ID'];
  meta?: Maybe<Scalars['JSON']>;
};

export type MutationCreateTournamentWinnerArgs = {
  tournamentAwardId?: Maybe<Scalars['ID']>;
  tournamentParticipantId?: Maybe<Scalars['ID']>;
};

export type MutationDeleteTournamentWinnerArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateTournamentWinnerArgs = {
  id: Scalars['ID'];
};

export type MutationCreateChallengeArgs = {
  title?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  title_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
};

export type MutationDeleteChallengeArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateChallengeArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  title_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
};

export type MutationCreateChatArgs = {
  name: Scalars['String'];
  gamePartyId?: Maybe<Scalars['ID']>;
};

export type MutationDeleteChatArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateChatArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type MutationCreateChatmemberArgs = {
  chatId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationDeleteChatmemberArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateChatmemberArgs = {
  id: Scalars['ID'];
};

export type MutationCreateLeaderboardpointArgs = {
  wagerId?: Maybe<Scalars['ID']>;
  tournamentId?: Maybe<Scalars['ID']>;
  point: Scalars['Int'];
};

export type MutationDeleteLeaderboardpointArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateLeaderboardpointArgs = {
  id: Scalars['ID'];
  input: LeaderboardPointsInput;
};

export type MutationCreateBadgeArgs = {
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type MutationDeleteBadgeArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateBadgeArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type MutationCreateGamepartyArgs = {
  name: Scalars['String'];
};

export type MutationDeleteGamepartyArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateGamepartyArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type MutationCreateGamepartymemberArgs = {
  partyId: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
};

export type MutationDeleteGamepartymemberArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateGamepartymemberArgs = {
  id: Scalars['ID'];
};

export type MutationInviteUserToGamePartyArgs = {
  userId: Scalars['ID'];
  gamePartyId: Scalars['ID'];
};

export type MutationCreateSocialaccountArgs = {
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
};

export type MutationDeleteSocialaccountArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateSocialaccountArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
};

export type MutationCreateUsersocialaccountArgs = {
  socialAccountId: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
  username: Scalars['String'];
  email: Scalars['String'];
};

export type MutationDeleteUsersocialaccountArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateUsersocialaccountArgs = {
  id: Scalars['ID'];
};

export type MutationCreateSaisonArgs = {
  title?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type MutationDeleteSaisonArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateSaisonArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type MutationCreateTextContentArgs = {
  index?: Maybe<Scalars['Int']>;
  contentFr?: Maybe<Scalars['String']>;
  contentEn?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  textType?: Maybe<Text_Type>;
};

export type MutationUpdateTextContentArgs = {
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  contentFr?: Maybe<Scalars['String']>;
  contentEn?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  textType?: Maybe<Text_Type>;
  removed?: Maybe<Scalars['Boolean']>;
};

export type MutationDeleteTextContentArgs = {
  id: Scalars['ID'];
};

export type MutationCreateAssistancepageArgs = {
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  title_en?: Maybe<Scalars['String']>;
  content_en?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  category_en?: Maybe<Scalars['String']>;
};

export type MutationDeleteAssistancepageArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateAssistancepageArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  title_en?: Maybe<Scalars['String']>;
  content_en?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  category_en?: Maybe<Scalars['String']>;
};

export type MutationSendMessageChannelDiscordArgs = {
  channelId: Scalars['String'];
  message: Scalars['String'];
};

export type MutationUpdateMessageChannelDiscordArgs = {
  channelId: Scalars['String'];
  messageId: Scalars['String'];
  message: Scalars['String'];
};

export type MutationCreateMessageArgs = {
  input: CreateMessageInput;
};

export type MutationCreateMessageWithWebHookArgs = {
  input: CreateMessageWithWebHookInput;
};

export type MutationCreateWehbookArgs = {
  input: CreateWebhookInput;
};

export type MutationCheckWebhookUserInChannelArgs = {
  input: FindWebhookInput;
};

export type MutationCreateGuildRoleArgs = {
  input: CreateGuildRoleInput;
};

export type MutationUpdatePositionGuildRoleArgs = {
  input: UpdatePositionGuildRoleInput;
};

export type MutationDeleteWebhookArgs = {
  input: DeletedWebhookInput;
};

export type MutationCreatePrivateChannelArgs = {
  input: PrivateChannelInput;
};

export type MutationAssignUserToRoleArgs = {
  input: AssignUserToRoleInput;
};

export type Notificaiton_Type =
  | 'WAGER'
  | 'TOURNAMENT'
  | 'LEAGUE'
  | 'NEWFRIEND'
  | 'CHAT'
  | 'CHALLENGE'
  | 'NEWBADGE'
  | 'XP'
  | 'OTHER';

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  title: Scalars['String'];
  content: Scalars['String'];
  type: Notificaiton_Type;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  amount?: Maybe<Scalars['Float']>;
  paymentMethod?: Maybe<Payment_Method>;
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type OrderFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type Organiser = {
  __typename?: 'Organiser';
  id: Scalars['ID'];
  tournaments?: Maybe<Array<Maybe<Tournament>>>;
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  moreInfo?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  leagues?: Maybe<Array<Maybe<League>>>;
};

export type OrganiserFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type Payment_Method = 'JETTON' | 'TRANSACTION';

export type Payment_Object = 'WAGER' | 'TOURNAMENT' | 'LEAGUE';

export type Page = {
  __typename?: 'Page';
  id: Scalars['ID'];
  slug: Scalars['String'];
  pageContentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  contents?: Maybe<Array<Maybe<PageContent>>>;
  title: Scalars['String'];
};

export type PageContent = {
  __typename?: 'PageContent';
  id: Scalars['ID'];
  label: Scalars['String'];
  value: Scalars['String'];
  value_en?: Maybe<Scalars['String']>;
};

export type PageContentInput = {
  label: Scalars['String'];
  value: Scalars['String'];
  value_en?: Maybe<Scalars['String']>;
};

export type PageFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ParamsMessageInput = {
  around?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type Post = {
  __typename?: 'Post';
  id: Scalars['ID'];
  title: Scalars['String'];
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  author: User;
  authorId: Scalars['ID'];
  likers: Array<User>;
};

export type PostResult = {
  __typename?: 'PostResult';
  results: Array<Post>;
  total: Scalars['Int'];
};

export type PrivateChannelInput = {
  name: Scalars['String'];
  categoryParent: Scalars['String'];
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  category: ProductCategory;
  creator: User;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  removed?: Maybe<Scalars['Boolean']>;
  price: Scalars['Float'];
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductResult = {
  __typename?: 'ProductResult';
  results: Array<Product>;
  total: Scalars['Int'];
};

export type ProductsFilterInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  categoryIds?: Maybe<Array<Scalars['ID']>>;
};

export type ProgramLevelValue = 'Beginner' | 'Novice' | 'Intermediate' | 'Advanced';

export type Query = {
  __typename?: 'Query';
  me: User;
  /**
   * user(id: ID!): User
   * tournament(id: ID!): Tournament
   * game(id: ID!): Game
   * team(id: ID!): Team
   */
  video?: Maybe<Video>;
  users: UserResult;
  trainings: Array<Training>;
  trainingDetail?: Maybe<TrainingDetail>;
  videos: VideosResult;
  videoCategories: Array<VideoCategory>;
  counts: CountsResult;
  gamePlatforms: Array<GamePlatform>;
  productCategories: Array<ProductCategory>;
  products: ProductResult;
  tournaments: TournamentResult;
  findLastTenTournaments: TournamentResult;
  tournamentFormats: Array<TournamentFormat>;
  steamGames?: Maybe<SteamGameResult>;
  match?: Maybe<Match>;
  subscriptions?: Maybe<Array<SubscriptionType>>;
  coupons: Array<Maybe<Coupon>>;
  usersTournament: UserResult;
  getLeaguePoint: LeaguePoint;
  allThread: ThreadResult;
  allPrivateThread: ThreadResult;
  getPrivateThreadUser: Thread;
  thread: Thread;
  teamsTournament: TeamResult;
  allUsers?: Maybe<Array<Maybe<User>>>;
  _allUsersMeta?: Maybe<ListMetadata>;
  User?: Maybe<User>;
  findUserToken?: Maybe<User>;
  totalUserCount?: Maybe<Scalars['Int']>;
  userFriendsSuggestion?: Maybe<Array<Maybe<User>>>;
  onlineFriends?: Maybe<Array<Maybe<User>>>;
  getUsersByRand?: Maybe<Array<Maybe<User>>>;
  getTwitterOauthFromCode?: Maybe<TwitterOauthTokenResponse>;
  getTwitterRequestToken?: Maybe<TwitterOauthToken>;
  getTwitterUser?: Maybe<TwitterUser>;
  getTwitterOauthUserWithEmail?: Maybe<TwitterUserAuth>;
  getAuthorizationFromOauth10?: Maybe<TwitterAuthorizationOAuth>;
  allGamePlatforms?: Maybe<Array<Maybe<GamePlatform>>>;
  _allGamePlatformsMeta?: Maybe<ListMetadata>;
  GamePlatform?: Maybe<GamePlatform>;
  _user?: Maybe<User>;
  _game?: Maybe<Game>;
  _team?: Maybe<Team>;
  allGames?: Maybe<Array<Maybe<Game>>>;
  _allGamesMeta?: Maybe<ListMetadata>;
  Game?: Maybe<Game>;
  totalGameCount?: Maybe<Scalars['Int']>;
  userGameStat?: Maybe<UserGameStat>;
  allTournaments?: Maybe<Array<Maybe<Tournament>>>;
  _allTournamentsMeta?: Maybe<ListMetadata>;
  allTournamentsFeatured?: Maybe<Array<Maybe<Tournament>>>;
  _allTournamentsFeaturedMeta?: Maybe<ListMetadata>;
  Tournament?: Maybe<Tournament>;
  _gamePlateform?: Maybe<GamePlatform>;
  _format?: Maybe<TournamentFormat>;
  _organiser?: Maybe<Organiser>;
  totalTournamentCount?: Maybe<Scalars['Int']>;
  activeTournaments?: Maybe<Array<Maybe<Tournament>>>;
  /** Tournament rounds */
  allTournamentrounds?: Maybe<Array<Maybe<Tournamentround>>>;
  _allTournamentroundsMeta?: Maybe<ListMetadata>;
  Tournamentround?: Maybe<Tournamentround>;
  thisMonthTournamentCount?: Maybe<Scalars['Int']>;
  allTournamentrankings?: Maybe<Array<Maybe<Tournamentranking>>>;
  _allTournamentrankingsMeta?: Maybe<ListMetadata>;
  Tournamentranking?: Maybe<Tournamentranking>;
  totalTournamentCashPrize?: Maybe<Scalars['Float']>;
  tournamentInformation?: Maybe<TournamentInformationPayload>;
  allTournamentFormats?: Maybe<Array<Maybe<TournamentFormat>>>;
  _allTournamentFormatsMeta?: Maybe<ListMetadata>;
  TournamentFormat?: Maybe<TournamentFormat>;
  allTournamentAwards?: Maybe<Array<Maybe<TournamentAward>>>;
  _allTournamentAwardsMeta?: Maybe<ListMetadata>;
  TournamentAward?: Maybe<TournamentAward>;
  _tournament?: Maybe<Tournament>;
  _tournamentParticipant?: Maybe<TournamentParticipant>;
  allTournamentRules?: Maybe<Array<Maybe<TournamentRule>>>;
  _allTournamentRulesMeta?: Maybe<ListMetadata>;
  TournamentRule?: Maybe<TournamentRule>;
  allTournamentParticipants?: Maybe<Array<Maybe<TournamentParticipant>>>;
  _allTournamentParticipantsMeta?: Maybe<ListMetadata>;
  TournamentParticipant?: Maybe<TournamentParticipant>;
  _wager?: Maybe<Wager>;
  _league?: Maybe<League>;
  allTeams?: Maybe<Array<Maybe<Team>>>;
  _allTeamsMeta?: Maybe<ListMetadata>;
  Team?: Maybe<Team>;
  totalTeamCount?: Maybe<Scalars['Int']>;
  allTournamentBrackets?: Maybe<Array<Maybe<TournamentBracket>>>;
  _allTournamentBracketsMeta?: Maybe<ListMetadata>;
  TournamentBracket?: Maybe<TournamentBracket>;
  _tournamentround?: Maybe<Tournamentround>;
  allGameAccounts?: Maybe<Array<Maybe<GameAccount>>>;
  _allGameAccountsMeta?: Maybe<ListMetadata>;
  GameAccount?: Maybe<GameAccount>;
  allOrganisers?: Maybe<Array<Maybe<Organiser>>>;
  _allOrganisersMeta?: Maybe<ListMetadata>;
  Organiser?: Maybe<Organiser>;
  allTeamMembers?: Maybe<Array<Maybe<TeamMember>>>;
  _allTeamMembersMeta?: Maybe<ListMetadata>;
  /** q arg allow us to filter by username */
  allUsersNotInTeam?: Maybe<Array<Maybe<User>>>;
  TeamMember?: Maybe<TeamMember>;
  getVersusTeamMember?: Maybe<Array<Maybe<TeamMember>>>;
  getCustomTeamMembers?: Maybe<Array<Maybe<TeamMember>>>;
  allFiles?: Maybe<Array<Maybe<File>>>;
  _allFilesMeta?: Maybe<ListMetadata>;
  File?: Maybe<File>;
  _tournamentBracket?: Maybe<TournamentBracket>;
  allPages?: Maybe<Array<Maybe<Page>>>;
  _allPagesMeta?: Maybe<ListMetadata>;
  Page?: Maybe<Page>;
  pageBySlug?: Maybe<Page>;
  allPageContents?: Maybe<Array<Maybe<PageContent>>>;
  _allPageContentsMeta?: Maybe<ListMetadata>;
  PageContent?: Maybe<PageContent>;
  myCodModernWarefareStat?: Maybe<RapidapiCodModernWarfareStat>;
  myBO4Stat?: Maybe<Scalars['JSON']>;
  wzstatsDotGG?: Maybe<WzstatsDotGg>;
  wzstatsDotGGPlayerRecentMatch?: Maybe<Array<Maybe<WzstatsDotGgMatchStat>>>;
  wzstatsDotGGGameMatchDetail?: Maybe<WzstatsDotGgMatchStat>;
  modernWarefarecodTrackerDotGGStats?: Maybe<CodTrackerDotGgStats>;
  blackops4codTrackerDotGGstats?: Maybe<CodTrackerDotGgStats>;
  codWarezoneTrackerDotGGLeaderboard?: Maybe<Array<Maybe<CodWarezoneTrackerDotGgLeaderboardItem>>>;
  codMWTrackerDotGGLeaderboard?: Maybe<Array<Maybe<CodWarezoneTrackerDotGgLeaderboardItem>>>;
  codBo4TrackerDotGGLeaderboard?: Maybe<Array<Maybe<CodWarezoneTrackerDotGgLeaderboardItem>>>;
  myApexLegendStat?: Maybe<ApexLegendStat>;
  apexMatchHistoryBrokenBySession?: Maybe<Array<Maybe<ApexLegendMatchHistory>>>;
  myForniteBRStat?: Maybe<FortniteBrPlayerStat>;
  myForniteBRRecentMatches?: Maybe<FortnitePlayerRecentMatches>;
  playerPowerRanking?: Maybe<Scalars['JSON']>;
  myr6Stats?: Maybe<R6Stat>;
  allLeagues?: Maybe<Array<Maybe<League>>>;
  _allLeaguesMeta?: Maybe<ListMetadata>;
  League?: Maybe<League>;
  allLeagueRules?: Maybe<Array<Maybe<LeagueRule>>>;
  _allLeagueRulesMeta?: Maybe<ListMetadata>;
  LeagueRule?: Maybe<LeagueRule>;
  allLeagueAwards?: Maybe<Array<Maybe<LeagueAward>>>;
  _allLeagueAwardsMeta?: Maybe<ListMetadata>;
  LeagueAward?: Maybe<LeagueAward>;
  activeLeagues?: Maybe<Array<Maybe<League>>>;
  totalLeagueCount?: Maybe<Scalars['Int']>;
  allActualities?: Maybe<Array<Maybe<Actuality>>>;
  _allActualitiesMeta?: Maybe<ListMetadata>;
  Actuality?: Maybe<Actuality>;
  fut21PlayerStat?: Maybe<Array<Maybe<FutPlayerInfo>>>;
  allWagers?: Maybe<Array<Maybe<Wager>>>;
  _allWagersMeta?: Maybe<ListMetadata>;
  Wager?: Maybe<Wager>;
  activeWagers?: Maybe<Array<Maybe<Wager>>>;
  totalWagerCount?: Maybe<Scalars['Int']>;
  wagerInformation?: Maybe<WagerInformationPayload>;
  allNotifications?: Maybe<Array<Maybe<Notification>>>;
  _allNotificationsMeta?: Maybe<ListMetadata>;
  Notification?: Maybe<Notification>;
  myNotifications?: Maybe<Array<Maybe<Notification>>>;
  allMaps?: Maybe<Array<Maybe<Map>>>;
  _allMapsMeta?: Maybe<ListMetadata>;
  Map?: Maybe<Map>;
  allGameservers?: Maybe<Array<Maybe<Gameserver>>>;
  _allGameserversMeta?: Maybe<ListMetadata>;
  Gameserver?: Maybe<Gameserver>;
  allRegions?: Maybe<Array<Maybe<Region>>>;
  _allRegionsMeta?: Maybe<ListMetadata>;
  Region?: Maybe<Region>;
  allAudiochatplateforms?: Maybe<Array<Maybe<Audiochatplateform>>>;
  _allAudiochatplateformsMeta?: Maybe<ListMetadata>;
  Audiochatplateform?: Maybe<Audiochatplateform>;
  allGamemodes?: Maybe<Array<Maybe<Gamemode>>>;
  _allGamemodesMeta?: Maybe<ListMetadata>;
  Gamemode?: Maybe<Gamemode>;
  allGameformats?: Maybe<Array<Maybe<Gameformat>>>;
  _allGameformatsMeta?: Maybe<ListMetadata>;
  Gameformat?: Maybe<Gameformat>;
  allCountries?: Maybe<Array<Maybe<Country>>>;
  _allCountriesMeta?: Maybe<ListMetadata>;
  Country?: Maybe<Country>;
  _gameserver?: Maybe<Gameserver>;
  activeTwitchLive?: Maybe<Array<Maybe<TwitchLive>>>;
  allWallets?: Maybe<Array<Maybe<Wallet>>>;
  _allWalletsMeta?: Maybe<ListMetadata>;
  Wallet?: Maybe<Wallet>;
  allWallettransactions?: Maybe<Array<Maybe<Wallettransaction>>>;
  _allWallettransactionsMeta?: Maybe<ListMetadata>;
  Wallettransaction?: Maybe<Wallettransaction>;
  allWalletOrders?: Maybe<Array<Maybe<WalletOrder>>>;
  _allWalletOrdersMeta?: Maybe<ListMetadata>;
  WalletOrder?: Maybe<WalletOrder>;
  allOrders?: Maybe<Array<Maybe<Order>>>;
  _allOrdersMeta?: Maybe<ListMetadata>;
  Order?: Maybe<Order>;
  allTournamentWinners?: Maybe<Array<Maybe<TournamentWinner>>>;
  _allTournamentWinnersMeta?: Maybe<ListMetadata>;
  TournamentWinner?: Maybe<TournamentWinner>;
  _tournamentAward?: Maybe<TournamentAward>;
  rocketLeagueTrackerGGStats?: Maybe<RocketLeagueTrackerGgStat>;
  rocketLeagueTrackerGGLeaderboards?: Maybe<Array<Maybe<RocketLeagueTrackerGgLeaderBoardItem>>>;
  allChallenges?: Maybe<Array<Maybe<Challenge>>>;
  _allChallengesMeta?: Maybe<ListMetadata>;
  Challenge?: Maybe<Challenge>;
  allChats?: Maybe<Array<Maybe<Chat>>>;
  _allChatsMeta?: Maybe<ListMetadata>;
  Chat?: Maybe<Chat>;
  allChatmembers?: Maybe<Array<Maybe<Chatmember>>>;
  _allChatmembersMeta?: Maybe<ListMetadata>;
  Chatmember?: Maybe<Chatmember>;
  _gameparty?: Maybe<Gameparty>;
  allLeaderboardpoints?: Maybe<Array<Maybe<Leaderboardpoint>>>;
  _allLeaderboardpointsMeta?: Maybe<ListMetadata>;
  Leaderboardpoint?: Maybe<Leaderboardpoint>;
  /** Gammer leaderboard */
  userLeaderboard?: Maybe<Array<Maybe<User>>>;
  currentLeaderboard?: Maybe<Array<Maybe<UserLeaderBoardPayload>>>;
  rankingUserPoint?: Maybe<Array<Maybe<User>>>;
  allBadges?: Maybe<Array<Maybe<Badge>>>;
  _allBadgesMeta?: Maybe<ListMetadata>;
  Badge?: Maybe<Badge>;
  allGamepartys?: Maybe<Array<Maybe<Gameparty>>>;
  _allGamepartysMeta?: Maybe<ListMetadata>;
  Gameparty?: Maybe<Gameparty>;
  allGamepartymembers?: Maybe<Array<Maybe<Gamepartymember>>>;
  _allGamepartymembersMeta?: Maybe<ListMetadata>;
  Gamepartymember?: Maybe<Gamepartymember>;
  allSocialaccounts?: Maybe<Array<Maybe<Socialaccount>>>;
  _allSocialaccountsMeta?: Maybe<ListMetadata>;
  Socialaccount?: Maybe<Socialaccount>;
  findSocialAccountByLabel?: Maybe<Socialaccount>;
  findUserSocialAccountByLabel?: Maybe<Usersocialaccount>;
  hasUserSocialAccount: Scalars['Boolean'];
  allUsersocialaccounts?: Maybe<Array<Maybe<Usersocialaccount>>>;
  _allUsersocialaccountsMeta?: Maybe<ListMetadata>;
  Usersocialaccount?: Maybe<Usersocialaccount>;
  allSaisons?: Maybe<Array<Maybe<Saison>>>;
  _allSaisonsMeta?: Maybe<ListMetadata>;
  Saison?: Maybe<Saison>;
  /** Saison courant */
  currentSaison?: Maybe<Saison>;
  getTextContent?: Maybe<TextContent>;
  allTextContents?: Maybe<Array<Maybe<TextContent>>>;
  TextContent?: Maybe<TextContent>;
  _allTextContentsMeta?: Maybe<ListMetadata>;
  allAssistancepages?: Maybe<Array<Maybe<Assistancepage>>>;
  _allAssistancepagesMeta?: Maybe<ListMetadata>;
  Assistancepage?: Maybe<Assistancepage>;
  AssistancePage?: Maybe<Assistancepage>;
  searchAssistance?: Maybe<Array<Maybe<Assistancepage>>>;
  discordChannel?: Maybe<DiscordChannel>;
  discordChannelInviteLink: Scalars['String'];
  onlineUsersCount?: Maybe<Scalars['Int']>;
  totalDiscordMember?: Maybe<Scalars['Int']>;
  totalDiscordMessage?: Maybe<Scalars['Int']>;
  allDiscordChannelGuilds?: Maybe<Array<Maybe<DiscordChannel>>>;
  allMessagesChannel?: Maybe<Array<Maybe<DiscordChannelMessage>>>;
  findMessageChannel?: Maybe<DiscordChannelMessage>;
  testRabbitMessageQueue?: Maybe<Scalars['Boolean']>;
  testConsumerMessageQueue?: Maybe<Scalars['Boolean']>;
  findWebhookByUserAndChannel?: Maybe<Webhook>;
  allGuildRole?: Maybe<Array<Maybe<GuildRole>>>;
  guildRole?: Maybe<GuildRole>;
  findPinnedMessages?: Maybe<Array<Maybe<DiscordChannelMessage>>>;
  /** find all member in guild */
  findGuildMember?: Maybe<Scalars['Boolean']>;
};

export type QueryVideoArgs = {
  id: Scalars['ID'];
};

export type QueryUsersArgs = {
  filter: UsersFilterInput;
};

export type QueryTrainingDetailArgs = {
  trainingProgramId: Scalars['ID'];
};

export type QueryVideosArgs = {
  filter: VideosFilterInput;
};

export type QueryProductsArgs = {
  filter: ProductsFilterInput;
};

export type QueryTournamentsArgs = {
  filter: TournamentFilterInput;
};

export type QuerySteamGamesArgs = {
  filter: SteamGamesFilterInput;
};

export type QueryMatchArgs = {
  id: Scalars['ID'];
};

export type QueryUsersTournamentArgs = {
  filter: UsersTournamentFilterInput;
};

export type QueryGetLeaguePointArgs = {
  filter: LeaguePointInput;
};

export type QueryAllThreadArgs = {
  filter: AllThreadFilter;
};

export type QueryAllPrivateThreadArgs = {
  filter: AllThreadFilter;
};

export type QueryGetPrivateThreadUserArgs = {
  input: GetPrivateThreadUserInput;
};

export type QueryThreadArgs = {
  threadId: Scalars['ID'];
};

export type QueryTeamsTournamentArgs = {
  filter: TeamsTournamentFilterInput;
};

export type QueryAllUsersArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<UserFilter>;
};

export type Query_AllUsersMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<UserFilter>;
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type QueryFindUserTokenArgs = {
  token: Scalars['String'];
};

export type QueryOnlineFriendsArgs = {
  limit?: Maybe<Scalars['Int']>;
};

export type QueryGetUsersByRandArgs = {
  limit?: Maybe<Scalars['Int']>;
};

export type QueryGetTwitterOauthFromCodeArgs = {
  code?: Maybe<Scalars['String']>;
};

export type QueryGetTwitterUserArgs = {
  access_token?: Maybe<Scalars['String']>;
};

export type QueryGetTwitterOauthUserWithEmailArgs = {
  token?: Maybe<Scalars['String']>;
  secretToken?: Maybe<Scalars['String']>;
};

export type QueryGetAuthorizationFromOauth10Args = {
  url?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
};

export type QueryAllGamePlatformsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<GamePlatformFilter>;
};

export type Query_AllGamePlatformsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<GamePlatformFilter>;
};

export type QueryGamePlatformArgs = {
  id: Scalars['ID'];
};

export type Query_UserArgs = {
  id: Scalars['ID'];
};

export type Query_GameArgs = {
  id: Scalars['ID'];
};

export type Query_TeamArgs = {
  id: Scalars['ID'];
};

export type QueryAllGamesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<GameFilter>;
};

export type Query_AllGamesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<GameFilter>;
};

export type QueryGameArgs = {
  id: Scalars['ID'];
};

export type QueryUserGameStatArgs = {
  gameId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type QueryAllTournamentsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TournamentFilter>;
};

export type Query_AllTournamentsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TournamentFilter>;
};

export type QueryAllTournamentsFeaturedArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TournamentFilter>;
};

export type Query_AllTournamentsFeaturedMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TournamentFilter>;
};

export type QueryTournamentArgs = {
  id: Scalars['ID'];
};

export type Query_GamePlateformArgs = {
  id: Scalars['ID'];
};

export type Query_FormatArgs = {
  id: Scalars['ID'];
};

export type Query_OrganiserArgs = {
  id: Scalars['ID'];
};

export type QueryAllTournamentroundsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TournamentroundFilter>;
};

export type Query_AllTournamentroundsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TournamentroundFilter>;
};

export type QueryTournamentroundArgs = {
  id: Scalars['ID'];
};

export type QueryAllTournamentrankingsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TournamentrankingFilter>;
};

export type Query_AllTournamentrankingsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TournamentrankingFilter>;
};

export type QueryTournamentrankingArgs = {
  id: Scalars['ID'];
};

export type QueryAllTournamentFormatsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TournamentFormatFilter>;
};

export type Query_AllTournamentFormatsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TournamentFormatFilter>;
};

export type QueryTournamentFormatArgs = {
  id: Scalars['ID'];
};

export type QueryAllTournamentAwardsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TournamentAwardFilter>;
};

export type Query_AllTournamentAwardsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TournamentAwardFilter>;
};

export type QueryTournamentAwardArgs = {
  id: Scalars['ID'];
};

export type Query_TournamentArgs = {
  id: Scalars['ID'];
};

export type Query_TournamentParticipantArgs = {
  id: Scalars['ID'];
};

export type QueryAllTournamentRulesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TournamentRuleFilter>;
};

export type Query_AllTournamentRulesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TournamentRuleFilter>;
};

export type QueryTournamentRuleArgs = {
  id: Scalars['ID'];
};

export type QueryAllTournamentParticipantsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TournamentParticipantFilter>;
};

export type Query_AllTournamentParticipantsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TournamentParticipantFilter>;
};

export type QueryTournamentParticipantArgs = {
  id: Scalars['ID'];
};

export type Query_WagerArgs = {
  id: Scalars['ID'];
};

export type Query_LeagueArgs = {
  id: Scalars['ID'];
};

export type QueryAllTeamsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TeamFilter>;
};

export type Query_AllTeamsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TeamFilter>;
};

export type QueryTeamArgs = {
  id: Scalars['ID'];
};

export type QueryAllTournamentBracketsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TournamentBracketFilter>;
};

export type Query_AllTournamentBracketsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TournamentBracketFilter>;
};

export type QueryTournamentBracketArgs = {
  id: Scalars['ID'];
};

export type Query_TournamentroundArgs = {
  id: Scalars['ID'];
};

export type QueryAllGameAccountsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<GameAccountFilter>;
};

export type Query_AllGameAccountsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<GameAccountFilter>;
};

export type QueryGameAccountArgs = {
  id: Scalars['ID'];
};

export type QueryAllOrganisersArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<OrganiserFilter>;
};

export type Query_AllOrganisersMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<OrganiserFilter>;
};

export type QueryOrganiserArgs = {
  id: Scalars['ID'];
};

export type QueryAllTeamMembersArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TeamMemberFilter>;
};

export type Query_AllTeamMembersMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TeamMemberFilter>;
};

export type QueryAllUsersNotInTeamArgs = {
  teamId?: Maybe<Scalars['ID']>;
  q?: Maybe<Scalars['String']>;
};

export type QueryTeamMemberArgs = {
  id: Scalars['ID'];
};

export type QueryGetVersusTeamMemberArgs = {
  limit?: Maybe<Scalars['Int']>;
};

export type QueryGetCustomTeamMembersArgs = {
  limit?: Maybe<Scalars['Int']>;
  ownerIn?: Maybe<Scalars['Boolean']>;
};

export type QueryAllFilesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FileFilter>;
};

export type Query_AllFilesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<FileFilter>;
};

export type QueryFileArgs = {
  id: Scalars['ID'];
};

export type Query_TournamentBracketArgs = {
  id: Scalars['ID'];
};

export type QueryAllPagesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<PageFilter>;
};

export type Query_AllPagesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<PageFilter>;
};

export type QueryPageArgs = {
  id: Scalars['ID'];
};

export type QueryPageBySlugArgs = {
  slug: Scalars['String'];
};

export type QueryAllPageContentsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<PageFilter>;
};

export type Query_AllPageContentsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<PageFilter>;
};

export type QueryPageContentArgs = {
  id: Scalars['ID'];
};

export type QueryMyCodModernWarefareStatArgs = {
  platform?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type QueryMyBo4StatArgs = {
  plateform?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type QueryWzstatsDotGgArgs = {
  plateform?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type QueryWzstatsDotGgPlayerRecentMatchArgs = {
  plateform?: Maybe<Scalars['String']>;
  playerUsername?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type QueryWzstatsDotGgGameMatchDetailArgs = {
  matchId: Scalars['String'];
  userId?: Maybe<Scalars['ID']>;
};

export type QueryModernWarefarecodTrackerDotGgStatsArgs = {
  username?: Maybe<Scalars['String']>;
  plateform?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type QueryBlackops4codTrackerDotGGstatsArgs = {
  username?: Maybe<Scalars['String']>;
  plateform?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type QueryCodWarezoneTrackerDotGgLeaderboardArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  plateform?: Maybe<Scalars['String']>;
};

export type QueryCodMwTrackerDotGgLeaderboardArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  plateform?: Maybe<Scalars['String']>;
};

export type QueryCodBo4TrackerDotGgLeaderboardArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  plateform?: Maybe<Scalars['String']>;
};

export type QueryMyApexLegendStatArgs = {
  plateform?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type QueryApexMatchHistoryBrokenBySessionArgs = {
  plateform?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type QueryMyForniteBrStatArgs = {
  platform?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type QueryMyForniteBrRecentMatchesArgs = {
  platform?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type QueryPlayerPowerRankingArgs = {
  plateform?: Maybe<Fortine_Plateform>;
  region?: Maybe<Scalars['String']>;
  epic?: Maybe<Scalars['String']>;
};

export type QueryMyr6StatsArgs = {
  platform?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type QueryAllLeaguesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<LeagueFilter>;
};

export type Query_AllLeaguesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<LeagueFilter>;
};

export type QueryLeagueArgs = {
  id: Scalars['ID'];
};

export type QueryAllLeagueRulesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<LeagueRuleFilter>;
};

export type Query_AllLeagueRulesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<LeagueRuleFilter>;
};

export type QueryLeagueRuleArgs = {
  id: Scalars['ID'];
};

export type QueryAllLeagueAwardsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<LeagueAwardFilter>;
};

export type Query_AllLeagueAwardsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<LeagueAwardFilter>;
};

export type QueryLeagueAwardArgs = {
  id: Scalars['ID'];
};

export type QueryAllActualitiesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<ActualityFilter>;
};

export type Query_AllActualitiesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<ActualityFilter>;
};

export type QueryActualityArgs = {
  id: Scalars['ID'];
};

export type QueryFut21PlayerStatArgs = {
  page?: Maybe<Scalars['Int']>;
};

export type QueryAllWagersArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<WagerFilter>;
};

export type Query_AllWagersMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<WagerFilter>;
};

export type QueryWagerArgs = {
  id: Scalars['ID'];
};

export type QueryAllNotificationsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<NotificationFilter>;
};

export type Query_AllNotificationsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<NotificationFilter>;
};

export type QueryNotificationArgs = {
  id: Scalars['ID'];
};

export type QueryMyNotificationsArgs = {
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};

export type QueryAllMapsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<MapFilter>;
};

export type Query_AllMapsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<MapFilter>;
};

export type QueryMapArgs = {
  id: Scalars['ID'];
};

export type QueryAllGameserversArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<GameserverFilter>;
};

export type Query_AllGameserversMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<GameserverFilter>;
};

export type QueryGameserverArgs = {
  id: Scalars['ID'];
};

export type QueryAllRegionsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<RegionFilter>;
};

export type Query_AllRegionsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<RegionFilter>;
};

export type QueryRegionArgs = {
  id: Scalars['ID'];
};

export type QueryAllAudiochatplateformsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<AudiochatplateformFilter>;
};

export type Query_AllAudiochatplateformsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<AudiochatplateformFilter>;
};

export type QueryAudiochatplateformArgs = {
  id: Scalars['ID'];
};

export type QueryAllGamemodesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<GamemodeFilter>;
};

export type Query_AllGamemodesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<GamemodeFilter>;
};

export type QueryGamemodeArgs = {
  id: Scalars['ID'];
};

export type QueryAllGameformatsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<GameformatFilter>;
};

export type Query_AllGameformatsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<GameformatFilter>;
};

export type QueryGameformatArgs = {
  id: Scalars['ID'];
};

export type QueryAllCountriesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<CountryFilter>;
};

export type Query_AllCountriesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<CountryFilter>;
};

export type QueryCountryArgs = {
  id: Scalars['ID'];
};

export type Query_GameserverArgs = {
  id: Scalars['ID'];
};

export type QueryActiveTwitchLiveArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  gameId?: Maybe<Scalars['ID']>;
};

export type QueryAllWalletsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<WalletFilter>;
};

export type Query_AllWalletsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<WalletFilter>;
};

export type QueryWalletArgs = {
  id: Scalars['ID'];
};

export type QueryAllWallettransactionsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<WallettransactionFilter>;
};

export type Query_AllWallettransactionsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<WallettransactionFilter>;
};

export type QueryWallettransactionArgs = {
  id: Scalars['ID'];
};

export type QueryAllWalletOrdersArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<WalletOrderFilter>;
};

export type Query_AllWalletOrdersMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<WalletOrderFilter>;
};

export type QueryWalletOrderArgs = {
  id: Scalars['ID'];
};

export type QueryAllOrdersArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<OrderFilter>;
};

export type Query_AllOrdersMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<OrderFilter>;
};

export type QueryOrderArgs = {
  id: Scalars['ID'];
};

export type QueryAllTournamentWinnersArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TournamentWinnerFilter>;
};

export type Query_AllTournamentWinnersMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TournamentWinnerFilter>;
};

export type QueryTournamentWinnerArgs = {
  id: Scalars['ID'];
};

export type Query_TournamentAwardArgs = {
  id: Scalars['ID'];
};

export type QueryRocketLeagueTrackerGgStatsArgs = {
  plateform?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type QueryRocketLeagueTrackerGgLeaderboardsArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  plateform?: Maybe<Scalars['String']>;
};

export type QueryAllChallengesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<ChallengeFilter>;
};

export type Query_AllChallengesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<ChallengeFilter>;
};

export type QueryChallengeArgs = {
  id: Scalars['ID'];
};

export type QueryAllChatsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<ChatFilter>;
};

export type Query_AllChatsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<ChatFilter>;
};

export type QueryChatArgs = {
  id: Scalars['ID'];
};

export type QueryAllChatmembersArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<ChatmemberFilter>;
};

export type Query_AllChatmembersMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<ChatmemberFilter>;
};

export type QueryChatmemberArgs = {
  id: Scalars['ID'];
};

export type Query_GamepartyArgs = {
  id: Scalars['ID'];
};

export type QueryAllLeaderboardpointsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<LeaderboardpointFilter>;
};

export type Query_AllLeaderboardpointsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<LeaderboardpointFilter>;
};

export type QueryLeaderboardpointArgs = {
  id: Scalars['ID'];
};

export type QueryUserLeaderboardArgs = {
  limit?: Maybe<Scalars['Int']>;
};

export type QueryCurrentLeaderboardArgs = {
  limit?: Maybe<Scalars['Int']>;
  type?: Maybe<Leaderboard_Type>;
};

export type QueryRankingUserPointArgs = {
  limit?: Maybe<Scalars['Int']>;
};

export type QueryAllBadgesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<BadgeFilter>;
};

export type Query_AllBadgesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<BadgeFilter>;
};

export type QueryBadgeArgs = {
  id: Scalars['ID'];
};

export type QueryAllGamepartysArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<GamepartyFilter>;
};

export type Query_AllGamepartysMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<GamepartyFilter>;
};

export type QueryGamepartyArgs = {
  id: Scalars['ID'];
};

export type QueryAllGamepartymembersArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<GamepartymemberFilter>;
};

export type Query_AllGamepartymembersMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<GamepartymemberFilter>;
};

export type QueryGamepartymemberArgs = {
  id: Scalars['ID'];
};

export type QueryAllSocialaccountsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<SocialaccountFilter>;
};

export type Query_AllSocialaccountsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<SocialaccountFilter>;
};

export type QuerySocialaccountArgs = {
  id: Scalars['ID'];
};

export type QueryFindSocialAccountByLabelArgs = {
  label: Scalars['String'];
};

export type QueryFindUserSocialAccountByLabelArgs = {
  label: Scalars['String'];
};

export type QueryHasUserSocialAccountArgs = {
  socialAccountId: Scalars['ID'];
};

export type QueryAllUsersocialaccountsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<UsersocialaccountFilter>;
};

export type Query_AllUsersocialaccountsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<UsersocialaccountFilter>;
};

export type QueryUsersocialaccountArgs = {
  id: Scalars['ID'];
};

export type QueryAllSaisonsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<SaisonFilter>;
};

export type Query_AllSaisonsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<SaisonFilter>;
};

export type QuerySaisonArgs = {
  id: Scalars['ID'];
};

export type QueryGetTextContentArgs = {
  section?: Maybe<Scalars['String']>;
  textType?: Maybe<Text_Type>;
  index?: Maybe<Scalars['Int']>;
};

export type QueryAllTextContentsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TextContentFilter>;
};

export type QueryTextContentArgs = {
  id: Scalars['ID'];
};

export type Query_AllTextContentsMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TextContentFilter>;
};

export type QueryAllAssistancepagesArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<AssistancepageFilter>;
};

export type Query_AllAssistancepagesMetaArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<AssistancepageFilter>;
};

export type QueryAssistancepageArgs = {
  id: Scalars['ID'];
};

export type QueryAssistancePageArgs = {
  slug: Scalars['String'];
};

export type QuerySearchAssistanceArgs = {
  query: Scalars['String'];
};

export type QueryDiscordChannelArgs = {
  channelId: Scalars['String'];
};

export type QueryDiscordChannelInviteLinkArgs = {
  channelId: Scalars['String'];
};

export type QueryAllDiscordChannelGuildsArgs = {
  params?: Maybe<Scalars['JSON']>;
};

export type QueryAllMessagesChannelArgs = {
  channelId: Scalars['String'];
  params?: Maybe<ParamsMessageInput>;
};

export type QueryFindMessageChannelArgs = {
  input: FindDiscordMessageInput;
};

export type QueryFindWebhookByUserAndChannelArgs = {
  input: FindWebhookInput;
};

export type QueryAllGuildRoleArgs = {
  input?: Maybe<FindGuildRoleInput>;
};

export type QueryGuildRoleArgs = {
  input?: Maybe<FindGuildRoleInput>;
};

export type QueryFindPinnedMessagesArgs = {
  input?: Maybe<FindPinnedMessageInput>;
};

export type QueryFindGuildMemberArgs = {
  input: FindGuildMemberInput;
};

export type R6Stat = {
  __typename?: 'R6Stat';
  id?: Maybe<Scalars['String']>;
  pvp?: Maybe<Scalars['JSON']>;
  pve?: Maybe<Scalars['JSON']>;
};

export type RankNameValue = 'bronze' | 'silver' | 'gold' | 'diamond' | 'master';

export type RankPositionValue = 'niv_0' | 'niv_1' | 'niv_2' | 'niv_3' | 'niv_4' | 'niv_5';

export type RapidapiCodMwMultiplayerMatches = {
  __typename?: 'RapidapiCodMWMultiplayerMatches';
  summary?: Maybe<Scalars['JSON']>;
  matches?: Maybe<Scalars['JSON']>;
};

export type RapidapiCodMwWarZoneMatch = {
  __typename?: 'RapidapiCodMWWarZoneMatch';
  summary?: Maybe<Scalars['JSON']>;
  matches?: Maybe<Scalars['JSON']>;
};

export type RapidapiCodModernWarfareStat = {
  __typename?: 'RapidapiCodModernWarfareStat';
  warzoneStats?: Maybe<RapidapiCodWarZoneStat>;
  warzoneMatches?: Maybe<RapidapiCodMwWarZoneMatch>;
  multiplayerStats?: Maybe<Scalars['JSON']>;
  multiplayerMatches?: Maybe<RapidapiCodMwMultiplayerMatches>;
};

export type RapidapiCodWarZoneStat = {
  __typename?: 'RapidapiCodWarZoneStat';
  br?: Maybe<Scalars['JSON']>;
  br_all?: Maybe<Scalars['JSON']>;
  br_dmz?: Maybe<Scalars['JSON']>;
};

export type RecentCodMwMatchDetails = {
  __typename?: 'RecentCodMWMatchDetails';
  summary?: Maybe<Scalars['JSON']>;
  matches?: Maybe<Scalars['JSON']>;
};

export type Region = {
  __typename?: 'Region';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type RegionFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type RemoveCouponInput = {
  id: Scalars['ID'];
};

export type RemoveGameInput = {
  id: Scalars['ID'];
};

export type RemoveSubscriptionInput = {
  id: Scalars['ID'];
};

export type RemoveTournamentFormatInput = {
  id: Scalars['ID'];
};

export type RemoveUserInTeamInput = {
  userId: Scalars['ID'];
  teamId: Scalars['ID'];
};

export type RemoveUserTypingInput = {
  threadId: Scalars['String'];
  userId: Scalars['String'];
};

export type ReorganizeUserInTournamentInput = {
  tournamentId: Scalars['ID'];
  userId: Scalars['ID'];
  otherUserId: Scalars['ID'];
};

export type Report = {
  __typename?: 'Report';
  id: Scalars['ID'];
  description: Scalars['String'];
  author: User;
  authorId: Scalars['ID'];
  userReported: User;
  userReportedId: Scalars['ID'];
  type: ReportType;
  /** content: String */
  removed: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReportActionContent = Tournament | TournamentMessage;

export type ReportType = 'message' | 'tournament';

export type ReportUserActionInput = {
  userId: Scalars['ID'];
  description: Scalars['String'];
  messageId?: Maybe<Scalars['ID']>;
  tournamentId?: Maybe<Scalars['ID']>;
};

export type ReportsFilterInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type ReportsResult = {
  __typename?: 'ReportsResult';
  results: Array<Report>;
  total: Scalars['Int'];
};

export type RocketLeagueTrackerGgLeaderBoardItem = {
  __typename?: 'RocketLeagueTrackerGGLeaderBoardItem';
  id?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['JSON']>;
  value?: Maybe<Scalars['Float']>;
  displayValue?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['Int']>;
  iconUrl?: Maybe<Scalars['String']>;
};

export type RocketLeagueTrackerGgStat = {
  __typename?: 'RocketLeagueTrackerGGStat';
  profilStatOverView?: Maybe<Scalars['JSON']>;
  recentMatches?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  playlistAverage?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

export type RoleTags = {
  __typename?: 'RoleTags';
  bot_id?: Maybe<Scalars['String']>;
  integration_id?: Maybe<Scalars['String']>;
  premium_subscriber?: Maybe<Scalars['String']>;
};

export type RuleInput = {
  name: Scalars['String'];
  description: Scalars['String'];
};

export type Social_App =
  | 'FACEBOOK'
  | 'TWITTER'
  | 'TWITCH'
  | 'GOOGLE'
  | 'DISCORD'
  | 'MICROSOFT'
  | 'BATTLENET';

export type Saison = {
  __typename?: 'Saison';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type SaisonFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type SetMatchPointInput = {
  matchId: Scalars['ID'];
  pointA: Scalars['Int'];
  pointB: Scalars['Int'];
  winner?: Maybe<MatchWinnerValue>;
};

export type SignupInput = {
  password: Scalars['String'];
  birthDate?: Maybe<Scalars['Date']>;
  email: Scalars['EmailAddress'];
  avatar?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  language?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  devise?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
};

export type Socialaccount = {
  __typename?: 'Socialaccount';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type SocialaccountFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type SortEnum = 'ASC' | 'DESC';

export type StartVideoStreamInput = {
  videoId: Scalars['ID'];
};

export type SteamGame = {
  __typename?: 'SteamGame';
  appId: Scalars['String'];
  name: Scalars['String'];
};

export type SteamGameResult = {
  __typename?: 'SteamGameResult';
  results: Array<SteamGame>;
  total: Scalars['Int'];
};

export type SteamGamesFilterInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search: Scalars['String'];
};

export type StreamStatus = 'requestLive' | 'live' | 'finished';

/**
 * input WagerInput {
 *   gameId: ID!
 *   entryAmount: Float!
 *   isTeam: Boolean
 *   teamSize: Int!
 *   competitionType: String
 *   allowed: [String]
 *   support: String
 *   rule: String
 *   seriesType: String
 *   team1: ID
 *   opponent1: ID
 *   startDate: DateTime!
 * }
 */
export type SubscribeToPremiumInput = {
  subscriptionTypeId?: Maybe<Scalars['ID']>;
  isTrial?: Maybe<Scalars['Boolean']>;
  payment?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  gameAdded: Game;
  tournamentStarted: Tournament;
  tournamentFinished: Tournament;
  /** Match */
  matchPointSet: Match;
  matchFinished: FinishMatchReturn;
  matchFileAdded: Match;
  matchFileRemoved: Match;
  userSubscribedInTournament: UserSubscriptionToTournamentReturn;
  tournamentAdded: Tournament;
  /** Chat */
  tournamentMessageAdded: TournamentMessage;
  tournamentMessageRemoved: TournamentMessage;
  /** Video */
  videoStreamFinished: Video;
  videoStreamBegin: Video;
  videoAdded: Video;
  videoRemoved: Video;
  videoEdited: Video;
  videoThumbnailCreated: Video;
  /** Chat thread */
  userTyping: User;
  messageThreadAdded: Message;
  messageThreadEdit: Message;
  messageThreadRemoved: Message;
  userOnline: User;
  userOffline: User;
  newInvitationGameParty: User;
  newMessageChannelDiscord?: Maybe<DiscordChannelMessage>;
  deleteMessageChannelDiscord?: Maybe<DiscordChannelMessage>;
};

export type SubscriptionTournamentStartedArgs = {
  id: Scalars['ID'];
};

export type SubscriptionTournamentFinishedArgs = {
  id: Scalars['ID'];
};

export type SubscriptionMatchPointSetArgs = {
  tournamentId: Scalars['ID'];
};

export type SubscriptionMatchFinishedArgs = {
  tournamentId: Scalars['ID'];
};

export type SubscriptionMatchFileAddedArgs = {
  tournamentId: Scalars['ID'];
};

export type SubscriptionMatchFileRemovedArgs = {
  tournamentId: Scalars['ID'];
};

export type SubscriptionUserSubscribedInTournamentArgs = {
  tournamentId: Scalars['ID'];
};

export type SubscriptionTournamentMessageAddedArgs = {
  tournamentId: Scalars['ID'];
};

export type SubscriptionTournamentMessageRemovedArgs = {
  tournamentId: Scalars['ID'];
};

export type SubscriptionUserTypingArgs = {
  threadId: Scalars['ID'];
};

export type SubscriptionMessageThreadAddedArgs = {
  threadId: Scalars['ID'];
};

export type SubscriptionMessageThreadEditArgs = {
  threadId: Scalars['ID'];
};

export type SubscriptionMessageThreadRemovedArgs = {
  threadId: Scalars['ID'];
};

export type SubscriptionNewMessageChannelDiscordArgs = {
  channelId: Scalars['String'];
};

export type SubscriptionDeleteMessageChannelDiscordArgs = {
  channelId: Scalars['String'];
};

export type SubscriptionType = {
  __typename?: 'SubscriptionType';
  id: Scalars['ID'];
  totalMonth: Scalars['Int'];
  pricePerMonth: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Team_Role = 'OWNER' | 'MEMBER';

export type Text_Type = 'TITLE' | 'PARAGRAPH';

export type Tournament_Format_Type = 'oneVsOne' | 'teamVsTeam';

export type Team = {
  __typename?: 'Team';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  /** author: User */
  authorId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** userNotInTeams(filter: UserNotInTeamsFilter): UserResult */
  removed?: Maybe<Scalars['Boolean']>;
  gamePlateform?: Maybe<GamePlatform>;
  point?: Maybe<Scalars['Float']>;
  /** All tournaments that the team had joined */
  joinedParticipationTournaments?: Maybe<Array<Maybe<TournamentParticipant>>>;
  joinedWagers?: Maybe<Array<Maybe<TournamentParticipant>>>;
  joinedLeagues?: Maybe<Array<Maybe<TournamentParticipant>>>;
  tag?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  discordChannelId?: Maybe<Scalars['String']>;
  discordVocalChannelId?: Maybe<Scalars['String']>;
  members?: Maybe<Array<Maybe<TeamMember>>>;
  owner?: Maybe<TeamMember>;
};

export type TeamFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type TeamMember = {
  __typename?: 'TeamMember';
  id: Scalars['ID'];
  _id?: Maybe<Scalars['ID']>;
  role?: Maybe<Team_Role>;
  team?: Maybe<Team>;
  user?: Maybe<User>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type TeamMemberFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type TeamResult = {
  __typename?: 'TeamResult';
  results: Array<Team>;
  total: Scalars['Int'];
};

export type TeamSubscriptionToTournamentInput = {
  tournamentId: Scalars['ID'];
  teamId: Scalars['ID'];
};

export type TeamSubscriptionToTournamentReturn = {
  __typename?: 'TeamSubscriptionToTournamentReturn';
  tournamentId: Scalars['ID'];
  team: Team;
};

export type TeamsTournamentFilterInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  tournamentId: Scalars['String'];
};

export type TextContent = {
  __typename?: 'TextContent';
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  contentFr?: Maybe<Scalars['String']>;
  contentEn?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  textType?: Maybe<Text_Type>;
  removed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type TextContentFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type Thread = {
  __typename?: 'Thread';
  id: Scalars['ID'];
  title: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  author?: Maybe<User>;
  authorId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isPrivate?: Maybe<Scalars['Boolean']>;
  isRemoved?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
  messages: Array<Maybe<Message>>;
  usersTyping?: Maybe<Array<Maybe<User>>>;
  totalMessage: Scalars['Int'];
};

export type ThreadMessagesArgs = {
  filter?: Maybe<Filter>;
};

export type ThreadResult = {
  __typename?: 'ThreadResult';
  results: Array<Thread>;
  total: Scalars['Int'];
};

export type TopGamePayload = {
  __typename?: 'TopGamePayload';
  game?: Maybe<Game>;
  percent?: Maybe<Scalars['Float']>;
};

export type Tournament = {
  __typename?: 'Tournament';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  title_en?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  description_en?: Maybe<Scalars['String']>;
  maxParticipants?: Maybe<Scalars['Int']>;
  minParticipants?: Maybe<Scalars['Int']>;
  gameSlug?: Maybe<Scalars['String']>;
  isSignupEnd?: Maybe<Scalars['Boolean']>;
  /** Game id */
  gameId?: Maybe<Scalars['String']>;
  /** Game plateform id */
  gamePlateformId?: Maybe<Scalars['String']>;
  /** Tournament format id */
  formatId?: Maybe<Scalars['ID']>;
  game?: Maybe<Game>;
  gamePlateform?: Maybe<GamePlatform>;
  format?: Maybe<TournamentFormat>;
  roundNumber?: Maybe<Scalars['Int']>;
  endAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organiserId?: Maybe<Scalars['ID']>;
  organiser?: Maybe<Organiser>;
  status: TournamentStatus;
  entryCost?: Maybe<Scalars['Float']>;
  signupStartedAt?: Maybe<Scalars['DateTime']>;
  signupEndAt?: Maybe<Scalars['DateTime']>;
  descordUrl?: Maybe<Scalars['String']>;
  entryType?: Maybe<TournamentEntryType>;
  rounds?: Maybe<Array<Maybe<Tournamentround>>>;
  roundIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Duration in day count */
  durationDay?: Maybe<Scalars['Int']>;
  /** Current user ranking */
  myRanking?: Maybe<Tournamentranking>;
  /** Discord channel schema */
  discordChannelId?: Maybe<Scalars['String']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  welcomeMessage_en?: Maybe<Scalars['String']>;
  featuredImage?: Maybe<Scalars['String']>;
  author?: Maybe<User>;
  authorId?: Maybe<Scalars['ID']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  awards?: Maybe<Array<Maybe<TournamentAward>>>;
  awardIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  totalAwardValue?: Maybe<Scalars['Float']>;
  rewardsTotal: Scalars['Int'];
  rules?: Maybe<Array<Maybe<TournamentRule>>>;
  rulesId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  participants?: Maybe<Array<Maybe<TournamentParticipant>>>;
  isMeAlreadyParticipant?: Maybe<Scalars['Boolean']>;
  signupAt?: Maybe<Scalars['DateTime']>;
  participantIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  bracketsId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  brackets?: Maybe<Array<Maybe<TournamentBracket>>>;
  mapId?: Maybe<Scalars['ID']>;
  map?: Maybe<Map>;
  gameServerId?: Maybe<Scalars['ID']>;
  gameserver?: Maybe<Gameserver>;
  regionId?: Maybe<Scalars['ID']>;
  region?: Maybe<Region>;
  audiochatId?: Maybe<Scalars['ID']>;
  audiochat?: Maybe<Audiochatplateform>;
  gameModeId?: Maybe<Scalars['ID']>;
  gamemode?: Maybe<Gamemode>;
  gameformatId?: Maybe<Scalars['ID']>;
  gameformat?: Maybe<Gameformat>;
  saison?: Maybe<Saison>;
};

export type TournamentAward = {
  __typename?: 'TournamentAward';
  id: Scalars['ID'];
  winnerParticipants?: Maybe<Array<Maybe<TournamentParticipant>>>;
  winnerParticipantIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  title?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
  startRank?: Maybe<Scalars['Int']>;
  endRank?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  sponsorName?: Maybe<Scalars['String']>;
  sponsorImage?: Maybe<Scalars['String']>;
  type?: Maybe<Award_Type>;
  tournament?: Maybe<Tournament>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['ID']>;
  author?: Maybe<User>;
  removed?: Maybe<Scalars['Boolean']>;
  winnerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  winners?: Maybe<Array<Maybe<TournamentWinner>>>;
};

export type TournamentAwardFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type TournamentAwardInput = {
  title?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
  startRank?: Maybe<Scalars['Int']>;
  endRank?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  sponsorName?: Maybe<Scalars['String']>;
  sponsorImage?: Maybe<Scalars['String']>;
  type?: Maybe<Award_Type>;
  title_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
};

export type TournamentBracket = {
  __typename?: 'TournamentBracket';
  id: Scalars['ID'];
  round?: Maybe<Scalars['Int']>;
  generatedTitle?: Maybe<Scalars['String']>;
  participant1?: Maybe<TournamentParticipant>;
  participant2?: Maybe<TournamentParticipant>;
  participant1Id?: Maybe<Scalars['ID']>;
  participant2Id?: Maybe<Scalars['ID']>;
  /** Bracket result */
  winner?: Maybe<TournamentParticipant>;
  pointParticipant1?: Maybe<Scalars['Int']>;
  pointParticipant2?: Maybe<Scalars['Int']>;
  isLive?: Maybe<Scalars['Boolean']>;
  isFinished?: Maybe<Scalars['Boolean']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  endedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  tournament?: Maybe<Scalars['String']>;
  chatEngineId?: Maybe<Scalars['String']>;
  chatEngineAccessKey?: Maybe<Scalars['String']>;
  isMeCanEdit?: Maybe<Scalars['Boolean']>;
  attachments?: Maybe<Array<Maybe<File>>>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type TournamentBracketFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type TournamentEntryType = 'public' | 'invitation';

export type TournamentFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type TournamentFilterInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  gameId?: Maybe<Scalars['ID']>;
};

export type TournamentFormat = {
  __typename?: 'TournamentFormat';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Tournament_Format_Type>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  removed?: Maybe<Scalars['Boolean']>;
  tournaments?: Maybe<Array<Maybe<Tournament>>>;
  authorId?: Maybe<Scalars['ID']>;
  author?: Maybe<User>;
  title_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
};

export type TournamentFormatFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type TournamentFormatType = 'oneVsOne' | 'teamVsTeam';

export type TournamentGain = {
  __typename?: 'TournamentGain';
  id: Scalars['ID'];
  label: Scalars['String'];
  price: Scalars['Float'];
  unity?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  removed?: Maybe<Scalars['Boolean']>;
};

export type TournamentInformationPayload = {
  __typename?: 'TournamentInformationPayload';
  totalTournamentCashPrize?: Maybe<Scalars['Float']>;
  totalMonthTournament?: Maybe<Scalars['Int']>;
  totalTournamentParticipant?: Maybe<Scalars['Int']>;
  topGame?: Maybe<TopGamePayload>;
};

export type TournamentMessage = {
  __typename?: 'TournamentMessage';
  id: Scalars['ID'];
  tournamentId: Scalars['ID'];
  tournament: Tournament;
  content: TournamentMessageContent;
  parentId?: Maybe<Scalars['ID']>;
  parent?: Maybe<TournamentMessage>;
  authorId: Scalars['ID'];
  author: User;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<MessageType>;
  isMine: Scalars['Boolean'];
  isRemoved: Scalars['Boolean'];
};

export type TournamentMessageContent = MessageFile | MessageText;

export type TournamentMessagesResult = {
  __typename?: 'TournamentMessagesResult';
  results: Array<TournamentMessage>;
  total: Scalars['Int'];
};

export type TournamentParticipant = {
  __typename?: 'TournamentParticipant';
  id: Scalars['ID'];
  awardId?: Maybe<Scalars['ID']>;
  award?: Maybe<TournamentAward>;
  diplayName?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
  team?: Maybe<Team>;
  teamId?: Maybe<Scalars['ID']>;
  wager?: Maybe<Wager>;
  tournament?: Maybe<Tournament>;
  league?: Maybe<League>;
  createdAt?: Maybe<Scalars['DateTime']>;
  point?: Maybe<Scalars['Float']>;
  brackets?: Maybe<Array<Maybe<TournamentBracket>>>;
  leagueTotalPoint?: Maybe<Scalars['Int']>;
  winingAwardId?: Maybe<Scalars['ID']>;
  winingAward?: Maybe<TournamentWinner>;
};

export type TournamentParticipantFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  tournament?: Maybe<Scalars['ID']>;
};

export type TournamentParticipants = ListUser | ListTeam;

export type TournamentResult = {
  __typename?: 'TournamentResult';
  results: Array<Tournament>;
  total: Scalars['Int'];
};

export type TournamentRule = {
  __typename?: 'TournamentRule';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  removed?: Maybe<Scalars['Boolean']>;
  authorId?: Maybe<Scalars['ID']>;
  author?: Maybe<User>;
  name_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
};

export type TournamentRuleFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type TournamentRuleInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  name_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
};

export type TournamentStatus = 'waiting' | 'started' | 'ended' | 'configuring';

export type TournamentTeamWinners = {
  __typename?: 'TournamentTeamWinners';
  id: Scalars['ID'];
  isTeam: Scalars['Boolean'];
  first?: Maybe<Team>;
  second?: Maybe<Team>;
  third?: Maybe<Team>;
};

export type TournamentUserWinners = {
  __typename?: 'TournamentUserWinners';
  id: Scalars['ID'];
  isTeam: Scalars['Boolean'];
  first?: Maybe<User>;
  second?: Maybe<User>;
  third?: Maybe<User>;
};

export type TournamentWinner = {
  __typename?: 'TournamentWinner';
  id: Scalars['ID'];
  tournamentAwardId?: Maybe<Scalars['ID']>;
  tournamentParticipantId?: Maybe<Scalars['ID']>;
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type TournamentWinnerFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type TournamentWinners = TournamentUserWinners | TournamentTeamWinners;

export type Tournamentranking = {
  __typename?: 'Tournamentranking';
  id: Scalars['ID'];
  rank?: Maybe<Scalars['Int']>;
  tournament?: Maybe<Tournament>;
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type TournamentrankingFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type Tournamentround = {
  __typename?: 'Tournamentround';
  id?: Maybe<Scalars['ID']>;
  roundNumber?: Maybe<Scalars['Int']>;
  /** Current bracket number */
  currentBracketNumber?: Maybe<Scalars['Int']>;
  roundDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  bracketIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  brackets?: Maybe<Array<Maybe<TournamentBracket>>>;
};

export type TournamentroundFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type TournamentroundInput = {
  roundNumber?: Maybe<Scalars['Int']>;
  roundDate?: Maybe<Scalars['Date']>;
};

export type Training = {
  __typename?: 'Training';
  id: Scalars['ID'];
  trainingGameName?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  jacketImage?: Maybe<Scalars['String']>;
  trainingPrograms: Array<TrainingProgram>;
};

export type TrainingDetail = {
  __typename?: 'TrainingDetail';
  id: Scalars['ID'];
  trainingGameName?: Maybe<Scalars['String']>;
  parent: Scalars['ID'];
  advice?: Maybe<Scalars['String']>;
  about?: Maybe<TrainingDetailAbout>;
  system?: Maybe<TrainingDetailSystem>;
  trainingPrograms?: Maybe<Array<Maybe<TrainingProgram>>>;
  logo?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
};

export type TrainingDetailAbout = {
  __typename?: 'TrainingDetailAbout';
  basicIntro?: Maybe<Scalars['String']>;
  perks?: Maybe<Array<Maybe<Scalars['String']>>>;
  skills?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TrainingDetailAboutInput = {
  basicIntro?: Maybe<Scalars['String']>;
  perks?: Maybe<Array<Maybe<Scalars['String']>>>;
  skills?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TrainingDetailSystem = {
  __typename?: 'TrainingDetailSystem';
  description?: Maybe<Scalars['String']>;
  steps?: Maybe<Array<Maybe<Scalars['String']>>>;
  assignment?: Maybe<Scalars['String']>;
};

export type TrainingDetailSystemInput = {
  description?: Maybe<Scalars['String']>;
  steps?: Maybe<Array<Maybe<Scalars['String']>>>;
  assignment?: Maybe<Scalars['String']>;
};

export type TrainingProgram = {
  __typename?: 'TrainingProgram';
  id: Scalars['ID'];
  programName?: Maybe<Scalars['String']>;
  programLevel?: Maybe<Scalars['String']>;
  programEstimateDuration?: Maybe<Scalars['String']>;
  programDescription?: Maybe<Scalars['String']>;
  progression?: Maybe<Scalars['Int']>;
  training: Scalars['ID'];
};

export type Transaction = {
  __typename?: 'Transaction';
  id: Scalars['ID'];
  description: Scalars['String'];
  payment?: Maybe<Scalars['String']>;
  etat?: Maybe<Scalars['String']>;
  total: Scalars['String'];
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  subscriptionId?: Maybe<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type TwitchLive = {
  __typename?: 'TwitchLive';
  id?: Maybe<Scalars['ID']>;
  user_login?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
  game_id?: Maybe<Scalars['String']>;
  game_name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  viewer_count?: Maybe<Scalars['Int']>;
  started_at?: Maybe<Scalars['DateTime']>;
  language?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  tag_ids?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  is_mature?: Maybe<Scalars['Boolean']>;
};

export type TwitterAuthorizationOAuth = {
  __typename?: 'TwitterAuthorizationOAuth';
  oauth_callback?: Maybe<Scalars['String']>;
  oauth_consumer_key?: Maybe<Scalars['String']>;
  oauth_token?: Maybe<Scalars['String']>;
  oauth_nonce?: Maybe<Scalars['String']>;
  oauth_signature?: Maybe<Scalars['String']>;
  oauth_signature_method?: Maybe<Scalars['String']>;
  oauth_timestamp?: Maybe<Scalars['String']>;
  oauth_version?: Maybe<Scalars['String']>;
};

export type TwitterOauthAccessToken = {
  __typename?: 'TwitterOauthAccessToken';
  oauth_token?: Maybe<Scalars['String']>;
  oauth_token_secret?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  screen_name?: Maybe<Scalars['String']>;
};

export type TwitterOauthToken = {
  __typename?: 'TwitterOauthToken';
  oauth_token?: Maybe<Scalars['String']>;
  oauth_token_secret?: Maybe<Scalars['String']>;
  oauth_callback_confirmed?: Maybe<Scalars['String']>;
};

export type TwitterOauthTokenResponse = {
  __typename?: 'TwitterOauthTokenResponse';
  token_type?: Maybe<Scalars['String']>;
  expires_in?: Maybe<Scalars['Int']>;
  access_token?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
};

export type TwitterUser = {
  __typename?: 'TwitterUser';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type TwitterUserAuth = {
  __typename?: 'TwitterUserAuth';
  providerId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  screen_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
};

export type User_Role = 'admin' | 'moderator' | 'gamer';

export type UpdatePositionGuildRoleInput = {
  id: Scalars['String'];
  position: Scalars['Int'];
  guildId: Scalars['String'];
};

export type UpdateTournamentInput = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  title_en?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  maxParticipants?: Maybe<Scalars['Int']>;
  minParticipants?: Maybe<Scalars['Int']>;
  formatId?: Maybe<Scalars['ID']>;
  roundNumber?: Maybe<Scalars['Int']>;
  newAwards?: Maybe<Array<Maybe<TournamentAwardInput>>>;
  newRules?: Maybe<Array<Maybe<TournamentRuleInput>>>;
  newOrganiserId?: Maybe<Scalars['ID']>;
  entryCost?: Maybe<Scalars['Float']>;
  signupStartedAt?: Maybe<Scalars['DateTime']>;
  signupEndAt?: Maybe<Scalars['DateTime']>;
  mapId?: Maybe<Scalars['ID']>;
  gameServerId?: Maybe<Scalars['ID']>;
  audiochatId?: Maybe<Scalars['ID']>;
  regionId?: Maybe<Scalars['ID']>;
  descordUrl?: Maybe<Scalars['String']>;
  entryType?: Maybe<TournamentEntryType>;
  newRounds?: Maybe<Array<Maybe<TournamentroundInput>>>;
  gameformatId?: Maybe<Scalars['ID']>;
  gameModeId?: Maybe<Scalars['ID']>;
  gameSlug?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['DateTime']>;
  saisonId?: Maybe<Scalars['ID']>;
  welcomeMessage?: Maybe<Scalars['String']>;
  welcomeMessage_en?: Maybe<Scalars['String']>;
  featuredImage?: Maybe<Scalars['String']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
};

export type User = {
  __typename?: 'User';
  id?: Maybe<Scalars['ID']>;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['EmailAddress']>;
  avatar?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  followings?: Maybe<Array<User>>;
  followers?: Maybe<Array<User>>;
  isBanned?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  devise?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isMe?: Maybe<Scalars['Boolean']>;
  posts?: Maybe<PostResult>;
  teams?: Maybe<TeamResult>;
  subscription?: Maybe<SubscriptionType>;
  phoneNumber?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  games?: Maybe<GamesResult>;
  trainings?: Maybe<Array<Training>>;
  nbPost?: Maybe<Scalars['Int']>;
  receiveCommunication?: Maybe<Scalars['Boolean']>;
  isPremium?: Maybe<Scalars['Boolean']>;
  endPremium?: Maybe<Scalars['DateTime']>;
  isUsedTrial?: Maybe<Scalars['Boolean']>;
  currentSubsciption?: Maybe<SubscriptionType>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
  reports?: Maybe<ReportsResult>;
  _id?: Maybe<Scalars['ID']>;
  googleUserId?: Maybe<Scalars['String']>;
  facebookUserId?: Maybe<Scalars['String']>;
  twitterUserId?: Maybe<Scalars['String']>;
  microsoftUserId?: Maybe<Scalars['String']>;
  discordUserId?: Maybe<Scalars['String']>;
  /** https://getstream.io/activity-feeds/docs/react/?language=javascript */
  getStreamToken?: Maybe<Scalars['String']>;
  /** Is this user my friend */
  isMyfriend?: Maybe<Scalars['Boolean']>;
  removed?: Maybe<Scalars['Boolean']>;
  oauth2?: Maybe<Scalars['Boolean']>;
  friends?: Maybe<Array<Maybe<User>>>;
  twitchLogin?: Maybe<Scalars['String']>;
  twitchUserId?: Maybe<Scalars['String']>;
  role?: Maybe<User_Role>;
  chatEnginePublicSecret?: Maybe<Scalars['String']>;
  myTotalPoint?: Maybe<Scalars['Int']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  chatEngineId?: Maybe<Scalars['String']>;
  chatEngineSecret?: Maybe<Scalars['String']>;
  isSignupFinish?: Maybe<Scalars['Boolean']>;
  webhooks?: Maybe<Array<Maybe<Webhook>>>;
  leaderboardPoint?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  myMainGamePlateform?: Maybe<GamePlatform>;
  myGames?: Maybe<Array<Maybe<Game>>>;
  tournaments?: Maybe<Array<Maybe<Tournament>>>;
  tournamentFormat?: Maybe<Array<Maybe<TournamentFormat>>>;
  tournamentAward?: Maybe<Array<Maybe<TournamentAward>>>;
  tournamentRules?: Maybe<Array<Maybe<TournamentRule>>>;
  /** All tournaments that user had joined */
  point?: Maybe<Scalars['Float']>;
  joinedParticipationTournaments?: Maybe<Array<Maybe<TournamentParticipant>>>;
  joinedWagers?: Maybe<Array<Maybe<TournamentParticipant>>>;
  joinedLeagues?: Maybe<Array<Maybe<TournamentParticipant>>>;
  gameAccounts?: Maybe<Array<Maybe<UserGameAccountPayload>>>;
  joinedTeams?: Maybe<Array<Maybe<TeamMember>>>;
  createdWagers?: Maybe<Array<Maybe<Wager>>>;
  gameformats?: Maybe<Array<Maybe<Gameformat>>>;
  countries?: Maybe<Array<Maybe<Country>>>;
  wallet?: Maybe<Wallet>;
  walletId?: Maybe<Scalars['ID']>;
  totalWallet?: Maybe<Scalars['Float']>;
  orders?: Maybe<Array<Maybe<Order>>>;
  orderIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  chats?: Maybe<Array<Maybe<Chat>>>;
  leaderboardPoints?: Maybe<Array<Maybe<Leaderboardpoint>>>;
  leaderBoardTotalPoints?: Maybe<Scalars['Float']>;
  gameParty?: Maybe<Gameparty>;
  gamePartyInvitation?: Maybe<GamePartyInvitation>;
  socialAccounts?: Maybe<Array<Maybe<Usersocialaccount>>>;
};

export type UserPostsArgs = {
  filter?: Maybe<UserPostFilterInput>;
};

export type UserTeamsArgs = {
  filter?: Maybe<UserTeamFilterInput>;
};

export type UserGamesArgs = {
  filter?: Maybe<GamesFilterInput>;
};

export type UserReportsArgs = {
  filter?: Maybe<ReportsFilterInput>;
};

export type UserCreationPerMonths = {
  __typename?: 'UserCreationPerMonths';
  year: Scalars['Int'];
  month: Scalars['Int'];
  count: Scalars['Int'];
};

export type UserFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

/** Info about user game account */
export type UserGameAccount = {
  __typename?: 'UserGameAccount';
  id: Scalars['ID'];
  gamertag?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  gameAccount?: Maybe<GameAccount>;
  accountId?: Maybe<Scalars['String']>;
};

export type UserGameAccountPayload = {
  __typename?: 'UserGameAccountPayload';
  id: Scalars['ID'];
  gameAccount?: Maybe<GameAccount>;
  userGameAccount?: Maybe<UserGameAccount>;
};

export type UserGameStat = {
  __typename?: 'UserGameStat';
  id: Scalars['ID'];
  partyCount?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['String']>;
  winRate?: Maybe<Scalars['Int']>;
  recentResults?: Maybe<Array<Maybe<Game_Recent_Result>>>;
};

export type UserLeaderBoardPayload = {
  __typename?: 'UserLeaderBoardPayload';
  user?: Maybe<User>;
  point?: Maybe<Scalars['Int']>;
  eventCount?: Maybe<Scalars['Int']>;
};

export type UserNotInTeamsFilter = {
  name?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UserPostFilterInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UserResult = {
  __typename?: 'UserResult';
  results: Array<User>;
  total: Scalars['Int'];
};

export type UserSubscriptionToTournamentReturn = {
  __typename?: 'UserSubscriptionToTournamentReturn';
  tournamentId: Scalars['ID'];
  user: User;
};

export type UserTeamFilterInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  adminInput?: Maybe<AdminUserTeamFilterInput>;
};

export type UsersFilterInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filters: UsersSearchFilterInput;
};

export type UsersSearchFilterInput = {
  isBanned?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  excludeMe?: Maybe<Scalars['Boolean']>;
};

export type UsersTournamentFilterInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  tournamentId: Scalars['String'];
};

export type Usersocialaccount = {
  __typename?: 'Usersocialaccount';
  id: Scalars['ID'];
  socialAccount?: Maybe<Socialaccount>;
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type UsersocialaccountFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type Video = {
  __typename?: 'Video';
  id: Scalars['ID'];
  path: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  author: User;
  category: VideoCategory;
  authorId: Scalars['ID'];
  categoryId: Scalars['ID'];
  imgPreview?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  lickers: Array<User>;
  removed?: Maybe<Scalars['Boolean']>;
  streamStatus?: Maybe<StreamStatus>;
  canEdit: Scalars['Boolean'];
};

export type VideoCategory = {
  __typename?: 'VideoCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  videos?: Maybe<VideosResult>;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VideoCategoryVideosArgs = {
  filters: CategoryVideosFilterInput;
};

export type VideosFilterInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  categoryIds?: Maybe<Array<Scalars['ID']>>;
  isOnline?: Maybe<Scalars['Boolean']>;
};

export type VideosResult = {
  __typename?: 'VideosResult';
  results: Array<Video>;
  total: Scalars['Int'];
};

export type Wager_Format = 'oneVsOne' | 'teamVsTeam';

export type Wager = {
  __typename?: 'Wager';
  id: Scalars['ID'];
  isMeAlreadyParticipant?: Maybe<Scalars['Boolean']>;
  participants?: Maybe<Array<Maybe<TournamentParticipant>>>;
  participantIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  winnerId?: Maybe<Scalars['ID']>;
  winner?: Maybe<TournamentParticipant>;
  signupAt?: Maybe<Scalars['DateTime']>;
  matcheIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  matches?: Maybe<Array<Maybe<TournamentBracket>>>;
  cashPrice: Scalars['Float'];
  format: Wager_Format;
  isActive?: Maybe<Scalars['Boolean']>;
  participantNumber?: Maybe<Scalars['Int']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  game?: Maybe<Game>;
  plateform?: Maybe<GamePlatform>;
  createdBy?: Maybe<User>;
  rule?: Maybe<Scalars['String']>;
  gameId?: Maybe<Scalars['ID']>;
  createdById?: Maybe<Scalars['ID']>;
  gamePlateformId?: Maybe<Scalars['ID']>;
  entryCost?: Maybe<Scalars['Float']>;
  entryType?: Maybe<TournamentEntryType>;
  discordUrl?: Maybe<Scalars['String']>;
  title_en?: Maybe<Scalars['String']>;
  rule_en?: Maybe<Scalars['String']>;
  chatEngineId?: Maybe<Scalars['String']>;
  chatEngineAccessKey?: Maybe<Scalars['String']>;
  isStarting?: Maybe<Scalars['Boolean']>;
  /** Current user wager rank */
  myRank?: Maybe<Scalars['Int']>;
  discordChannelId?: Maybe<Scalars['String']>;
  isWelcommeMessageSent?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['ID']>;
  author?: Maybe<User>;
  mapId?: Maybe<Scalars['ID']>;
  map?: Maybe<Map>;
  gameServerId?: Maybe<Scalars['ID']>;
  gameserver?: Maybe<Gameserver>;
  regionId?: Maybe<Scalars['ID']>;
  region?: Maybe<Region>;
  audiochatId?: Maybe<Scalars['ID']>;
  audiochat?: Maybe<Audiochatplateform>;
  gameModeId?: Maybe<Scalars['ID']>;
  gamemode?: Maybe<Gamemode>;
  gameformatId?: Maybe<Scalars['ID']>;
  gameformat?: Maybe<Gameformat>;
};

export type WagerFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type WagerInformationPayload = {
  __typename?: 'WagerInformationPayload';
  totalWagerCashPrize?: Maybe<Scalars['Float']>;
  totalMonthWager?: Maybe<Scalars['Int']>;
  totalWagerParticipant?: Maybe<Scalars['Int']>;
  topGame?: Maybe<WagerTopGamePayload>;
};

export type WagerTopGamePayload = {
  __typename?: 'WagerTopGamePayload';
  game?: Maybe<Game>;
  percent?: Maybe<Scalars['Float']>;
};

export type Wallet = {
  __typename?: 'Wallet';
  id: Scalars['ID'];
  transactions?: Maybe<Array<Maybe<Wallettransaction>>>;
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type WalletFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type WalletOrder = {
  __typename?: 'WalletOrder';
  id: Scalars['ID'];
  amount?: Maybe<Scalars['Float']>;
  jetton?: Maybe<Scalars['Float']>;
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type WalletOrderFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type Wallettransaction = {
  __typename?: 'Wallettransaction';
  id: Scalars['ID'];
  amount?: Maybe<Scalars['Float']>;
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type WallettransactionFilter = {
  q?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type Webhook = {
  __typename?: 'Webhook';
  id?: Maybe<Scalars['String']>;
  webhookId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  applicationId?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type WzstatsDotGg = {
  __typename?: 'WzstatsDotGG';
  weekly?: Maybe<Scalars['JSON']>;
  last100games?: Maybe<WzstatsDotGgLastGameStat>;
  last100RebirthGames?: Maybe<WzstatsDotGgLastGameStat>;
  lifetime?: Maybe<Scalars['JSON']>;
};

export type WzstatsDotGgLastGameStat = {
  __typename?: 'WzstatsDotGGLastGameStat';
  kills?: Maybe<Scalars['Float']>;
  deaths?: Maybe<Scalars['Float']>;
  headshots?: Maybe<Scalars['Float']>;
  wins?: Maybe<Scalars['Float']>;
  gamesPlayed?: Maybe<Scalars['Float']>;
  damageDone?: Maybe<Scalars['Float']>;
  damageTaken?: Maybe<Scalars['Float']>;
  gulagWinPercentage?: Maybe<Scalars['Float']>;
};

export type WzstatsDotGgMatchStat = {
  __typename?: 'WzstatsDotGGMatchStat';
  id?: Maybe<Scalars['ID']>;
  mode?: Maybe<Scalars['String']>;
  kills?: Maybe<Scalars['Float']>;
  deaths?: Maybe<Scalars['Float']>;
  damage?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  startedAt?: Maybe<Scalars['Float']>;
  username?: Maybe<Scalars['String']>;
  uno?: Maybe<Scalars['String']>;
  refreshAt?: Maybe<Scalars['Float']>;
  matchStatData?: Maybe<Scalars['JSON']>;
  killPlacement?: Maybe<Scalars['Float']>;
  matchTeamStat?: Maybe<Scalars['JSON']>;
  playerCountInApi?: Maybe<Scalars['Float']>;
  playerCountInDB?: Maybe<Scalars['Float']>;
  playerCountStillMissing?: Maybe<Scalars['Float']>;
  data?: Maybe<WzstatsDotGgMatchStatData>;
};

export type WzstatsDotGgMatchStatData = {
  __typename?: 'WzstatsDotGGMatchStatData';
  mode?: Maybe<Scalars['String']>;
  players?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  startedAt?: Maybe<Scalars['Float']>;
};

export type LeaderboardPointsInput = {
  wagerId?: Maybe<Scalars['ID']>;
  tournamentId?: Maybe<Scalars['ID']>;
  point: Scalars['Int'];
};

export type MatchFileInfosFragment = { __typename?: 'MatchFile' } & Pick<
  MatchFile,
  'id' | 'url' | 'name'
>;

export type AddTournamentMatchFileMutationVariables = Exact<{
  input: AddMatchFileInput;
}>;

export type AddTournamentMatchFileMutation = { __typename?: 'Mutation' } & {
  addMatchFile?: Maybe<
    { __typename?: 'Match' } & Pick<Match, 'id'> & {
        files: Array<Maybe<{ __typename?: 'MatchFile' } & MatchFileInfosFragment>>;
      }
  >;
};

export type RemoveTournamentMatchFileMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RemoveTournamentMatchFileMutation = { __typename?: 'Mutation' } & {
  removeMatchFile?: Maybe<
    { __typename?: 'Match' } & Pick<Match, 'id'> & {
        files: Array<Maybe<{ __typename?: 'MatchFile' } & MatchFileInfosFragment>>;
      }
  >;
};

export type ActualityInfosFragment = { __typename?: 'Actuality' } & Pick<
  Actuality,
  'id' | 'title' | 'imagePreview' | 'content' | 'description' | 'createdAt' | 'updatedAt'
>;

export type AllActualitiesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<ActualityFilter>;
}>;

export type AllActualitiesQuery = { __typename?: 'Query' } & {
  allActualities?: Maybe<Array<Maybe<{ __typename?: 'Actuality' } & ActualityInfosFragment>>>;
};

export type ActualityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ActualityQuery = { __typename?: 'Query' } & {
  Actuality?: Maybe<{ __typename?: 'Actuality' } & ActualityInfosFragment>;
};

export type AssistancePageInfosFragment = { __typename?: 'Assistancepage' } & Pick<
  Assistancepage,
  | 'id'
  | 'title'
  | 'content'
  | 'title_en'
  | 'content_en'
  | 'category'
  | 'category_en'
  | 'slug'
  | 'order'
  | 'removed'
  | 'createdAt'
>;

export type AllAssistancePagesQueryVariables = Exact<{ [key: string]: never }>;

export type AllAssistancePagesQuery = { __typename?: 'Query' } & {
  allAssistancepages?: Maybe<
    Array<Maybe<{ __typename?: 'Assistancepage' } & AssistancePageInfosFragment>>
  >;
};

export type GetAssistancePageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetAssistancePageQuery = { __typename?: 'Query' } & {
  Assistancepage?: Maybe<{ __typename?: 'Assistancepage' } & AssistancePageInfosFragment>;
};

export type GetAssistancePageSlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetAssistancePageSlugQuery = { __typename?: 'Query' } & {
  AssistancePage?: Maybe<{ __typename?: 'Assistancepage' } & AssistancePageInfosFragment>;
};

export type LoginSocialInfosFragment = { __typename?: 'LoginSocial' } & Pick<
  LoginSocial,
  'token' | 'authName' | 'userName'
>;

export type TwitterOauthTokenInfosFragment = { __typename?: 'TwitterOauthToken' } & Pick<
  TwitterOauthToken,
  'oauth_token' | 'oauth_token_secret' | 'oauth_callback_confirmed'
>;

export type TwitterOauthAccessTokenInfosFragment = {
  __typename?: 'TwitterOauthAccessToken';
} & Pick<TwitterOauthAccessToken, 'oauth_token' | 'oauth_token_secret' | 'user_id' | 'screen_name'>;

export type TwitterUserAuthInfosFragment = { __typename?: 'TwitterUserAuth' } & Pick<
  TwitterUserAuth,
  'providerId' | 'name' | 'screen_name' | 'email' | 'avatar'
>;

export type LoginSocialMutationVariables = Exact<{
  input: LoginSocialInput;
}>;

export type LoginSocialMutation = { __typename?: 'Mutation' } & {
  loginSocial: { __typename?: 'LoginSocial' } & LoginSocialInfosFragment;
};

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;

export type LoginMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'login'>;

export type SignupMutationVariables = Exact<{
  input: SignupInput;
}>;

export type SignupMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'signup'>;

export type LoginTwitchMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type LoginTwitchMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'loginTwitch'>;

export type LoginDiscordMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type LoginDiscordMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'loginDiscord'>;

export type LinkDiscordMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type LinkDiscordMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'linkDiscord'>;

export type LoginXboxMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type LoginXboxMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'loginXbox'>;

export type LinkMicrosoftMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type LinkMicrosoftMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'linkMicrosoft'>;

export type LoginBattlenetMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type LoginBattlenetMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'loginBattlenet'>;

export type SendInvitationMobileMutationVariables = Exact<{
  email: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;

export type SendInvitationMobileMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'sendInvitationMobile'
>;

export type GetAccessTokenMutationVariables = Exact<{
  oauth_token?: Maybe<Scalars['String']>;
  oauth_verifier?: Maybe<Scalars['String']>;
}>;

export type GetAccessTokenMutation = { __typename?: 'Mutation' } & {
  getAccessToken?: Maybe<
    { __typename?: 'TwitterOauthAccessToken' } & TwitterOauthAccessTokenInfosFragment
  >;
};

export type GetTwitterRequestTokenQueryVariables = Exact<{ [key: string]: never }>;

export type GetTwitterRequestTokenQuery = { __typename?: 'Query' } & {
  getTwitterRequestToken?: Maybe<
    { __typename?: 'TwitterOauthToken' } & TwitterOauthTokenInfosFragment
  >;
};

export type GetTwitterOauthUserWithEmailQueryVariables = Exact<{
  token?: Maybe<Scalars['String']>;
  secretToken?: Maybe<Scalars['String']>;
}>;

export type GetTwitterOauthUserWithEmailQuery = { __typename?: 'Query' } & {
  getTwitterOauthUserWithEmail?: Maybe<
    { __typename?: 'TwitterUserAuth' } & TwitterUserAuthInfosFragment
  >;
};

export type ChatGroupInfoFragment = { __typename?: 'Chat' } & Pick<
  Chat,
  'id' | 'name' | 'chatengineId' | 'chatEngineAccessKey' | 'createdAt'
> & {
    owner?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>;
    members?: Maybe<
      Array<
        Maybe<
          { __typename?: 'Chatmember' } & Pick<Chatmember, 'id' | 'removed'> & {
              user?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>;
            }
        >
      >
    >;
  };

export type ChatMemberInfoFragment = { __typename?: 'Chatmember' } & Pick<
  Chatmember,
  'id' | 'removed'
> & {
    chat?: Maybe<{ __typename?: 'Chat' } & ChatGroupInfoFragment>;
    user?: Maybe<{ __typename?: 'User' } & UserBasicInfosFragment>;
  };

export type GamePartyFragmentFragment = { __typename?: 'Gameparty' } & Pick<
  Gameparty,
  'id' | 'name' | 'removed'
> & {
    chat?: Maybe<{ __typename?: 'Chat' } & ChatGroupInfoFragment>;
    members?: Maybe<Array<Maybe<{ __typename?: 'Gamepartymember' } & Pick<Gamepartymember, 'id'>>>>;
  };

export type CreateChatMutationVariables = Exact<{
  name: Scalars['String'];
  gamePartyId: Scalars['ID'];
}>;

export type CreateChatMutation = { __typename?: 'Mutation' } & {
  createChat?: Maybe<{ __typename?: 'Chat' } & ChatGroupInfoFragment>;
};

export type DeleteChatMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteChatMutation = { __typename?: 'Mutation' } & {
  deleteChat?: Maybe<{ __typename?: 'Chat' } & ChatGroupInfoFragment>;
};

export type UpdateChatMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
}>;

export type UpdateChatMutation = { __typename?: 'Mutation' } & {
  updateChat?: Maybe<{ __typename?: 'Chat' } & ChatGroupInfoFragment>;
};

export type CreateChatmemberMutationVariables = Exact<{
  chatId: Scalars['ID'];
  userId: Scalars['ID'];
}>;

export type CreateChatmemberMutation = { __typename?: 'Mutation' } & {
  createChatmember?: Maybe<
    { __typename?: 'Chatmember' } & Pick<Chatmember, 'id' | 'removed'> & {
        chat?: Maybe<{ __typename?: 'Chat' } & ChatGroupInfoFragment>;
      }
  >;
};

export type DeleteChatmemberMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteChatmemberMutation = { __typename?: 'Mutation' } & {
  deleteChatmember?: Maybe<
    { __typename?: 'Chatmember' } & Pick<Chatmember, 'id' | 'removed'> & {
        chat?: Maybe<{ __typename?: 'Chat' } & ChatGroupInfoFragment>;
      }
  >;
};

export type CreateGamepartyMutationVariables = Exact<{
  name: Scalars['String'];
}>;

export type CreateGamepartyMutation = { __typename?: 'Mutation' } & {
  createGameparty?: Maybe<
    { __typename?: 'Gameparty' } & Pick<Gameparty, 'id' | 'removed' | 'name'> & {
        chat?: Maybe<{ __typename?: 'Chat' } & ChatGroupInfoFragment>;
      }
  >;
};

export type EditGamepartyMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
}>;

export type EditGamepartyMutation = { __typename?: 'Mutation' } & {
  updateGameparty?: Maybe<
    { __typename?: 'Gameparty' } & Pick<Gameparty, 'id' | 'removed' | 'name'>
  >;
};

export type InviteUserToGamePartyMutationVariables = Exact<{
  userId: Scalars['ID'];
  gamePartyId: Scalars['ID'];
}>;

export type InviteUserToGamePartyMutation = { __typename?: 'Mutation' } & {
  inviteUserToGameParty?: Maybe<
    { __typename?: 'InvitationGamepartyPayload' } & Pick<
      InvitationGamepartyPayload,
      'success' | 'message'
    >
  >;
};

export type DeleteGamepartymemberMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteGamepartymemberMutation = { __typename?: 'Mutation' } & {
  deleteGamepartymember?: Maybe<{ __typename?: 'Gamepartymember' } & Pick<Gamepartymember, 'id'>>;
};

export type MyAllChatGroupQueryVariables = Exact<{ [key: string]: never }>;

export type MyAllChatGroupQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'User' } & Pick<User, 'id'> & {
      chats?: Maybe<Array<Maybe<{ __typename?: 'Chat' } & ChatGroupInfoFragment>>>;
    };
};

export type MyGamePartyQueryVariables = Exact<{ [key: string]: never }>;

export type MyGamePartyQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'User' } & Pick<User, 'id'> & {
      gameParty?: Maybe<
        { __typename?: 'Gameparty' } & Pick<Gameparty, 'id' | 'removed' | 'name'> & {
            members?: Maybe<
              Array<Maybe<{ __typename?: 'Gamepartymember' } & Pick<Gamepartymember, 'id'>>>
            >;
            chat?: Maybe<{ __typename?: 'Chat' } & ChatGroupInfoFragment>;
          }
      >;
    };
};

export type ChatQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ChatQuery = { __typename?: 'Query' } & {
  Chat?: Maybe<
    { __typename?: 'Chat' } & {
      members?: Maybe<
        Array<
          Maybe<
            { __typename?: 'Chatmember' } & Pick<Chatmember, 'id' | 'removed'> & {
                user?: Maybe<{ __typename?: 'User' } & UserBasicInfosFragment>;
              }
          >
        >
      >;
    } & ChatGroupInfoFragment
  >;
};

export type CountryInfosFragment = { __typename?: 'Country' } & Pick<
  Country,
  'id' | 'name' | 'code'
>;

export type AllCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type AllCountriesQuery = { __typename?: 'Query' } & {
  allCountries?: Maybe<Array<Maybe<{ __typename?: 'Country' } & CountryInfosFragment>>>;
};

export type DiscordChannelInfosFragment = { __typename?: 'DiscordChannel' } & Pick<
  DiscordChannel,
  | 'id'
  | 'name'
  | 'rawPosition'
  | 'lastMessageId'
  | 'rateLimitPerUser'
  | 'nsfw'
  | 'parentId'
  | 'guildId'
>;

export type EmbedProviderInfosFragment = { __typename?: 'EmbedProvider' } & Pick<
  EmbedProvider,
  'name' | 'url'
>;

export type EmbedThumbnailInfosFragment = { __typename?: 'EmbedThumbnail' } & Pick<
  EmbedThumbnail,
  'url' | 'proxyUrl' | 'width' | 'height'
>;

export type EmbedVideoInfosFragment = { __typename?: 'EmbedVideo' } & Pick<
  EmbedVideo,
  'url' | 'proxyUrl' | 'width' | 'height'
>;

export type EmbedInfosFragment = { __typename?: 'Embed' } & Pick<Embed, 'type' | 'url'> & {
    provider?: Maybe<{ __typename?: 'EmbedProvider' } & EmbedProviderInfosFragment>;
    thumbnail?: Maybe<{ __typename?: 'EmbedThumbnail' } & EmbedThumbnailInfosFragment>;
    video?: Maybe<{ __typename?: 'EmbedVideo' } & EmbedVideoInfosFragment>;
  };

export type MessageReferenceInfosFragment = { __typename?: 'DiscordMessageReference' } & Pick<
  DiscordMessageReference,
  'channelId' | 'guildId' | 'messageId'
>;

export type DiscordAuthorMessageInfosFragment = { __typename?: 'DiscordAuthorMessage' } & Pick<
  DiscordAuthorMessage,
  'id' | 'username' | 'avatar' | 'avatar_decoration' | 'discriminator' | 'public_flags'
>;

export type ReferencedMessageInfosFragment = { __typename?: 'DiscordChannelMessage' } & Pick<
  DiscordChannelMessage,
  | 'id'
  | 'guildId'
  | 'channelId'
  | 'deleted'
  | 'createdTimestamp'
  | 'type'
  | 'system'
  | 'content'
  | 'pinned'
  | 'tts'
  | 'timestamp'
> & {
    author?: Maybe<{ __typename?: 'DiscordAuthorMessage' } & DiscordAuthorMessageInfosFragment>;
    embeds?: Maybe<Array<Maybe<{ __typename?: 'Embed' } & EmbedInfosFragment>>>;
    messageReference?: Maybe<
      { __typename?: 'DiscordMessageReference' } & MessageReferenceInfosFragment
    >;
  };

export type DiscordChannelMessageInfosFragment = { __typename?: 'DiscordChannelMessage' } & Pick<
  DiscordChannelMessage,
  | 'id'
  | 'guildId'
  | 'channelId'
  | 'deleted'
  | 'createdTimestamp'
  | 'type'
  | 'system'
  | 'content'
  | 'pinned'
  | 'tts'
  | 'timestamp'
> & {
    author?: Maybe<{ __typename?: 'DiscordAuthorMessage' } & DiscordAuthorMessageInfosFragment>;
    embeds?: Maybe<Array<Maybe<{ __typename?: 'Embed' } & EmbedInfosFragment>>>;
    messageReference?: Maybe<
      { __typename?: 'DiscordMessageReference' } & MessageReferenceInfosFragment
    >;
    referencedMessage?: Maybe<
      { __typename?: 'DiscordChannelMessage' } & ReferencedMessageInfosFragment
    >;
  };

export type DiscordWebhookInfosFragment = { __typename?: 'DiscordWebhook' } & Pick<
  DiscordWebhook,
  'id' | 'type' | 'name' | 'avatar' | 'channelId' | 'guildId' | 'applicationId' | 'token'
> & { user?: Maybe<{ __typename?: 'DiscordAuthorMessage' } & DiscordAuthorMessageInfosFragment> };

export type WebhookInfosFragment = { __typename?: 'Webhook' } & Pick<
  Webhook,
  'id' | 'webhookId' | 'username' | 'token' | 'avatar' | 'channelId' | 'applicationId' | 'user'
>;

export type RoleTagInfosFragment = { __typename?: 'RoleTags' } & Pick<
  RoleTags,
  'bot_id' | 'integration_id' | 'premium_subscriber'
>;

export type GuildRoleInfosFragment = { __typename?: 'GuildRole' } & Pick<
  GuildRole,
  | 'id'
  | 'name'
  | 'permissions'
  | 'position'
  | 'color'
  | 'hoist'
  | 'managed'
  | 'mentionable'
  | 'icon'
  | 'unicode_emoji'
  | 'flags'
  | 'permissions_new'
> & { tags?: Maybe<{ __typename?: 'RoleTags' } & RoleTagInfosFragment> };

export type CreateMessageMutationVariables = Exact<{
  input: CreateMessageInput;
}>;

export type CreateMessageMutation = { __typename?: 'Mutation' } & {
  createMessage?: Maybe<
    { __typename?: 'DiscordChannelMessage' } & DiscordChannelMessageInfosFragment
  >;
};

export type CreateMessageWithWebHookMutationVariables = Exact<{
  input: CreateMessageWithWebHookInput;
}>;

export type CreateMessageWithWebHookMutation = { __typename?: 'Mutation' } & {
  createMessageWithWebHook?: Maybe<
    { __typename?: 'DiscordChannelMessage' } & DiscordChannelMessageInfosFragment
  >;
};

export type CheckWebhookUserInChannelMutationVariables = Exact<{
  input: FindWebhookInput;
}>;

export type CheckWebhookUserInChannelMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'checkWebhookUserInChannel'
>;

export type CreateGuildRoleMutationVariables = Exact<{
  input: CreateGuildRoleInput;
}>;

export type CreateGuildRoleMutation = { __typename?: 'Mutation' } & {
  createGuildRole?: Maybe<{ __typename?: 'GuildRole' } & GuildRoleInfosFragment>;
};

export type GetDiscordInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetDiscordInfoQuery = { __typename?: 'Query' } & Pick<
  Query,
  'onlineUsersCount' | 'totalDiscordMember' | 'totalDiscordMessage'
>;

export type AllDiscordChannelGuildsQueryVariables = Exact<{
  params?: Maybe<Scalars['JSON']>;
}>;

export type AllDiscordChannelGuildsQuery = { __typename?: 'Query' } & {
  allDiscordChannelGuilds?: Maybe<
    Array<Maybe<{ __typename?: 'DiscordChannel' } & DiscordChannelInfosFragment>>
  >;
};

export type AllMessagesChannelQueryVariables = Exact<{
  channelId: Scalars['String'];
  params?: Maybe<ParamsMessageInput>;
}>;

export type AllMessagesChannelQuery = { __typename?: 'Query' } & {
  allMessagesChannel?: Maybe<
    Array<
      Maybe<
        { __typename?: 'DiscordChannelMessage' } & {
          author?: Maybe<
            { __typename?: 'DiscordAuthorMessage' } & DiscordAuthorMessageInfosFragment
          >;
        } & DiscordChannelMessageInfosFragment
      >
    >
  >;
};

export type DiscordChannelQueryVariables = Exact<{
  channelId: Scalars['String'];
}>;

export type DiscordChannelQuery = { __typename?: 'Query' } & {
  discordChannel?: Maybe<{ __typename?: 'DiscordChannel' } & DiscordChannelInfosFragment>;
};

export type FindWebhookByUserAndChannelQueryVariables = Exact<{
  input: FindWebhookInput;
}>;

export type FindWebhookByUserAndChannelQuery = { __typename?: 'Query' } & {
  findWebhookByUserAndChannel?: Maybe<{ __typename?: 'Webhook' } & WebhookInfosFragment>;
};

export type AllGuildRoleQueryVariables = Exact<{
  input?: Maybe<FindGuildRoleInput>;
}>;

export type AllGuildRoleQuery = { __typename?: 'Query' } & {
  allGuildRole?: Maybe<Array<Maybe<{ __typename?: 'GuildRole' } & GuildRoleInfosFragment>>>;
};

export type GuildRoleQueryVariables = Exact<{
  input?: Maybe<FindGuildRoleInput>;
}>;

export type GuildRoleQuery = { __typename?: 'Query' } & {
  guildRole?: Maybe<{ __typename?: 'GuildRole' } & GuildRoleInfosFragment>;
};

export type GameInfosFragment = { __typename?: 'Game' } & Pick<
  Game,
  | 'id'
  | 'name'
  | 'logo'
  | 'description'
  | 'mainJacket'
  | 'jacketImages'
  | 'coverImage'
  | 'popularity'
  | 'note'
  | 'twitchGameId'
  | 'isInCurrentUserFavorite'
  | 'landingPageJacket'
  | 'landingPageLogo'
> & {
    platforms?: Maybe<Array<Maybe<{ __typename?: 'GamePlatform' } & GamePlatformInfosFragment>>>;
  };

export type AddGameMutationVariables = Exact<{
  input: AddGameInput;
}>;

export type AddGameMutation = { __typename?: 'Mutation' } & {
  addGame?: Maybe<{ __typename?: 'Game' } & GameInfosFragment>;
};

export type EditGameMutationVariables = Exact<{
  input: EditGameInput;
}>;

export type EditGameMutation = { __typename?: 'Mutation' } & {
  editGame?: Maybe<{ __typename?: 'Game' } & GameInfosFragment>;
};

export type AddGameToFavoriteMutationVariables = Exact<{
  gameId: Scalars['ID'];
}>;

export type AddGameToFavoriteMutation = { __typename?: 'Mutation' } & {
  addGameToMyFavorite?: Maybe<
    { __typename?: 'Game' } & Pick<Game, 'id' | 'isInCurrentUserFavorite'>
  >;
};

export type RemoveGameToFavoriteMutationVariables = Exact<{
  gameId: Scalars['ID'];
}>;

export type RemoveGameToFavoriteMutation = { __typename?: 'Mutation' } & {
  removeGameFromMyFavorite?: Maybe<
    { __typename?: 'Game' } & Pick<Game, 'id' | 'isInCurrentUserFavorite'>
  >;
};

export type GetGamesQueryVariables = Exact<{
  filter: GamesFilterInput;
}>;

export type GetGamesQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'User' } & Pick<User, 'id' | '_id'> & {
      games?: Maybe<
        { __typename?: 'GamesResult' } & Pick<GamesResult, 'total'> & {
            results: Array<{ __typename?: 'Game' } & GameInfosFragment>;
          }
      >;
    };
};

export type AllGamesQueryVariables = Exact<{ [key: string]: never }>;

export type AllGamesQuery = { __typename?: 'Query' } & {
  allGames?: Maybe<Array<Maybe<{ __typename?: 'Game' } & GameInfosFragment>>>;
};

export type AllGamePlatformsQueryVariables = Exact<{ [key: string]: never }>;

export type AllGamePlatformsQuery = { __typename?: 'Query' } & {
  allGamePlatforms?: Maybe<
    Array<Maybe<{ __typename?: 'GamePlatform' } & Pick<GamePlatform, 'id' | 'logo' | 'name'>>>
  >;
};

export type AllGameModesQueryVariables = Exact<{ [key: string]: never }>;

export type AllGameModesQuery = { __typename?: 'Query' } & {
  allGamemodes?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Gamemode' } & Pick<
          Gamemode,
          'id' | 'title' | 'teamSize' | 'title_en' | 'type'
        >
      >
    >
  >;
};

export type AllRegionsQueryVariables = Exact<{ [key: string]: never }>;

export type AllRegionsQuery = { __typename?: 'Query' } & {
  allRegions?: Maybe<Array<Maybe<{ __typename?: 'Region' } & Pick<Region, 'id' | 'name'>>>>;
};

export type AllGameServersQueryVariables = Exact<{ [key: string]: never }>;

export type AllGameServersQuery = { __typename?: 'Query' } & {
  allGameservers?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Gameserver' } & Pick<Gameserver, 'id' | 'name'> & {
            country?: Maybe<{ __typename?: 'Country' } & Pick<Country, 'id' | 'name' | 'code'>>;
          }
      >
    >
  >;
};

export type AllAudioChatsQueryVariables = Exact<{ [key: string]: never }>;

export type AllAudioChatsQuery = { __typename?: 'Query' } & {
  allAudiochatplateforms?: Maybe<
    Array<Maybe<{ __typename?: 'Audiochatplateform' } & Pick<Audiochatplateform, 'id' | 'name'>>>
  >;
};

export type AllGamesLogoQueryVariables = Exact<{ [key: string]: never }>;

export type AllGamesLogoQuery = { __typename?: 'Query' } & {
  allGames?: Maybe<
    Array<Maybe<{ __typename?: 'Game' } & Pick<Game, 'id' | 'logo' | 'coverImage'>>>
  >;
};

export type GetGameQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetGameQuery = { __typename?: 'Query' } & {
  Game?: Maybe<{ __typename?: 'Game' } & GameInfosFragment>;
};

export type GetGamesByTwitchIdQueryVariables = Exact<{ [key: string]: never }>;

export type GetGamesByTwitchIdQuery = { __typename?: 'Query' } & {
  allGames?: Maybe<
    Array<
      Maybe<{ __typename?: 'Game' } & Pick<Game, 'id' | 'name' | 'twitchGameId' | 'twitchGameName'>>
    >
  >;
};

export type GetGameAllDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetGameAllDetailsQuery = { __typename?: 'Query' } & {
  Game?: Maybe<
    { __typename?: 'Game' } & {
      leagues?: Maybe<
        Array<
          Maybe<
            { __typename?: 'League' } & Pick<
              League,
              'id' | 'description' | 'startedAt' | 'title' | 'gameId'
            > & {
                awards?: Maybe<
                  Array<Maybe<{ __typename?: 'LeagueAward' } & Pick<LeagueAward, 'id' | 'value'>>>
                >;
                gamePlateform?: Maybe<
                  { __typename?: 'GamePlatform' } & Pick<GamePlatform, 'id' | 'logo'>
                >;
              }
          >
        >
      >;
      tournaments?: Maybe<
        Array<
          Maybe<
            { __typename?: 'Tournament' } & Pick<
              Tournament,
              'id' | 'description' | 'startedAt' | 'title' | 'rewardsTotal'
            > & {
                gamePlateform?: Maybe<
                  { __typename?: 'GamePlatform' } & Pick<GamePlatform, 'id' | 'logo'>
                >;
              }
          >
        >
      >;
      wagers?: Maybe<
        Array<
          Maybe<{ __typename?: 'Wager' } & Pick<Wager, 'id' | 'startedAt' | 'title' | 'cashPrice'>>
        >
      >;
    } & GameInfosFragment
  >;
};

export type GetGameTournamentsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetGameTournamentsQuery = { __typename?: 'Query' } & {
  Game?: Maybe<
    { __typename?: 'Game' } & {
      tournaments?: Maybe<
        Array<
          Maybe<
            { __typename?: 'Tournament' } & Pick<
              Tournament,
              | 'id'
              | 'title'
              | 'isActive'
              | 'startedAt'
              | 'createdAt'
              | 'entryCost'
              | 'rewardsTotal'
              | 'description'
              | 'maxParticipants'
              | 'isMeAlreadyParticipant'
            > & {
                format?: Maybe<
                  { __typename?: 'TournamentFormat' } & Pick<TournamentFormat, 'title'>
                >;
                awards?: Maybe<
                  Array<Maybe<{ __typename?: 'TournamentAward' } & Pick<TournamentAward, 'value'>>>
                >;
                gamePlateform?: Maybe<
                  { __typename?: 'GamePlatform' } & Pick<GamePlatform, 'id' | 'name' | 'logo'>
                >;
                gamemode?: Maybe<
                  { __typename?: 'Gamemode' } & Pick<Gamemode, 'id' | 'title' | 'teamSize' | 'type'>
                >;
                participants?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'>
                    >
                  >
                >;
                game?: Maybe<
                  { __typename?: 'Game' } & Pick<
                    Game,
                    'id' | 'name' | 'mainJacket' | 'logo' | 'twitchGameId'
                  >
                >;
              }
          >
        >
      >;
    } & GameInfosFragment
  >;
};

export type GetGameLeaguesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetGameLeaguesQuery = { __typename?: 'Query' } & {
  Game?: Maybe<
    { __typename?: 'Game' } & {
      leagues?: Maybe<
        Array<
          Maybe<
            { __typename?: 'League' } & Pick<
              League,
              | 'id'
              | 'title'
              | 'description'
              | 'startedAt'
              | 'format'
              | 'maxParticipants'
              | 'minParticipants'
              | 'isActive'
              | 'entryCost'
              | 'isMeAlreadyParticipant'
            > & {
                organiser?: Maybe<{ __typename?: 'Organiser' } & Pick<Organiser, 'id' | 'name'>>;
                participants?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'>
                    >
                  >
                >;
              }
          >
        >
      >;
    } & GameInfosFragment
  >;
};

export type GetGameWagersQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetGameWagersQuery = { __typename?: 'Query' } & {
  Game?: Maybe<
    { __typename?: 'Game' } & {
      wagers?: Maybe<
        Array<
          Maybe<
            { __typename?: 'Wager' } & Pick<
              Wager,
              | 'id'
              | 'isMeAlreadyParticipant'
              | 'cashPrice'
              | 'format'
              | 'isActive'
              | 'participantNumber'
              | 'startedAt'
              | 'title'
              | 'createdAt'
              | 'rule'
              | 'entryCost'
              | 'entryType'
            > & {
                participants?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'>
                    >
                  >
                >;
                gameformat?: Maybe<{ __typename?: 'Gameformat' } & Pick<Gameformat, 'id' | 'name'>>;
                gamemode?: Maybe<
                  { __typename?: 'Gamemode' } & Pick<Gamemode, 'id' | 'title' | 'teamSize' | 'type'>
                >;
                gameserver?: Maybe<{ __typename?: 'Gameserver' } & Pick<Gameserver, 'id' | 'name'>>;
              }
          >
        >
      >;
    } & GameInfosFragment
  >;
};

export type SubscribeToGameAddedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type SubscribeToGameAddedSubscription = { __typename?: 'Subscription' } & {
  gameAdded: { __typename?: 'Game' } & GameInfosFragment;
};

export type GameAccountInfosFragment = { __typename?: 'GameAccount' } & Pick<
  GameAccount,
  'id' | 'name' | 'logo' | 'label' | 'createdAt' | 'updatedAt'
>;

export type UserGameAccountPayloadInfosFragment = { __typename?: 'UserGameAccountPayload' } & Pick<
  UserGameAccountPayload,
  'id'
> & {
    gameAccount?: Maybe<{ __typename?: 'GameAccount' } & GameAccountInfosFragment>;
    userGameAccount?: Maybe<
      { __typename?: 'UserGameAccount' } & Pick<
        UserGameAccount,
        'id' | 'gamertag' | 'username' | 'accountId'
      >
    >;
  };

export type CreateGameAccountMutationVariables = Exact<{
  name: Scalars['String'];
  logo: Scalars['String'];
  label?: Maybe<Scalars['String']>;
}>;

export type CreateGameAccountMutation = { __typename?: 'Mutation' } & {
  createGameAccount?: Maybe<{ __typename?: 'GameAccount' } & GameAccountInfosFragment>;
};

export type LinkAGameAccountMutationVariables = Exact<{
  accountId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  gamertag?: Maybe<Scalars['String']>;
  gameAccountId: Scalars['ID'];
}>;

export type LinkAGameAccountMutation = { __typename?: 'Mutation' } & {
  linkAGameAccount?: Maybe<
    { __typename?: 'UserGameAccount' } & Pick<UserGameAccount, 'id' | 'accountId'> & {
        gameAccount?: Maybe<{ __typename?: 'GameAccount' } & GameAccountInfosFragment>;
      }
  >;
};

export type GetGameAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type GetGameAccountsQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'User' } & Pick<User, 'id'> & {
      gameAccounts?: Maybe<
        Array<
          Maybe<{ __typename?: 'UserGameAccountPayload' } & UserGameAccountPayloadInfosFragment>
        >
      >;
    };
};

export type GetGameByGameAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type GetGameByGameAccountsQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'User' } & Pick<User, 'id'> & {
      gameAccounts?: Maybe<
        Array<
          Maybe<
            { __typename?: 'UserGameAccountPayload' } & Pick<UserGameAccountPayload, 'id'> & {
                gameAccount?: Maybe<
                  { __typename?: 'GameAccount' } & {
                    games?: Maybe<Array<Maybe<{ __typename?: 'Game' } & GameInfosFragment>>>;
                  } & GameAccountInfosFragment
                >;
                userGameAccount?: Maybe<
                  { __typename?: 'UserGameAccount' } & Pick<
                    UserGameAccount,
                    'id' | 'gamertag' | 'username' | 'accountId'
                  >
                >;
              }
          >
        >
      >;
    };
};

export type GamePlatformInfosFragment = { __typename?: 'GamePlatform' } & Pick<
  GamePlatform,
  'id' | 'name' | 'logo'
>;

export type GetGamePlatformsQueryVariables = Exact<{ [key: string]: never }>;

export type GetGamePlatformsQuery = { __typename?: 'Query' } & {
  gamePlatforms: Array<{ __typename?: 'GamePlatform' } & GamePlatformInfosFragment>;
};

export type RapidapiCodMwWarZoneMatchInfoFragment = {
  __typename?: 'RapidapiCodMWWarZoneMatch';
} & Pick<RapidapiCodMwWarZoneMatch, 'summary' | 'matches'>;

export type RapidapiCodModernWarfareStatInfoFragment = {
  __typename?: 'RapidapiCodModernWarfareStat';
} & Pick<RapidapiCodModernWarfareStat, 'multiplayerStats'> & {
    warzoneStats?: Maybe<
      { __typename?: 'RapidapiCodWarZoneStat' } & Pick<
        RapidapiCodWarZoneStat,
        'br' | 'br_all' | 'br_dmz'
      >
    >;
    warzoneMatches?: Maybe<
      { __typename?: 'RapidapiCodMWWarZoneMatch' } & Pick<
        RapidapiCodMwWarZoneMatch,
        'summary' | 'matches'
      >
    >;
    multiplayerMatches?: Maybe<
      { __typename?: 'RapidapiCodMWMultiplayerMatches' } & Pick<
        RapidapiCodMwMultiplayerMatches,
        'summary' | 'matches'
      >
    >;
  };

export type FutPlayerInfoFragment = { __typename?: 'FutPlayerInfo' } & Pick<
  FutPlayerInfo,
  | 'commonName'
  | 'firstName'
  | 'lastName'
  | 'league'
  | 'nation'
  | 'club'
  | 'headshot'
  | 'position'
  | 'composure'
  | 'playStyle'
  | 'playStyleId'
  | 'height'
  | 'weight'
  | 'birthdate'
  | 'age'
  | 'acceleration'
  | 'aggression'
  | 'agility'
  | 'balance'
  | 'ballcontrol'
  | 'foot'
  | 'skillMoves'
  | 'crossing'
  | 'curve'
  | 'dribbling'
  | 'finishing'
  | 'freekickaccuracy'
  | 'gkdiving'
  | 'gkhandling'
  | 'gkkicking'
  | 'gkpositioning'
  | 'gkreflexes'
  | 'headingaccuracy'
  | 'interceptions'
  | 'jumping'
  | 'longpassing'
  | 'longshots'
  | 'marking'
  | 'penalties'
  | 'positioning'
  | 'potential'
  | 'reactions'
  | 'shortpassing'
  | 'shotpower'
  | 'slidingtackle'
  | 'sprintspeed'
  | 'standingtackle'
  | 'stamina'
  | 'strength'
  | 'vision'
  | 'volleys'
  | 'weakFoot'
>;

export type WzstatsDotGgLastGameStatInfoFragment = {
  __typename?: 'WzstatsDotGGLastGameStat';
} & Pick<
  WzstatsDotGgLastGameStat,
  | 'kills'
  | 'deaths'
  | 'headshots'
  | 'wins'
  | 'gamesPlayed'
  | 'damageDone'
  | 'damageTaken'
  | 'gulagWinPercentage'
>;

export type WzstatsDotGgInfoFragment = { __typename?: 'WzstatsDotGG' } & Pick<
  WzstatsDotGg,
  'weekly' | 'lifetime'
> & {
    last100games?: Maybe<
      { __typename?: 'WzstatsDotGGLastGameStat' } & WzstatsDotGgLastGameStatInfoFragment
    >;
    last100RebirthGames?: Maybe<
      { __typename?: 'WzstatsDotGGLastGameStat' } & WzstatsDotGgLastGameStatInfoFragment
    >;
  };

export type CodTrackerDotGgStatsInfoFragment = { __typename?: 'CODTrackerDotGGStats' } & Pick<
  CodTrackerDotGgStats,
  'profilStatOverView' | 'killstreaks' | 'weaponStats'
>;

export type WzstatsDotGgMatchStatInfoFragment = { __typename?: 'WzstatsDotGGMatchStat' } & Pick<
  WzstatsDotGgMatchStat,
  | 'id'
  | 'mode'
  | 'kills'
  | 'deaths'
  | 'damage'
  | 'position'
  | 'startedAt'
  | 'username'
  | 'uno'
  | 'refreshAt'
  | 'matchStatData'
  | 'killPlacement'
  | 'matchTeamStat'
  | 'playerCountInApi'
  | 'playerCountInDB'
  | 'playerCountStillMissing'
> & {
    data?: Maybe<
      { __typename?: 'WzstatsDotGGMatchStatData' } & Pick<
        WzstatsDotGgMatchStatData,
        'mode' | 'players' | 'startedAt'
      >
    >;
  };

export type RocketLeagueTrackerGgLeaderBoardItemInfoFragment = {
  __typename?: 'RocketLeagueTrackerGGLeaderBoardItem';
} & Pick<
  RocketLeagueTrackerGgLeaderBoardItem,
  'id' | 'owner' | 'value' | 'displayValue' | 'rank' | 'iconUrl'
>;

export type CodWarezoneTrackerDotGgLeaderboardItemInfoFragment = {
  __typename?: 'CodWarezoneTrackerDotGGLeaderboardItem';
} & Pick<
  CodWarezoneTrackerDotGgLeaderboardItem,
  'id' | 'owner' | 'value' | 'displayValue' | 'rank' | 'percentile' | 'iconUrl'
>;

export type RocketLeagueTrackerGgStatInfoFragment = {
  __typename?: 'RocketLeagueTrackerGGStat';
} & Pick<RocketLeagueTrackerGgStat, 'profilStatOverView' | 'recentMatches' | 'playlistAverage'>;

export type MyCodModernWarfareStatQueryVariables = Exact<{
  platform?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
}>;

export type MyCodModernWarfareStatQuery = { __typename?: 'Query' } & {
  myCodModernWarefareStat?: Maybe<
    { __typename?: 'RapidapiCodModernWarfareStat' } & RapidapiCodModernWarfareStatInfoFragment
  >;
};

export type MyBo4StatQueryVariables = Exact<{
  platform?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
}>;

export type MyBo4StatQuery = { __typename?: 'Query' } & Pick<Query, 'myBO4Stat'>;

export type WzstatsDotGgQueryVariables = Exact<{
  platform?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
}>;

export type WzstatsDotGgQuery = { __typename?: 'Query' } & {
  wzstatsDotGG?: Maybe<{ __typename?: 'WzstatsDotGG' } & WzstatsDotGgInfoFragment>;
};

export type ModernWarefarecodTrackerDotGgStatsQueryVariables = Exact<{
  username?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
}>;

export type ModernWarefarecodTrackerDotGgStatsQuery = { __typename?: 'Query' } & {
  modernWarefarecodTrackerDotGGStats?: Maybe<
    { __typename?: 'CODTrackerDotGGStats' } & CodTrackerDotGgStatsInfoFragment
  >;
};

export type Blackops4codTrackerDotGGstatsQueryVariables = Exact<{
  username?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
}>;

export type Blackops4codTrackerDotGGstatsQuery = { __typename?: 'Query' } & {
  blackops4codTrackerDotGGstats?: Maybe<
    { __typename?: 'CODTrackerDotGGStats' } & CodTrackerDotGgStatsInfoFragment
  >;
};

export type WzstatsDotGgPlayerRecentMatchQueryVariables = Exact<{
  playerUsername?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
}>;

export type WzstatsDotGgPlayerRecentMatchQuery = { __typename?: 'Query' } & {
  wzstatsDotGGPlayerRecentMatch?: Maybe<
    Array<Maybe<{ __typename?: 'WzstatsDotGGMatchStat' } & WzstatsDotGgMatchStatInfoFragment>>
  >;
};

export type RocketLeagueTrackerGgStatsQueryVariables = Exact<{
  username?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
}>;

export type RocketLeagueTrackerGgStatsQuery = { __typename?: 'Query' } & {
  rocketLeagueTrackerGGStats?: Maybe<
    { __typename?: 'RocketLeagueTrackerGGStat' } & RocketLeagueTrackerGgStatInfoFragment
  >;
};

export type RocketLeagueTrackerGgLeaderboardsQueryVariables = Exact<{
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  platform?: Maybe<Scalars['String']>;
}>;

export type RocketLeagueTrackerGgLeaderboardsQuery = { __typename?: 'Query' } & {
  rocketLeagueTrackerGGLeaderboards?: Maybe<
    Array<
      Maybe<
        {
          __typename?: 'RocketLeagueTrackerGGLeaderBoardItem';
        } & RocketLeagueTrackerGgLeaderBoardItemInfoFragment
      >
    >
  >;
};

export type CodWarezoneTrackerDotGgLeaderboardQueryVariables = Exact<{
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  platform?: Maybe<Scalars['String']>;
}>;

export type CodWarezoneTrackerDotGgLeaderboardQuery = { __typename?: 'Query' } & {
  codWarezoneTrackerDotGGLeaderboard?: Maybe<
    Array<
      Maybe<
        {
          __typename?: 'CodWarezoneTrackerDotGGLeaderboardItem';
        } & CodWarezoneTrackerDotGgLeaderboardItemInfoFragment
      >
    >
  >;
};

export type CodMwTrackerDotGgLeaderboardQueryVariables = Exact<{
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  platform?: Maybe<Scalars['String']>;
}>;

export type CodMwTrackerDotGgLeaderboardQuery = { __typename?: 'Query' } & {
  codMWTrackerDotGGLeaderboard?: Maybe<
    Array<
      Maybe<
        {
          __typename?: 'CodWarezoneTrackerDotGGLeaderboardItem';
        } & CodWarezoneTrackerDotGgLeaderboardItemInfoFragment
      >
    >
  >;
};

export type CodBo4TrackerDotGgLeaderboardQueryVariables = Exact<{
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  platform?: Maybe<Scalars['String']>;
}>;

export type CodBo4TrackerDotGgLeaderboardQuery = { __typename?: 'Query' } & {
  codBo4TrackerDotGGLeaderboard?: Maybe<
    Array<
      Maybe<
        {
          __typename?: 'CodWarezoneTrackerDotGGLeaderboardItem';
        } & CodWarezoneTrackerDotGgLeaderboardItemInfoFragment
      >
    >
  >;
};

export type WzstatsDotGgGameMatchDetailQueryVariables = Exact<{
  matchId: Scalars['String'];
}>;

export type WzstatsDotGgGameMatchDetailQuery = { __typename?: 'Query' } & {
  wzstatsDotGGGameMatchDetail?: Maybe<
    { __typename?: 'WzstatsDotGGMatchStat' } & WzstatsDotGgMatchStatInfoFragment
  >;
};

export type MyApexLegendStatQueryVariables = Exact<{
  plateform?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
}>;

export type MyApexLegendStatQuery = { __typename?: 'Query' } & {
  myApexLegendStat?: Maybe<
    { __typename?: 'ApexLegendStat' } & Pick<
      ApexLegendStat,
      'platformInfo' | 'userInfo' | 'metadata' | 'segments' | 'availableSegments' | 'expiryDate'
    >
  >;
};

export type MyApexLegendMatchHistoryQueryVariables = Exact<{
  plateform?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
}>;

export type MyApexLegendMatchHistoryQuery = { __typename?: 'Query' } & {
  apexMatchHistoryBrokenBySession?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ApexLegendMatchHistory' } & Pick<
          ApexLegendMatchHistory,
          'matches' | 'metadata' | 'stats' | 'playlists'
        >
      >
    >
  >;
};

export type MyFortniteBrStatQueryVariables = Exact<{
  platform?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
}>;

export type MyFortniteBrStatQuery = { __typename?: 'Query' } & {
  myForniteBRStat?: Maybe<
    { __typename?: 'FortniteBRPlayerStat' } & Pick<
      FortniteBrPlayerStat,
      'account' | 'battlePass' | 'image' | 'stats'
    >
  >;
};

export type MyFortniteBrRecentMatchesQueryVariables = Exact<{
  platform?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
}>;

export type MyFortniteBrRecentMatchesQuery = { __typename?: 'Query' } & {
  myForniteBRRecentMatches?: Maybe<
    { __typename?: 'FortnitePlayerRecentMatches' } & Pick<
      FortnitePlayerRecentMatches,
      'account' | 'name' | 'max_results'
    > & {
        matches?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ForniteBRMatchItem' } & Pick<
                ForniteBrMatchItem,
                | 'platform'
                | 'date'
                | 'mode'
                | 'readable_name'
                | 'kills'
                | 'matchesplayed'
                | 'minutesplayed'
                | 'playersoutlived'
                | 'score'
                | 'placetop1'
                | 'placetop3'
                | 'placetop5'
                | 'placetop6'
                | 'placetop10'
                | 'placetop12'
                | 'placetop25'
              >
            >
          >
        >;
      }
  >;
};

export type Myr6StatsQueryVariables = Exact<{
  platform?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
}>;

export type Myr6StatsQuery = { __typename?: 'Query' } & {
  myr6Stats?: Maybe<{ __typename?: 'R6Stat' } & Pick<R6Stat, 'id' | 'pvp' | 'pve'>>;
};

export type Fut21PlayerStatQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
}>;

export type Fut21PlayerStatQuery = { __typename?: 'Query' } & {
  fut21PlayerStat?: Maybe<Array<Maybe<{ __typename?: 'FutPlayerInfo' } & FutPlayerInfoFragment>>>;
};

export type LeaderboardpointInfosFragment = { __typename?: 'Leaderboardpoint' } & Pick<
  Leaderboardpoint,
  'id' | 'point' | 'removed'
>;

export type AllLeaderboardpointsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<LeaderboardpointFilter>;
}>;

export type AllLeaderboardpointsQuery = { __typename?: 'Query' } & {
  allLeaderboardpoints?: Maybe<
    Array<Maybe<{ __typename?: 'Leaderboardpoint' } & LeaderboardpointInfosFragment>>
  >;
};

export type CurrentLeaderboardQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  type?: Maybe<Leaderboard_Type>;
}>;

export type CurrentLeaderboardQuery = { __typename?: 'Query' } & {
  currentLeaderboard?: Maybe<
    Array<
      Maybe<
        { __typename?: 'UserLeaderBoardPayload' } & Pick<
          UserLeaderBoardPayload,
          'point' | 'eventCount'
        > & { user?: Maybe<{ __typename?: 'User' } & UserBasicInfosFragment> }
      >
    >
  >;
};

export type ProvisionalClassificationQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
}>;

export type ProvisionalClassificationQuery = { __typename?: 'Query' } & {
  rankingUserPoint?: Maybe<Array<Maybe<{ __typename?: 'User' } & UserBasicInfosFragment>>>;
};

export type LeagueInfosFragment = { __typename?: 'League' } & Pick<
  League,
  | 'id'
  | 'title'
  | 'isActive'
  | 'description'
  | 'maxParticipants'
  | 'minParticipants'
  | 'format'
  | 'gameId'
  | 'gamePlateformId'
  | 'roundNumber'
  | 'startedAt'
  | 'createdAt'
  | 'updatedAt'
  | 'organiserId'
  | 'winnerMatchPoint'
  | 'looserMatchPoint'
  | 'drawMatchPoint'
  | 'isMeAlreadyParticipant'
  | 'entryCost'
>;

export type LeagueAwardInfosFragment = { __typename?: 'LeagueAward' } & Pick<
  LeagueAward,
  'id' | 'title' | 'value' | 'startRank' | 'endRank' | 'description' | 'updatedAt' | 'createdAt'
>;

export type LeagueRuleInfosFragment = { __typename?: 'LeagueRule' } & Pick<
  LeagueRule,
  'id' | 'name' | 'description' | 'updatedAt' | 'createdAt'
>;

export type LeagueCompleteInfosFragment = { __typename?: 'League' } & Pick<
  League,
  | 'id'
  | 'title'
  | 'isActive'
  | 'description'
  | 'maxParticipants'
  | 'minParticipants'
  | 'format'
  | 'gameId'
  | 'gamePlateformId'
  | 'winnerMatchPoint'
  | 'looserMatchPoint'
  | 'drawMatchPoint'
  | 'roundNumber'
  | 'startedAt'
  | 'createdAt'
  | 'updatedAt'
  | 'organiserId'
  | 'isMeAlreadyParticipant'
  | 'entryCost'
> & {
    game?: Maybe<{ __typename?: 'Game' } & GameInfosFragment>;
    gamePlateform?: Maybe<{ __typename?: 'GamePlatform' } & GamePlatformInfosFragment>;
    awards?: Maybe<Array<Maybe<{ __typename?: 'LeagueAward' } & LeagueAwardInfosFragment>>>;
    rules?: Maybe<Array<Maybe<{ __typename?: 'LeagueRule' } & LeagueRuleInfosFragment>>>;
    organiser?: Maybe<{ __typename?: 'Organiser' } & Pick<Organiser, 'id'>>;
    matches?: Maybe<
      Array<Maybe<{ __typename?: 'TournamentBracket' } & TournamentBracketCompleteInfosFragment>>
    >;
    participants?: Maybe<
      Array<
        Maybe<
          { __typename?: 'TournamentParticipant' } & Pick<
            TournamentParticipant,
            'id' | 'createdAt' | 'leagueTotalPoint'
          > & {
              user?: Maybe<{ __typename?: 'User' } & UserBasicInfosFragment>;
              team?: Maybe<{ __typename?: 'Team' } & TeamBasicInfosFragment>;
              brackets?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'TournamentBracket' } & TournamentBracketCompleteInfosFragment
                  >
                >
              >;
            }
        >
      >
    >;
  };

export type JoinTeamToLeagueMutationVariables = Exact<{
  leagueId: Scalars['ID'];
  teamId: Scalars['ID'];
}>;

export type JoinTeamToLeagueMutation = { __typename?: 'Mutation' } & {
  joinTeamToLeague?: Maybe<
    { __typename?: 'TournamentParticipant' } & Pick<
      TournamentParticipant,
      'id' | 'leagueTotalPoint'
    > & {
        user?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>;
        team?: Maybe<{ __typename?: 'Team' } & Pick<Team, 'id'>>;
        league?: Maybe<{ __typename?: 'League' } & Pick<League, 'id'>>;
      }
  >;
};

export type JoinMeToLeagueMutationVariables = Exact<{
  leagueId: Scalars['ID'];
}>;

export type JoinMeToLeagueMutation = { __typename?: 'Mutation' } & {
  joinMeToLeague?: Maybe<
    { __typename?: 'TournamentParticipant' } & Pick<
      TournamentParticipant,
      'id' | 'leagueTotalPoint'
    > & {
        user?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>;
        team?: Maybe<{ __typename?: 'Team' } & Pick<Team, 'id'>>;
        league?: Maybe<{ __typename?: 'League' } & Pick<League, 'id'>>;
      }
  >;
};

export type AllLeaguesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<LeagueFilter>;
}>;

export type AllLeaguesQuery = { __typename?: 'Query' } & {
  allLeagues?: Maybe<Array<Maybe<{ __typename?: 'League' } & LeagueCompleteInfosFragment>>>;
};

export type ActiveLeaguesQueryVariables = Exact<{ [key: string]: never }>;

export type ActiveLeaguesQuery = { __typename?: 'Query' } & {
  activeLeagues?: Maybe<
    Array<
      Maybe<
        { __typename?: 'League' } & Pick<
          League,
          | 'id'
          | 'title'
          | 'isActive'
          | 'startedAt'
          | 'entryCost'
          | 'format'
          | 'createdAt'
          | 'description'
          | 'isMeAlreadyParticipant'
        > & {
            awards?: Maybe<
              Array<Maybe<{ __typename?: 'LeagueAward' } & Pick<LeagueAward, 'value'>>>
            >;
            gamePlateform?: Maybe<
              { __typename?: 'GamePlatform' } & Pick<GamePlatform, 'id' | 'name' | 'logo'>
            >;
            game?: Maybe<{ __typename?: 'Game' } & Pick<Game, 'id' | 'name'>>;
          }
      >
    >
  >;
};

export type LeagueQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type LeagueQuery = { __typename?: 'Query' } & {
  League?: Maybe<{ __typename?: 'League' } & LeagueCompleteInfosFragment>;
};

export type GetGameLeagueQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetGameLeagueQuery = { __typename?: 'Query' } & {
  League?: Maybe<
    { __typename?: 'League' } & Pick<
      League,
      'id' | 'title' | 'description' | 'isMeAlreadyParticipant'
    > & {
        game?: Maybe<{ __typename?: 'Game' } & Pick<Game, 'id' | 'name' | 'mainJacket' | 'logo'>>;
        gamePlateform?: Maybe<
          { __typename?: 'GamePlatform' } & Pick<GamePlatform, 'id' | 'name' | 'logo'>
        >;
      }
  >;
};

export type GetLeagueGameQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetLeagueGameQuery = { __typename?: 'Query' } & {
  Game?: Maybe<
    { __typename?: 'Game' } & {
      leagues?: Maybe<Array<Maybe<{ __typename?: 'League' } & LeagueInfosFragment>>>;
    } & GameInfosFragment
  >;
};

export type GetGeneralInfoLeagueQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetGeneralInfoLeagueQuery = { __typename?: 'Query' } & {
  League?: Maybe<
    { __typename?: 'League' } & Pick<
      League,
      | 'id'
      | 'title'
      | 'description'
      | 'startedAt'
      | 'createdAt'
      | 'format'
      | 'maxParticipants'
      | 'minParticipants'
      | 'isActive'
      | 'entryCost'
      | 'isMeAlreadyParticipant'
    > & {
        organiser?: Maybe<{ __typename?: 'Organiser' } & Pick<Organiser, 'id' | 'name'>>;
        game?: Maybe<{ __typename?: 'Game' } & Pick<Game, 'id' | 'logo'>>;
        gamePlateform?: Maybe<
          { __typename?: 'GamePlatform' } & Pick<GamePlatform, 'id' | 'name' | 'logo'>
        >;
        awards?: Maybe<
          Array<
            Maybe<
              { __typename?: 'LeagueAward' } & Pick<
                LeagueAward,
                'id' | 'value' | 'startRank' | 'endRank'
              >
            >
          >
        >;
      }
  >;
};

export type GetBracketLeagueQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetBracketLeagueQuery = { __typename?: 'Query' } & {
  League?: Maybe<
    { __typename?: 'League' } & Pick<League, 'id' | 'title' | 'description' | 'startedAt'> & {
        matches?: Maybe<
          Array<
            Maybe<
              { __typename?: 'TournamentBracket' } & {
                participant1?: Maybe<
                  { __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'> & {
                      user?: Maybe<{ __typename?: 'User' } & UserBasicInfosFragment>;
                      team?: Maybe<{ __typename?: 'Team' } & TeamBasicInfosFragment>;
                    }
                >;
                participant2?: Maybe<
                  { __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'> & {
                      user?: Maybe<{ __typename?: 'User' } & UserBasicInfosFragment>;
                      team?: Maybe<{ __typename?: 'Team' } & TeamBasicInfosFragment>;
                    }
                >;
                winner?: Maybe<
                  { __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'> & {
                      user?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>;
                      team?: Maybe<{ __typename?: 'Team' } & Pick<Team, 'id'>>;
                    }
                >;
              } & TournamentBracketInfosFragment
            >
          >
        >;
      }
  >;
};

export type GetBracketClassmentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetBracketClassmentQuery = { __typename?: 'Query' } & {
  League?: Maybe<
    { __typename?: 'League' } & Pick<League, 'id' | 'title' | 'description' | 'startedAt'> & {
        participants?: Maybe<
          Array<
            Maybe<
              { __typename?: 'TournamentParticipant' } & Pick<
                TournamentParticipant,
                'id' | 'createdAt' | 'leagueTotalPoint'
              > & {
                  user?: Maybe<{ __typename?: 'User' } & UserBasicInfosFragment>;
                  team?: Maybe<{ __typename?: 'Team' } & TeamBasicInfosFragment>;
                  brackets?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'TournamentBracket' } & {
                          winner?: Maybe<
                            { __typename?: 'TournamentParticipant' } & Pick<
                              TournamentParticipant,
                              'id'
                            > & {
                                user?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>;
                                team?: Maybe<{ __typename?: 'Team' } & Pick<Team, 'id'>>;
                              }
                          >;
                        } & TournamentBracketInfosFragment
                      >
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type GetParticipantsLeagueQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetParticipantsLeagueQuery = { __typename?: 'Query' } & {
  League?: Maybe<
    { __typename?: 'League' } & Pick<
      League,
      'id' | 'title' | 'description' | 'isMeAlreadyParticipant'
    > & {
        participants?: Maybe<
          Array<
            Maybe<
              { __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'> & {
                  user?: Maybe<{ __typename?: 'User' } & UserBasicInfosFragment>;
                  team?: Maybe<{ __typename?: 'Team' } & TeamBasicInfosFragment>;
                }
            >
          >
        >;
      }
  >;
};

export type GetRulesLeagueQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetRulesLeagueQuery = { __typename?: 'Query' } & {
  League?: Maybe<
    { __typename?: 'League' } & Pick<League, 'id' | 'title' | 'description'> & {
        rules?: Maybe<Array<Maybe<{ __typename?: 'LeagueRule' } & LeagueRuleInfosFragment>>>;
      }
  >;
};

export type MessageInfosFragment = { __typename?: 'Message' } & Pick<
  Message,
  'id' | 'content' | 'authorId' | 'threadId' | 'createdAt' | 'updatedAt' | 'isMine' | 'isRemoved'
>;

export type AddMessageThreadMutationVariables = Exact<{
  input: AddMessageInput;
}>;

export type AddMessageThreadMutation = { __typename?: 'Mutation' } & {
  addMessage: { __typename?: 'Message' } & {
    author: { __typename?: 'User' } & UserInfosFragment;
  } & MessageInfosFragment;
};

export type EditMessageThreadMutationVariables = Exact<{
  input: EditMessageInput;
}>;

export type EditMessageThreadMutation = { __typename?: 'Mutation' } & {
  editMessage: { __typename?: 'Message' } & MessageInfosFragment;
};

export type DeleteMessageThreadMutationVariables = Exact<{
  input: DeleteMessageInput;
}>;

export type DeleteMessageThreadMutation = { __typename?: 'Mutation' } & {
  deleteMessage: { __typename?: 'Message' } & MessageInfosFragment;
};

export type SubscribeToMessageThreadAddedSubscriptionVariables = Exact<{
  threadId: Scalars['ID'];
}>;

export type SubscribeToMessageThreadAddedSubscription = { __typename?: 'Subscription' } & {
  messageThreadAdded: { __typename?: 'Message' } & MessageInfosFragment;
};

export type SubscribeToMessageThreadEditSubscriptionVariables = Exact<{
  threadId: Scalars['ID'];
}>;

export type SubscribeToMessageThreadEditSubscription = { __typename?: 'Subscription' } & {
  messageThreadEdit: { __typename?: 'Message' } & MessageInfosFragment;
};

export type SubscribeToMessageThreadRemovedSubscriptionVariables = Exact<{
  threadId: Scalars['ID'];
}>;

export type SubscribeToMessageThreadRemovedSubscription = { __typename?: 'Subscription' } & {
  messageThreadRemoved: { __typename?: 'Message' } & MessageInfosFragment;
};

export type NotificationInfosFragment = { __typename?: 'Notification' } & Pick<
  Notification,
  'id' | 'title' | 'content' | 'type' | 'createdAt'
>;

export type AllNotificationsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<NotificationFilter>;
}>;

export type AllNotificationsQuery = { __typename?: 'Query' } & {
  allNotifications?: Maybe<
    Array<Maybe<{ __typename?: 'Notification' } & NotificationInfosFragment>>
  >;
};

export type PageInfosFragment = { __typename?: 'Page' } & Pick<
  Page,
  'id' | 'slug' | 'pageContentIds' | 'title'
>;

export type PageContentInfosFragment = { __typename?: 'PageContent' } & Pick<
  PageContent,
  'id' | 'label' | 'value' | 'value_en'
>;

export type AllPagesQueryVariables = Exact<{ [key: string]: never }>;

export type AllPagesQuery = { __typename?: 'Query' } & {
  allPages?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Page' } & {
          contents?: Maybe<Array<Maybe<{ __typename?: 'PageContent' } & PageContentInfosFragment>>>;
        } & PageInfosFragment
      >
    >
  >;
};

export type PageBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type PageBySlugQuery = { __typename?: 'Query' } & {
  pageBySlug?: Maybe<
    { __typename?: 'Page' } & {
      contents?: Maybe<Array<Maybe<{ __typename?: 'PageContent' } & PageContentInfosFragment>>>;
    } & PageInfosFragment
  >;
};

export type OrderInfosFragment = { __typename?: 'Order' } & Pick<
  Order,
  'id' | 'amount' | 'paymentMethod'
>;

export type MakePaymentMutationVariables = Exact<{
  paymentMethod?: Maybe<Payment_Method>;
  tournamentId?: Maybe<Scalars['ID']>;
  wagerId?: Maybe<Scalars['ID']>;
  leagueId?: Maybe<Scalars['ID']>;
  object?: Maybe<Payment_Object>;
}>;

export type MakePaymentMutation = { __typename?: 'Mutation' } & {
  makePayment?: Maybe<
    { __typename?: 'MakePaymentPayload' } & Pick<
      MakePaymentPayload,
      'clientSecret' | 'paymentMethod'
    > & { order?: Maybe<{ __typename?: 'Order' } & OrderInfosFragment> }
  >;
};

export type CreateOrderMutationVariables = Exact<{
  amount?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['ID']>;
  tournamentId?: Maybe<Scalars['ID']>;
  wagerId?: Maybe<Scalars['ID']>;
  leagueId?: Maybe<Scalars['ID']>;
}>;

export type CreateOrderMutation = { __typename?: 'Mutation' } & {
  createOrder?: Maybe<{ __typename?: 'Order' } & OrderInfosFragment>;
};

export type DeleteOrderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteOrderMutation = { __typename?: 'Mutation' } & {
  deleteOrder?: Maybe<{ __typename?: 'Order' } & Pick<Order, 'id'>>;
};

export type ConfirmOrderPaymentMutationVariables = Exact<{
  orderId: Scalars['ID'];
  meta?: Maybe<Scalars['JSON']>;
}>;

export type ConfirmOrderPaymentMutation = { __typename?: 'Mutation' } & {
  confirmOrderPayment?: Maybe<{ __typename?: 'Order' } & OrderInfosFragment>;
};

export type ReportUserActionMutationVariables = Exact<{
  input: ReportUserActionInput;
}>;

export type ReportUserActionMutation = { __typename?: 'Mutation' } & {
  reportUserAction?: Maybe<{ __typename?: 'Report' } & Pick<Report, 'id'>>;
};

export type SaisonInfoFragment = { __typename?: 'Saison' } & Pick<
  Saison,
  'id' | 'title' | 'startDate' | 'endDate' | 'removed'
>;

export type CurrentSaisonQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentSaisonQuery = { __typename?: 'Query' } & {
  currentSaison?: Maybe<{ __typename?: 'Saison' } & SaisonInfoFragment>;
};

export type ThisMonthTournamentCountQueryVariables = Exact<{ [key: string]: never }>;

export type ThisMonthTournamentCountQuery = { __typename?: 'Query' } & Pick<
  Query,
  'thisMonthTournamentCount'
>;

export type SocialAccountInfosFragment = { __typename?: 'Socialaccount' } & Pick<
  Socialaccount,
  'id' | 'name' | 'label' | 'logo'
>;

export type UserSocialAccountInfosFragment = { __typename?: 'Usersocialaccount' } & Pick<
  Usersocialaccount,
  'id' | 'email' | 'username'
> & { socialAccount?: Maybe<{ __typename?: 'Socialaccount' } & SocialAccountInfosFragment> };

export type CreateUserSocialAccountMutationVariables = Exact<{
  socialAccountId: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
  username: Scalars['String'];
  email: Scalars['String'];
}>;

export type CreateUserSocialAccountMutation = { __typename?: 'Mutation' } & {
  createUsersocialaccount?: Maybe<
    { __typename?: 'Usersocialaccount' } & UserSocialAccountInfosFragment
  >;
};

export type DeleteUsersocialaccountMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteUsersocialaccountMutation = { __typename?: 'Mutation' } & {
  deleteUsersocialaccount?: Maybe<
    { __typename?: 'Usersocialaccount' } & UserSocialAccountInfosFragment
  >;
};

export type AllSocialaccountsQueryVariables = Exact<{ [key: string]: never }>;

export type AllSocialaccountsQuery = { __typename?: 'Query' } & {
  allSocialaccounts?: Maybe<
    Array<Maybe<{ __typename?: 'Socialaccount' } & SocialAccountInfosFragment>>
  >;
};

export type FindSocialAccountByLabelQueryVariables = Exact<{
  label: Scalars['String'];
}>;

export type FindSocialAccountByLabelQuery = { __typename?: 'Query' } & {
  findSocialAccountByLabel?: Maybe<{ __typename?: 'Socialaccount' } & SocialAccountInfosFragment>;
};

export type FindUserSocialAccountByLabelQueryVariables = Exact<{
  label: Scalars['String'];
}>;

export type FindUserSocialAccountByLabelQuery = { __typename?: 'Query' } & {
  findUserSocialAccountByLabel?: Maybe<
    { __typename?: 'Usersocialaccount' } & UserSocialAccountInfosFragment
  >;
};

export type HasUserSocialAccountQueryVariables = Exact<{
  socialAccountId: Scalars['ID'];
}>;

export type HasUserSocialAccountQuery = { __typename?: 'Query' } & Pick<
  Query,
  'hasUserSocialAccount'
>;

export type SubscriptionInfosFragment = { __typename?: 'SubscriptionType' } & Pick<
  SubscriptionType,
  'id' | 'totalMonth' | 'pricePerMonth' | 'description' | 'title'
>;

export type SubscribeToPermiumMutationMutationVariables = Exact<{
  input: SubscribeToPremiumInput;
}>;

export type SubscribeToPermiumMutationMutation = { __typename?: 'Mutation' } & {
  subscribeToPremium?: Maybe<
    { __typename?: 'User' } & {
      currentSubsciption?: Maybe<{ __typename?: 'SubscriptionType' } & SubscriptionInfosFragment>;
    } & UserInfosFragment
  >;
};

export type ActiveCouponMutationMutationVariables = Exact<{
  input: ActiveCouponInput;
}>;

export type ActiveCouponMutationMutation = { __typename?: 'Mutation' } & {
  activeCoupon?: Maybe<
    { __typename?: 'User' } & {
      currentSubsciption?: Maybe<{ __typename?: 'SubscriptionType' } & SubscriptionInfosFragment>;
    } & UserInfosFragment
  >;
};

export type CurrentSubscriptionUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentSubscriptionUserQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'User' } & Pick<User, 'id' | 'endPremium'> & {
      currentSubsciption?: Maybe<{ __typename?: 'SubscriptionType' } & SubscriptionInfosFragment>;
    };
};

export type GetAllSubscriptionQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllSubscriptionQuery = { __typename?: 'Query' } & {
  subscriptions?: Maybe<Array<{ __typename?: 'SubscriptionType' } & SubscriptionInfosFragment>>;
};

export type TeamBasicInfosFragment = { __typename?: 'Team' } & Pick<
  Team,
  'id' | 'name' | 'description' | 'logo' | 'banner' | 'createdAt' | 'country' | 'point'
> & { members?: Maybe<Array<Maybe<{ __typename?: 'TeamMember' } & Pick<TeamMember, 'id'>>>> };

export type TeamInfosFragment = { __typename?: 'Team' } & Pick<
  Team,
  'id' | 'name' | 'description' | 'logo' | 'banner' | 'createdAt'
> & {
    owner?: Maybe<{ __typename?: 'TeamMember' } & TeamMemberInfosFragment>;
    members?: Maybe<Array<Maybe<{ __typename?: 'TeamMember' } & TeamMemberInfosFragment>>>;
  };

export type CreateTeamMutationVariables = Exact<{
  name: Scalars['String'];
  tag?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
}>;

export type CreateTeamMutation = { __typename?: 'Mutation' } & {
  createTeam?: Maybe<{ __typename?: 'Team' } & TeamInfosFragment>;
};

export type DeleteTeamMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteTeamMutation = { __typename?: 'Mutation' } & {
  deleteTeam?: Maybe<{ __typename?: 'Team' } & Pick<Team, 'id'>>;
};

export type UpdateTeamMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
}>;

export type UpdateTeamMutation = { __typename?: 'Mutation' } & {
  updateTeam?: Maybe<{ __typename?: 'Team' } & TeamInfosFragment>;
};

export type GetTeamsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTeamsQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'User' } & Pick<User, 'id'> & {
      joinedTeams?: Maybe<
        Array<
          Maybe<
            { __typename?: 'TeamMember' } & Pick<TeamMember, 'id' | 'role'> & {
                team?: Maybe<
                  { __typename?: 'Team' } & {
                    owner?: Maybe<
                      { __typename?: 'TeamMember' } & Pick<TeamMember, 'id'> & {
                          user?: Maybe<{ __typename?: 'User' } & UserBasicInfosFragment>;
                        }
                    >;
                  } & TeamBasicInfosFragment
                >;
              }
          >
        >
      >;
    };
};

export type GetTeamQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetTeamQuery = { __typename?: 'Query' } & {
  Team?: Maybe<{ __typename?: 'Team' } & TeamInfosFragment>;
};

export type ListTeamsNotInTournamentQueryVariables = Exact<{
  filter: TeamsTournamentFilterInput;
}>;

export type ListTeamsNotInTournamentQuery = { __typename?: 'Query' } & {
  teamsTournament: { __typename?: 'TeamResult' } & Pick<TeamResult, 'total'> & {
      results: Array<{ __typename?: 'Team' } & TeamInfosFragment>;
    };
};

export type GetTeamDiscordQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetTeamDiscordQuery = { __typename?: 'Query' } & {
  Team?: Maybe<
    { __typename?: 'Team' } & Pick<Team, 'id' | 'discordChannelId' | 'discordVocalChannelId'>
  >;
};

export type TeamMemberInfosFragment = { __typename?: 'TeamMember' } & Pick<
  TeamMember,
  'id' | 'role' | 'createdAt'
> & {
    user?: Maybe<
      { __typename?: 'User' } & Pick<
        User,
        | 'id'
        | 'isMe'
        | 'firstname'
        | 'lastname'
        | 'email'
        | 'avatar'
        | 'username'
        | 'isAdmin'
        | 'phoneNumber'
        | 'displayName'
      >
    >;
    team?: Maybe<
      { __typename?: 'Team' } & Pick<
        Team,
        'id' | 'name' | 'description' | 'logo' | 'banner' | 'createdAt'
      >
    >;
  };

export type InviteUserToTeamMutationVariables = Exact<{
  teamId: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
}>;

export type InviteUserToTeamMutation = { __typename?: 'Mutation' } & {
  inviteUserToTeam?: Maybe<{ __typename?: 'TeamMember' } & Pick<TeamMember, 'id'>>;
};

export type DeleteTeamMemberMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteTeamMemberMutation = { __typename?: 'Mutation' } & {
  deleteTeamMember?: Maybe<{ __typename?: 'DeletedTeam' } & Pick<DeletedTeam, 'n' | 'ok'>>;
};

export type AllUsersNotInTeamQueryVariables = Exact<{
  teamId?: Maybe<Scalars['ID']>;
  q?: Maybe<Scalars['String']>;
}>;

export type AllUsersNotInTeamQuery = { __typename?: 'Query' } & {
  allUsersNotInTeam?: Maybe<Array<Maybe<{ __typename?: 'User' } & UserInfosFragment>>>;
};

export type GetCustomTeamMembersQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  ownerIn?: Maybe<Scalars['Boolean']>;
}>;

export type GetCustomTeamMembersQuery = { __typename?: 'Query' } & {
  getCustomTeamMembers?: Maybe<
    Array<Maybe<{ __typename?: 'TeamMember' } & TeamMemberInfosFragment>>
  >;
};

export type GetVersusTeamMemberQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
}>;

export type GetVersusTeamMemberQuery = { __typename?: 'Query' } & {
  getVersusTeamMember?: Maybe<
    Array<Maybe<{ __typename?: 'TeamMember' } & TeamMemberInfosFragment>>
  >;
};

export type TextContentInfosFragment = { __typename?: 'TextContent' } & Pick<
  TextContent,
  'id' | 'contentFr' | 'contentEn' | 'section' | 'textType' | 'removed' | 'createdAt'
>;

export type CreateTextContentMutationVariables = Exact<{
  contentFr?: Maybe<Scalars['String']>;
  contentEn?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  textType?: Maybe<Text_Type>;
}>;

export type CreateTextContentMutation = { __typename?: 'Mutation' } & {
  createTextContent?: Maybe<{ __typename?: 'TextContent' } & TextContentInfosFragment>;
};

export type UpdateTextContentMutationVariables = Exact<{
  id: Scalars['ID'];
  contentFr?: Maybe<Scalars['String']>;
  contentEn?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  textType?: Maybe<Text_Type>;
}>;

export type UpdateTextContentMutation = { __typename?: 'Mutation' } & {
  updateTextContent?: Maybe<{ __typename?: 'TextContent' } & TextContentInfosFragment>;
};

export type DeleteTextContentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteTextContentMutation = { __typename?: 'Mutation' } & {
  deleteTextContent?: Maybe<{ __typename?: 'TextContent' } & TextContentInfosFragment>;
};

export type GetTextContentQueryVariables = Exact<{
  section?: Maybe<Scalars['String']>;
  textType?: Maybe<Text_Type>;
  index?: Maybe<Scalars['Int']>;
}>;

export type GetTextContentQuery = { __typename?: 'Query' } & {
  getTextContent?: Maybe<
    { __typename?: 'TextContent' } & Pick<
      TextContent,
      'id' | 'contentFr' | 'contentEn' | 'index' | 'section' | 'textType' | 'removed' | 'createdAt'
    >
  >;
};

export type ThreadInfosFragment = { __typename?: 'Thread' } & Pick<
  Thread,
  | 'id'
  | 'title'
  | 'authorId'
  | 'createdAt'
  | 'updatedAt'
  | 'isPrivate'
  | 'isRemoved'
  | 'totalMessage'
>;

export type ListPrivateThreadQueryVariables = Exact<{
  filter: AllThreadFilter;
}>;

export type ListPrivateThreadQuery = { __typename?: 'Query' } & {
  allPrivateThread: { __typename?: 'ThreadResult' } & Pick<ThreadResult, 'total'> & {
      results: Array<
        { __typename?: 'Thread' } & {
          user?: Maybe<{ __typename?: 'User' } & UserInfosFragment>;
        } & ThreadInfosFragment
      >;
    };
};

export type ThreadDetailQueryVariables = Exact<{
  threadId: Scalars['ID'];
  filter?: Maybe<Filter>;
}>;

export type ThreadDetailQuery = { __typename?: 'Query' } & {
  thread: { __typename?: 'Thread' } & {
    user?: Maybe<{ __typename?: 'User' } & UserInfosFragment>;
    messages: Array<
      Maybe<
        { __typename?: 'Message' } & {
          author: { __typename?: 'User' } & UserInfosFragment;
        } & MessageInfosFragment
      >
    >;
  } & ThreadInfosFragment;
};

export type GetPrivateThreadUserQueryVariables = Exact<{
  input: GetPrivateThreadUserInput;
}>;

export type GetPrivateThreadUserQuery = { __typename?: 'Query' } & {
  getPrivateThreadUser: { __typename?: 'Thread' } & ThreadInfosFragment;
};

export type TournamentInfosFragment = { __typename?: 'Tournament' } & Pick<
  Tournament,
  | 'id'
  | 'title'
  | 'description'
  | 'discordChannelId'
  | 'startedAt'
  | 'createdAt'
  | 'maxParticipants'
  | 'minParticipants'
  | 'isActive'
  | 'status'
  | 'rewardsTotal'
  | 'entryCost'
  | 'isMeAlreadyParticipant'
  | 'entryType'
> & {
    game?: Maybe<{ __typename?: 'Game' } & GameInfosFragment>;
    format?: Maybe<{ __typename?: 'TournamentFormat' } & TournamentFormatInfosFragment>;
    gamePlateform?: Maybe<{ __typename?: 'GamePlatform' } & GamePlatformInfosFragment>;
    organiser?: Maybe<{ __typename?: 'Organiser' } & Pick<Organiser, 'id' | 'name'>>;
  };

export type UpdateTournamentMutationVariables = Exact<{
  input: UpdateTournamentInput;
}>;

export type UpdateTournamentMutation = { __typename?: 'Mutation' } & {
  updateTournament: { __typename?: 'Tournament' } & TournamentInfosFragment;
};

export type JoinUserToTournamentMutationVariables = Exact<{
  tournamentId: Scalars['ID'];
}>;

export type JoinUserToTournamentMutation = { __typename?: 'Mutation' } & {
  joinUserToTournament?: Maybe<
    { __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'> & {
        user?: Maybe<{ __typename?: 'User' } & UserInfosFragment>;
        team?: Maybe<{ __typename?: 'Team' } & TeamInfosFragment>;
      }
  >;
};

export type JoinTeamToTournamentMutationVariables = Exact<{
  tournamentId: Scalars['ID'];
  teamId: Scalars['ID'];
}>;

export type JoinTeamToTournamentMutation = { __typename?: 'Mutation' } & {
  joinTeamToTournament?: Maybe<
    { __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'> & {
        user?: Maybe<{ __typename?: 'User' } & UserInfosFragment>;
        team?: Maybe<{ __typename?: 'Team' } & TeamInfosFragment>;
      }
  >;
};

export type GetTournamentsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TournamentFilter>;
}>;

export type GetTournamentsQuery = { __typename?: 'Query' } & {
  allTournaments?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Tournament' } & {
          awards?: Maybe<
            Array<Maybe<{ __typename?: 'TournamentAward' } & TournamentAwardInfoFragment>>
          >;
        } & TournamentInfosFragment
      >
    >
  >;
};

export type AllTournamentsFeaturedQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<TournamentFilter>;
}>;

export type AllTournamentsFeaturedQuery = { __typename?: 'Query' } & {
  allTournamentsFeatured?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Tournament' } & {
          awards?: Maybe<
            Array<Maybe<{ __typename?: 'TournamentAward' } & TournamentAwardInfoFragment>>
          >;
          participants?: Maybe<
            Array<
              Maybe<
                { __typename?: 'TournamentParticipant' } & Pick<
                  TournamentParticipant,
                  'id' | 'point'
                > & {
                    user?: Maybe<{ __typename?: 'User' } & UserBasicInfosFragment>;
                    team?: Maybe<{ __typename?: 'Team' } & TeamBasicInfosFragment>;
                  }
              >
            >
          >;
        } & TournamentInfosFragment
      >
    >
  >;
};

export type AllTournamentsQueryVariables = Exact<{ [key: string]: never }>;

export type AllTournamentsQuery = { __typename?: 'Query' } & {
  allTournaments?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Tournament' } & Pick<
          Tournament,
          | 'id'
          | 'title'
          | 'isActive'
          | 'startedAt'
          | 'createdAt'
          | 'entryCost'
          | 'totalAwardValue'
          | 'rewardsTotal'
          | 'description'
          | 'entryType'
          | 'maxParticipants'
          | 'isMeAlreadyParticipant'
        > & {
            format?: Maybe<
              { __typename?: 'TournamentFormat' } & Pick<TournamentFormat, 'title' | 'type'>
            >;
            awards?: Maybe<
              Array<Maybe<{ __typename?: 'TournamentAward' } & Pick<TournamentAward, 'value'>>>
            >;
            gamePlateform?: Maybe<
              { __typename?: 'GamePlatform' } & Pick<GamePlatform, 'id' | 'name' | 'logo'>
            >;
            gamemode?: Maybe<
              { __typename?: 'Gamemode' } & Pick<Gamemode, 'id' | 'title' | 'teamSize' | 'type'>
            >;
            organiser?: Maybe<{ __typename?: 'Organiser' } & Pick<Organiser, 'id' | 'name'>>;
            participants?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'TournamentParticipant' } & Pick<
                    TournamentParticipant,
                    'id' | 'point'
                  > & {
                      user?: Maybe<
                        { __typename?: 'User' } & Pick<User, 'id' | 'avatar' | 'username' | 'point'>
                      >;
                      team?: Maybe<
                        { __typename?: 'Team' } & Pick<Team, 'id' | 'logo' | 'name' | 'point'>
                      >;
                    }
                >
              >
            >;
            game?: Maybe<
              { __typename?: 'Game' } & Pick<
                Game,
                'id' | 'name' | 'logo' | 'twitchGameId' | 'mainJacket' | 'landingPageJacket'
              >
            >;
          }
      >
    >
  >;
};

export type GetActiveTournamentsQueryVariables = Exact<{ [key: string]: never }>;

export type GetActiveTournamentsQuery = { __typename?: 'Query' } & {
  activeTournaments?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Tournament' } & Pick<
          Tournament,
          | 'id'
          | 'title'
          | 'isActive'
          | 'startedAt'
          | 'createdAt'
          | 'entryCost'
          | 'rewardsTotal'
          | 'description'
          | 'maxParticipants'
          | 'isMeAlreadyParticipant'
        > & {
            format?: Maybe<{ __typename?: 'TournamentFormat' } & Pick<TournamentFormat, 'title'>>;
            awards?: Maybe<
              Array<Maybe<{ __typename?: 'TournamentAward' } & Pick<TournamentAward, 'value'>>>
            >;
            gamePlateform?: Maybe<
              { __typename?: 'GamePlatform' } & Pick<GamePlatform, 'id' | 'name' | 'logo'>
            >;
            gamemode?: Maybe<
              { __typename?: 'Gamemode' } & Pick<Gamemode, 'id' | 'title' | 'teamSize' | 'type'>
            >;
            participants?: Maybe<
              Array<
                Maybe<{ __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'>>
              >
            >;
            game?: Maybe<
              { __typename?: 'Game' } & Pick<
                Game,
                'id' | 'name' | 'mainJacket' | 'logo' | 'twitchGameId'
              >
            >;
          }
      >
    >
  >;
};

export type GetTournamentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetTournamentQuery = { __typename?: 'Query' } & {
  Tournament?: Maybe<
    { __typename?: 'Tournament' } & {
      participants?: Maybe<
        Array<
          Maybe<
            { __typename?: 'TournamentParticipant' } & Pick<
              TournamentParticipant,
              'id' | 'point'
            > & {
                user?: Maybe<{ __typename?: 'User' } & UserBasicInfosFragment>;
                team?: Maybe<{ __typename?: 'Team' } & TeamBasicInfosFragment>;
              }
          >
        >
      >;
      rules?: Maybe<Array<Maybe<{ __typename?: 'TournamentRule' } & TournamentRuleInfosFragment>>>;
      awards?: Maybe<
        Array<Maybe<{ __typename?: 'TournamentAward' } & TournamentAwardInfoFragment>>
      >;
      brackets?: Maybe<
        Array<
          Maybe<
            { __typename?: 'TournamentBracket' } & {
              participant1?: Maybe<
                { __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'> & {
                    user?: Maybe<{ __typename?: 'User' } & UserBasicInfosFragment>;
                    team?: Maybe<{ __typename?: 'Team' } & TeamBasicInfosFragment>;
                  }
              >;
              participant2?: Maybe<
                { __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'> & {
                    user?: Maybe<{ __typename?: 'User' } & UserBasicInfosFragment>;
                    team?: Maybe<{ __typename?: 'Team' } & TeamBasicInfosFragment>;
                  }
              >;
              winner?: Maybe<
                { __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'> & {
                    user?: Maybe<{ __typename?: 'User' } & UserBasicInfosFragment>;
                    team?: Maybe<{ __typename?: 'Team' } & TeamBasicInfosFragment>;
                  }
              >;
            } & TournamentBracketInfosFragment
          >
        >
      >;
    } & TournamentInfosFragment
  >;
};

export type GetLastTenTournamentsQueryVariables = Exact<{ [key: string]: never }>;

export type GetLastTenTournamentsQuery = { __typename?: 'Query' } & {
  findLastTenTournaments: { __typename?: 'TournamentResult' } & Pick<TournamentResult, 'total'> & {
      results: Array<{ __typename?: 'Tournament' } & TournamentInfosFragment>;
    };
};

export type GetGeneralInfoTournamentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetGeneralInfoTournamentQuery = { __typename?: 'Query' } & {
  Tournament?: Maybe<
    { __typename?: 'Tournament' } & Pick<
      Tournament,
      | 'id'
      | 'title'
      | 'description'
      | 'discordChannelId'
      | 'startedAt'
      | 'createdAt'
      | 'isSignupEnd'
      | 'maxParticipants'
      | 'minParticipants'
      | 'isActive'
      | 'status'
      | 'entryType'
      | 'descordUrl'
      | 'signupStartedAt'
      | 'signupEndAt'
      | 'totalAwardValue'
      | 'rewardsTotal'
      | 'entryCost'
      | 'signupAt'
      | 'isMeAlreadyParticipant'
    > & {
        organiser?: Maybe<{ __typename?: 'Organiser' } & Pick<Organiser, 'id' | 'name'>>;
        game?: Maybe<{ __typename?: 'Game' } & Pick<Game, 'id' | 'logo'>>;
        format?: Maybe<
          { __typename?: 'TournamentFormat' } & Pick<TournamentFormat, 'id' | 'title' | 'type'>
        >;
        gamePlateform?: Maybe<
          { __typename?: 'GamePlatform' } & Pick<GamePlatform, 'id' | 'name' | 'logo'>
        >;
        awards?: Maybe<
          Array<
            Maybe<
              { __typename?: 'TournamentAward' } & Pick<
                TournamentAward,
                'id' | 'value' | 'startRank' | 'endRank'
              >
            >
          >
        >;
        rounds?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Tournamentround' } & Pick<
                Tournamentround,
                'id' | 'roundNumber' | 'roundDate'
              >
            >
          >
        >;
        region?: Maybe<{ __typename?: 'Region' } & Pick<Region, 'id' | 'name'>>;
        gameserver?: Maybe<
          { __typename?: 'Gameserver' } & Pick<Gameserver, 'id' | 'name'> & {
              country?: Maybe<{ __typename?: 'Country' } & Pick<Country, 'id' | 'code'>>;
            }
        >;
        map?: Maybe<{ __typename?: 'Map' } & Pick<Map, 'id' | 'name' | 'thumbnail'>>;
        audiochat?: Maybe<
          { __typename?: 'Audiochatplateform' } & Pick<Audiochatplateform, 'id' | 'name'>
        >;
        myRanking?: Maybe<
          { __typename?: 'Tournamentranking' } & Pick<Tournamentranking, 'id' | 'rank'>
        >;
      }
  >;
};

export type GetWinnerTournamentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetWinnerTournamentQuery = { __typename?: 'Query' } & {
  Tournament?: Maybe<
    { __typename?: 'Tournament' } & Pick<Tournament, 'id'> & {
        awards?: Maybe<
          Array<
            Maybe<
              { __typename?: 'TournamentAward' } & Pick<
                TournamentAward,
                'id' | 'value' | 'startRank' | 'endRank'
              > & {
                  winnerParticipants?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'TournamentParticipant' } & Pick<
                          TournamentParticipant,
                          'id'
                        > & {
                            user?: Maybe<{ __typename?: 'User' } & UserBasicInfosFragment>;
                            team?: Maybe<{ __typename?: 'Team' } & TeamBasicInfosFragment>;
                          }
                      >
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type GetBracketTournamentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetBracketTournamentQuery = { __typename?: 'Query' } & {
  Tournament?: Maybe<
    { __typename?: 'Tournament' } & Pick<
      Tournament,
      'id' | 'title' | 'description' | 'status' | 'startedAt' | 'maxParticipants'
    > & {
        participants?: Maybe<
          Array<Maybe<{ __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'>>>
        >;
        brackets?: Maybe<
          Array<
            Maybe<
              { __typename?: 'TournamentBracket' } & {
                participant1?: Maybe<
                  { __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'> & {
                      user?: Maybe<{ __typename?: 'User' } & UserBasicInfosFragment>;
                      team?: Maybe<{ __typename?: 'Team' } & TeamBasicInfosFragment>;
                    }
                >;
                participant2?: Maybe<
                  { __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'> & {
                      user?: Maybe<{ __typename?: 'User' } & UserBasicInfosFragment>;
                      team?: Maybe<{ __typename?: 'Team' } & TeamBasicInfosFragment>;
                    }
                >;
                winner?: Maybe<
                  { __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'> & {
                      user?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>;
                      team?: Maybe<{ __typename?: 'Team' } & Pick<Team, 'id'>>;
                    }
                >;
              } & TournamentBracketInfosFragment
            >
          >
        >;
      }
  >;
};

export type GetGameTournamentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetGameTournamentQuery = { __typename?: 'Query' } & {
  Tournament?: Maybe<
    { __typename?: 'Tournament' } & Pick<
      Tournament,
      | 'id'
      | 'title'
      | 'description'
      | 'discordChannelId'
      | 'startedAt'
      | 'endAt'
      | 'signupStartedAt'
      | 'signupEndAt'
      | 'descordUrl'
      | 'isMeAlreadyParticipant'
    > & {
        organiser?: Maybe<{ __typename?: 'Organiser' } & Pick<Organiser, 'id' | 'logo'>>;
        gamemode?: Maybe<
          { __typename?: 'Gamemode' } & Pick<Gamemode, 'id' | 'title' | 'teamSize' | 'type'>
        >;
        game?: Maybe<
          { __typename?: 'Game' } & Pick<
            Game,
            'id' | 'name' | 'mainJacket' | 'logo' | 'twitchGameId'
          >
        >;
        participants?: Maybe<
          Array<Maybe<{ __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'>>>
        >;
        gamePlateform?: Maybe<
          { __typename?: 'GamePlatform' } & Pick<GamePlatform, 'id' | 'name' | 'logo'>
        >;
        gameserver?: Maybe<
          { __typename?: 'Gameserver' } & Pick<Gameserver, 'id'> & {
              country?: Maybe<{ __typename?: 'Country' } & Pick<Country, 'id' | 'code'>>;
            }
        >;
      }
  >;
};

export type GetParticipantsTournamentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetParticipantsTournamentQuery = { __typename?: 'Query' } & {
  Tournament?: Maybe<
    { __typename?: 'Tournament' } & Pick<
      Tournament,
      | 'id'
      | 'title'
      | 'description'
      | 'maxParticipants'
      | 'status'
      | 'signupEndAt'
      | 'entryCost'
      | 'isMeAlreadyParticipant'
    > & {
        format?: Maybe<{ __typename?: 'TournamentFormat' } & Pick<TournamentFormat, 'id' | 'type'>>;
        participants?: Maybe<
          Array<
            Maybe<
              { __typename?: 'TournamentParticipant' } & Pick<
                TournamentParticipant,
                'id' | 'point'
              > & {
                  user?: Maybe<{ __typename?: 'User' } & UserBasicInfosFragment>;
                  team?: Maybe<{ __typename?: 'Team' } & TeamBasicInfosFragment>;
                }
            >
          >
        >;
      }
  >;
};

export type GetRulesTournamentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetRulesTournamentQuery = { __typename?: 'Query' } & {
  Tournament?: Maybe<
    { __typename?: 'Tournament' } & Pick<Tournament, 'id' | 'title' | 'description'> & {
        rules?: Maybe<
          Array<Maybe<{ __typename?: 'TournamentRule' } & TournamentRuleInfosFragment>>
        >;
      }
  >;
};

export type GetTournamentDiscordQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetTournamentDiscordQuery = { __typename?: 'Query' } & {
  Tournament?: Maybe<{ __typename?: 'Tournament' } & Pick<Tournament, 'id' | 'discordChannelId'>>;
};

export type GetTournamentInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetTournamentInfoQuery = { __typename?: 'Query' } & {
  tournamentInformation?: Maybe<
    { __typename?: 'TournamentInformationPayload' } & Pick<
      TournamentInformationPayload,
      'totalTournamentCashPrize' | 'totalMonthTournament' | 'totalTournamentParticipant'
    > & {
        topGame?: Maybe<
          { __typename?: 'TopGamePayload' } & Pick<TopGamePayload, 'percent'> & {
              game?: Maybe<
                { __typename?: 'Game' } & Pick<Game, 'id' | 'name' | 'logo' | 'twitchGameId'>
              >;
            }
        >;
      }
  >;
};

export type SubscribeToTournamentAddedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type SubscribeToTournamentAddedSubscription = { __typename?: 'Subscription' } & {
  tournamentAdded: { __typename?: 'Tournament' } & TournamentInfosFragment;
};

export type UserSubscribedInTournamentSubscriptionVariables = Exact<{
  tournamentId: Scalars['ID'];
}>;

export type UserSubscribedInTournamentSubscription = { __typename?: 'Subscription' } & {
  userSubscribedInTournament: { __typename?: 'UserSubscriptionToTournamentReturn' } & Pick<
    UserSubscriptionToTournamentReturn,
    'tournamentId'
  > & { user: { __typename?: 'User' } & UserInfosFragment };
};

export type SubscribeToTournamentStartedSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SubscribeToTournamentStartedSubscription = { __typename?: 'Subscription' } & {
  tournamentStarted: { __typename?: 'Tournament' } & TournamentInfosFragment;
};

export type SubscribeToTournamentFinishedSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SubscribeToTournamentFinishedSubscription = { __typename?: 'Subscription' } & {
  tournamentFinished: { __typename?: 'Tournament' } & Pick<Tournament, 'id'>;
};

export type TournamentAwardInfoFragment = { __typename?: 'TournamentAward' } & Pick<
  TournamentAward,
  | 'id'
  | 'title'
  | 'value'
  | 'startRank'
  | 'endRank'
  | 'description'
  | 'sponsorName'
  | 'sponsorImage'
  | 'type'
  | 'createdAt'
  | 'updatedAt'
  | 'removed'
>;

export type TournamentBracketInfosFragment = { __typename?: 'TournamentBracket' } & Pick<
  TournamentBracket,
  | 'id'
  | 'round'
  | 'pointParticipant1'
  | 'pointParticipant2'
  | 'isLive'
  | 'isFinished'
  | 'startedAt'
  | 'createdAt'
  | 'updatedAt'
  | 'endedAt'
>;

export type TournamentBracketCompleteInfosFragment = { __typename?: 'TournamentBracket' } & Pick<
  TournamentBracket,
  | 'id'
  | 'round'
  | 'pointParticipant1'
  | 'pointParticipant2'
  | 'isLive'
  | 'isFinished'
  | 'startedAt'
  | 'createdAt'
  | 'updatedAt'
  | 'endedAt'
  | 'tournament'
  | 'chatEngineId'
  | 'chatEngineAccessKey'
> & {
    participant1?: Maybe<
      { __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'> & {
          user?: Maybe<{ __typename?: 'User' } & UserBasicInfosFragment>;
          team?: Maybe<{ __typename?: 'Team' } & TeamBasicInfosFragment>;
        }
    >;
    participant2?: Maybe<
      { __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'> & {
          user?: Maybe<{ __typename?: 'User' } & UserBasicInfosFragment>;
          team?: Maybe<{ __typename?: 'Team' } & TeamBasicInfosFragment>;
        }
    >;
    winner?: Maybe<
      { __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'> & {
          user?: Maybe<{ __typename?: 'User' } & UserBasicInfosFragment>;
          team?: Maybe<{ __typename?: 'Team' } & TeamBasicInfosFragment>;
        }
    >;
  };

export type StartTournamentBracketMutationVariables = Exact<{
  id: Scalars['ID'];
  startedAt?: Maybe<Scalars['DateTime']>;
}>;

export type StartTournamentBracketMutation = { __typename?: 'Mutation' } & {
  updateTournamentBracket?: Maybe<
    { __typename?: 'TournamentBracket' } & Pick<TournamentBracket, 'id'>
  >;
};

export type SetTournamentBracketWinnerMutationVariables = Exact<{
  id: Scalars['ID'];
  winnerId: Scalars['ID'];
}>;

export type SetTournamentBracketWinnerMutation = { __typename?: 'Mutation' } & {
  updateTournamentBracket?: Maybe<
    { __typename?: 'TournamentBracket' } & Pick<TournamentBracket, 'id'>
  >;
};

export type MarkTournamentBracketAsFinishedMutationVariables = Exact<{
  id: Scalars['ID'];
  isFinished?: Maybe<Scalars['Boolean']>;
}>;

export type MarkTournamentBracketAsFinishedMutation = { __typename?: 'Mutation' } & {
  updateTournamentBracket?: Maybe<
    { __typename?: 'TournamentBracket' } & Pick<TournamentBracket, 'id'>
  >;
};

export type UpdateMatchBracketMutationVariables = Exact<{
  id: Scalars['ID'];
  startedAt?: Maybe<Scalars['DateTime']>;
  winnerId?: Maybe<Scalars['ID']>;
  pointParticipant1?: Maybe<Scalars['Int']>;
  pointParticipant2?: Maybe<Scalars['Int']>;
  isLive?: Maybe<Scalars['Boolean']>;
  isFinished?: Maybe<Scalars['Boolean']>;
  endedAt?: Maybe<Scalars['DateTime']>;
}>;

export type UpdateMatchBracketMutation = { __typename?: 'Mutation' } & {
  updateTournamentBracket?: Maybe<
    { __typename?: 'TournamentBracket' } & Pick<
      TournamentBracket,
      | 'id'
      | 'isLive'
      | 'isFinished'
      | 'pointParticipant1'
      | 'pointParticipant2'
      | 'startedAt'
      | 'endedAt'
    > & {
        winner?: Maybe<
          { __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'>
        >;
      }
  >;
};

export type GetMatchDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMatchDetailsQuery = { __typename?: 'Query' } & {
  TournamentBracket?: Maybe<
    { __typename?: 'TournamentBracket' } & TournamentBracketCompleteInfosFragment
  >;
};

export type TournamentFormatInfosFragment = { __typename?: 'TournamentFormat' } & Pick<
  TournamentFormat,
  'id' | 'title' | 'type' | 'description' | 'removed'
>;

export type GetTournamentFormatsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTournamentFormatsQuery = { __typename?: 'Query' } & {
  tournamentFormats: Array<{ __typename?: 'TournamentFormat' } & TournamentFormatInfosFragment>;
};

export type TournamentGainInfosFragment = { __typename?: 'TournamentGain' } & Pick<
  TournamentGain,
  'id' | 'label' | 'price' | 'unity' | 'description' | 'removed'
>;

export type AddTournamentGainMutationVariables = Exact<{
  input: AddGainInput;
}>;

export type AddTournamentGainMutation = { __typename?: 'Mutation' } & {
  addTournamentGain?: Maybe<{ __typename?: 'Tournament' } & Pick<Tournament, 'id'>>;
};

export type EditTournamentGainMutationVariables = Exact<{
  input: EditGainInput;
}>;

export type EditTournamentGainMutation = { __typename?: 'Mutation' } & {
  editTournamentGain?: Maybe<{ __typename?: 'TournamentGain' } & TournamentGainInfosFragment>;
};

export type DeleteTournamentGainMutationVariables = Exact<{
  input: DeleteGainInput;
}>;

export type DeleteTournamentGainMutation = { __typename?: 'Mutation' } & {
  deleteTournamentGain?: Maybe<{ __typename?: 'TournamentGain' } & TournamentGainInfosFragment>;
};

export type MatchInfosFragment = { __typename?: 'Match' } & Pick<
  Match,
  'id' | 'tournamentId' | 'isLive' | 'roundNumber' | 'matchNumber' | 'dateBegin' | 'isFinished'
> & {
    parents?: Maybe<{ __typename?: 'MatchParent' } & Pick<MatchParent, 'parentAId' | 'parentBId'>>;
    opponents?: Maybe<
      | ({ __typename?: 'MatchUserOpponent' } & Pick<MatchUserOpponent, 'isTeam'> & {
            userA?: Maybe<{ __typename?: 'User' } & UserInfosFragment>;
            userB?: Maybe<{ __typename?: 'User' } & UserInfosFragment>;
          })
      | ({ __typename?: 'MatchTeamOpponent' } & Pick<MatchTeamOpponent, 'isTeam'> & {
            teamA?: Maybe<{ __typename?: 'Team' } & TeamInfosFragment>;
            teamB?: Maybe<{ __typename?: 'Team' } & TeamInfosFragment>;
          })
    >;
    result?: Maybe<
      { __typename?: 'MatchResult' } & Pick<MatchResult, 'pointA' | 'pointB' | 'winner'>
    >;
    files: Array<Maybe<{ __typename?: 'MatchFile' } & MatchFileInfosFragment>>;
  };

export type SetMatchPointMutationVariables = Exact<{
  input: SetMatchPointInput;
}>;

export type SetMatchPointMutation = { __typename?: 'Mutation' } & {
  setMatchPoint?: Maybe<{ __typename?: 'Match' } & MatchInfosFragment>;
};

export type FinishMatchMutationVariables = Exact<{
  input: FinishMatchInput;
}>;

export type FinishMatchMutation = { __typename?: 'Mutation' } & {
  finishMatch?: Maybe<
    { __typename?: 'FinishMatchReturn' } & {
      match: { __typename?: 'Match' } & MatchInfosFragment;
      nextMatch?: Maybe<{ __typename?: 'Match' } & MatchInfosFragment>;
      thirdOperatedMatch?: Maybe<{ __typename?: 'Match' } & MatchInfosFragment>;
    }
  >;
};

export type SubscribeToMatchPointSetSubscriptionVariables = Exact<{
  tournamentId: Scalars['ID'];
}>;

export type SubscribeToMatchPointSetSubscription = { __typename?: 'Subscription' } & {
  matchPointSet: { __typename?: 'Match' } & MatchInfosFragment;
};

export type SubscribeToMatchFinishedSubscriptionVariables = Exact<{
  tournamentId: Scalars['ID'];
}>;

export type SubscribeToMatchFinishedSubscription = { __typename?: 'Subscription' } & {
  matchFinished: { __typename?: 'FinishMatchReturn' } & {
    match: { __typename?: 'Match' } & MatchInfosFragment;
    nextMatch?: Maybe<{ __typename?: 'Match' } & MatchInfosFragment>;
    thirdOperatedMatch?: Maybe<{ __typename?: 'Match' } & MatchInfosFragment>;
  };
};

export type MessageTextInfosFragment = { __typename?: 'MessageText' } & Pick<MessageText, 'text'>;

export type MessageFileInfosFragment = { __typename?: 'MessageFile' } & Pick<
  MessageFile,
  'id' | 'url' | 'size' | 'name'
>;

export type TournamentMessageInfosFragment = { __typename?: 'TournamentMessage' } & Pick<
  TournamentMessage,
  'id' | 'tournamentId' | 'type' | 'isMine' | 'isRemoved' | 'createdAt' | 'updatedAt'
> & {
    parent?: Maybe<
      { __typename?: 'TournamentMessage' } & Pick<
        TournamentMessage,
        'id' | 'tournamentId' | 'type' | 'isMine' | 'isRemoved' | 'createdAt' | 'updatedAt'
      > & {
          author: { __typename?: 'User' } & UserInfosFragment;
          content:
            | ({ __typename?: 'MessageFile' } & MessageFileInfosFragment)
            | ({ __typename?: 'MessageText' } & MessageTextInfosFragment);
        }
    >;
    author: { __typename?: 'User' } & UserInfosFragment;
    content:
      | ({ __typename?: 'MessageFile' } & MessageFileInfosFragment)
      | ({ __typename?: 'MessageText' } & MessageTextInfosFragment);
  };

export type AddTournamentMessageTextMutationVariables = Exact<{
  input: AddTournamentMessageInput;
}>;

export type AddTournamentMessageTextMutation = { __typename?: 'Mutation' } & {
  addTournamentMessage?: Maybe<
    { __typename?: 'TournamentMessage' } & TournamentMessageInfosFragment
  >;
};

export type RemoveTournamentMessageMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RemoveTournamentMessageMutation = { __typename?: 'Mutation' } & {
  removeTournamentMessage?: Maybe<
    { __typename?: 'TournamentMessage' } & Pick<
      TournamentMessage,
      'id' | 'tournamentId' | 'isRemoved'
    >
  >;
};

export type GetTournamentMessagesQueryVariables = Exact<{
  tournamentId: Scalars['ID'];
  filter: FilterTournamentMessageInput;
}>;

export type GetTournamentMessagesQuery = { __typename?: 'Query' } & {
  Tournament?: Maybe<{ __typename?: 'Tournament' } & Pick<Tournament, 'id'>>;
};

export type SubscribeToTournamentMessageAddedSubscriptionVariables = Exact<{
  tournamentId: Scalars['ID'];
}>;

export type SubscribeToTournamentMessageAddedSubscription = { __typename?: 'Subscription' } & {
  tournamentMessageAdded: { __typename?: 'TournamentMessage' } & TournamentMessageInfosFragment;
};

export type SubscribeToTournamentMessageRemovedSubscriptionVariables = Exact<{
  tournamentId: Scalars['ID'];
}>;

export type SubscribeToTournamentMessageRemovedSubscription = { __typename?: 'Subscription' } & {
  tournamentMessageRemoved: { __typename?: 'TournamentMessage' } & Pick<
    TournamentMessage,
    'id' | 'isRemoved' | 'tournamentId'
  >;
};

export type TournamentRuleInfosFragment = { __typename?: 'TournamentRule' } & Pick<
  TournamentRule,
  'id' | 'name' | 'description' | 'removed' | 'name_en' | 'description_en'
>;

export type TrainingsQueryVariables = Exact<{ [key: string]: never }>;

export type TrainingsQuery = { __typename?: 'Query' } & {
  trainings: Array<
    { __typename?: 'Training' } & Pick<
      Training,
      'id' | 'trainingGameName' | 'logo' | 'jacketImage'
    > & {
        trainingPrograms: Array<
          { __typename?: 'TrainingProgram' } & Pick<
            TrainingProgram,
            | 'id'
            | 'programName'
            | 'programLevel'
            | 'programEstimateDuration'
            | 'progression'
            | 'programDescription'
          >
        >;
      }
  >;
};

export type GetTrainingDetailQueryVariables = Exact<{
  trainingProgramId: Scalars['ID'];
}>;

export type GetTrainingDetailQuery = { __typename?: 'Query' } & {
  trainingDetail?: Maybe<
    { __typename?: 'TrainingDetail' } & Pick<TrainingDetail, 'id' | 'parent' | 'advice'> & {
        trainingPrograms?: Maybe<
          Array<
            Maybe<
              { __typename?: 'TrainingProgram' } & Pick<
                TrainingProgram,
                | 'id'
                | 'programName'
                | 'programLevel'
                | 'programEstimateDuration'
                | 'progression'
                | 'programDescription'
              >
            >
          >
        >;
        about?: Maybe<
          { __typename?: 'TrainingDetailAbout' } & Pick<
            TrainingDetailAbout,
            'basicIntro' | 'perks' | 'skills'
          >
        >;
        system?: Maybe<
          { __typename?: 'TrainingDetailSystem' } & Pick<
            TrainingDetailSystem,
            'description' | 'steps' | 'assignment'
          >
        >;
      }
  >;
};

export type TransactionInfosFragment = { __typename?: 'Transaction' } & Pick<
  Transaction,
  'id' | 'description' | 'payment' | 'etat' | 'total' | 'endDate' | 'startDate' | 'userId'
>;

export type AllUserTransactionsQueryVariables = Exact<{ [key: string]: never }>;

export type AllUserTransactionsQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'User' } & Pick<User, 'id'> & {
      transactions?: Maybe<Array<Maybe<{ __typename?: 'Transaction' } & TransactionInfosFragment>>>;
    };
};

export type TwitchLiveInfosFragment = { __typename?: 'TwitchLive' } & Pick<
  TwitchLive,
  | 'id'
  | 'user_login'
  | 'user_name'
  | 'game_id'
  | 'game_name'
  | 'type'
  | 'title'
  | 'viewer_count'
  | 'started_at'
  | 'language'
  | 'thumbnail_url'
  | 'tag_ids'
  | 'is_mature'
>;

export type AuthorizeTwichUserMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type AuthorizeTwichUserMutation = { __typename?: 'Mutation' } & {
  authorizeTwichUser?: Maybe<
    { __typename?: 'User' } & Pick<User, 'id' | 'twitchLogin' | 'twitchUserId'>
  >;
};

export type ActiveTwitchLiveQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  gameId?: Maybe<Scalars['ID']>;
}>;

export type ActiveTwitchLiveQuery = { __typename?: 'Query' } & {
  activeTwitchLive?: Maybe<Array<Maybe<{ __typename?: 'TwitchLive' } & TwitchLiveInfosFragment>>>;
};

export type UserBasicInfosFragment = { __typename?: 'User' } & Pick<
  User,
  | 'id'
  | 'isMe'
  | 'firstname'
  | 'lastname'
  | 'email'
  | 'avatar'
  | 'username'
  | 'isAdmin'
  | 'phoneNumber'
  | 'displayName'
  | 'totalWallet'
  | 'isOnline'
  | 'point'
  | 'isSignupFinish'
  | 'twitchUserId'
  | 'googleUserId'
  | 'facebookUserId'
  | 'twitterUserId'
  | 'discordUserId'
  | 'microsoftUserId'
  | 'description'
  | 'status'
> & {
    myMainGamePlateform?: Maybe<
      { __typename?: 'GamePlatform' } & Pick<GamePlatform, 'id' | 'name'>
    >;
  };

export type UserInfosFragment = { __typename?: 'User' } & Pick<
  User,
  | 'id'
  | 'isMe'
  | 'firstname'
  | 'lastname'
  | 'birthDate'
  | 'email'
  | 'avatar'
  | 'coverPhoto'
  | 'username'
  | 'isBanned'
  | 'language'
  | 'country'
  | 'devise'
  | 'oauth2'
  | 'createdAt'
  | 'updatedAt'
  | 'isAdmin'
  | 'phoneNumber'
  | 'uid'
  | 'displayName'
  | 'receiveCommunication'
  | 'isUsedTrial'
  | 'isPremium'
  | 'endPremium'
  | 'getStreamToken'
  | 'isMyfriend'
  | 'twitchLogin'
  | 'twitchUserId'
  | 'googleUserId'
  | 'facebookUserId'
  | 'twitterUserId'
  | 'discordUserId'
  | 'microsoftUserId'
  | 'totalWallet'
  | 'chatEngineId'
  | 'chatEngineSecret'
  | 'isOnline'
  | 'isSignupFinish'
  | 'description'
  | 'status'
> & {
    myMainGamePlateform?: Maybe<
      { __typename?: 'GamePlatform' } & Pick<GamePlatform, 'id' | 'name'>
    >;
  };

export type EditProfilMutationMutationVariables = Exact<{
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  avatar?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  isSignupFinish?: Maybe<Scalars['Boolean']>;
}>;

export type EditProfilMutationMutation = { __typename?: 'Mutation' } & {
  updateUser?: Maybe<{ __typename?: 'User' } & UserInfosFragment>;
};

export type CheckUserUidExistsMutationVariables = Exact<{
  uid: Scalars['String'];
}>;

export type CheckUserUidExistsMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'checkUserUidExists'
>;

export type ForgotPasswordMutationVariables = Exact<{
  input: ForgotPasswordInput;
}>;

export type ForgotPasswordMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'forgotPassword'>;

export type CheckExpiredTokenMutationVariables = Exact<{
  input: CheckExpiredTokenInput;
}>;

export type CheckExpiredTokenMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'checkExpiredToken'
>;

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;

export type ChangePasswordMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'changePassword'>;

export type CheckSocialAuthMutationVariables = Exact<{
  socialApp?: Maybe<Social_App>;
  uid: Scalars['String'];
}>;

export type CheckSocialAuthMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'checkSocialAuth'
>;

export type AddUserToMyfriendsMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type AddUserToMyfriendsMutation = { __typename?: 'Mutation' } & {
  addUserToMyfriends?: Maybe<
    { __typename?: 'AddUserToMyfriendsPayload' } & {
      user?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'isMyfriend'>>;
      chat?: Maybe<
        { __typename?: 'DirectChat' } & Pick<DirectChat, 'chatId' | 'accessKey' | 'title'>
      >;
    }
  >;
};

export type UpdateUserMainGamePlateformMutationVariables = Exact<{
  gamePlateformId: Scalars['ID'];
}>;

export type UpdateUserMainGamePlateformMutation = { __typename?: 'Mutation' } & {
  updateUserMainGamePlateform?: Maybe<
    { __typename?: 'User' } & Pick<User, 'id'> & {
        myMainGamePlateform?: Maybe<
          { __typename?: 'GamePlatform' } & Pick<GamePlatform, 'id' | 'name'>
        >;
      }
  >;
};

export type CreateGamepartymemberMutationVariables = Exact<{
  partyId: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
}>;

export type CreateGamepartymemberMutation = { __typename?: 'Mutation' } & {
  createGamepartymember?: Maybe<{ __typename?: 'Gamepartymember' } & Pick<Gamepartymember, 'id'>>;
};

export type CheckIfEmailIsAlreadyTakenMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type CheckIfEmailIsAlreadyTakenMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'checkIfEmailIsAlreadyTaken'
>;

export type CheckIfUsernameIsAlreadyTakenMutationVariables = Exact<{
  username: Scalars['String'];
}>;

export type CheckIfUsernameIsAlreadyTakenMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'checkIfUsernameIsAlreadyTaken'
>;

export type SendWelcomeEmailMutationVariables = Exact<{
  email: Scalars['String'];
  username: Scalars['String'];
}>;

export type SendWelcomeEmailMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'sendWelcomeEmail'
>;

export type EnableoAuth2MutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type EnableoAuth2Mutation = { __typename?: 'Mutation' } & {
  enableoAuth2: { __typename?: 'User' } & UserInfosFragment;
};

export type DisableoAuth2MutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DisableoAuth2Mutation = { __typename?: 'Mutation' } & {
  disableoAuth2: { __typename?: 'User' } & UserInfosFragment;
};

export type CheckIfEmailAndPasswordMatchedMutationVariables = Exact<{
  input: LoginInput;
}>;

export type CheckIfEmailAndPasswordMatchedMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'checkIfEmailAndPasswordMatched'
>;

export type LoginOauth2MutationVariables = Exact<{
  input: LoginOauth2Input;
}>;

export type LoginOauth2Mutation = { __typename?: 'Mutation' } & Pick<Mutation, 'loginOauth2'>;

export type CheckTokenOauth2MutationVariables = Exact<{
  input: CheckExpiredTokenInput;
}>;

export type CheckTokenOauth2Mutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'checkTokenOauth2'
>;

export type CreateAuthTokenMutationVariables = Exact<{
  input: LoginInput;
}>;

export type CreateAuthTokenMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'createAuthToken'
>;

export type CheckIfUserMatchInSocialAppMutationVariables = Exact<{
  socialApp: Social_App;
  email: Scalars['String'];
  providerUid: Scalars['String'];
}>;

export type CheckIfUserMatchInSocialAppMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'checkIfUserMatchInSocialApp'
>;

export type LoginSocialWithoutUpdatingUserInfoMutationVariables = Exact<{
  input: LoginSocialInput;
}>;

export type LoginSocialWithoutUpdatingUserInfoMutation = { __typename?: 'Mutation' } & {
  loginSocialWithoutUpdatingUserInfo: { __typename?: 'LoginSocial' } & LoginSocialInfosFragment;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'User' } & Pick<User, 'chatEnginePublicSecret'> & UserInfosFragment;
};

export type UserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UserQuery = { __typename?: 'Query' } & {
  User?: Maybe<{ __typename?: 'User' } & Pick<User, 'chatEnginePublicSecret'> & UserInfosFragment>;
};

export type MeBasicQueryVariables = Exact<{ [key: string]: never }>;

export type MeBasicQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'User' } & UserBasicInfosFragment;
};

export type MyGamePartyInvitationQueryVariables = Exact<{ [key: string]: never }>;

export type MyGamePartyInvitationQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'User' } & Pick<User, 'id'> & {
      gamePartyInvitation?: Maybe<
        { __typename?: 'GamePartyInvitation' } & {
          gameParty?: Maybe<
            { __typename?: 'Gameparty' } & Pick<Gameparty, 'id'> & {
                chat?: Maybe<
                  { __typename?: 'Chat' } & Pick<Chat, 'id'> & {
                      owner?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'username'>>;
                    }
                >;
              }
          >;
        }
      >;
    };
};

export type FindUserTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type FindUserTokenQuery = { __typename?: 'Query' } & {
  findUserToken?: Maybe<{ __typename?: 'User' } & UserInfosFragment>;
};

export type ListUsersInfosQueryVariables = Exact<{
  filter: UsersFilterInput;
}>;

export type ListUsersInfosQuery = { __typename?: 'Query' } & {
  users: { __typename?: 'UserResult' } & Pick<UserResult, 'total'> & {
      results: Array<{ __typename?: 'User' } & UserInfosFragment>;
    };
};

export type ListUsersNotInTournamentQueryVariables = Exact<{
  filter: UsersTournamentFilterInput;
}>;

export type ListUsersNotInTournamentQuery = { __typename?: 'Query' } & {
  usersTournament: { __typename?: 'UserResult' } & Pick<UserResult, 'total'> & {
      results: Array<{ __typename?: 'User' } & UserInfosFragment>;
    };
};

export type UserFriendSuggestionsQueryVariables = Exact<{ [key: string]: never }>;

export type UserFriendSuggestionsQuery = { __typename?: 'Query' } & {
  userFriendsSuggestion?: Maybe<Array<Maybe<{ __typename?: 'User' } & UserInfosFragment>>>;
};

export type OnlineFriendsQueryVariables = Exact<{ [key: string]: never }>;

export type OnlineFriendsQuery = { __typename?: 'Query' } & {
  onlineFriends?: Maybe<Array<Maybe<{ __typename?: 'User' } & UserInfosFragment>>>;
};

export type GetUsersByRandQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
}>;

export type GetUsersByRandQuery = { __typename?: 'Query' } & {
  getUsersByRand?: Maybe<Array<Maybe<{ __typename?: 'User' } & UserInfosFragment>>>;
};

export type GetUserTeamsQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type GetUserTeamsQuery = { __typename?: 'Query' } & {
  User?: Maybe<
    { __typename?: 'User' } & Pick<User, 'id'> & {
        joinedTeams?: Maybe<
          Array<
            Maybe<
              { __typename?: 'TeamMember' } & Pick<TeamMember, 'id' | 'role'> & {
                  team?: Maybe<
                    { __typename?: 'Team' } & {
                      owner?: Maybe<
                        { __typename?: 'TeamMember' } & Pick<TeamMember, 'id'> & {
                            user?: Maybe<{ __typename?: 'User' } & UserBasicInfosFragment>;
                          }
                      >;
                    } & TeamBasicInfosFragment
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type AllUserTournamentsQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type AllUserTournamentsQuery = { __typename?: 'Query' } & {
  User?: Maybe<
    { __typename?: 'User' } & Pick<User, 'id'> & {
        joinedParticipationTournaments?: Maybe<
          Array<
            Maybe<
              { __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'> & {
                  tournament?: Maybe<
                    { __typename?: 'Tournament' } & Pick<
                      Tournament,
                      | 'id'
                      | 'title'
                      | 'isActive'
                      | 'startedAt'
                      | 'createdAt'
                      | 'entryCost'
                      | 'rewardsTotal'
                      | 'totalAwardValue'
                      | 'description'
                      | 'maxParticipants'
                      | 'isMeAlreadyParticipant'
                    > & {
                        format?: Maybe<
                          { __typename?: 'TournamentFormat' } & Pick<TournamentFormat, 'title'>
                        >;
                        awards?: Maybe<
                          Array<
                            Maybe<
                              { __typename?: 'TournamentAward' } & Pick<TournamentAward, 'value'>
                            >
                          >
                        >;
                        gamePlateform?: Maybe<
                          { __typename?: 'GamePlatform' } & Pick<
                            GamePlatform,
                            'id' | 'name' | 'logo'
                          >
                        >;
                        myRanking?: Maybe<
                          { __typename?: 'Tournamentranking' } & Pick<
                            Tournamentranking,
                            'id' | 'rank'
                          >
                        >;
                        gamemode?: Maybe<
                          { __typename?: 'Gamemode' } & Pick<
                            Gamemode,
                            'id' | 'title' | 'teamSize' | 'type'
                          >
                        >;
                        participants?: Maybe<
                          Array<
                            Maybe<
                              { __typename?: 'TournamentParticipant' } & Pick<
                                TournamentParticipant,
                                'id'
                              >
                            >
                          >
                        >;
                        game?: Maybe<
                          { __typename?: 'Game' } & Pick<Game, 'id' | 'name' | 'mainJacket'>
                        >;
                      }
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type AllUserWagersQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type AllUserWagersQuery = { __typename?: 'Query' } & {
  User?: Maybe<
    { __typename?: 'User' } & Pick<User, 'id'> & {
        joinedWagers?: Maybe<
          Array<
            Maybe<
              { __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'> & {
                  wager?: Maybe<
                    { __typename?: 'Wager' } & Pick<
                      Wager,
                      | 'id'
                      | 'isMeAlreadyParticipant'
                      | 'cashPrice'
                      | 'format'
                      | 'isActive'
                      | 'participantNumber'
                      | 'startedAt'
                      | 'updatedAt'
                      | 'title'
                      | 'createdAt'
                      | 'rule'
                      | 'rule_en'
                      | 'entryCost'
                    > & {
                        game?: Maybe<{ __typename?: 'Game' } & Pick<Game, 'id' | 'name'>>;
                        plateform?: Maybe<
                          { __typename?: 'GamePlatform' } & Pick<
                            GamePlatform,
                            'id' | 'name' | 'logo'
                          >
                        >;
                        createdBy?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'username'>>;
                      }
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type MyUserSocialQueryVariables = Exact<{ [key: string]: never }>;

export type MyUserSocialQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'User' } & Pick<User, 'id'> & {
      socialAccounts?: Maybe<
        Array<Maybe<{ __typename?: 'Usersocialaccount' } & UserSocialAccountInfosFragment>>
      >;
    };
};

export type UserOnlineSubscriptionVariables = Exact<{ [key: string]: never }>;

export type UserOnlineSubscription = { __typename?: 'Subscription' } & {
  userOnline: { __typename?: 'User' } & Pick<User, 'chatEnginePublicSecret'> & UserInfosFragment;
};

export type UserOfflineSubscriptionVariables = Exact<{ [key: string]: never }>;

export type UserOfflineSubscription = { __typename?: 'Subscription' } & {
  userOffline: { __typename?: 'User' } & Pick<User, 'chatEnginePublicSecret'> & UserInfosFragment;
};

export type GamePartyInvitationSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GamePartyInvitationSubscription = { __typename?: 'Subscription' } & {
  newInvitationGameParty: { __typename?: 'User' } & Pick<User, 'id'> & {
      gameParty?: Maybe<
        { __typename?: 'Gameparty' } & Pick<Gameparty, 'id' | 'removed' | 'name'> & {
            members?: Maybe<
              Array<Maybe<{ __typename?: 'Gamepartymember' } & Pick<Gamepartymember, 'id'>>>
            >;
            chat?: Maybe<{ __typename?: 'Chat' } & ChatGroupInfoFragment>;
          }
      >;
      gamePartyInvitation?: Maybe<
        { __typename?: 'GamePartyInvitation' } & {
          user?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>;
          gameParty?: Maybe<{ __typename?: 'Gameparty' } & Pick<Gameparty, 'id'>>;
        }
      >;
    };
};

export type VideoInfosFragment = { __typename?: 'Video' } & Pick<
  Video,
  | 'id'
  | 'path'
  | 'title'
  | 'description'
  | 'imgPreview'
  | 'categoryId'
  | 'createdAt'
  | 'streamStatus'
  | 'canEdit'
> & {
    author: { __typename?: 'User' } & UserInfosFragment;
    category: { __typename?: 'VideoCategory' } & Pick<VideoCategory, 'id' | 'name'>;
  };

export type AddVideoMutationVariables = Exact<{
  input: AddVideoInput;
}>;

export type AddVideoMutation = { __typename?: 'Mutation' } & {
  addVideo?: Maybe<{ __typename?: 'Video' } & VideoInfosFragment>;
};

export type EditVideoMutationVariables = Exact<{
  input: EditVideoInput;
}>;

export type EditVideoMutation = { __typename?: 'Mutation' } & {
  editVideo?: Maybe<
    { __typename?: 'Video' } & Pick<
      Video,
      'id' | 'title' | 'description' | 'imgPreview' | 'categoryId'
    >
  >;
};

export type DeleteVideoMutationVariables = Exact<{
  input: DeleteVideoInput;
}>;

export type DeleteVideoMutation = { __typename?: 'Mutation' } & {
  deleteVideo?: Maybe<{ __typename?: 'Video' } & Pick<Video, 'id' | 'removed'>>;
};

export type CreateVideoThumbnailMutationVariables = Exact<{
  input: CreateVideoThumbnailInput;
}>;

export type CreateVideoThumbnailMutation = { __typename?: 'Mutation' } & {
  createVideoThumbnail?: Maybe<{ __typename?: 'Video' } & Pick<Video, 'id' | 'imgPreview'>>;
};

export type GetVideosQueryVariables = Exact<{
  filter: VideosFilterInput;
}>;

export type GetVideosQuery = { __typename?: 'Query' } & {
  videos: { __typename?: 'VideosResult' } & Pick<VideosResult, 'total'> & {
      results: Array<{ __typename?: 'Video' } & VideoInfosFragment>;
    };
};

export type GetVideoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetVideoQuery = { __typename?: 'Query' } & {
  video?: Maybe<
    { __typename?: 'Video' } & {
      author: { __typename?: 'User' } & UserInfosFragment;
    } & VideoInfosFragment
  >;
};

export type SubscribeToVideoStreamBeginSubscriptionVariables = Exact<{ [key: string]: never }>;

export type SubscribeToVideoStreamBeginSubscription = { __typename?: 'Subscription' } & {
  videoStreamBegin: { __typename?: 'Video' } & VideoInfosFragment;
};

export type SubscribeToVideoStreamFinishedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type SubscribeToVideoStreamFinishedSubscription = { __typename?: 'Subscription' } & {
  videoStreamFinished: { __typename?: 'Video' } & VideoInfosFragment;
};

export type VideoCategoryInfosFragment = { __typename?: 'VideoCategory' } & Pick<
  VideoCategory,
  'id' | 'logo' | 'name'
>;

export type GetVideoCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetVideoCategoriesQuery = { __typename?: 'Query' } & {
  videoCategories: Array<{ __typename?: 'VideoCategory' } & VideoCategoryInfosFragment>;
};

export type WagerInfosFragment = { __typename?: 'Wager' } & Pick<
  Wager,
  | 'id'
  | 'isMeAlreadyParticipant'
  | 'cashPrice'
  | 'format'
  | 'isActive'
  | 'participantNumber'
  | 'startedAt'
  | 'updatedAt'
  | 'title'
  | 'createdAt'
  | 'rule'
  | 'entryCost'
>;

export type WagerCompleteInfosFragment = { __typename?: 'Wager' } & Pick<
  Wager,
  | 'id'
  | 'isMeAlreadyParticipant'
  | 'cashPrice'
  | 'format'
  | 'isActive'
  | 'participantNumber'
  | 'startedAt'
  | 'updatedAt'
  | 'title'
  | 'createdAt'
  | 'rule'
  | 'entryCost'
> & {
    participants?: Maybe<
      Array<
        Maybe<
          { __typename?: 'TournamentParticipant' } & Pick<
            TournamentParticipant,
            'id' | 'createdAt' | 'leagueTotalPoint'
          > & {
              user?: Maybe<{ __typename?: 'User' } & UserBasicInfosFragment>;
              team?: Maybe<{ __typename?: 'Team' } & TeamBasicInfosFragment>;
              brackets?: Maybe<
                Array<Maybe<{ __typename?: 'TournamentBracket' } & TournamentBracketInfosFragment>>
              >;
            }
        >
      >
    >;
    game?: Maybe<{ __typename?: 'Game' } & GameInfosFragment>;
    plateform?: Maybe<{ __typename?: 'GamePlatform' } & GamePlatformInfosFragment>;
    createdBy?: Maybe<{ __typename?: 'User' } & UserBasicInfosFragment>;
  };

export type CreateWagerMutationVariables = Exact<{
  gameId?: Maybe<Scalars['ID']>;
  gamePlateformId?: Maybe<Scalars['ID']>;
  format?: Maybe<Wager_Format>;
  title?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  cashPrice?: Maybe<Scalars['Float']>;
  rule?: Maybe<Scalars['String']>;
}>;

export type CreateWagerMutation = { __typename?: 'Mutation' } & {
  createWager?: Maybe<{ __typename?: 'Wager' } & WagerInfosFragment>;
};

export type CreateWagerFullMutationVariables = Exact<{
  gameId?: Maybe<Scalars['ID']>;
  gamePlateformId?: Maybe<Scalars['ID']>;
  format?: Maybe<Wager_Format>;
  title?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  cashPrice?: Maybe<Scalars['Float']>;
  rule?: Maybe<Scalars['String']>;
  entryCost?: Maybe<Scalars['Float']>;
  gameformatId?: Maybe<Scalars['ID']>;
  gameModeId?: Maybe<Scalars['ID']>;
  entryType?: Maybe<TournamentEntryType>;
  mapId?: Maybe<Scalars['ID']>;
  gameServerId?: Maybe<Scalars['ID']>;
  regionId?: Maybe<Scalars['ID']>;
  audiochatId?: Maybe<Scalars['ID']>;
  discordUrl?: Maybe<Scalars['String']>;
  title_en?: Maybe<Scalars['String']>;
  rule_en?: Maybe<Scalars['String']>;
}>;

export type CreateWagerFullMutation = { __typename?: 'Mutation' } & {
  createWager?: Maybe<{ __typename?: 'Wager' } & WagerInfosFragment>;
};

export type UpdateWagerMutationVariables = Exact<{
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  cashPrice?: Maybe<Scalars['Float']>;
  rule?: Maybe<Scalars['String']>;
}>;

export type UpdateWagerMutation = { __typename?: 'Mutation' } & {
  updateWager?: Maybe<{ __typename?: 'Wager' } & WagerInfosFragment>;
};

export type JoinTeamToWagerMutationVariables = Exact<{
  wagerId: Scalars['ID'];
  teamId: Scalars['ID'];
}>;

export type JoinTeamToWagerMutation = { __typename?: 'Mutation' } & {
  joinTeamToWager?: Maybe<
    { __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'> & {
        user?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>;
        team?: Maybe<{ __typename?: 'Team' } & Pick<Team, 'id'>>;
        wager?: Maybe<{ __typename?: 'Wager' } & Pick<Wager, 'id'>>;
      }
  >;
};

export type JoinMeToWagerMutationVariables = Exact<{
  wagerId: Scalars['ID'];
}>;

export type JoinMeToWagerMutation = { __typename?: 'Mutation' } & {
  joinMeToWager?: Maybe<
    { __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'> & {
        user?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>;
        team?: Maybe<{ __typename?: 'Team' } & Pick<Team, 'id'>>;
        wager?: Maybe<{ __typename?: 'Wager' } & Pick<Wager, 'id'>>;
      }
  >;
};

export type AllWagersQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<WagerFilter>;
}>;

export type AllWagersQuery = { __typename?: 'Query' } & {
  allWagers?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Wager' } & Pick<
          Wager,
          | 'id'
          | 'isMeAlreadyParticipant'
          | 'cashPrice'
          | 'format'
          | 'isActive'
          | 'participantNumber'
          | 'startedAt'
          | 'updatedAt'
          | 'title'
          | 'createdAt'
          | 'entryType'
          | 'rule'
          | 'rule_en'
          | 'entryCost'
        > & {
            game?: Maybe<
              { __typename?: 'Game' } & Pick<Game, 'id' | 'name' | 'logo' | 'twitchGameId'>
            >;
            participants?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'> & {
                      user?: Maybe<
                        { __typename?: 'User' } & Pick<User, 'id' | 'username' | 'avatar'>
                      >;
                      team?: Maybe<{ __typename?: 'Team' } & Pick<Team, 'id' | 'logo' | 'name'>>;
                    }
                >
              >
            >;
            plateform?: Maybe<
              { __typename?: 'GamePlatform' } & Pick<GamePlatform, 'id' | 'name' | 'logo'>
            >;
            gamemode?: Maybe<
              { __typename?: 'Gamemode' } & Pick<Gamemode, 'id' | 'title' | 'teamSize' | 'type'>
            >;
            createdBy?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'username'>>;
          }
      >
    >
  >;
};

export type ActiveWagersQueryVariables = Exact<{ [key: string]: never }>;

export type ActiveWagersQuery = { __typename?: 'Query' } & {
  activeWagers?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Wager' } & {
          plateform?: Maybe<
            { __typename?: 'GamePlatform' } & Pick<GamePlatform, 'id' | 'name' | 'logo'>
          >;
          game?: Maybe<{ __typename?: 'Game' } & Pick<Game, 'id' | 'name'>>;
        } & WagerInfosFragment
      >
    >
  >;
};

export type WagerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type WagerQuery = { __typename?: 'Query' } & {
  Wager?: Maybe<{ __typename?: 'Wager' } & WagerCompleteInfosFragment>;
};

export type GetGameWagerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetGameWagerQuery = { __typename?: 'Query' } & {
  Wager?: Maybe<
    { __typename?: 'Wager' } & Pick<
      Wager,
      | 'id'
      | 'title'
      | 'startedAt'
      | 'discordUrl'
      | 'participantNumber'
      | 'isMeAlreadyParticipant'
      | 'chatEngineId'
      | 'chatEngineAccessKey'
    > & {
        game?: Maybe<
          { __typename?: 'Game' } & Pick<Game, 'id' | 'mainJacket' | 'logo' | 'twitchGameId'>
        >;
        plateform?: Maybe<
          { __typename?: 'GamePlatform' } & Pick<GamePlatform, 'id' | 'name' | 'logo'>
        >;
        gamemode?: Maybe<
          { __typename?: 'Gamemode' } & Pick<Gamemode, 'id' | 'title' | 'teamSize' | 'type'>
        >;
        participants?: Maybe<
          Array<Maybe<{ __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'>>>
        >;
        gameserver?: Maybe<
          { __typename?: 'Gameserver' } & Pick<Gameserver, 'id' | 'name'> & {
              country?: Maybe<{ __typename?: 'Country' } & Pick<Country, 'id' | 'code'>>;
            }
        >;
      }
  >;
};

export type GetGeneralInfoWagerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetGeneralInfoWagerQuery = { __typename?: 'Query' } & {
  Wager?: Maybe<
    { __typename?: 'Wager' } & Pick<
      Wager,
      | 'id'
      | 'isMeAlreadyParticipant'
      | 'signupAt'
      | 'cashPrice'
      | 'format'
      | 'isActive'
      | 'participantNumber'
      | 'startedAt'
      | 'updatedAt'
      | 'title'
      | 'createdAt'
      | 'rule'
      | 'rule_en'
      | 'entryCost'
      | 'entryType'
    > & {
        game?: Maybe<{ __typename?: 'Game' } & Pick<Game, 'id' | 'name' | 'logo'>>;
        plateform?: Maybe<
          { __typename?: 'GamePlatform' } & Pick<GamePlatform, 'id' | 'name' | 'logo'>
        >;
        map?: Maybe<{ __typename?: 'Map' } & Pick<Map, 'id' | 'name'>>;
        gameformat?: Maybe<{ __typename?: 'Gameformat' } & Pick<Gameformat, 'id' | 'name'>>;
        gamemode?: Maybe<
          { __typename?: 'Gamemode' } & Pick<Gamemode, 'id' | 'title' | 'teamSize' | 'type'>
        >;
        region?: Maybe<{ __typename?: 'Region' } & Pick<Region, 'id' | 'name'>>;
        audiochat?: Maybe<
          { __typename?: 'Audiochatplateform' } & Pick<Audiochatplateform, 'id' | 'name'>
        >;
        gameserver?: Maybe<{ __typename?: 'Gameserver' } & Pick<Gameserver, 'id' | 'name'>>;
      }
  >;
};

export type GetWinnerWagerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetWinnerWagerQuery = { __typename?: 'Query' } & {
  Wager?: Maybe<
    { __typename?: 'Wager' } & Pick<Wager, 'id' | 'winnerId'> & {
        winner?: Maybe<
          { __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'> & {
              user?: Maybe<
                { __typename?: 'User' } & Pick<User, 'id' | 'firstname' | 'lastname' | 'email'>
              >;
              team?: Maybe<{ __typename?: 'Team' } & Pick<Team, 'id' | 'name'>>;
            }
        >;
      }
  >;
};

export type GetRuleWagerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetRuleWagerQuery = { __typename?: 'Query' } & {
  Wager?: Maybe<{ __typename?: 'Wager' } & Pick<Wager, 'id' | 'title' | 'rule' | 'rule_en'>>;
};

export type GetParticipantsWagerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetParticipantsWagerQuery = { __typename?: 'Query' } & {
  Wager?: Maybe<
    { __typename?: 'Wager' } & Pick<
      Wager,
      'id' | 'title' | 'isMeAlreadyParticipant' | 'participantNumber'
    > & {
        participants?: Maybe<
          Array<
            Maybe<
              { __typename?: 'TournamentParticipant' } & Pick<TournamentParticipant, 'id'> & {
                  user?: Maybe<{ __typename?: 'User' } & UserBasicInfosFragment>;
                  team?: Maybe<{ __typename?: 'Team' } & TeamBasicInfosFragment>;
                }
            >
          >
        >;
      }
  >;
};

export type GetWagerDiscordChannelQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetWagerDiscordChannelQuery = { __typename?: 'Query' } & {
  Wager?: Maybe<{ __typename?: 'Wager' } & Pick<Wager, 'id' | 'discordChannelId'>>;
};

export type GetWagerInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetWagerInfoQuery = { __typename?: 'Query' } & {
  wagerInformation?: Maybe<
    { __typename?: 'WagerInformationPayload' } & Pick<
      WagerInformationPayload,
      'totalWagerCashPrize' | 'totalMonthWager' | 'totalWagerParticipant'
    > & {
        topGame?: Maybe<
          { __typename?: 'WagerTopGamePayload' } & Pick<WagerTopGamePayload, 'percent'> & {
              game?: Maybe<
                { __typename?: 'Game' } & Pick<Game, 'id' | 'name' | 'logo' | 'twitchGameId'>
              >;
            }
        >;
      }
  >;
};

export type WallettransactionInfoFragment = { __typename?: 'Wallettransaction' } & Pick<
  Wallettransaction,
  'id' | 'amount' | 'removed' | 'createdAt'
>;

export type WalletInfoFragment = { __typename?: 'Wallet' } & Pick<Wallet, 'id' | 'removed'> & {
    transactions?: Maybe<
      Array<Maybe<{ __typename?: 'Wallettransaction' } & WallettransactionInfoFragment>>
    >;
  };

export type WalletOrderInfoFragment = { __typename?: 'WalletOrder' } & Pick<
  WalletOrder,
  'id' | 'amount' | 'jetton'
>;

export type MakeWalletPaymentMutationVariables = Exact<{
  amount?: Maybe<Scalars['Float']>;
  jetton?: Maybe<Scalars['Float']>;
}>;

export type MakeWalletPaymentMutation = { __typename?: 'Mutation' } & {
  makeWalletPayment?: Maybe<
    { __typename?: 'MakeWalletPaymentPayload' } & Pick<MakeWalletPaymentPayload, 'clientSecret'> & {
        order?: Maybe<{ __typename?: 'WalletOrder' } & WalletOrderInfoFragment>;
      }
  >;
};

export type ConfirmWalletOrderPaymentMutationVariables = Exact<{
  walletOrderId: Scalars['ID'];
  meta?: Maybe<Scalars['JSON']>;
}>;

export type ConfirmWalletOrderPaymentMutation = { __typename?: 'Mutation' } & {
  confirmWalletOrderPayment?: Maybe<{ __typename?: 'WalletOrder' } & WalletOrderInfoFragment>;
};

export type AllWalletsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<WalletFilter>;
}>;

export type AllWalletsQuery = { __typename?: 'Query' } & {
  allWallets?: Maybe<Array<Maybe<{ __typename?: 'Wallet' } & WalletInfoFragment>>>;
};

export type AllWallettransactionsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  filter?: Maybe<WallettransactionFilter>;
}>;

export type AllWallettransactionsQuery = { __typename?: 'Query' } & {
  allWallettransactions?: Maybe<
    Array<Maybe<{ __typename?: 'Wallettransaction' } & WallettransactionInfoFragment>>
  >;
};

export type MyWalletTransactionQueryVariables = Exact<{ [key: string]: never }>;

export type MyWalletTransactionQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'User' } & Pick<User, 'id' | 'totalWallet'> & {
      wallet?: Maybe<{ __typename?: 'Wallet' } & WalletInfoFragment>;
    };
};

export const ActualityInfosFragmentDoc = gql`
  fragment ActualityInfos on Actuality {
    id
    title
    imagePreview
    content
    description
    createdAt
    updatedAt
  }
`;
export const AssistancePageInfosFragmentDoc = gql`
  fragment AssistancePageInfos on Assistancepage {
    id
    title
    content
    title_en
    content_en
    category
    category_en
    slug
    order
    removed
    createdAt
  }
`;
export const LoginSocialInfosFragmentDoc = gql`
  fragment LoginSocialInfos on LoginSocial {
    token
    authName
    userName
  }
`;
export const TwitterOauthTokenInfosFragmentDoc = gql`
  fragment TwitterOauthTokenInfos on TwitterOauthToken {
    oauth_token
    oauth_token_secret
    oauth_callback_confirmed
  }
`;
export const TwitterOauthAccessTokenInfosFragmentDoc = gql`
  fragment TwitterOauthAccessTokenInfos on TwitterOauthAccessToken {
    oauth_token
    oauth_token_secret
    user_id
    screen_name
  }
`;
export const TwitterUserAuthInfosFragmentDoc = gql`
  fragment TwitterUserAuthInfos on TwitterUserAuth {
    providerId
    name
    screen_name
    email
    avatar
  }
`;
export const ChatGroupInfoFragmentDoc = gql`
  fragment ChatGroupInfo on Chat {
    id
    name
    chatengineId
    owner {
      id
    }
    chatEngineAccessKey
    createdAt
    members {
      id
      removed
      user {
        id
      }
    }
  }
`;
export const UserBasicInfosFragmentDoc = gql`
  fragment UserBasicInfos on User {
    id
    isMe
    firstname
    lastname
    email
    avatar
    username
    isAdmin
    phoneNumber
    displayName
    totalWallet
    isOnline
    point
    isSignupFinish
    twitchUserId
    googleUserId
    facebookUserId
    twitterUserId
    discordUserId
    microsoftUserId
    description
    status
    myMainGamePlateform {
      id
      name
    }
  }
`;
export const ChatMemberInfoFragmentDoc = gql`
  fragment ChatMemberInfo on Chatmember {
    id
    chat {
      ...ChatGroupInfo
    }
    user {
      ...UserBasicInfos
    }
    removed
  }
  ${ChatGroupInfoFragmentDoc}
  ${UserBasicInfosFragmentDoc}
`;
export const GamePartyFragmentFragmentDoc = gql`
  fragment GamePartyFragment on Gameparty {
    id
    name
    chat {
      ...ChatGroupInfo
    }
    members {
      id
    }
    removed
  }
  ${ChatGroupInfoFragmentDoc}
`;
export const CountryInfosFragmentDoc = gql`
  fragment CountryInfos on Country {
    id
    name
    code
  }
`;
export const DiscordChannelInfosFragmentDoc = gql`
  fragment DiscordChannelInfos on DiscordChannel {
    id
    name
    rawPosition
    lastMessageId
    rateLimitPerUser
    nsfw
    parentId
    guildId
  }
`;
export const DiscordAuthorMessageInfosFragmentDoc = gql`
  fragment DiscordAuthorMessageInfos on DiscordAuthorMessage {
    id
    username
    avatar
    avatar_decoration
    discriminator
    public_flags
  }
`;
export const EmbedProviderInfosFragmentDoc = gql`
  fragment EmbedProviderInfos on EmbedProvider {
    name
    url
  }
`;
export const EmbedThumbnailInfosFragmentDoc = gql`
  fragment EmbedThumbnailInfos on EmbedThumbnail {
    url
    proxyUrl
    width
    height
  }
`;
export const EmbedVideoInfosFragmentDoc = gql`
  fragment EmbedVideoInfos on EmbedVideo {
    url
    proxyUrl
    width
    height
  }
`;
export const EmbedInfosFragmentDoc = gql`
  fragment EmbedInfos on Embed {
    type
    url
    provider {
      ...EmbedProviderInfos
    }
    thumbnail {
      ...EmbedThumbnailInfos
    }
    video {
      ...EmbedVideoInfos
    }
  }
  ${EmbedProviderInfosFragmentDoc}
  ${EmbedThumbnailInfosFragmentDoc}
  ${EmbedVideoInfosFragmentDoc}
`;
export const MessageReferenceInfosFragmentDoc = gql`
  fragment MessageReferenceInfos on DiscordMessageReference {
    channelId
    guildId
    messageId
  }
`;
export const ReferencedMessageInfosFragmentDoc = gql`
  fragment ReferencedMessageInfos on DiscordChannelMessage {
    id
    guildId
    channelId
    deleted
    createdTimestamp
    type
    system
    content
    author {
      ...DiscordAuthorMessageInfos
    }
    pinned
    tts
    timestamp
    embeds {
      ...EmbedInfos
    }
    messageReference {
      ...MessageReferenceInfos
    }
  }
  ${DiscordAuthorMessageInfosFragmentDoc}
  ${EmbedInfosFragmentDoc}
  ${MessageReferenceInfosFragmentDoc}
`;
export const DiscordChannelMessageInfosFragmentDoc = gql`
  fragment DiscordChannelMessageInfos on DiscordChannelMessage {
    id
    guildId
    channelId
    deleted
    createdTimestamp
    type
    system
    content
    author {
      ...DiscordAuthorMessageInfos
    }
    pinned
    tts
    timestamp
    embeds {
      ...EmbedInfos
    }
    messageReference {
      ...MessageReferenceInfos
    }
    referencedMessage {
      ...ReferencedMessageInfos
    }
  }
  ${DiscordAuthorMessageInfosFragmentDoc}
  ${EmbedInfosFragmentDoc}
  ${MessageReferenceInfosFragmentDoc}
  ${ReferencedMessageInfosFragmentDoc}
`;
export const DiscordWebhookInfosFragmentDoc = gql`
  fragment DiscordWebhookInfos on DiscordWebhook {
    id
    type
    name
    avatar
    channelId
    guildId
    applicationId
    token
    user {
      ...DiscordAuthorMessageInfos
    }
  }
  ${DiscordAuthorMessageInfosFragmentDoc}
`;
export const WebhookInfosFragmentDoc = gql`
  fragment WebhookInfos on Webhook {
    id
    webhookId
    username
    token
    avatar
    channelId
    applicationId
    token
    user
  }
`;
export const RoleTagInfosFragmentDoc = gql`
  fragment RoleTagInfos on RoleTags {
    bot_id
    integration_id
    premium_subscriber
  }
`;
export const GuildRoleInfosFragmentDoc = gql`
  fragment GuildRoleInfos on GuildRole {
    id
    name
    permissions
    position
    color
    hoist
    managed
    mentionable
    icon
    unicode_emoji
    flags
    permissions_new
    tags {
      ...RoleTagInfos
    }
  }
  ${RoleTagInfosFragmentDoc}
`;
export const GameAccountInfosFragmentDoc = gql`
  fragment GameAccountInfos on GameAccount {
    id
    name
    logo
    label
    createdAt
    updatedAt
  }
`;
export const UserGameAccountPayloadInfosFragmentDoc = gql`
  fragment UserGameAccountPayloadInfos on UserGameAccountPayload {
    id
    gameAccount {
      ...GameAccountInfos
    }
    userGameAccount {
      id
      gamertag
      username
      accountId
    }
  }
  ${GameAccountInfosFragmentDoc}
`;
export const RapidapiCodMwWarZoneMatchInfoFragmentDoc = gql`
  fragment RapidapiCodMWWarZoneMatchInfo on RapidapiCodMWWarZoneMatch {
    summary
    matches
  }
`;
export const RapidapiCodModernWarfareStatInfoFragmentDoc = gql`
  fragment RapidapiCodModernWarfareStatInfo on RapidapiCodModernWarfareStat {
    warzoneStats {
      br
      br_all
      br_dmz
    }
    warzoneMatches {
      summary
      matches
    }
    multiplayerStats
    multiplayerMatches {
      summary
      matches
    }
  }
`;
export const FutPlayerInfoFragmentDoc = gql`
  fragment FutPlayerInfo on FutPlayerInfo {
    commonName
    firstName
    lastName
    league
    nation
    club
    headshot
    position
    composure
    playStyle
    playStyleId
    height
    weight
    birthdate
    age
    acceleration
    aggression
    agility
    balance
    ballcontrol
    foot
    skillMoves
    crossing
    curve
    dribbling
    finishing
    freekickaccuracy
    gkdiving
    gkhandling
    gkkicking
    gkpositioning
    gkreflexes
    headingaccuracy
    interceptions
    jumping
    longpassing
    longshots
    marking
    penalties
    positioning
    potential
    reactions
    shortpassing
    shotpower
    slidingtackle
    sprintspeed
    standingtackle
    stamina
    strength
    vision
    volleys
    weakFoot
  }
`;
export const WzstatsDotGgLastGameStatInfoFragmentDoc = gql`
  fragment WzstatsDotGGLastGameStatInfo on WzstatsDotGGLastGameStat {
    kills
    deaths
    headshots
    wins
    gamesPlayed
    damageDone
    damageTaken
    gulagWinPercentage
  }
`;
export const WzstatsDotGgInfoFragmentDoc = gql`
  fragment WzstatsDotGGInfo on WzstatsDotGG {
    weekly
    last100games {
      ...WzstatsDotGGLastGameStatInfo
    }
    last100RebirthGames {
      ...WzstatsDotGGLastGameStatInfo
    }
    lifetime
  }
  ${WzstatsDotGgLastGameStatInfoFragmentDoc}
`;
export const CodTrackerDotGgStatsInfoFragmentDoc = gql`
  fragment CODTrackerDotGGStatsInfo on CODTrackerDotGGStats {
    profilStatOverView
    killstreaks
    weaponStats
  }
`;
export const WzstatsDotGgMatchStatInfoFragmentDoc = gql`
  fragment WzstatsDotGGMatchStatInfo on WzstatsDotGGMatchStat {
    id
    mode
    kills
    deaths
    damage
    position
    startedAt
    username
    uno
    refreshAt
    matchStatData
    killPlacement
    matchTeamStat
    playerCountInApi
    playerCountInDB
    playerCountStillMissing
    data {
      mode
      players
      startedAt
    }
  }
`;
export const RocketLeagueTrackerGgLeaderBoardItemInfoFragmentDoc = gql`
  fragment RocketLeagueTrackerGGLeaderBoardItemInfo on RocketLeagueTrackerGGLeaderBoardItem {
    id
    owner
    value
    displayValue
    rank
    iconUrl
  }
`;
export const CodWarezoneTrackerDotGgLeaderboardItemInfoFragmentDoc = gql`
  fragment CodWarezoneTrackerDotGGLeaderboardItemInfo on CodWarezoneTrackerDotGGLeaderboardItem {
    id
    owner
    value
    displayValue
    rank
    percentile
    iconUrl
  }
`;
export const RocketLeagueTrackerGgStatInfoFragmentDoc = gql`
  fragment RocketLeagueTrackerGGStatInfo on RocketLeagueTrackerGGStat {
    profilStatOverView
    recentMatches
    playlistAverage
  }
`;
export const LeaderboardpointInfosFragmentDoc = gql`
  fragment LeaderboardpointInfos on Leaderboardpoint {
    id
    point
    removed
  }
`;
export const LeagueInfosFragmentDoc = gql`
  fragment LeagueInfos on League {
    id
    title
    isActive
    description
    maxParticipants
    minParticipants
    format
    gameId
    gamePlateformId
    roundNumber
    startedAt
    createdAt
    updatedAt
    organiserId
    winnerMatchPoint
    looserMatchPoint
    drawMatchPoint
    isMeAlreadyParticipant
    entryCost
  }
`;
export const GamePlatformInfosFragmentDoc = gql`
  fragment GamePlatformInfos on GamePlatform {
    id
    name
    logo
  }
`;
export const GameInfosFragmentDoc = gql`
  fragment GameInfos on Game {
    id
    name
    logo
    description
    mainJacket
    jacketImages
    coverImage
    popularity
    note
    twitchGameId
    isInCurrentUserFavorite
    landingPageJacket
    landingPageLogo
    platforms {
      ...GamePlatformInfos
    }
  }
  ${GamePlatformInfosFragmentDoc}
`;
export const LeagueAwardInfosFragmentDoc = gql`
  fragment LeagueAwardInfos on LeagueAward {
    id
    title
    value
    startRank
    endRank
    description
    updatedAt
    createdAt
  }
`;
export const LeagueRuleInfosFragmentDoc = gql`
  fragment LeagueRuleInfos on LeagueRule {
    id
    name
    description
    updatedAt
    createdAt
  }
`;
export const TeamBasicInfosFragmentDoc = gql`
  fragment TeamBasicInfos on Team {
    id
    name
    description
    logo
    banner
    createdAt
    country
    point
    members {
      id
    }
  }
`;
export const TournamentBracketCompleteInfosFragmentDoc = gql`
  fragment TournamentBracketCompleteInfos on TournamentBracket {
    id
    round
    pointParticipant1
    pointParticipant2
    isLive
    isFinished
    startedAt
    createdAt
    updatedAt
    endedAt
    tournament
    participant1 {
      id
      user {
        ...UserBasicInfos
      }
      team {
        ...TeamBasicInfos
      }
    }
    participant2 {
      id
      user {
        ...UserBasicInfos
      }
      team {
        ...TeamBasicInfos
      }
    }
    winner {
      id
      user {
        ...UserBasicInfos
      }
      team {
        ...TeamBasicInfos
      }
    }
    chatEngineId
    chatEngineAccessKey
  }
  ${UserBasicInfosFragmentDoc}
  ${TeamBasicInfosFragmentDoc}
`;
export const LeagueCompleteInfosFragmentDoc = gql`
  fragment LeagueCompleteInfos on League {
    id
    title
    isActive
    description
    maxParticipants
    minParticipants
    format
    gameId
    gamePlateformId
    winnerMatchPoint
    looserMatchPoint
    drawMatchPoint
    roundNumber
    startedAt
    createdAt
    updatedAt
    organiserId
    isMeAlreadyParticipant
    game {
      ...GameInfos
    }
    gamePlateform {
      ...GamePlatformInfos
    }
    awards {
      ...LeagueAwardInfos
    }
    rules {
      ...LeagueRuleInfos
    }
    organiser {
      id
    }
    matches {
      ...TournamentBracketCompleteInfos
    }
    participants {
      id
      user {
        ...UserBasicInfos
      }
      team {
        ...TeamBasicInfos
      }
      createdAt
      brackets {
        ...TournamentBracketCompleteInfos
      }
      leagueTotalPoint
    }
    entryCost
  }
  ${GameInfosFragmentDoc}
  ${GamePlatformInfosFragmentDoc}
  ${LeagueAwardInfosFragmentDoc}
  ${LeagueRuleInfosFragmentDoc}
  ${TournamentBracketCompleteInfosFragmentDoc}
  ${UserBasicInfosFragmentDoc}
  ${TeamBasicInfosFragmentDoc}
`;
export const MessageInfosFragmentDoc = gql`
  fragment MessageInfos on Message {
    id
    content
    authorId
    threadId
    createdAt
    updatedAt
    isMine
    isRemoved
  }
`;
export const NotificationInfosFragmentDoc = gql`
  fragment NotificationInfos on Notification {
    id
    title
    content
    type
    createdAt
  }
`;
export const PageInfosFragmentDoc = gql`
  fragment PageInfos on Page {
    id
    slug
    pageContentIds
    title
  }
`;
export const PageContentInfosFragmentDoc = gql`
  fragment PageContentInfos on PageContent {
    id
    label
    value
    value_en
  }
`;
export const OrderInfosFragmentDoc = gql`
  fragment OrderInfos on Order {
    id
    amount
    paymentMethod
  }
`;
export const SaisonInfoFragmentDoc = gql`
  fragment SaisonInfo on Saison {
    id
    title
    startDate
    endDate
    removed
  }
`;
export const SocialAccountInfosFragmentDoc = gql`
  fragment SocialAccountInfos on Socialaccount {
    id
    name
    label
    logo
  }
`;
export const UserSocialAccountInfosFragmentDoc = gql`
  fragment UserSocialAccountInfos on Usersocialaccount {
    id
    socialAccount {
      ...SocialAccountInfos
    }
    email
    username
  }
  ${SocialAccountInfosFragmentDoc}
`;
export const SubscriptionInfosFragmentDoc = gql`
  fragment SubscriptionInfos on SubscriptionType {
    id
    totalMonth
    pricePerMonth
    description
    title
  }
`;
export const TextContentInfosFragmentDoc = gql`
  fragment TextContentInfos on TextContent {
    id
    contentFr
    contentEn
    section
    textType
    removed
    createdAt
  }
`;
export const ThreadInfosFragmentDoc = gql`
  fragment ThreadInfos on Thread {
    id
    title
    authorId
    createdAt
    updatedAt
    isPrivate
    isRemoved
    totalMessage
  }
`;
export const TournamentFormatInfosFragmentDoc = gql`
  fragment TournamentFormatInfos on TournamentFormat {
    id
    title
    type
    description
    removed
  }
`;
export const TournamentInfosFragmentDoc = gql`
  fragment TournamentInfos on Tournament {
    id
    title
    description
    discordChannelId
    startedAt
    createdAt
    game {
      ...GameInfos
    }
    format {
      ...TournamentFormatInfos
    }
    maxParticipants
    minParticipants
    isActive
    status
    gamePlateform {
      ...GamePlatformInfos
    }
    rewardsTotal
    entryCost
    isMeAlreadyParticipant
    entryType
    organiser {
      id
      name
    }
  }
  ${GameInfosFragmentDoc}
  ${TournamentFormatInfosFragmentDoc}
  ${GamePlatformInfosFragmentDoc}
`;
export const TournamentAwardInfoFragmentDoc = gql`
  fragment TournamentAwardInfo on TournamentAward {
    id
    title
    value
    startRank
    endRank
    description
    sponsorName
    sponsorImage
    type
    createdAt
    updatedAt
    removed
  }
`;
export const TournamentGainInfosFragmentDoc = gql`
  fragment TournamentGainInfos on TournamentGain {
    id
    label
    price
    unity
    description
    removed
  }
`;
export const UserInfosFragmentDoc = gql`
  fragment UserInfos on User {
    id
    isMe
    firstname
    lastname
    birthDate
    email
    avatar
    coverPhoto
    username
    isBanned
    language
    country
    devise
    oauth2
    createdAt
    updatedAt
    isAdmin
    phoneNumber
    uid
    displayName
    receiveCommunication
    isUsedTrial
    isPremium
    endPremium
    getStreamToken
    isMyfriend
    twitchLogin
    twitchUserId
    googleUserId
    facebookUserId
    twitterUserId
    discordUserId
    microsoftUserId
    totalWallet
    chatEngineId
    chatEngineSecret
    isOnline
    isSignupFinish
    description
    status
    myMainGamePlateform {
      id
      name
    }
  }
`;
export const TeamMemberInfosFragmentDoc = gql`
  fragment TeamMemberInfos on TeamMember {
    id
    role
    createdAt
    user {
      id
      isMe
      firstname
      lastname
      email
      avatar
      username
      isAdmin
      phoneNumber
      displayName
    }
    team {
      id
      name
      description
      logo
      banner
      createdAt
    }
  }
`;
export const TeamInfosFragmentDoc = gql`
  fragment TeamInfos on Team {
    id
    name
    description
    logo
    banner
    createdAt
    owner {
      ...TeamMemberInfos
    }
    members {
      ...TeamMemberInfos
    }
  }
  ${TeamMemberInfosFragmentDoc}
`;
export const MatchFileInfosFragmentDoc = gql`
  fragment MatchFileInfos on MatchFile {
    id
    url
    name
  }
`;
export const MatchInfosFragmentDoc = gql`
  fragment MatchInfos on Match {
    id
    tournamentId
    isLive
    roundNumber
    matchNumber
    dateBegin
    parents {
      parentAId
      parentBId
    }
    isFinished
    opponents {
      ... on MatchUserOpponent {
        isTeam
        userA {
          ...UserInfos
        }
        userB {
          ...UserInfos
        }
      }
      ... on MatchTeamOpponent {
        isTeam
        teamA {
          ...TeamInfos
        }
        teamB {
          ...TeamInfos
        }
      }
    }
    result {
      pointA
      pointB
      winner
    }
    files {
      ...MatchFileInfos
    }
  }
  ${UserInfosFragmentDoc}
  ${TeamInfosFragmentDoc}
  ${MatchFileInfosFragmentDoc}
`;
export const MessageTextInfosFragmentDoc = gql`
  fragment MessageTextInfos on MessageText {
    text
  }
`;
export const MessageFileInfosFragmentDoc = gql`
  fragment MessageFileInfos on MessageFile {
    id
    url
    size
    name
  }
`;
export const TournamentMessageInfosFragmentDoc = gql`
  fragment TournamentMessageInfos on TournamentMessage {
    id
    tournamentId
    type
    isMine
    isRemoved
    createdAt
    updatedAt
    parent {
      id
      tournamentId
      type
      isMine
      isRemoved
      createdAt
      updatedAt
      author {
        ...UserInfos
      }
      content {
        ... on MessageText {
          ...MessageTextInfos
        }
        ... on MessageFile {
          ...MessageFileInfos
        }
      }
    }
    author {
      ...UserInfos
    }
    content {
      ... on MessageText {
        ...MessageTextInfos
      }
      ... on MessageFile {
        ...MessageFileInfos
      }
    }
  }
  ${UserInfosFragmentDoc}
  ${MessageTextInfosFragmentDoc}
  ${MessageFileInfosFragmentDoc}
`;
export const TournamentRuleInfosFragmentDoc = gql`
  fragment TournamentRuleInfos on TournamentRule {
    id
    name
    description
    removed
    name_en
    description_en
  }
`;
export const TransactionInfosFragmentDoc = gql`
  fragment TransactionInfos on Transaction {
    id
    description
    payment
    etat
    total
    endDate
    startDate
    userId
  }
`;
export const TwitchLiveInfosFragmentDoc = gql`
  fragment TwitchLiveInfos on TwitchLive {
    id
    user_login
    user_name
    game_id
    game_name
    type
    title
    viewer_count
    started_at
    language
    thumbnail_url
    tag_ids
    is_mature
  }
`;
export const VideoInfosFragmentDoc = gql`
  fragment VideoInfos on Video {
    id
    path
    title
    description
    imgPreview
    categoryId
    createdAt
    streamStatus
    canEdit
    author {
      ...UserInfos
    }
    category {
      id
      name
    }
  }
  ${UserInfosFragmentDoc}
`;
export const VideoCategoryInfosFragmentDoc = gql`
  fragment VideoCategoryInfos on VideoCategory {
    id
    logo
    name
  }
`;
export const WagerInfosFragmentDoc = gql`
  fragment WagerInfos on Wager {
    id
    isMeAlreadyParticipant
    cashPrice
    format
    isActive
    participantNumber
    startedAt
    updatedAt
    title
    createdAt
    rule
    entryCost
  }
`;
export const TournamentBracketInfosFragmentDoc = gql`
  fragment TournamentBracketInfos on TournamentBracket {
    id
    round
    pointParticipant1
    pointParticipant2
    isLive
    isFinished
    startedAt
    createdAt
    updatedAt
    endedAt
  }
`;
export const WagerCompleteInfosFragmentDoc = gql`
  fragment WagerCompleteInfos on Wager {
    id
    isMeAlreadyParticipant
    participants {
      id
      user {
        ...UserBasicInfos
      }
      team {
        ...TeamBasicInfos
      }
      createdAt
      brackets {
        ...TournamentBracketInfos
      }
      leagueTotalPoint
    }
    cashPrice
    format
    isActive
    participantNumber
    startedAt
    updatedAt
    title
    createdAt
    game {
      ...GameInfos
    }
    plateform {
      ...GamePlatformInfos
    }
    createdBy {
      ...UserBasicInfos
    }
    rule
    entryCost
  }
  ${UserBasicInfosFragmentDoc}
  ${TeamBasicInfosFragmentDoc}
  ${TournamentBracketInfosFragmentDoc}
  ${GameInfosFragmentDoc}
  ${GamePlatformInfosFragmentDoc}
`;
export const WallettransactionInfoFragmentDoc = gql`
  fragment WallettransactionInfo on Wallettransaction {
    id
    amount
    removed
    createdAt
  }
`;
export const WalletInfoFragmentDoc = gql`
  fragment WalletInfo on Wallet {
    id
    transactions {
      ...WallettransactionInfo
    }
    removed
  }
  ${WallettransactionInfoFragmentDoc}
`;
export const WalletOrderInfoFragmentDoc = gql`
  fragment WalletOrderInfo on WalletOrder {
    id
    amount
    jetton
  }
`;
export const AddTournamentMatchFileDocument = gql`
  mutation AddTournamentMatchFile($input: AddMatchFileInput!) {
    addMatchFile(input: $input) {
      id
      files {
        ...MatchFileInfos
      }
    }
  }
  ${MatchFileInfosFragmentDoc}
`;
export type AddTournamentMatchFileMutationFn = Apollo.MutationFunction<
  AddTournamentMatchFileMutation,
  AddTournamentMatchFileMutationVariables
>;

/**
 * __useAddTournamentMatchFileMutation__
 *
 * To run a mutation, you first call `useAddTournamentMatchFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTournamentMatchFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTournamentMatchFileMutation, { data, loading, error }] = useAddTournamentMatchFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTournamentMatchFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTournamentMatchFileMutation,
    AddTournamentMatchFileMutationVariables
  >
) {
  return Apollo.useMutation<
    AddTournamentMatchFileMutation,
    AddTournamentMatchFileMutationVariables
  >(AddTournamentMatchFileDocument, baseOptions);
}
export type AddTournamentMatchFileMutationHookResult = ReturnType<
  typeof useAddTournamentMatchFileMutation
>;
export type AddTournamentMatchFileMutationResult =
  Apollo.MutationResult<AddTournamentMatchFileMutation>;
export type AddTournamentMatchFileMutationOptions = Apollo.BaseMutationOptions<
  AddTournamentMatchFileMutation,
  AddTournamentMatchFileMutationVariables
>;
export const RemoveTournamentMatchFileDocument = gql`
  mutation RemoveTournamentMatchFile($id: ID!) {
    removeMatchFile(id: $id) {
      id
      files {
        ...MatchFileInfos
      }
    }
  }
  ${MatchFileInfosFragmentDoc}
`;
export type RemoveTournamentMatchFileMutationFn = Apollo.MutationFunction<
  RemoveTournamentMatchFileMutation,
  RemoveTournamentMatchFileMutationVariables
>;

/**
 * __useRemoveTournamentMatchFileMutation__
 *
 * To run a mutation, you first call `useRemoveTournamentMatchFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTournamentMatchFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTournamentMatchFileMutation, { data, loading, error }] = useRemoveTournamentMatchFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveTournamentMatchFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveTournamentMatchFileMutation,
    RemoveTournamentMatchFileMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveTournamentMatchFileMutation,
    RemoveTournamentMatchFileMutationVariables
  >(RemoveTournamentMatchFileDocument, baseOptions);
}
export type RemoveTournamentMatchFileMutationHookResult = ReturnType<
  typeof useRemoveTournamentMatchFileMutation
>;
export type RemoveTournamentMatchFileMutationResult =
  Apollo.MutationResult<RemoveTournamentMatchFileMutation>;
export type RemoveTournamentMatchFileMutationOptions = Apollo.BaseMutationOptions<
  RemoveTournamentMatchFileMutation,
  RemoveTournamentMatchFileMutationVariables
>;
export const AllActualitiesDocument = gql`
  query AllActualities(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: ActualityFilter
  ) {
    allActualities(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...ActualityInfos
    }
  }
  ${ActualityInfosFragmentDoc}
`;

/**
 * __useAllActualitiesQuery__
 *
 * To run a query within a React component, call `useAllActualitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllActualitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllActualitiesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllActualitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<AllActualitiesQuery, AllActualitiesQueryVariables>
) {
  return Apollo.useQuery<AllActualitiesQuery, AllActualitiesQueryVariables>(
    AllActualitiesDocument,
    baseOptions
  );
}
export function useAllActualitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllActualitiesQuery, AllActualitiesQueryVariables>
) {
  return Apollo.useLazyQuery<AllActualitiesQuery, AllActualitiesQueryVariables>(
    AllActualitiesDocument,
    baseOptions
  );
}
export type AllActualitiesQueryHookResult = ReturnType<typeof useAllActualitiesQuery>;
export type AllActualitiesLazyQueryHookResult = ReturnType<typeof useAllActualitiesLazyQuery>;
export type AllActualitiesQueryResult = Apollo.QueryResult<
  AllActualitiesQuery,
  AllActualitiesQueryVariables
>;
export const ActualityDocument = gql`
  query Actuality($id: ID!) {
    Actuality(id: $id) {
      ...ActualityInfos
    }
  }
  ${ActualityInfosFragmentDoc}
`;

/**
 * __useActualityQuery__
 *
 * To run a query within a React component, call `useActualityQuery` and pass it any options that fit your needs.
 * When your component renders, `useActualityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActualityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActualityQuery(
  baseOptions: Apollo.QueryHookOptions<ActualityQuery, ActualityQueryVariables>
) {
  return Apollo.useQuery<ActualityQuery, ActualityQueryVariables>(ActualityDocument, baseOptions);
}
export function useActualityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ActualityQuery, ActualityQueryVariables>
) {
  return Apollo.useLazyQuery<ActualityQuery, ActualityQueryVariables>(
    ActualityDocument,
    baseOptions
  );
}
export type ActualityQueryHookResult = ReturnType<typeof useActualityQuery>;
export type ActualityLazyQueryHookResult = ReturnType<typeof useActualityLazyQuery>;
export type ActualityQueryResult = Apollo.QueryResult<ActualityQuery, ActualityQueryVariables>;
export const AllAssistancePagesDocument = gql`
  query AllAssistancePages {
    allAssistancepages {
      ...AssistancePageInfos
    }
  }
  ${AssistancePageInfosFragmentDoc}
`;

/**
 * __useAllAssistancePagesQuery__
 *
 * To run a query within a React component, call `useAllAssistancePagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAssistancePagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAssistancePagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllAssistancePagesQuery(
  baseOptions?: Apollo.QueryHookOptions<AllAssistancePagesQuery, AllAssistancePagesQueryVariables>
) {
  return Apollo.useQuery<AllAssistancePagesQuery, AllAssistancePagesQueryVariables>(
    AllAssistancePagesDocument,
    baseOptions
  );
}
export function useAllAssistancePagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllAssistancePagesQuery,
    AllAssistancePagesQueryVariables
  >
) {
  return Apollo.useLazyQuery<AllAssistancePagesQuery, AllAssistancePagesQueryVariables>(
    AllAssistancePagesDocument,
    baseOptions
  );
}
export type AllAssistancePagesQueryHookResult = ReturnType<typeof useAllAssistancePagesQuery>;
export type AllAssistancePagesLazyQueryHookResult = ReturnType<
  typeof useAllAssistancePagesLazyQuery
>;
export type AllAssistancePagesQueryResult = Apollo.QueryResult<
  AllAssistancePagesQuery,
  AllAssistancePagesQueryVariables
>;
export const GetAssistancePageDocument = gql`
  query GetAssistancePage($id: ID!) {
    Assistancepage(id: $id) {
      ...AssistancePageInfos
    }
  }
  ${AssistancePageInfosFragmentDoc}
`;

/**
 * __useGetAssistancePageQuery__
 *
 * To run a query within a React component, call `useGetAssistancePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssistancePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssistancePageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssistancePageQuery(
  baseOptions: Apollo.QueryHookOptions<GetAssistancePageQuery, GetAssistancePageQueryVariables>
) {
  return Apollo.useQuery<GetAssistancePageQuery, GetAssistancePageQueryVariables>(
    GetAssistancePageDocument,
    baseOptions
  );
}
export function useGetAssistancePageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAssistancePageQuery, GetAssistancePageQueryVariables>
) {
  return Apollo.useLazyQuery<GetAssistancePageQuery, GetAssistancePageQueryVariables>(
    GetAssistancePageDocument,
    baseOptions
  );
}
export type GetAssistancePageQueryHookResult = ReturnType<typeof useGetAssistancePageQuery>;
export type GetAssistancePageLazyQueryHookResult = ReturnType<typeof useGetAssistancePageLazyQuery>;
export type GetAssistancePageQueryResult = Apollo.QueryResult<
  GetAssistancePageQuery,
  GetAssistancePageQueryVariables
>;
export const GetAssistancePageSlugDocument = gql`
  query GetAssistancePageSlug($slug: String!) {
    AssistancePage(slug: $slug) {
      ...AssistancePageInfos
    }
  }
  ${AssistancePageInfosFragmentDoc}
`;

/**
 * __useGetAssistancePageSlugQuery__
 *
 * To run a query within a React component, call `useGetAssistancePageSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssistancePageSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssistancePageSlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetAssistancePageSlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssistancePageSlugQuery,
    GetAssistancePageSlugQueryVariables
  >
) {
  return Apollo.useQuery<GetAssistancePageSlugQuery, GetAssistancePageSlugQueryVariables>(
    GetAssistancePageSlugDocument,
    baseOptions
  );
}
export function useGetAssistancePageSlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssistancePageSlugQuery,
    GetAssistancePageSlugQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetAssistancePageSlugQuery, GetAssistancePageSlugQueryVariables>(
    GetAssistancePageSlugDocument,
    baseOptions
  );
}
export type GetAssistancePageSlugQueryHookResult = ReturnType<typeof useGetAssistancePageSlugQuery>;
export type GetAssistancePageSlugLazyQueryHookResult = ReturnType<
  typeof useGetAssistancePageSlugLazyQuery
>;
export type GetAssistancePageSlugQueryResult = Apollo.QueryResult<
  GetAssistancePageSlugQuery,
  GetAssistancePageSlugQueryVariables
>;
export const LoginSocialDocument = gql`
  mutation LoginSocial($input: LoginSocialInput!) {
    loginSocial(input: $input) {
      ...LoginSocialInfos
    }
  }
  ${LoginSocialInfosFragmentDoc}
`;
export type LoginSocialMutationFn = Apollo.MutationFunction<
  LoginSocialMutation,
  LoginSocialMutationVariables
>;

/**
 * __useLoginSocialMutation__
 *
 * To run a mutation, you first call `useLoginSocialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginSocialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginSocialMutation, { data, loading, error }] = useLoginSocialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginSocialMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginSocialMutation, LoginSocialMutationVariables>
) {
  return Apollo.useMutation<LoginSocialMutation, LoginSocialMutationVariables>(
    LoginSocialDocument,
    baseOptions
  );
}
export type LoginSocialMutationHookResult = ReturnType<typeof useLoginSocialMutation>;
export type LoginSocialMutationResult = Apollo.MutationResult<LoginSocialMutation>;
export type LoginSocialMutationOptions = Apollo.BaseMutationOptions<
  LoginSocialMutation,
  LoginSocialMutationVariables
>;
export const LoginDocument = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input)
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>
) {
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const SignupDocument = gql`
  mutation Signup($input: SignupInput!) {
    signup(input: $input)
  }
`;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupMutation(
  baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>
) {
  return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, baseOptions);
}
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<
  SignupMutation,
  SignupMutationVariables
>;
export const LoginTwitchDocument = gql`
  mutation LoginTwitch($code: String!) {
    loginTwitch(code: $code)
  }
`;
export type LoginTwitchMutationFn = Apollo.MutationFunction<
  LoginTwitchMutation,
  LoginTwitchMutationVariables
>;

/**
 * __useLoginTwitchMutation__
 *
 * To run a mutation, you first call `useLoginTwitchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginTwitchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginTwitchMutation, { data, loading, error }] = useLoginTwitchMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useLoginTwitchMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginTwitchMutation, LoginTwitchMutationVariables>
) {
  return Apollo.useMutation<LoginTwitchMutation, LoginTwitchMutationVariables>(
    LoginTwitchDocument,
    baseOptions
  );
}
export type LoginTwitchMutationHookResult = ReturnType<typeof useLoginTwitchMutation>;
export type LoginTwitchMutationResult = Apollo.MutationResult<LoginTwitchMutation>;
export type LoginTwitchMutationOptions = Apollo.BaseMutationOptions<
  LoginTwitchMutation,
  LoginTwitchMutationVariables
>;
export const LoginDiscordDocument = gql`
  mutation LoginDiscord($code: String!) {
    loginDiscord(code: $code)
  }
`;
export type LoginDiscordMutationFn = Apollo.MutationFunction<
  LoginDiscordMutation,
  LoginDiscordMutationVariables
>;

/**
 * __useLoginDiscordMutation__
 *
 * To run a mutation, you first call `useLoginDiscordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginDiscordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginDiscordMutation, { data, loading, error }] = useLoginDiscordMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useLoginDiscordMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginDiscordMutation, LoginDiscordMutationVariables>
) {
  return Apollo.useMutation<LoginDiscordMutation, LoginDiscordMutationVariables>(
    LoginDiscordDocument,
    baseOptions
  );
}
export type LoginDiscordMutationHookResult = ReturnType<typeof useLoginDiscordMutation>;
export type LoginDiscordMutationResult = Apollo.MutationResult<LoginDiscordMutation>;
export type LoginDiscordMutationOptions = Apollo.BaseMutationOptions<
  LoginDiscordMutation,
  LoginDiscordMutationVariables
>;
export const LinkDiscordDocument = gql`
  mutation LinkDiscord($code: String!) {
    linkDiscord(code: $code)
  }
`;
export type LinkDiscordMutationFn = Apollo.MutationFunction<
  LinkDiscordMutation,
  LinkDiscordMutationVariables
>;

/**
 * __useLinkDiscordMutation__
 *
 * To run a mutation, you first call `useLinkDiscordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkDiscordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkDiscordMutation, { data, loading, error }] = useLinkDiscordMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useLinkDiscordMutation(
  baseOptions?: Apollo.MutationHookOptions<LinkDiscordMutation, LinkDiscordMutationVariables>
) {
  return Apollo.useMutation<LinkDiscordMutation, LinkDiscordMutationVariables>(
    LinkDiscordDocument,
    baseOptions
  );
}
export type LinkDiscordMutationHookResult = ReturnType<typeof useLinkDiscordMutation>;
export type LinkDiscordMutationResult = Apollo.MutationResult<LinkDiscordMutation>;
export type LinkDiscordMutationOptions = Apollo.BaseMutationOptions<
  LinkDiscordMutation,
  LinkDiscordMutationVariables
>;
export const LoginXboxDocument = gql`
  mutation loginXbox($code: String!) {
    loginXbox(code: $code)
  }
`;
export type LoginXboxMutationFn = Apollo.MutationFunction<
  LoginXboxMutation,
  LoginXboxMutationVariables
>;

/**
 * __useLoginXboxMutation__
 *
 * To run a mutation, you first call `useLoginXboxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginXboxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginXboxMutation, { data, loading, error }] = useLoginXboxMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useLoginXboxMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginXboxMutation, LoginXboxMutationVariables>
) {
  return Apollo.useMutation<LoginXboxMutation, LoginXboxMutationVariables>(
    LoginXboxDocument,
    baseOptions
  );
}
export type LoginXboxMutationHookResult = ReturnType<typeof useLoginXboxMutation>;
export type LoginXboxMutationResult = Apollo.MutationResult<LoginXboxMutation>;
export type LoginXboxMutationOptions = Apollo.BaseMutationOptions<
  LoginXboxMutation,
  LoginXboxMutationVariables
>;
export const LinkMicrosoftDocument = gql`
  mutation linkMicrosoft($code: String!) {
    linkMicrosoft(code: $code)
  }
`;
export type LinkMicrosoftMutationFn = Apollo.MutationFunction<
  LinkMicrosoftMutation,
  LinkMicrosoftMutationVariables
>;

/**
 * __useLinkMicrosoftMutation__
 *
 * To run a mutation, you first call `useLinkMicrosoftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkMicrosoftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkMicrosoftMutation, { data, loading, error }] = useLinkMicrosoftMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useLinkMicrosoftMutation(
  baseOptions?: Apollo.MutationHookOptions<LinkMicrosoftMutation, LinkMicrosoftMutationVariables>
) {
  return Apollo.useMutation<LinkMicrosoftMutation, LinkMicrosoftMutationVariables>(
    LinkMicrosoftDocument,
    baseOptions
  );
}
export type LinkMicrosoftMutationHookResult = ReturnType<typeof useLinkMicrosoftMutation>;
export type LinkMicrosoftMutationResult = Apollo.MutationResult<LinkMicrosoftMutation>;
export type LinkMicrosoftMutationOptions = Apollo.BaseMutationOptions<
  LinkMicrosoftMutation,
  LinkMicrosoftMutationVariables
>;
export const LoginBattlenetDocument = gql`
  mutation loginBattlenet($code: String!) {
    loginBattlenet(code: $code)
  }
`;
export type LoginBattlenetMutationFn = Apollo.MutationFunction<
  LoginBattlenetMutation,
  LoginBattlenetMutationVariables
>;

/**
 * __useLoginBattlenetMutation__
 *
 * To run a mutation, you first call `useLoginBattlenetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginBattlenetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginBattlenetMutation, { data, loading, error }] = useLoginBattlenetMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useLoginBattlenetMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginBattlenetMutation, LoginBattlenetMutationVariables>
) {
  return Apollo.useMutation<LoginBattlenetMutation, LoginBattlenetMutationVariables>(
    LoginBattlenetDocument,
    baseOptions
  );
}
export type LoginBattlenetMutationHookResult = ReturnType<typeof useLoginBattlenetMutation>;
export type LoginBattlenetMutationResult = Apollo.MutationResult<LoginBattlenetMutation>;
export type LoginBattlenetMutationOptions = Apollo.BaseMutationOptions<
  LoginBattlenetMutation,
  LoginBattlenetMutationVariables
>;
export const SendInvitationMobileDocument = gql`
  mutation SendInvitationMobile($email: [String]!) {
    sendInvitationMobile(email: $email)
  }
`;
export type SendInvitationMobileMutationFn = Apollo.MutationFunction<
  SendInvitationMobileMutation,
  SendInvitationMobileMutationVariables
>;

/**
 * __useSendInvitationMobileMutation__
 *
 * To run a mutation, you first call `useSendInvitationMobileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvitationMobileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvitationMobileMutation, { data, loading, error }] = useSendInvitationMobileMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendInvitationMobileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendInvitationMobileMutation,
    SendInvitationMobileMutationVariables
  >
) {
  return Apollo.useMutation<SendInvitationMobileMutation, SendInvitationMobileMutationVariables>(
    SendInvitationMobileDocument,
    baseOptions
  );
}
export type SendInvitationMobileMutationHookResult = ReturnType<
  typeof useSendInvitationMobileMutation
>;
export type SendInvitationMobileMutationResult =
  Apollo.MutationResult<SendInvitationMobileMutation>;
export type SendInvitationMobileMutationOptions = Apollo.BaseMutationOptions<
  SendInvitationMobileMutation,
  SendInvitationMobileMutationVariables
>;
export const GetAccessTokenDocument = gql`
  mutation GetAccessToken($oauth_token: String, $oauth_verifier: String) {
    getAccessToken(oauth_token: $oauth_token, oauth_verifier: $oauth_verifier) {
      ...TwitterOauthAccessTokenInfos
    }
  }
  ${TwitterOauthAccessTokenInfosFragmentDoc}
`;
export type GetAccessTokenMutationFn = Apollo.MutationFunction<
  GetAccessTokenMutation,
  GetAccessTokenMutationVariables
>;

/**
 * __useGetAccessTokenMutation__
 *
 * To run a mutation, you first call `useGetAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAccessTokenMutation, { data, loading, error }] = useGetAccessTokenMutation({
 *   variables: {
 *      oauth_token: // value for 'oauth_token'
 *      oauth_verifier: // value for 'oauth_verifier'
 *   },
 * });
 */
export function useGetAccessTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<GetAccessTokenMutation, GetAccessTokenMutationVariables>
) {
  return Apollo.useMutation<GetAccessTokenMutation, GetAccessTokenMutationVariables>(
    GetAccessTokenDocument,
    baseOptions
  );
}
export type GetAccessTokenMutationHookResult = ReturnType<typeof useGetAccessTokenMutation>;
export type GetAccessTokenMutationResult = Apollo.MutationResult<GetAccessTokenMutation>;
export type GetAccessTokenMutationOptions = Apollo.BaseMutationOptions<
  GetAccessTokenMutation,
  GetAccessTokenMutationVariables
>;
export const GetTwitterRequestTokenDocument = gql`
  query GetTwitterRequestToken {
    getTwitterRequestToken {
      ...TwitterOauthTokenInfos
    }
  }
  ${TwitterOauthTokenInfosFragmentDoc}
`;

/**
 * __useGetTwitterRequestTokenQuery__
 *
 * To run a query within a React component, call `useGetTwitterRequestTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTwitterRequestTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTwitterRequestTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTwitterRequestTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTwitterRequestTokenQuery,
    GetTwitterRequestTokenQueryVariables
  >
) {
  return Apollo.useQuery<GetTwitterRequestTokenQuery, GetTwitterRequestTokenQueryVariables>(
    GetTwitterRequestTokenDocument,
    baseOptions
  );
}
export function useGetTwitterRequestTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTwitterRequestTokenQuery,
    GetTwitterRequestTokenQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetTwitterRequestTokenQuery, GetTwitterRequestTokenQueryVariables>(
    GetTwitterRequestTokenDocument,
    baseOptions
  );
}
export type GetTwitterRequestTokenQueryHookResult = ReturnType<
  typeof useGetTwitterRequestTokenQuery
>;
export type GetTwitterRequestTokenLazyQueryHookResult = ReturnType<
  typeof useGetTwitterRequestTokenLazyQuery
>;
export type GetTwitterRequestTokenQueryResult = Apollo.QueryResult<
  GetTwitterRequestTokenQuery,
  GetTwitterRequestTokenQueryVariables
>;
export const GetTwitterOauthUserWithEmailDocument = gql`
  query GetTwitterOauthUserWithEmail($token: String, $secretToken: String) {
    getTwitterOauthUserWithEmail(token: $token, secretToken: $secretToken) {
      ...TwitterUserAuthInfos
    }
  }
  ${TwitterUserAuthInfosFragmentDoc}
`;

/**
 * __useGetTwitterOauthUserWithEmailQuery__
 *
 * To run a query within a React component, call `useGetTwitterOauthUserWithEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTwitterOauthUserWithEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTwitterOauthUserWithEmailQuery({
 *   variables: {
 *      token: // value for 'token'
 *      secretToken: // value for 'secretToken'
 *   },
 * });
 */
export function useGetTwitterOauthUserWithEmailQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTwitterOauthUserWithEmailQuery,
    GetTwitterOauthUserWithEmailQueryVariables
  >
) {
  return Apollo.useQuery<
    GetTwitterOauthUserWithEmailQuery,
    GetTwitterOauthUserWithEmailQueryVariables
  >(GetTwitterOauthUserWithEmailDocument, baseOptions);
}
export function useGetTwitterOauthUserWithEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTwitterOauthUserWithEmailQuery,
    GetTwitterOauthUserWithEmailQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetTwitterOauthUserWithEmailQuery,
    GetTwitterOauthUserWithEmailQueryVariables
  >(GetTwitterOauthUserWithEmailDocument, baseOptions);
}
export type GetTwitterOauthUserWithEmailQueryHookResult = ReturnType<
  typeof useGetTwitterOauthUserWithEmailQuery
>;
export type GetTwitterOauthUserWithEmailLazyQueryHookResult = ReturnType<
  typeof useGetTwitterOauthUserWithEmailLazyQuery
>;
export type GetTwitterOauthUserWithEmailQueryResult = Apollo.QueryResult<
  GetTwitterOauthUserWithEmailQuery,
  GetTwitterOauthUserWithEmailQueryVariables
>;
export const CreateChatDocument = gql`
  mutation CreateChat($name: String!, $gamePartyId: ID!) {
    createChat(name: $name, gamePartyId: $gamePartyId) {
      ...ChatGroupInfo
    }
  }
  ${ChatGroupInfoFragmentDoc}
`;
export type CreateChatMutationFn = Apollo.MutationFunction<
  CreateChatMutation,
  CreateChatMutationVariables
>;

/**
 * __useCreateChatMutation__
 *
 * To run a mutation, you first call `useCreateChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatMutation, { data, loading, error }] = useCreateChatMutation({
 *   variables: {
 *      name: // value for 'name'
 *      gamePartyId: // value for 'gamePartyId'
 *   },
 * });
 */
export function useCreateChatMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateChatMutation, CreateChatMutationVariables>
) {
  return Apollo.useMutation<CreateChatMutation, CreateChatMutationVariables>(
    CreateChatDocument,
    baseOptions
  );
}
export type CreateChatMutationHookResult = ReturnType<typeof useCreateChatMutation>;
export type CreateChatMutationResult = Apollo.MutationResult<CreateChatMutation>;
export type CreateChatMutationOptions = Apollo.BaseMutationOptions<
  CreateChatMutation,
  CreateChatMutationVariables
>;
export const DeleteChatDocument = gql`
  mutation DeleteChat($id: ID!) {
    deleteChat(id: $id) {
      ...ChatGroupInfo
    }
  }
  ${ChatGroupInfoFragmentDoc}
`;
export type DeleteChatMutationFn = Apollo.MutationFunction<
  DeleteChatMutation,
  DeleteChatMutationVariables
>;

/**
 * __useDeleteChatMutation__
 *
 * To run a mutation, you first call `useDeleteChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChatMutation, { data, loading, error }] = useDeleteChatMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteChatMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteChatMutation, DeleteChatMutationVariables>
) {
  return Apollo.useMutation<DeleteChatMutation, DeleteChatMutationVariables>(
    DeleteChatDocument,
    baseOptions
  );
}
export type DeleteChatMutationHookResult = ReturnType<typeof useDeleteChatMutation>;
export type DeleteChatMutationResult = Apollo.MutationResult<DeleteChatMutation>;
export type DeleteChatMutationOptions = Apollo.BaseMutationOptions<
  DeleteChatMutation,
  DeleteChatMutationVariables
>;
export const UpdateChatDocument = gql`
  mutation UpdateChat($id: ID!, $name: String) {
    updateChat(id: $id, name: $name) {
      ...ChatGroupInfo
    }
  }
  ${ChatGroupInfoFragmentDoc}
`;
export type UpdateChatMutationFn = Apollo.MutationFunction<
  UpdateChatMutation,
  UpdateChatMutationVariables
>;

/**
 * __useUpdateChatMutation__
 *
 * To run a mutation, you first call `useUpdateChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChatMutation, { data, loading, error }] = useUpdateChatMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateChatMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateChatMutation, UpdateChatMutationVariables>
) {
  return Apollo.useMutation<UpdateChatMutation, UpdateChatMutationVariables>(
    UpdateChatDocument,
    baseOptions
  );
}
export type UpdateChatMutationHookResult = ReturnType<typeof useUpdateChatMutation>;
export type UpdateChatMutationResult = Apollo.MutationResult<UpdateChatMutation>;
export type UpdateChatMutationOptions = Apollo.BaseMutationOptions<
  UpdateChatMutation,
  UpdateChatMutationVariables
>;
export const CreateChatmemberDocument = gql`
  mutation CreateChatmember($chatId: ID!, $userId: ID!) {
    createChatmember(chatId: $chatId, userId: $userId) {
      id
      removed
      chat {
        ...ChatGroupInfo
      }
    }
  }
  ${ChatGroupInfoFragmentDoc}
`;
export type CreateChatmemberMutationFn = Apollo.MutationFunction<
  CreateChatmemberMutation,
  CreateChatmemberMutationVariables
>;

/**
 * __useCreateChatmemberMutation__
 *
 * To run a mutation, you first call `useCreateChatmemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatmemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatmemberMutation, { data, loading, error }] = useCreateChatmemberMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateChatmemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChatmemberMutation,
    CreateChatmemberMutationVariables
  >
) {
  return Apollo.useMutation<CreateChatmemberMutation, CreateChatmemberMutationVariables>(
    CreateChatmemberDocument,
    baseOptions
  );
}
export type CreateChatmemberMutationHookResult = ReturnType<typeof useCreateChatmemberMutation>;
export type CreateChatmemberMutationResult = Apollo.MutationResult<CreateChatmemberMutation>;
export type CreateChatmemberMutationOptions = Apollo.BaseMutationOptions<
  CreateChatmemberMutation,
  CreateChatmemberMutationVariables
>;
export const DeleteChatmemberDocument = gql`
  mutation DeleteChatmember($id: ID!) {
    deleteChatmember(id: $id) {
      id
      removed
      chat {
        ...ChatGroupInfo
      }
    }
  }
  ${ChatGroupInfoFragmentDoc}
`;
export type DeleteChatmemberMutationFn = Apollo.MutationFunction<
  DeleteChatmemberMutation,
  DeleteChatmemberMutationVariables
>;

/**
 * __useDeleteChatmemberMutation__
 *
 * To run a mutation, you first call `useDeleteChatmemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChatmemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChatmemberMutation, { data, loading, error }] = useDeleteChatmemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteChatmemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteChatmemberMutation,
    DeleteChatmemberMutationVariables
  >
) {
  return Apollo.useMutation<DeleteChatmemberMutation, DeleteChatmemberMutationVariables>(
    DeleteChatmemberDocument,
    baseOptions
  );
}
export type DeleteChatmemberMutationHookResult = ReturnType<typeof useDeleteChatmemberMutation>;
export type DeleteChatmemberMutationResult = Apollo.MutationResult<DeleteChatmemberMutation>;
export type DeleteChatmemberMutationOptions = Apollo.BaseMutationOptions<
  DeleteChatmemberMutation,
  DeleteChatmemberMutationVariables
>;
export const CreateGamepartyDocument = gql`
  mutation CreateGameparty($name: String!) {
    createGameparty(name: $name) {
      id
      removed
      name
      chat {
        ...ChatGroupInfo
      }
    }
  }
  ${ChatGroupInfoFragmentDoc}
`;
export type CreateGamepartyMutationFn = Apollo.MutationFunction<
  CreateGamepartyMutation,
  CreateGamepartyMutationVariables
>;

/**
 * __useCreateGamepartyMutation__
 *
 * To run a mutation, you first call `useCreateGamepartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGamepartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGamepartyMutation, { data, loading, error }] = useCreateGamepartyMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateGamepartyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGamepartyMutation,
    CreateGamepartyMutationVariables
  >
) {
  return Apollo.useMutation<CreateGamepartyMutation, CreateGamepartyMutationVariables>(
    CreateGamepartyDocument,
    baseOptions
  );
}
export type CreateGamepartyMutationHookResult = ReturnType<typeof useCreateGamepartyMutation>;
export type CreateGamepartyMutationResult = Apollo.MutationResult<CreateGamepartyMutation>;
export type CreateGamepartyMutationOptions = Apollo.BaseMutationOptions<
  CreateGamepartyMutation,
  CreateGamepartyMutationVariables
>;
export const EditGamepartyDocument = gql`
  mutation EditGameparty($id: ID!, $name: String) {
    updateGameparty(id: $id, name: $name) {
      id
      removed
      name
    }
  }
`;
export type EditGamepartyMutationFn = Apollo.MutationFunction<
  EditGamepartyMutation,
  EditGamepartyMutationVariables
>;

/**
 * __useEditGamepartyMutation__
 *
 * To run a mutation, you first call `useEditGamepartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGamepartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGamepartyMutation, { data, loading, error }] = useEditGamepartyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEditGamepartyMutation(
  baseOptions?: Apollo.MutationHookOptions<EditGamepartyMutation, EditGamepartyMutationVariables>
) {
  return Apollo.useMutation<EditGamepartyMutation, EditGamepartyMutationVariables>(
    EditGamepartyDocument,
    baseOptions
  );
}
export type EditGamepartyMutationHookResult = ReturnType<typeof useEditGamepartyMutation>;
export type EditGamepartyMutationResult = Apollo.MutationResult<EditGamepartyMutation>;
export type EditGamepartyMutationOptions = Apollo.BaseMutationOptions<
  EditGamepartyMutation,
  EditGamepartyMutationVariables
>;
export const InviteUserToGamePartyDocument = gql`
  mutation InviteUserToGameParty($userId: ID!, $gamePartyId: ID!) {
    inviteUserToGameParty(userId: $userId, gamePartyId: $gamePartyId) {
      success
      message
    }
  }
`;
export type InviteUserToGamePartyMutationFn = Apollo.MutationFunction<
  InviteUserToGamePartyMutation,
  InviteUserToGamePartyMutationVariables
>;

/**
 * __useInviteUserToGamePartyMutation__
 *
 * To run a mutation, you first call `useInviteUserToGamePartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserToGamePartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserToGamePartyMutation, { data, loading, error }] = useInviteUserToGamePartyMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      gamePartyId: // value for 'gamePartyId'
 *   },
 * });
 */
export function useInviteUserToGamePartyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteUserToGamePartyMutation,
    InviteUserToGamePartyMutationVariables
  >
) {
  return Apollo.useMutation<InviteUserToGamePartyMutation, InviteUserToGamePartyMutationVariables>(
    InviteUserToGamePartyDocument,
    baseOptions
  );
}
export type InviteUserToGamePartyMutationHookResult = ReturnType<
  typeof useInviteUserToGamePartyMutation
>;
export type InviteUserToGamePartyMutationResult =
  Apollo.MutationResult<InviteUserToGamePartyMutation>;
export type InviteUserToGamePartyMutationOptions = Apollo.BaseMutationOptions<
  InviteUserToGamePartyMutation,
  InviteUserToGamePartyMutationVariables
>;
export const DeleteGamepartymemberDocument = gql`
  mutation DeleteGamepartymember($id: ID!) {
    deleteGamepartymember(id: $id) {
      id
    }
  }
`;
export type DeleteGamepartymemberMutationFn = Apollo.MutationFunction<
  DeleteGamepartymemberMutation,
  DeleteGamepartymemberMutationVariables
>;

/**
 * __useDeleteGamepartymemberMutation__
 *
 * To run a mutation, you first call `useDeleteGamepartymemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGamepartymemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGamepartymemberMutation, { data, loading, error }] = useDeleteGamepartymemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGamepartymemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGamepartymemberMutation,
    DeleteGamepartymemberMutationVariables
  >
) {
  return Apollo.useMutation<DeleteGamepartymemberMutation, DeleteGamepartymemberMutationVariables>(
    DeleteGamepartymemberDocument,
    baseOptions
  );
}
export type DeleteGamepartymemberMutationHookResult = ReturnType<
  typeof useDeleteGamepartymemberMutation
>;
export type DeleteGamepartymemberMutationResult =
  Apollo.MutationResult<DeleteGamepartymemberMutation>;
export type DeleteGamepartymemberMutationOptions = Apollo.BaseMutationOptions<
  DeleteGamepartymemberMutation,
  DeleteGamepartymemberMutationVariables
>;
export const MyAllChatGroupDocument = gql`
  query MyAllChatGroup {
    me {
      id
      chats {
        ...ChatGroupInfo
      }
    }
  }
  ${ChatGroupInfoFragmentDoc}
`;

/**
 * __useMyAllChatGroupQuery__
 *
 * To run a query within a React component, call `useMyAllChatGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAllChatGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAllChatGroupQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyAllChatGroupQuery(
  baseOptions?: Apollo.QueryHookOptions<MyAllChatGroupQuery, MyAllChatGroupQueryVariables>
) {
  return Apollo.useQuery<MyAllChatGroupQuery, MyAllChatGroupQueryVariables>(
    MyAllChatGroupDocument,
    baseOptions
  );
}
export function useMyAllChatGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyAllChatGroupQuery, MyAllChatGroupQueryVariables>
) {
  return Apollo.useLazyQuery<MyAllChatGroupQuery, MyAllChatGroupQueryVariables>(
    MyAllChatGroupDocument,
    baseOptions
  );
}
export type MyAllChatGroupQueryHookResult = ReturnType<typeof useMyAllChatGroupQuery>;
export type MyAllChatGroupLazyQueryHookResult = ReturnType<typeof useMyAllChatGroupLazyQuery>;
export type MyAllChatGroupQueryResult = Apollo.QueryResult<
  MyAllChatGroupQuery,
  MyAllChatGroupQueryVariables
>;
export const MyGamePartyDocument = gql`
  query MyGameParty {
    me {
      id
      gameParty {
        id
        removed
        name
        members {
          id
        }
        chat {
          ...ChatGroupInfo
        }
      }
    }
  }
  ${ChatGroupInfoFragmentDoc}
`;

/**
 * __useMyGamePartyQuery__
 *
 * To run a query within a React component, call `useMyGamePartyQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyGamePartyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyGamePartyQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyGamePartyQuery(
  baseOptions?: Apollo.QueryHookOptions<MyGamePartyQuery, MyGamePartyQueryVariables>
) {
  return Apollo.useQuery<MyGamePartyQuery, MyGamePartyQueryVariables>(
    MyGamePartyDocument,
    baseOptions
  );
}
export function useMyGamePartyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyGamePartyQuery, MyGamePartyQueryVariables>
) {
  return Apollo.useLazyQuery<MyGamePartyQuery, MyGamePartyQueryVariables>(
    MyGamePartyDocument,
    baseOptions
  );
}
export type MyGamePartyQueryHookResult = ReturnType<typeof useMyGamePartyQuery>;
export type MyGamePartyLazyQueryHookResult = ReturnType<typeof useMyGamePartyLazyQuery>;
export type MyGamePartyQueryResult = Apollo.QueryResult<
  MyGamePartyQuery,
  MyGamePartyQueryVariables
>;
export const ChatDocument = gql`
  query Chat($id: ID!) {
    Chat(id: $id) {
      ...ChatGroupInfo
      members {
        id
        removed
        user {
          ...UserBasicInfos
        }
      }
    }
  }
  ${ChatGroupInfoFragmentDoc}
  ${UserBasicInfosFragmentDoc}
`;

/**
 * __useChatQuery__
 *
 * To run a query within a React component, call `useChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChatQuery(baseOptions: Apollo.QueryHookOptions<ChatQuery, ChatQueryVariables>) {
  return Apollo.useQuery<ChatQuery, ChatQueryVariables>(ChatDocument, baseOptions);
}
export function useChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChatQuery, ChatQueryVariables>
) {
  return Apollo.useLazyQuery<ChatQuery, ChatQueryVariables>(ChatDocument, baseOptions);
}
export type ChatQueryHookResult = ReturnType<typeof useChatQuery>;
export type ChatLazyQueryHookResult = ReturnType<typeof useChatLazyQuery>;
export type ChatQueryResult = Apollo.QueryResult<ChatQuery, ChatQueryVariables>;
export const AllCountriesDocument = gql`
  query AllCountries {
    allCountries {
      ...CountryInfos
    }
  }
  ${CountryInfosFragmentDoc}
`;

/**
 * __useAllCountriesQuery__
 *
 * To run a query within a React component, call `useAllCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCountriesQuery(
  baseOptions?: Apollo.QueryHookOptions<AllCountriesQuery, AllCountriesQueryVariables>
) {
  return Apollo.useQuery<AllCountriesQuery, AllCountriesQueryVariables>(
    AllCountriesDocument,
    baseOptions
  );
}
export function useAllCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllCountriesQuery, AllCountriesQueryVariables>
) {
  return Apollo.useLazyQuery<AllCountriesQuery, AllCountriesQueryVariables>(
    AllCountriesDocument,
    baseOptions
  );
}
export type AllCountriesQueryHookResult = ReturnType<typeof useAllCountriesQuery>;
export type AllCountriesLazyQueryHookResult = ReturnType<typeof useAllCountriesLazyQuery>;
export type AllCountriesQueryResult = Apollo.QueryResult<
  AllCountriesQuery,
  AllCountriesQueryVariables
>;
export const CreateMessageDocument = gql`
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      ...DiscordChannelMessageInfos
    }
  }
  ${DiscordChannelMessageInfosFragmentDoc}
`;
export type CreateMessageMutationFn = Apollo.MutationFunction<
  CreateMessageMutation,
  CreateMessageMutationVariables
>;

/**
 * __useCreateMessageMutation__
 *
 * To run a mutation, you first call `useCreateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageMutation, { data, loading, error }] = useCreateMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMessageMutation, CreateMessageMutationVariables>
) {
  return Apollo.useMutation<CreateMessageMutation, CreateMessageMutationVariables>(
    CreateMessageDocument,
    baseOptions
  );
}
export type CreateMessageMutationHookResult = ReturnType<typeof useCreateMessageMutation>;
export type CreateMessageMutationResult = Apollo.MutationResult<CreateMessageMutation>;
export type CreateMessageMutationOptions = Apollo.BaseMutationOptions<
  CreateMessageMutation,
  CreateMessageMutationVariables
>;
export const CreateMessageWithWebHookDocument = gql`
  mutation CreateMessageWithWebHook($input: CreateMessageWithWebHookInput!) {
    createMessageWithWebHook(input: $input) {
      ...DiscordChannelMessageInfos
    }
  }
  ${DiscordChannelMessageInfosFragmentDoc}
`;
export type CreateMessageWithWebHookMutationFn = Apollo.MutationFunction<
  CreateMessageWithWebHookMutation,
  CreateMessageWithWebHookMutationVariables
>;

/**
 * __useCreateMessageWithWebHookMutation__
 *
 * To run a mutation, you first call `useCreateMessageWithWebHookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageWithWebHookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageWithWebHookMutation, { data, loading, error }] = useCreateMessageWithWebHookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMessageWithWebHookMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMessageWithWebHookMutation,
    CreateMessageWithWebHookMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateMessageWithWebHookMutation,
    CreateMessageWithWebHookMutationVariables
  >(CreateMessageWithWebHookDocument, baseOptions);
}
export type CreateMessageWithWebHookMutationHookResult = ReturnType<
  typeof useCreateMessageWithWebHookMutation
>;
export type CreateMessageWithWebHookMutationResult =
  Apollo.MutationResult<CreateMessageWithWebHookMutation>;
export type CreateMessageWithWebHookMutationOptions = Apollo.BaseMutationOptions<
  CreateMessageWithWebHookMutation,
  CreateMessageWithWebHookMutationVariables
>;
export const CheckWebhookUserInChannelDocument = gql`
  mutation CheckWebhookUserInChannel($input: FindWebhookInput!) {
    checkWebhookUserInChannel(input: $input)
  }
`;
export type CheckWebhookUserInChannelMutationFn = Apollo.MutationFunction<
  CheckWebhookUserInChannelMutation,
  CheckWebhookUserInChannelMutationVariables
>;

/**
 * __useCheckWebhookUserInChannelMutation__
 *
 * To run a mutation, you first call `useCheckWebhookUserInChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckWebhookUserInChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkWebhookUserInChannelMutation, { data, loading, error }] = useCheckWebhookUserInChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckWebhookUserInChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckWebhookUserInChannelMutation,
    CheckWebhookUserInChannelMutationVariables
  >
) {
  return Apollo.useMutation<
    CheckWebhookUserInChannelMutation,
    CheckWebhookUserInChannelMutationVariables
  >(CheckWebhookUserInChannelDocument, baseOptions);
}
export type CheckWebhookUserInChannelMutationHookResult = ReturnType<
  typeof useCheckWebhookUserInChannelMutation
>;
export type CheckWebhookUserInChannelMutationResult =
  Apollo.MutationResult<CheckWebhookUserInChannelMutation>;
export type CheckWebhookUserInChannelMutationOptions = Apollo.BaseMutationOptions<
  CheckWebhookUserInChannelMutation,
  CheckWebhookUserInChannelMutationVariables
>;
export const CreateGuildRoleDocument = gql`
  mutation CreateGuildRole($input: CreateGuildRoleInput!) {
    createGuildRole(input: $input) {
      ...GuildRoleInfos
    }
  }
  ${GuildRoleInfosFragmentDoc}
`;
export type CreateGuildRoleMutationFn = Apollo.MutationFunction<
  CreateGuildRoleMutation,
  CreateGuildRoleMutationVariables
>;

/**
 * __useCreateGuildRoleMutation__
 *
 * To run a mutation, you first call `useCreateGuildRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGuildRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGuildRoleMutation, { data, loading, error }] = useCreateGuildRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGuildRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGuildRoleMutation,
    CreateGuildRoleMutationVariables
  >
) {
  return Apollo.useMutation<CreateGuildRoleMutation, CreateGuildRoleMutationVariables>(
    CreateGuildRoleDocument,
    baseOptions
  );
}
export type CreateGuildRoleMutationHookResult = ReturnType<typeof useCreateGuildRoleMutation>;
export type CreateGuildRoleMutationResult = Apollo.MutationResult<CreateGuildRoleMutation>;
export type CreateGuildRoleMutationOptions = Apollo.BaseMutationOptions<
  CreateGuildRoleMutation,
  CreateGuildRoleMutationVariables
>;
export const GetDiscordInfoDocument = gql`
  query GetDiscordInfo {
    onlineUsersCount
    totalDiscordMember
    totalDiscordMessage
  }
`;

/**
 * __useGetDiscordInfoQuery__
 *
 * To run a query within a React component, call `useGetDiscordInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscordInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscordInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDiscordInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<GetDiscordInfoQuery, GetDiscordInfoQueryVariables>
) {
  return Apollo.useQuery<GetDiscordInfoQuery, GetDiscordInfoQueryVariables>(
    GetDiscordInfoDocument,
    baseOptions
  );
}
export function useGetDiscordInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDiscordInfoQuery, GetDiscordInfoQueryVariables>
) {
  return Apollo.useLazyQuery<GetDiscordInfoQuery, GetDiscordInfoQueryVariables>(
    GetDiscordInfoDocument,
    baseOptions
  );
}
export type GetDiscordInfoQueryHookResult = ReturnType<typeof useGetDiscordInfoQuery>;
export type GetDiscordInfoLazyQueryHookResult = ReturnType<typeof useGetDiscordInfoLazyQuery>;
export type GetDiscordInfoQueryResult = Apollo.QueryResult<
  GetDiscordInfoQuery,
  GetDiscordInfoQueryVariables
>;
export const AllDiscordChannelGuildsDocument = gql`
  query AllDiscordChannelGuilds($params: JSON) {
    allDiscordChannelGuilds(params: $params) {
      ...DiscordChannelInfos
    }
  }
  ${DiscordChannelInfosFragmentDoc}
`;

/**
 * __useAllDiscordChannelGuildsQuery__
 *
 * To run a query within a React component, call `useAllDiscordChannelGuildsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDiscordChannelGuildsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDiscordChannelGuildsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useAllDiscordChannelGuildsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllDiscordChannelGuildsQuery,
    AllDiscordChannelGuildsQueryVariables
  >
) {
  return Apollo.useQuery<AllDiscordChannelGuildsQuery, AllDiscordChannelGuildsQueryVariables>(
    AllDiscordChannelGuildsDocument,
    baseOptions
  );
}
export function useAllDiscordChannelGuildsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllDiscordChannelGuildsQuery,
    AllDiscordChannelGuildsQueryVariables
  >
) {
  return Apollo.useLazyQuery<AllDiscordChannelGuildsQuery, AllDiscordChannelGuildsQueryVariables>(
    AllDiscordChannelGuildsDocument,
    baseOptions
  );
}
export type AllDiscordChannelGuildsQueryHookResult = ReturnType<
  typeof useAllDiscordChannelGuildsQuery
>;
export type AllDiscordChannelGuildsLazyQueryHookResult = ReturnType<
  typeof useAllDiscordChannelGuildsLazyQuery
>;
export type AllDiscordChannelGuildsQueryResult = Apollo.QueryResult<
  AllDiscordChannelGuildsQuery,
  AllDiscordChannelGuildsQueryVariables
>;
export const AllMessagesChannelDocument = gql`
  query AllMessagesChannel($channelId: String!, $params: ParamsMessageInput) {
    allMessagesChannel(channelId: $channelId, params: $params) {
      ...DiscordChannelMessageInfos
      author {
        ...DiscordAuthorMessageInfos
      }
    }
  }
  ${DiscordChannelMessageInfosFragmentDoc}
  ${DiscordAuthorMessageInfosFragmentDoc}
`;

/**
 * __useAllMessagesChannelQuery__
 *
 * To run a query within a React component, call `useAllMessagesChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMessagesChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMessagesChannelQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useAllMessagesChannelQuery(
  baseOptions: Apollo.QueryHookOptions<AllMessagesChannelQuery, AllMessagesChannelQueryVariables>
) {
  return Apollo.useQuery<AllMessagesChannelQuery, AllMessagesChannelQueryVariables>(
    AllMessagesChannelDocument,
    baseOptions
  );
}
export function useAllMessagesChannelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllMessagesChannelQuery,
    AllMessagesChannelQueryVariables
  >
) {
  return Apollo.useLazyQuery<AllMessagesChannelQuery, AllMessagesChannelQueryVariables>(
    AllMessagesChannelDocument,
    baseOptions
  );
}
export type AllMessagesChannelQueryHookResult = ReturnType<typeof useAllMessagesChannelQuery>;
export type AllMessagesChannelLazyQueryHookResult = ReturnType<
  typeof useAllMessagesChannelLazyQuery
>;
export type AllMessagesChannelQueryResult = Apollo.QueryResult<
  AllMessagesChannelQuery,
  AllMessagesChannelQueryVariables
>;
export const DiscordChannelDocument = gql`
  query DiscordChannel($channelId: String!) {
    discordChannel(channelId: $channelId) {
      ...DiscordChannelInfos
    }
  }
  ${DiscordChannelInfosFragmentDoc}
`;

/**
 * __useDiscordChannelQuery__
 *
 * To run a query within a React component, call `useDiscordChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscordChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscordChannelQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useDiscordChannelQuery(
  baseOptions: Apollo.QueryHookOptions<DiscordChannelQuery, DiscordChannelQueryVariables>
) {
  return Apollo.useQuery<DiscordChannelQuery, DiscordChannelQueryVariables>(
    DiscordChannelDocument,
    baseOptions
  );
}
export function useDiscordChannelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DiscordChannelQuery, DiscordChannelQueryVariables>
) {
  return Apollo.useLazyQuery<DiscordChannelQuery, DiscordChannelQueryVariables>(
    DiscordChannelDocument,
    baseOptions
  );
}
export type DiscordChannelQueryHookResult = ReturnType<typeof useDiscordChannelQuery>;
export type DiscordChannelLazyQueryHookResult = ReturnType<typeof useDiscordChannelLazyQuery>;
export type DiscordChannelQueryResult = Apollo.QueryResult<
  DiscordChannelQuery,
  DiscordChannelQueryVariables
>;
export const FindWebhookByUserAndChannelDocument = gql`
  query FindWebhookByUserAndChannel($input: FindWebhookInput!) {
    findWebhookByUserAndChannel(input: $input) {
      ...WebhookInfos
    }
  }
  ${WebhookInfosFragmentDoc}
`;

/**
 * __useFindWebhookByUserAndChannelQuery__
 *
 * To run a query within a React component, call `useFindWebhookByUserAndChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindWebhookByUserAndChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindWebhookByUserAndChannelQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindWebhookByUserAndChannelQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindWebhookByUserAndChannelQuery,
    FindWebhookByUserAndChannelQueryVariables
  >
) {
  return Apollo.useQuery<
    FindWebhookByUserAndChannelQuery,
    FindWebhookByUserAndChannelQueryVariables
  >(FindWebhookByUserAndChannelDocument, baseOptions);
}
export function useFindWebhookByUserAndChannelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindWebhookByUserAndChannelQuery,
    FindWebhookByUserAndChannelQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    FindWebhookByUserAndChannelQuery,
    FindWebhookByUserAndChannelQueryVariables
  >(FindWebhookByUserAndChannelDocument, baseOptions);
}
export type FindWebhookByUserAndChannelQueryHookResult = ReturnType<
  typeof useFindWebhookByUserAndChannelQuery
>;
export type FindWebhookByUserAndChannelLazyQueryHookResult = ReturnType<
  typeof useFindWebhookByUserAndChannelLazyQuery
>;
export type FindWebhookByUserAndChannelQueryResult = Apollo.QueryResult<
  FindWebhookByUserAndChannelQuery,
  FindWebhookByUserAndChannelQueryVariables
>;
export const AllGuildRoleDocument = gql`
  query AllGuildRole($input: FindGuildRoleInput) {
    allGuildRole(input: $input) {
      ...GuildRoleInfos
    }
  }
  ${GuildRoleInfosFragmentDoc}
`;

/**
 * __useAllGuildRoleQuery__
 *
 * To run a query within a React component, call `useAllGuildRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllGuildRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllGuildRoleQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAllGuildRoleQuery(
  baseOptions?: Apollo.QueryHookOptions<AllGuildRoleQuery, AllGuildRoleQueryVariables>
) {
  return Apollo.useQuery<AllGuildRoleQuery, AllGuildRoleQueryVariables>(
    AllGuildRoleDocument,
    baseOptions
  );
}
export function useAllGuildRoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllGuildRoleQuery, AllGuildRoleQueryVariables>
) {
  return Apollo.useLazyQuery<AllGuildRoleQuery, AllGuildRoleQueryVariables>(
    AllGuildRoleDocument,
    baseOptions
  );
}
export type AllGuildRoleQueryHookResult = ReturnType<typeof useAllGuildRoleQuery>;
export type AllGuildRoleLazyQueryHookResult = ReturnType<typeof useAllGuildRoleLazyQuery>;
export type AllGuildRoleQueryResult = Apollo.QueryResult<
  AllGuildRoleQuery,
  AllGuildRoleQueryVariables
>;
export const GuildRoleDocument = gql`
  query GuildRole($input: FindGuildRoleInput) {
    guildRole(input: $input) {
      ...GuildRoleInfos
    }
  }
  ${GuildRoleInfosFragmentDoc}
`;

/**
 * __useGuildRoleQuery__
 *
 * To run a query within a React component, call `useGuildRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuildRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuildRoleQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuildRoleQuery(
  baseOptions?: Apollo.QueryHookOptions<GuildRoleQuery, GuildRoleQueryVariables>
) {
  return Apollo.useQuery<GuildRoleQuery, GuildRoleQueryVariables>(GuildRoleDocument, baseOptions);
}
export function useGuildRoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GuildRoleQuery, GuildRoleQueryVariables>
) {
  return Apollo.useLazyQuery<GuildRoleQuery, GuildRoleQueryVariables>(
    GuildRoleDocument,
    baseOptions
  );
}
export type GuildRoleQueryHookResult = ReturnType<typeof useGuildRoleQuery>;
export type GuildRoleLazyQueryHookResult = ReturnType<typeof useGuildRoleLazyQuery>;
export type GuildRoleQueryResult = Apollo.QueryResult<GuildRoleQuery, GuildRoleQueryVariables>;
export const AddGameDocument = gql`
  mutation AddGame($input: AddGameInput!) {
    addGame(input: $input) {
      ...GameInfos
    }
  }
  ${GameInfosFragmentDoc}
`;
export type AddGameMutationFn = Apollo.MutationFunction<AddGameMutation, AddGameMutationVariables>;

/**
 * __useAddGameMutation__
 *
 * To run a mutation, you first call `useAddGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGameMutation, { data, loading, error }] = useAddGameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGameMutation(
  baseOptions?: Apollo.MutationHookOptions<AddGameMutation, AddGameMutationVariables>
) {
  return Apollo.useMutation<AddGameMutation, AddGameMutationVariables>(
    AddGameDocument,
    baseOptions
  );
}
export type AddGameMutationHookResult = ReturnType<typeof useAddGameMutation>;
export type AddGameMutationResult = Apollo.MutationResult<AddGameMutation>;
export type AddGameMutationOptions = Apollo.BaseMutationOptions<
  AddGameMutation,
  AddGameMutationVariables
>;
export const EditGameDocument = gql`
  mutation EditGame($input: EditGameInput!) {
    editGame(input: $input) {
      ...GameInfos
    }
  }
  ${GameInfosFragmentDoc}
`;
export type EditGameMutationFn = Apollo.MutationFunction<
  EditGameMutation,
  EditGameMutationVariables
>;

/**
 * __useEditGameMutation__
 *
 * To run a mutation, you first call `useEditGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGameMutation, { data, loading, error }] = useEditGameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditGameMutation(
  baseOptions?: Apollo.MutationHookOptions<EditGameMutation, EditGameMutationVariables>
) {
  return Apollo.useMutation<EditGameMutation, EditGameMutationVariables>(
    EditGameDocument,
    baseOptions
  );
}
export type EditGameMutationHookResult = ReturnType<typeof useEditGameMutation>;
export type EditGameMutationResult = Apollo.MutationResult<EditGameMutation>;
export type EditGameMutationOptions = Apollo.BaseMutationOptions<
  EditGameMutation,
  EditGameMutationVariables
>;
export const AddGameToFavoriteDocument = gql`
  mutation AddGameToFavorite($gameId: ID!) {
    addGameToMyFavorite(gameId: $gameId) {
      id
      isInCurrentUserFavorite
    }
  }
`;
export type AddGameToFavoriteMutationFn = Apollo.MutationFunction<
  AddGameToFavoriteMutation,
  AddGameToFavoriteMutationVariables
>;

/**
 * __useAddGameToFavoriteMutation__
 *
 * To run a mutation, you first call `useAddGameToFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGameToFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGameToFavoriteMutation, { data, loading, error }] = useAddGameToFavoriteMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useAddGameToFavoriteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGameToFavoriteMutation,
    AddGameToFavoriteMutationVariables
  >
) {
  return Apollo.useMutation<AddGameToFavoriteMutation, AddGameToFavoriteMutationVariables>(
    AddGameToFavoriteDocument,
    baseOptions
  );
}
export type AddGameToFavoriteMutationHookResult = ReturnType<typeof useAddGameToFavoriteMutation>;
export type AddGameToFavoriteMutationResult = Apollo.MutationResult<AddGameToFavoriteMutation>;
export type AddGameToFavoriteMutationOptions = Apollo.BaseMutationOptions<
  AddGameToFavoriteMutation,
  AddGameToFavoriteMutationVariables
>;
export const RemoveGameToFavoriteDocument = gql`
  mutation RemoveGameToFavorite($gameId: ID!) {
    removeGameFromMyFavorite(gameId: $gameId) {
      id
      isInCurrentUserFavorite
    }
  }
`;
export type RemoveGameToFavoriteMutationFn = Apollo.MutationFunction<
  RemoveGameToFavoriteMutation,
  RemoveGameToFavoriteMutationVariables
>;

/**
 * __useRemoveGameToFavoriteMutation__
 *
 * To run a mutation, you first call `useRemoveGameToFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGameToFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGameToFavoriteMutation, { data, loading, error }] = useRemoveGameToFavoriteMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useRemoveGameToFavoriteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveGameToFavoriteMutation,
    RemoveGameToFavoriteMutationVariables
  >
) {
  return Apollo.useMutation<RemoveGameToFavoriteMutation, RemoveGameToFavoriteMutationVariables>(
    RemoveGameToFavoriteDocument,
    baseOptions
  );
}
export type RemoveGameToFavoriteMutationHookResult = ReturnType<
  typeof useRemoveGameToFavoriteMutation
>;
export type RemoveGameToFavoriteMutationResult =
  Apollo.MutationResult<RemoveGameToFavoriteMutation>;
export type RemoveGameToFavoriteMutationOptions = Apollo.BaseMutationOptions<
  RemoveGameToFavoriteMutation,
  RemoveGameToFavoriteMutationVariables
>;
export const GetGamesDocument = gql`
  query GetGames($filter: GamesFilterInput!) {
    me {
      id
      _id
      games(filter: $filter) {
        results {
          ...GameInfos
        }
        total
      }
    }
  }
  ${GameInfosFragmentDoc}
`;

/**
 * __useGetGamesQuery__
 *
 * To run a query within a React component, call `useGetGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGamesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetGamesQuery(
  baseOptions: Apollo.QueryHookOptions<GetGamesQuery, GetGamesQueryVariables>
) {
  return Apollo.useQuery<GetGamesQuery, GetGamesQueryVariables>(GetGamesDocument, baseOptions);
}
export function useGetGamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGamesQuery, GetGamesQueryVariables>
) {
  return Apollo.useLazyQuery<GetGamesQuery, GetGamesQueryVariables>(GetGamesDocument, baseOptions);
}
export type GetGamesQueryHookResult = ReturnType<typeof useGetGamesQuery>;
export type GetGamesLazyQueryHookResult = ReturnType<typeof useGetGamesLazyQuery>;
export type GetGamesQueryResult = Apollo.QueryResult<GetGamesQuery, GetGamesQueryVariables>;
export const AllGamesDocument = gql`
  query AllGames {
    allGames {
      ...GameInfos
    }
  }
  ${GameInfosFragmentDoc}
`;

/**
 * __useAllGamesQuery__
 *
 * To run a query within a React component, call `useAllGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllGamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllGamesQuery(
  baseOptions?: Apollo.QueryHookOptions<AllGamesQuery, AllGamesQueryVariables>
) {
  return Apollo.useQuery<AllGamesQuery, AllGamesQueryVariables>(AllGamesDocument, baseOptions);
}
export function useAllGamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllGamesQuery, AllGamesQueryVariables>
) {
  return Apollo.useLazyQuery<AllGamesQuery, AllGamesQueryVariables>(AllGamesDocument, baseOptions);
}
export type AllGamesQueryHookResult = ReturnType<typeof useAllGamesQuery>;
export type AllGamesLazyQueryHookResult = ReturnType<typeof useAllGamesLazyQuery>;
export type AllGamesQueryResult = Apollo.QueryResult<AllGamesQuery, AllGamesQueryVariables>;
export const AllGamePlatformsDocument = gql`
  query AllGamePlatforms {
    allGamePlatforms {
      id
      logo
      name
    }
  }
`;

/**
 * __useAllGamePlatformsQuery__
 *
 * To run a query within a React component, call `useAllGamePlatformsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllGamePlatformsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllGamePlatformsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllGamePlatformsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllGamePlatformsQuery, AllGamePlatformsQueryVariables>
) {
  return Apollo.useQuery<AllGamePlatformsQuery, AllGamePlatformsQueryVariables>(
    AllGamePlatformsDocument,
    baseOptions
  );
}
export function useAllGamePlatformsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllGamePlatformsQuery, AllGamePlatformsQueryVariables>
) {
  return Apollo.useLazyQuery<AllGamePlatformsQuery, AllGamePlatformsQueryVariables>(
    AllGamePlatformsDocument,
    baseOptions
  );
}
export type AllGamePlatformsQueryHookResult = ReturnType<typeof useAllGamePlatformsQuery>;
export type AllGamePlatformsLazyQueryHookResult = ReturnType<typeof useAllGamePlatformsLazyQuery>;
export type AllGamePlatformsQueryResult = Apollo.QueryResult<
  AllGamePlatformsQuery,
  AllGamePlatformsQueryVariables
>;
export const AllGameModesDocument = gql`
  query AllGameModes {
    allGamemodes {
      id
      title
      teamSize
      title_en
      type
    }
  }
`;

/**
 * __useAllGameModesQuery__
 *
 * To run a query within a React component, call `useAllGameModesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllGameModesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllGameModesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllGameModesQuery(
  baseOptions?: Apollo.QueryHookOptions<AllGameModesQuery, AllGameModesQueryVariables>
) {
  return Apollo.useQuery<AllGameModesQuery, AllGameModesQueryVariables>(
    AllGameModesDocument,
    baseOptions
  );
}
export function useAllGameModesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllGameModesQuery, AllGameModesQueryVariables>
) {
  return Apollo.useLazyQuery<AllGameModesQuery, AllGameModesQueryVariables>(
    AllGameModesDocument,
    baseOptions
  );
}
export type AllGameModesQueryHookResult = ReturnType<typeof useAllGameModesQuery>;
export type AllGameModesLazyQueryHookResult = ReturnType<typeof useAllGameModesLazyQuery>;
export type AllGameModesQueryResult = Apollo.QueryResult<
  AllGameModesQuery,
  AllGameModesQueryVariables
>;
export const AllRegionsDocument = gql`
  query AllRegions {
    allRegions {
      id
      name
    }
  }
`;

/**
 * __useAllRegionsQuery__
 *
 * To run a query within a React component, call `useAllRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllRegionsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllRegionsQuery, AllRegionsQueryVariables>
) {
  return Apollo.useQuery<AllRegionsQuery, AllRegionsQueryVariables>(
    AllRegionsDocument,
    baseOptions
  );
}
export function useAllRegionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllRegionsQuery, AllRegionsQueryVariables>
) {
  return Apollo.useLazyQuery<AllRegionsQuery, AllRegionsQueryVariables>(
    AllRegionsDocument,
    baseOptions
  );
}
export type AllRegionsQueryHookResult = ReturnType<typeof useAllRegionsQuery>;
export type AllRegionsLazyQueryHookResult = ReturnType<typeof useAllRegionsLazyQuery>;
export type AllRegionsQueryResult = Apollo.QueryResult<AllRegionsQuery, AllRegionsQueryVariables>;
export const AllGameServersDocument = gql`
  query AllGameServers {
    allGameservers {
      id
      name
      country {
        id
        name
        code
      }
    }
  }
`;

/**
 * __useAllGameServersQuery__
 *
 * To run a query within a React component, call `useAllGameServersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllGameServersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllGameServersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllGameServersQuery(
  baseOptions?: Apollo.QueryHookOptions<AllGameServersQuery, AllGameServersQueryVariables>
) {
  return Apollo.useQuery<AllGameServersQuery, AllGameServersQueryVariables>(
    AllGameServersDocument,
    baseOptions
  );
}
export function useAllGameServersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllGameServersQuery, AllGameServersQueryVariables>
) {
  return Apollo.useLazyQuery<AllGameServersQuery, AllGameServersQueryVariables>(
    AllGameServersDocument,
    baseOptions
  );
}
export type AllGameServersQueryHookResult = ReturnType<typeof useAllGameServersQuery>;
export type AllGameServersLazyQueryHookResult = ReturnType<typeof useAllGameServersLazyQuery>;
export type AllGameServersQueryResult = Apollo.QueryResult<
  AllGameServersQuery,
  AllGameServersQueryVariables
>;
export const AllAudioChatsDocument = gql`
  query AllAudioChats {
    allAudiochatplateforms {
      id
      name
    }
  }
`;

/**
 * __useAllAudioChatsQuery__
 *
 * To run a query within a React component, call `useAllAudioChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAudioChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAudioChatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllAudioChatsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllAudioChatsQuery, AllAudioChatsQueryVariables>
) {
  return Apollo.useQuery<AllAudioChatsQuery, AllAudioChatsQueryVariables>(
    AllAudioChatsDocument,
    baseOptions
  );
}
export function useAllAudioChatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllAudioChatsQuery, AllAudioChatsQueryVariables>
) {
  return Apollo.useLazyQuery<AllAudioChatsQuery, AllAudioChatsQueryVariables>(
    AllAudioChatsDocument,
    baseOptions
  );
}
export type AllAudioChatsQueryHookResult = ReturnType<typeof useAllAudioChatsQuery>;
export type AllAudioChatsLazyQueryHookResult = ReturnType<typeof useAllAudioChatsLazyQuery>;
export type AllAudioChatsQueryResult = Apollo.QueryResult<
  AllAudioChatsQuery,
  AllAudioChatsQueryVariables
>;
export const AllGamesLogoDocument = gql`
  query AllGamesLogo {
    allGames {
      id
      logo
      coverImage
    }
  }
`;

/**
 * __useAllGamesLogoQuery__
 *
 * To run a query within a React component, call `useAllGamesLogoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllGamesLogoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllGamesLogoQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllGamesLogoQuery(
  baseOptions?: Apollo.QueryHookOptions<AllGamesLogoQuery, AllGamesLogoQueryVariables>
) {
  return Apollo.useQuery<AllGamesLogoQuery, AllGamesLogoQueryVariables>(
    AllGamesLogoDocument,
    baseOptions
  );
}
export function useAllGamesLogoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllGamesLogoQuery, AllGamesLogoQueryVariables>
) {
  return Apollo.useLazyQuery<AllGamesLogoQuery, AllGamesLogoQueryVariables>(
    AllGamesLogoDocument,
    baseOptions
  );
}
export type AllGamesLogoQueryHookResult = ReturnType<typeof useAllGamesLogoQuery>;
export type AllGamesLogoLazyQueryHookResult = ReturnType<typeof useAllGamesLogoLazyQuery>;
export type AllGamesLogoQueryResult = Apollo.QueryResult<
  AllGamesLogoQuery,
  AllGamesLogoQueryVariables
>;
export const GetGameDocument = gql`
  query GetGame($id: ID!) {
    Game(id: $id) {
      ...GameInfos
    }
  }
  ${GameInfosFragmentDoc}
`;

/**
 * __useGetGameQuery__
 *
 * To run a query within a React component, call `useGetGameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGameQuery(
  baseOptions: Apollo.QueryHookOptions<GetGameQuery, GetGameQueryVariables>
) {
  return Apollo.useQuery<GetGameQuery, GetGameQueryVariables>(GetGameDocument, baseOptions);
}
export function useGetGameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGameQuery, GetGameQueryVariables>
) {
  return Apollo.useLazyQuery<GetGameQuery, GetGameQueryVariables>(GetGameDocument, baseOptions);
}
export type GetGameQueryHookResult = ReturnType<typeof useGetGameQuery>;
export type GetGameLazyQueryHookResult = ReturnType<typeof useGetGameLazyQuery>;
export type GetGameQueryResult = Apollo.QueryResult<GetGameQuery, GetGameQueryVariables>;
export const GetGamesByTwitchIdDocument = gql`
  query GetGamesByTwitchId {
    allGames {
      id
      name
      twitchGameId
      twitchGameName
    }
  }
`;

/**
 * __useGetGamesByTwitchIdQuery__
 *
 * To run a query within a React component, call `useGetGamesByTwitchIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGamesByTwitchIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGamesByTwitchIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGamesByTwitchIdQuery(
  baseOptions?: Apollo.QueryHookOptions<GetGamesByTwitchIdQuery, GetGamesByTwitchIdQueryVariables>
) {
  return Apollo.useQuery<GetGamesByTwitchIdQuery, GetGamesByTwitchIdQueryVariables>(
    GetGamesByTwitchIdDocument,
    baseOptions
  );
}
export function useGetGamesByTwitchIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGamesByTwitchIdQuery,
    GetGamesByTwitchIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetGamesByTwitchIdQuery, GetGamesByTwitchIdQueryVariables>(
    GetGamesByTwitchIdDocument,
    baseOptions
  );
}
export type GetGamesByTwitchIdQueryHookResult = ReturnType<typeof useGetGamesByTwitchIdQuery>;
export type GetGamesByTwitchIdLazyQueryHookResult = ReturnType<
  typeof useGetGamesByTwitchIdLazyQuery
>;
export type GetGamesByTwitchIdQueryResult = Apollo.QueryResult<
  GetGamesByTwitchIdQuery,
  GetGamesByTwitchIdQueryVariables
>;
export const GetGameAllDetailsDocument = gql`
  query GetGameAllDetails($id: ID!) {
    Game(id: $id) {
      ...GameInfos
      leagues {
        id
        description
        startedAt
        title
        gameId
        awards {
          id
          value
        }
        gamePlateform {
          id
          logo
        }
      }
      tournaments {
        id
        description
        startedAt
        title
        rewardsTotal
        gamePlateform {
          id
          logo
        }
      }
      wagers {
        id
        startedAt
        title
        cashPrice
      }
    }
  }
  ${GameInfosFragmentDoc}
`;

/**
 * __useGetGameAllDetailsQuery__
 *
 * To run a query within a React component, call `useGetGameAllDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameAllDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameAllDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGameAllDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetGameAllDetailsQuery, GetGameAllDetailsQueryVariables>
) {
  return Apollo.useQuery<GetGameAllDetailsQuery, GetGameAllDetailsQueryVariables>(
    GetGameAllDetailsDocument,
    baseOptions
  );
}
export function useGetGameAllDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGameAllDetailsQuery, GetGameAllDetailsQueryVariables>
) {
  return Apollo.useLazyQuery<GetGameAllDetailsQuery, GetGameAllDetailsQueryVariables>(
    GetGameAllDetailsDocument,
    baseOptions
  );
}
export type GetGameAllDetailsQueryHookResult = ReturnType<typeof useGetGameAllDetailsQuery>;
export type GetGameAllDetailsLazyQueryHookResult = ReturnType<typeof useGetGameAllDetailsLazyQuery>;
export type GetGameAllDetailsQueryResult = Apollo.QueryResult<
  GetGameAllDetailsQuery,
  GetGameAllDetailsQueryVariables
>;
export const GetGameTournamentsDocument = gql`
  query GetGameTournaments($id: ID!) {
    Game(id: $id) {
      ...GameInfos
      tournaments {
        id
        title
        isActive
        startedAt
        createdAt
        entryCost
        rewardsTotal
        format {
          title
        }
        description
        awards {
          value
        }
        gamePlateform {
          id
          name
          logo
        }
        gamemode {
          id
          title
          teamSize
          type
        }
        maxParticipants
        participants {
          id
        }
        game {
          id
          name
          mainJacket
          logo
          twitchGameId
        }
        isMeAlreadyParticipant
      }
    }
  }
  ${GameInfosFragmentDoc}
`;

/**
 * __useGetGameTournamentsQuery__
 *
 * To run a query within a React component, call `useGetGameTournamentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameTournamentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameTournamentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGameTournamentsQuery(
  baseOptions: Apollo.QueryHookOptions<GetGameTournamentsQuery, GetGameTournamentsQueryVariables>
) {
  return Apollo.useQuery<GetGameTournamentsQuery, GetGameTournamentsQueryVariables>(
    GetGameTournamentsDocument,
    baseOptions
  );
}
export function useGetGameTournamentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGameTournamentsQuery,
    GetGameTournamentsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetGameTournamentsQuery, GetGameTournamentsQueryVariables>(
    GetGameTournamentsDocument,
    baseOptions
  );
}
export type GetGameTournamentsQueryHookResult = ReturnType<typeof useGetGameTournamentsQuery>;
export type GetGameTournamentsLazyQueryHookResult = ReturnType<
  typeof useGetGameTournamentsLazyQuery
>;
export type GetGameTournamentsQueryResult = Apollo.QueryResult<
  GetGameTournamentsQuery,
  GetGameTournamentsQueryVariables
>;
export const GetGameLeaguesDocument = gql`
  query GetGameLeagues($id: ID!) {
    Game(id: $id) {
      ...GameInfos
      leagues {
        id
        title
        description
        startedAt
        format
        maxParticipants
        minParticipants
        isActive
        organiser {
          id
          name
        }
        entryCost
        isMeAlreadyParticipant
        participants {
          id
        }
      }
    }
  }
  ${GameInfosFragmentDoc}
`;

/**
 * __useGetGameLeaguesQuery__
 *
 * To run a query within a React component, call `useGetGameLeaguesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameLeaguesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameLeaguesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGameLeaguesQuery(
  baseOptions: Apollo.QueryHookOptions<GetGameLeaguesQuery, GetGameLeaguesQueryVariables>
) {
  return Apollo.useQuery<GetGameLeaguesQuery, GetGameLeaguesQueryVariables>(
    GetGameLeaguesDocument,
    baseOptions
  );
}
export function useGetGameLeaguesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGameLeaguesQuery, GetGameLeaguesQueryVariables>
) {
  return Apollo.useLazyQuery<GetGameLeaguesQuery, GetGameLeaguesQueryVariables>(
    GetGameLeaguesDocument,
    baseOptions
  );
}
export type GetGameLeaguesQueryHookResult = ReturnType<typeof useGetGameLeaguesQuery>;
export type GetGameLeaguesLazyQueryHookResult = ReturnType<typeof useGetGameLeaguesLazyQuery>;
export type GetGameLeaguesQueryResult = Apollo.QueryResult<
  GetGameLeaguesQuery,
  GetGameLeaguesQueryVariables
>;
export const GetGameWagersDocument = gql`
  query GetGameWagers($id: ID!) {
    Game(id: $id) {
      ...GameInfos
      wagers {
        id
        isMeAlreadyParticipant
        participants {
          id
        }
        cashPrice
        format
        isActive
        participantNumber
        startedAt
        title
        createdAt
        rule
        entryCost
        entryType
        gameformat {
          id
          name
        }
        gamemode {
          id
          title
          teamSize
          type
        }
        gameserver {
          id
          name
        }
      }
    }
  }
  ${GameInfosFragmentDoc}
`;

/**
 * __useGetGameWagersQuery__
 *
 * To run a query within a React component, call `useGetGameWagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameWagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameWagersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGameWagersQuery(
  baseOptions: Apollo.QueryHookOptions<GetGameWagersQuery, GetGameWagersQueryVariables>
) {
  return Apollo.useQuery<GetGameWagersQuery, GetGameWagersQueryVariables>(
    GetGameWagersDocument,
    baseOptions
  );
}
export function useGetGameWagersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGameWagersQuery, GetGameWagersQueryVariables>
) {
  return Apollo.useLazyQuery<GetGameWagersQuery, GetGameWagersQueryVariables>(
    GetGameWagersDocument,
    baseOptions
  );
}
export type GetGameWagersQueryHookResult = ReturnType<typeof useGetGameWagersQuery>;
export type GetGameWagersLazyQueryHookResult = ReturnType<typeof useGetGameWagersLazyQuery>;
export type GetGameWagersQueryResult = Apollo.QueryResult<
  GetGameWagersQuery,
  GetGameWagersQueryVariables
>;
export const SubscribeToGameAddedDocument = gql`
  subscription SubscribeToGameAdded {
    gameAdded {
      ...GameInfos
    }
  }
  ${GameInfosFragmentDoc}
`;

/**
 * __useSubscribeToGameAddedSubscription__
 *
 * To run a query within a React component, call `useSubscribeToGameAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToGameAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToGameAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSubscribeToGameAddedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToGameAddedSubscription,
    SubscribeToGameAddedSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToGameAddedSubscription,
    SubscribeToGameAddedSubscriptionVariables
  >(SubscribeToGameAddedDocument, baseOptions);
}
export type SubscribeToGameAddedSubscriptionHookResult = ReturnType<
  typeof useSubscribeToGameAddedSubscription
>;
export type SubscribeToGameAddedSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToGameAddedSubscription>;
export const CreateGameAccountDocument = gql`
  mutation CreateGameAccount($name: String!, $logo: String!, $label: String) {
    createGameAccount(name: $name, logo: $logo, label: $label) {
      ...GameAccountInfos
    }
  }
  ${GameAccountInfosFragmentDoc}
`;
export type CreateGameAccountMutationFn = Apollo.MutationFunction<
  CreateGameAccountMutation,
  CreateGameAccountMutationVariables
>;

/**
 * __useCreateGameAccountMutation__
 *
 * To run a mutation, you first call `useCreateGameAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGameAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGameAccountMutation, { data, loading, error }] = useCreateGameAccountMutation({
 *   variables: {
 *      name: // value for 'name'
 *      logo: // value for 'logo'
 *      label: // value for 'label'
 *   },
 * });
 */
export function useCreateGameAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGameAccountMutation,
    CreateGameAccountMutationVariables
  >
) {
  return Apollo.useMutation<CreateGameAccountMutation, CreateGameAccountMutationVariables>(
    CreateGameAccountDocument,
    baseOptions
  );
}
export type CreateGameAccountMutationHookResult = ReturnType<typeof useCreateGameAccountMutation>;
export type CreateGameAccountMutationResult = Apollo.MutationResult<CreateGameAccountMutation>;
export type CreateGameAccountMutationOptions = Apollo.BaseMutationOptions<
  CreateGameAccountMutation,
  CreateGameAccountMutationVariables
>;
export const LinkAGameAccountDocument = gql`
  mutation LinkAGameAccount(
    $accountId: String
    $username: String
    $gamertag: String
    $gameAccountId: ID!
  ) {
    linkAGameAccount(
      accountId: $accountId
      username: $username
      gamertag: $gamertag
      gameAccountId: $gameAccountId
    ) {
      id
      gameAccount {
        ...GameAccountInfos
      }
      accountId
    }
  }
  ${GameAccountInfosFragmentDoc}
`;
export type LinkAGameAccountMutationFn = Apollo.MutationFunction<
  LinkAGameAccountMutation,
  LinkAGameAccountMutationVariables
>;

/**
 * __useLinkAGameAccountMutation__
 *
 * To run a mutation, you first call `useLinkAGameAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkAGameAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkAGameAccountMutation, { data, loading, error }] = useLinkAGameAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      username: // value for 'username'
 *      gamertag: // value for 'gamertag'
 *      gameAccountId: // value for 'gameAccountId'
 *   },
 * });
 */
export function useLinkAGameAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkAGameAccountMutation,
    LinkAGameAccountMutationVariables
  >
) {
  return Apollo.useMutation<LinkAGameAccountMutation, LinkAGameAccountMutationVariables>(
    LinkAGameAccountDocument,
    baseOptions
  );
}
export type LinkAGameAccountMutationHookResult = ReturnType<typeof useLinkAGameAccountMutation>;
export type LinkAGameAccountMutationResult = Apollo.MutationResult<LinkAGameAccountMutation>;
export type LinkAGameAccountMutationOptions = Apollo.BaseMutationOptions<
  LinkAGameAccountMutation,
  LinkAGameAccountMutationVariables
>;
export const GetGameAccountsDocument = gql`
  query GetGameAccounts {
    me {
      id
      gameAccounts {
        ...UserGameAccountPayloadInfos
      }
    }
  }
  ${UserGameAccountPayloadInfosFragmentDoc}
`;

/**
 * __useGetGameAccountsQuery__
 *
 * To run a query within a React component, call `useGetGameAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGameAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetGameAccountsQuery, GetGameAccountsQueryVariables>
) {
  return Apollo.useQuery<GetGameAccountsQuery, GetGameAccountsQueryVariables>(
    GetGameAccountsDocument,
    baseOptions
  );
}
export function useGetGameAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGameAccountsQuery, GetGameAccountsQueryVariables>
) {
  return Apollo.useLazyQuery<GetGameAccountsQuery, GetGameAccountsQueryVariables>(
    GetGameAccountsDocument,
    baseOptions
  );
}
export type GetGameAccountsQueryHookResult = ReturnType<typeof useGetGameAccountsQuery>;
export type GetGameAccountsLazyQueryHookResult = ReturnType<typeof useGetGameAccountsLazyQuery>;
export type GetGameAccountsQueryResult = Apollo.QueryResult<
  GetGameAccountsQuery,
  GetGameAccountsQueryVariables
>;
export const GetGameByGameAccountsDocument = gql`
  query GetGameByGameAccounts {
    me {
      id
      gameAccounts {
        id
        gameAccount {
          ...GameAccountInfos
          games {
            ...GameInfos
          }
        }
        userGameAccount {
          id
          gamertag
          username
          accountId
        }
      }
    }
  }
  ${GameAccountInfosFragmentDoc}
  ${GameInfosFragmentDoc}
`;

/**
 * __useGetGameByGameAccountsQuery__
 *
 * To run a query within a React component, call `useGetGameByGameAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameByGameAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameByGameAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGameByGameAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGameByGameAccountsQuery,
    GetGameByGameAccountsQueryVariables
  >
) {
  return Apollo.useQuery<GetGameByGameAccountsQuery, GetGameByGameAccountsQueryVariables>(
    GetGameByGameAccountsDocument,
    baseOptions
  );
}
export function useGetGameByGameAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGameByGameAccountsQuery,
    GetGameByGameAccountsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetGameByGameAccountsQuery, GetGameByGameAccountsQueryVariables>(
    GetGameByGameAccountsDocument,
    baseOptions
  );
}
export type GetGameByGameAccountsQueryHookResult = ReturnType<typeof useGetGameByGameAccountsQuery>;
export type GetGameByGameAccountsLazyQueryHookResult = ReturnType<
  typeof useGetGameByGameAccountsLazyQuery
>;
export type GetGameByGameAccountsQueryResult = Apollo.QueryResult<
  GetGameByGameAccountsQuery,
  GetGameByGameAccountsQueryVariables
>;
export const GetGamePlatformsDocument = gql`
  query GetGamePlatforms {
    gamePlatforms {
      ...GamePlatformInfos
    }
  }
  ${GamePlatformInfosFragmentDoc}
`;

/**
 * __useGetGamePlatformsQuery__
 *
 * To run a query within a React component, call `useGetGamePlatformsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGamePlatformsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGamePlatformsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGamePlatformsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetGamePlatformsQuery, GetGamePlatformsQueryVariables>
) {
  return Apollo.useQuery<GetGamePlatformsQuery, GetGamePlatformsQueryVariables>(
    GetGamePlatformsDocument,
    baseOptions
  );
}
export function useGetGamePlatformsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGamePlatformsQuery, GetGamePlatformsQueryVariables>
) {
  return Apollo.useLazyQuery<GetGamePlatformsQuery, GetGamePlatformsQueryVariables>(
    GetGamePlatformsDocument,
    baseOptions
  );
}
export type GetGamePlatformsQueryHookResult = ReturnType<typeof useGetGamePlatformsQuery>;
export type GetGamePlatformsLazyQueryHookResult = ReturnType<typeof useGetGamePlatformsLazyQuery>;
export type GetGamePlatformsQueryResult = Apollo.QueryResult<
  GetGamePlatformsQuery,
  GetGamePlatformsQueryVariables
>;
export const MyCodModernWarfareStatDocument = gql`
  query MyCodModernWarfareStat($platform: String, $userId: ID) {
    myCodModernWarefareStat(platform: $platform, userId: $userId) {
      ...RapidapiCodModernWarfareStatInfo
    }
  }
  ${RapidapiCodModernWarfareStatInfoFragmentDoc}
`;

/**
 * __useMyCodModernWarfareStatQuery__
 *
 * To run a query within a React component, call `useMyCodModernWarfareStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCodModernWarfareStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCodModernWarfareStatQuery({
 *   variables: {
 *      platform: // value for 'platform'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMyCodModernWarfareStatQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyCodModernWarfareStatQuery,
    MyCodModernWarfareStatQueryVariables
  >
) {
  return Apollo.useQuery<MyCodModernWarfareStatQuery, MyCodModernWarfareStatQueryVariables>(
    MyCodModernWarfareStatDocument,
    baseOptions
  );
}
export function useMyCodModernWarfareStatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyCodModernWarfareStatQuery,
    MyCodModernWarfareStatQueryVariables
  >
) {
  return Apollo.useLazyQuery<MyCodModernWarfareStatQuery, MyCodModernWarfareStatQueryVariables>(
    MyCodModernWarfareStatDocument,
    baseOptions
  );
}
export type MyCodModernWarfareStatQueryHookResult = ReturnType<
  typeof useMyCodModernWarfareStatQuery
>;
export type MyCodModernWarfareStatLazyQueryHookResult = ReturnType<
  typeof useMyCodModernWarfareStatLazyQuery
>;
export type MyCodModernWarfareStatQueryResult = Apollo.QueryResult<
  MyCodModernWarfareStatQuery,
  MyCodModernWarfareStatQueryVariables
>;
export const MyBo4StatDocument = gql`
  query MyBO4Stat($platform: String, $userId: ID) {
    myBO4Stat(plateform: $platform, userId: $userId)
  }
`;

/**
 * __useMyBo4StatQuery__
 *
 * To run a query within a React component, call `useMyBo4StatQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyBo4StatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyBo4StatQuery({
 *   variables: {
 *      platform: // value for 'platform'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMyBo4StatQuery(
  baseOptions?: Apollo.QueryHookOptions<MyBo4StatQuery, MyBo4StatQueryVariables>
) {
  return Apollo.useQuery<MyBo4StatQuery, MyBo4StatQueryVariables>(MyBo4StatDocument, baseOptions);
}
export function useMyBo4StatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyBo4StatQuery, MyBo4StatQueryVariables>
) {
  return Apollo.useLazyQuery<MyBo4StatQuery, MyBo4StatQueryVariables>(
    MyBo4StatDocument,
    baseOptions
  );
}
export type MyBo4StatQueryHookResult = ReturnType<typeof useMyBo4StatQuery>;
export type MyBo4StatLazyQueryHookResult = ReturnType<typeof useMyBo4StatLazyQuery>;
export type MyBo4StatQueryResult = Apollo.QueryResult<MyBo4StatQuery, MyBo4StatQueryVariables>;
export const WzstatsDotGgDocument = gql`
  query WzstatsDotGG($platform: String, $userId: ID) {
    wzstatsDotGG(plateform: $platform, userId: $userId) {
      ...WzstatsDotGGInfo
    }
  }
  ${WzstatsDotGgInfoFragmentDoc}
`;

/**
 * __useWzstatsDotGgQuery__
 *
 * To run a query within a React component, call `useWzstatsDotGgQuery` and pass it any options that fit your needs.
 * When your component renders, `useWzstatsDotGgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWzstatsDotGgQuery({
 *   variables: {
 *      platform: // value for 'platform'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useWzstatsDotGgQuery(
  baseOptions?: Apollo.QueryHookOptions<WzstatsDotGgQuery, WzstatsDotGgQueryVariables>
) {
  return Apollo.useQuery<WzstatsDotGgQuery, WzstatsDotGgQueryVariables>(
    WzstatsDotGgDocument,
    baseOptions
  );
}
export function useWzstatsDotGgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WzstatsDotGgQuery, WzstatsDotGgQueryVariables>
) {
  return Apollo.useLazyQuery<WzstatsDotGgQuery, WzstatsDotGgQueryVariables>(
    WzstatsDotGgDocument,
    baseOptions
  );
}
export type WzstatsDotGgQueryHookResult = ReturnType<typeof useWzstatsDotGgQuery>;
export type WzstatsDotGgLazyQueryHookResult = ReturnType<typeof useWzstatsDotGgLazyQuery>;
export type WzstatsDotGgQueryResult = Apollo.QueryResult<
  WzstatsDotGgQuery,
  WzstatsDotGgQueryVariables
>;
export const ModernWarefarecodTrackerDotGgStatsDocument = gql`
  query ModernWarefarecodTrackerDotGGStats($username: String, $platform: String, $userId: ID) {
    modernWarefarecodTrackerDotGGStats(username: $username, plateform: $platform, userId: $userId) {
      ...CODTrackerDotGGStatsInfo
    }
  }
  ${CodTrackerDotGgStatsInfoFragmentDoc}
`;

/**
 * __useModernWarefarecodTrackerDotGgStatsQuery__
 *
 * To run a query within a React component, call `useModernWarefarecodTrackerDotGgStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModernWarefarecodTrackerDotGgStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModernWarefarecodTrackerDotGgStatsQuery({
 *   variables: {
 *      username: // value for 'username'
 *      platform: // value for 'platform'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useModernWarefarecodTrackerDotGgStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ModernWarefarecodTrackerDotGgStatsQuery,
    ModernWarefarecodTrackerDotGgStatsQueryVariables
  >
) {
  return Apollo.useQuery<
    ModernWarefarecodTrackerDotGgStatsQuery,
    ModernWarefarecodTrackerDotGgStatsQueryVariables
  >(ModernWarefarecodTrackerDotGgStatsDocument, baseOptions);
}
export function useModernWarefarecodTrackerDotGgStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ModernWarefarecodTrackerDotGgStatsQuery,
    ModernWarefarecodTrackerDotGgStatsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ModernWarefarecodTrackerDotGgStatsQuery,
    ModernWarefarecodTrackerDotGgStatsQueryVariables
  >(ModernWarefarecodTrackerDotGgStatsDocument, baseOptions);
}
export type ModernWarefarecodTrackerDotGgStatsQueryHookResult = ReturnType<
  typeof useModernWarefarecodTrackerDotGgStatsQuery
>;
export type ModernWarefarecodTrackerDotGgStatsLazyQueryHookResult = ReturnType<
  typeof useModernWarefarecodTrackerDotGgStatsLazyQuery
>;
export type ModernWarefarecodTrackerDotGgStatsQueryResult = Apollo.QueryResult<
  ModernWarefarecodTrackerDotGgStatsQuery,
  ModernWarefarecodTrackerDotGgStatsQueryVariables
>;
export const Blackops4codTrackerDotGGstatsDocument = gql`
  query Blackops4codTrackerDotGGstats($username: String, $platform: String, $userId: ID) {
    blackops4codTrackerDotGGstats(username: $username, plateform: $platform, userId: $userId) {
      ...CODTrackerDotGGStatsInfo
    }
  }
  ${CodTrackerDotGgStatsInfoFragmentDoc}
`;

/**
 * __useBlackops4codTrackerDotGGstatsQuery__
 *
 * To run a query within a React component, call `useBlackops4codTrackerDotGGstatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlackops4codTrackerDotGGstatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlackops4codTrackerDotGGstatsQuery({
 *   variables: {
 *      username: // value for 'username'
 *      platform: // value for 'platform'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useBlackops4codTrackerDotGGstatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Blackops4codTrackerDotGGstatsQuery,
    Blackops4codTrackerDotGGstatsQueryVariables
  >
) {
  return Apollo.useQuery<
    Blackops4codTrackerDotGGstatsQuery,
    Blackops4codTrackerDotGGstatsQueryVariables
  >(Blackops4codTrackerDotGGstatsDocument, baseOptions);
}
export function useBlackops4codTrackerDotGGstatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Blackops4codTrackerDotGGstatsQuery,
    Blackops4codTrackerDotGGstatsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    Blackops4codTrackerDotGGstatsQuery,
    Blackops4codTrackerDotGGstatsQueryVariables
  >(Blackops4codTrackerDotGGstatsDocument, baseOptions);
}
export type Blackops4codTrackerDotGGstatsQueryHookResult = ReturnType<
  typeof useBlackops4codTrackerDotGGstatsQuery
>;
export type Blackops4codTrackerDotGGstatsLazyQueryHookResult = ReturnType<
  typeof useBlackops4codTrackerDotGGstatsLazyQuery
>;
export type Blackops4codTrackerDotGGstatsQueryResult = Apollo.QueryResult<
  Blackops4codTrackerDotGGstatsQuery,
  Blackops4codTrackerDotGGstatsQueryVariables
>;
export const WzstatsDotGgPlayerRecentMatchDocument = gql`
  query WzstatsDotGGPlayerRecentMatch($playerUsername: String, $platform: String, $userId: String) {
    wzstatsDotGGPlayerRecentMatch(
      playerUsername: $playerUsername
      plateform: $platform
      userId: $userId
    ) {
      ...WzstatsDotGGMatchStatInfo
    }
  }
  ${WzstatsDotGgMatchStatInfoFragmentDoc}
`;

/**
 * __useWzstatsDotGgPlayerRecentMatchQuery__
 *
 * To run a query within a React component, call `useWzstatsDotGgPlayerRecentMatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useWzstatsDotGgPlayerRecentMatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWzstatsDotGgPlayerRecentMatchQuery({
 *   variables: {
 *      playerUsername: // value for 'playerUsername'
 *      platform: // value for 'platform'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useWzstatsDotGgPlayerRecentMatchQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WzstatsDotGgPlayerRecentMatchQuery,
    WzstatsDotGgPlayerRecentMatchQueryVariables
  >
) {
  return Apollo.useQuery<
    WzstatsDotGgPlayerRecentMatchQuery,
    WzstatsDotGgPlayerRecentMatchQueryVariables
  >(WzstatsDotGgPlayerRecentMatchDocument, baseOptions);
}
export function useWzstatsDotGgPlayerRecentMatchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WzstatsDotGgPlayerRecentMatchQuery,
    WzstatsDotGgPlayerRecentMatchQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    WzstatsDotGgPlayerRecentMatchQuery,
    WzstatsDotGgPlayerRecentMatchQueryVariables
  >(WzstatsDotGgPlayerRecentMatchDocument, baseOptions);
}
export type WzstatsDotGgPlayerRecentMatchQueryHookResult = ReturnType<
  typeof useWzstatsDotGgPlayerRecentMatchQuery
>;
export type WzstatsDotGgPlayerRecentMatchLazyQueryHookResult = ReturnType<
  typeof useWzstatsDotGgPlayerRecentMatchLazyQuery
>;
export type WzstatsDotGgPlayerRecentMatchQueryResult = Apollo.QueryResult<
  WzstatsDotGgPlayerRecentMatchQuery,
  WzstatsDotGgPlayerRecentMatchQueryVariables
>;
export const RocketLeagueTrackerGgStatsDocument = gql`
  query RocketLeagueTrackerGGStats($username: String, $platform: String, $userId: ID) {
    rocketLeagueTrackerGGStats(username: $username, plateform: $platform, userId: $userId) {
      ...RocketLeagueTrackerGGStatInfo
    }
  }
  ${RocketLeagueTrackerGgStatInfoFragmentDoc}
`;

/**
 * __useRocketLeagueTrackerGgStatsQuery__
 *
 * To run a query within a React component, call `useRocketLeagueTrackerGgStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRocketLeagueTrackerGgStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRocketLeagueTrackerGgStatsQuery({
 *   variables: {
 *      username: // value for 'username'
 *      platform: // value for 'platform'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRocketLeagueTrackerGgStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RocketLeagueTrackerGgStatsQuery,
    RocketLeagueTrackerGgStatsQueryVariables
  >
) {
  return Apollo.useQuery<RocketLeagueTrackerGgStatsQuery, RocketLeagueTrackerGgStatsQueryVariables>(
    RocketLeagueTrackerGgStatsDocument,
    baseOptions
  );
}
export function useRocketLeagueTrackerGgStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RocketLeagueTrackerGgStatsQuery,
    RocketLeagueTrackerGgStatsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    RocketLeagueTrackerGgStatsQuery,
    RocketLeagueTrackerGgStatsQueryVariables
  >(RocketLeagueTrackerGgStatsDocument, baseOptions);
}
export type RocketLeagueTrackerGgStatsQueryHookResult = ReturnType<
  typeof useRocketLeagueTrackerGgStatsQuery
>;
export type RocketLeagueTrackerGgStatsLazyQueryHookResult = ReturnType<
  typeof useRocketLeagueTrackerGgStatsLazyQuery
>;
export type RocketLeagueTrackerGgStatsQueryResult = Apollo.QueryResult<
  RocketLeagueTrackerGgStatsQuery,
  RocketLeagueTrackerGgStatsQueryVariables
>;
export const RocketLeagueTrackerGgLeaderboardsDocument = gql`
  query RocketLeagueTrackerGGLeaderboards($take: Int, $skip: Int, $platform: String) {
    rocketLeagueTrackerGGLeaderboards(take: $take, skip: $skip, plateform: $platform) {
      ...RocketLeagueTrackerGGLeaderBoardItemInfo
    }
  }
  ${RocketLeagueTrackerGgLeaderBoardItemInfoFragmentDoc}
`;

/**
 * __useRocketLeagueTrackerGgLeaderboardsQuery__
 *
 * To run a query within a React component, call `useRocketLeagueTrackerGgLeaderboardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRocketLeagueTrackerGgLeaderboardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRocketLeagueTrackerGgLeaderboardsQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useRocketLeagueTrackerGgLeaderboardsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RocketLeagueTrackerGgLeaderboardsQuery,
    RocketLeagueTrackerGgLeaderboardsQueryVariables
  >
) {
  return Apollo.useQuery<
    RocketLeagueTrackerGgLeaderboardsQuery,
    RocketLeagueTrackerGgLeaderboardsQueryVariables
  >(RocketLeagueTrackerGgLeaderboardsDocument, baseOptions);
}
export function useRocketLeagueTrackerGgLeaderboardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RocketLeagueTrackerGgLeaderboardsQuery,
    RocketLeagueTrackerGgLeaderboardsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    RocketLeagueTrackerGgLeaderboardsQuery,
    RocketLeagueTrackerGgLeaderboardsQueryVariables
  >(RocketLeagueTrackerGgLeaderboardsDocument, baseOptions);
}
export type RocketLeagueTrackerGgLeaderboardsQueryHookResult = ReturnType<
  typeof useRocketLeagueTrackerGgLeaderboardsQuery
>;
export type RocketLeagueTrackerGgLeaderboardsLazyQueryHookResult = ReturnType<
  typeof useRocketLeagueTrackerGgLeaderboardsLazyQuery
>;
export type RocketLeagueTrackerGgLeaderboardsQueryResult = Apollo.QueryResult<
  RocketLeagueTrackerGgLeaderboardsQuery,
  RocketLeagueTrackerGgLeaderboardsQueryVariables
>;
export const CodWarezoneTrackerDotGgLeaderboardDocument = gql`
  query CodWarezoneTrackerDotGGLeaderboard($take: Int, $skip: Int, $platform: String) {
    codWarezoneTrackerDotGGLeaderboard(take: $take, skip: $skip, plateform: $platform) {
      ...CodWarezoneTrackerDotGGLeaderboardItemInfo
    }
  }
  ${CodWarezoneTrackerDotGgLeaderboardItemInfoFragmentDoc}
`;

/**
 * __useCodWarezoneTrackerDotGgLeaderboardQuery__
 *
 * To run a query within a React component, call `useCodWarezoneTrackerDotGgLeaderboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useCodWarezoneTrackerDotGgLeaderboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCodWarezoneTrackerDotGgLeaderboardQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useCodWarezoneTrackerDotGgLeaderboardQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CodWarezoneTrackerDotGgLeaderboardQuery,
    CodWarezoneTrackerDotGgLeaderboardQueryVariables
  >
) {
  return Apollo.useQuery<
    CodWarezoneTrackerDotGgLeaderboardQuery,
    CodWarezoneTrackerDotGgLeaderboardQueryVariables
  >(CodWarezoneTrackerDotGgLeaderboardDocument, baseOptions);
}
export function useCodWarezoneTrackerDotGgLeaderboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CodWarezoneTrackerDotGgLeaderboardQuery,
    CodWarezoneTrackerDotGgLeaderboardQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CodWarezoneTrackerDotGgLeaderboardQuery,
    CodWarezoneTrackerDotGgLeaderboardQueryVariables
  >(CodWarezoneTrackerDotGgLeaderboardDocument, baseOptions);
}
export type CodWarezoneTrackerDotGgLeaderboardQueryHookResult = ReturnType<
  typeof useCodWarezoneTrackerDotGgLeaderboardQuery
>;
export type CodWarezoneTrackerDotGgLeaderboardLazyQueryHookResult = ReturnType<
  typeof useCodWarezoneTrackerDotGgLeaderboardLazyQuery
>;
export type CodWarezoneTrackerDotGgLeaderboardQueryResult = Apollo.QueryResult<
  CodWarezoneTrackerDotGgLeaderboardQuery,
  CodWarezoneTrackerDotGgLeaderboardQueryVariables
>;
export const CodMwTrackerDotGgLeaderboardDocument = gql`
  query CodMWTrackerDotGGLeaderboard($take: Int, $skip: Int, $platform: String) {
    codMWTrackerDotGGLeaderboard(take: $take, skip: $skip, plateform: $platform) {
      ...CodWarezoneTrackerDotGGLeaderboardItemInfo
    }
  }
  ${CodWarezoneTrackerDotGgLeaderboardItemInfoFragmentDoc}
`;

/**
 * __useCodMwTrackerDotGgLeaderboardQuery__
 *
 * To run a query within a React component, call `useCodMwTrackerDotGgLeaderboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useCodMwTrackerDotGgLeaderboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCodMwTrackerDotGgLeaderboardQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useCodMwTrackerDotGgLeaderboardQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CodMwTrackerDotGgLeaderboardQuery,
    CodMwTrackerDotGgLeaderboardQueryVariables
  >
) {
  return Apollo.useQuery<
    CodMwTrackerDotGgLeaderboardQuery,
    CodMwTrackerDotGgLeaderboardQueryVariables
  >(CodMwTrackerDotGgLeaderboardDocument, baseOptions);
}
export function useCodMwTrackerDotGgLeaderboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CodMwTrackerDotGgLeaderboardQuery,
    CodMwTrackerDotGgLeaderboardQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CodMwTrackerDotGgLeaderboardQuery,
    CodMwTrackerDotGgLeaderboardQueryVariables
  >(CodMwTrackerDotGgLeaderboardDocument, baseOptions);
}
export type CodMwTrackerDotGgLeaderboardQueryHookResult = ReturnType<
  typeof useCodMwTrackerDotGgLeaderboardQuery
>;
export type CodMwTrackerDotGgLeaderboardLazyQueryHookResult = ReturnType<
  typeof useCodMwTrackerDotGgLeaderboardLazyQuery
>;
export type CodMwTrackerDotGgLeaderboardQueryResult = Apollo.QueryResult<
  CodMwTrackerDotGgLeaderboardQuery,
  CodMwTrackerDotGgLeaderboardQueryVariables
>;
export const CodBo4TrackerDotGgLeaderboardDocument = gql`
  query CodBo4TrackerDotGGLeaderboard($take: Int, $skip: Int, $platform: String) {
    codBo4TrackerDotGGLeaderboard(take: $take, skip: $skip, plateform: $platform) {
      ...CodWarezoneTrackerDotGGLeaderboardItemInfo
    }
  }
  ${CodWarezoneTrackerDotGgLeaderboardItemInfoFragmentDoc}
`;

/**
 * __useCodBo4TrackerDotGgLeaderboardQuery__
 *
 * To run a query within a React component, call `useCodBo4TrackerDotGgLeaderboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useCodBo4TrackerDotGgLeaderboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCodBo4TrackerDotGgLeaderboardQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useCodBo4TrackerDotGgLeaderboardQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CodBo4TrackerDotGgLeaderboardQuery,
    CodBo4TrackerDotGgLeaderboardQueryVariables
  >
) {
  return Apollo.useQuery<
    CodBo4TrackerDotGgLeaderboardQuery,
    CodBo4TrackerDotGgLeaderboardQueryVariables
  >(CodBo4TrackerDotGgLeaderboardDocument, baseOptions);
}
export function useCodBo4TrackerDotGgLeaderboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CodBo4TrackerDotGgLeaderboardQuery,
    CodBo4TrackerDotGgLeaderboardQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CodBo4TrackerDotGgLeaderboardQuery,
    CodBo4TrackerDotGgLeaderboardQueryVariables
  >(CodBo4TrackerDotGgLeaderboardDocument, baseOptions);
}
export type CodBo4TrackerDotGgLeaderboardQueryHookResult = ReturnType<
  typeof useCodBo4TrackerDotGgLeaderboardQuery
>;
export type CodBo4TrackerDotGgLeaderboardLazyQueryHookResult = ReturnType<
  typeof useCodBo4TrackerDotGgLeaderboardLazyQuery
>;
export type CodBo4TrackerDotGgLeaderboardQueryResult = Apollo.QueryResult<
  CodBo4TrackerDotGgLeaderboardQuery,
  CodBo4TrackerDotGgLeaderboardQueryVariables
>;
export const WzstatsDotGgGameMatchDetailDocument = gql`
  query WzstatsDotGGGameMatchDetail($matchId: String!) {
    wzstatsDotGGGameMatchDetail(matchId: $matchId) {
      ...WzstatsDotGGMatchStatInfo
    }
  }
  ${WzstatsDotGgMatchStatInfoFragmentDoc}
`;

/**
 * __useWzstatsDotGgGameMatchDetailQuery__
 *
 * To run a query within a React component, call `useWzstatsDotGgGameMatchDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useWzstatsDotGgGameMatchDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWzstatsDotGgGameMatchDetailQuery({
 *   variables: {
 *      matchId: // value for 'matchId'
 *   },
 * });
 */
export function useWzstatsDotGgGameMatchDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    WzstatsDotGgGameMatchDetailQuery,
    WzstatsDotGgGameMatchDetailQueryVariables
  >
) {
  return Apollo.useQuery<
    WzstatsDotGgGameMatchDetailQuery,
    WzstatsDotGgGameMatchDetailQueryVariables
  >(WzstatsDotGgGameMatchDetailDocument, baseOptions);
}
export function useWzstatsDotGgGameMatchDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WzstatsDotGgGameMatchDetailQuery,
    WzstatsDotGgGameMatchDetailQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    WzstatsDotGgGameMatchDetailQuery,
    WzstatsDotGgGameMatchDetailQueryVariables
  >(WzstatsDotGgGameMatchDetailDocument, baseOptions);
}
export type WzstatsDotGgGameMatchDetailQueryHookResult = ReturnType<
  typeof useWzstatsDotGgGameMatchDetailQuery
>;
export type WzstatsDotGgGameMatchDetailLazyQueryHookResult = ReturnType<
  typeof useWzstatsDotGgGameMatchDetailLazyQuery
>;
export type WzstatsDotGgGameMatchDetailQueryResult = Apollo.QueryResult<
  WzstatsDotGgGameMatchDetailQuery,
  WzstatsDotGgGameMatchDetailQueryVariables
>;
export const MyApexLegendStatDocument = gql`
  query MyApexLegendStat($plateform: String, $userId: ID) {
    myApexLegendStat(plateform: $plateform, userId: $userId) {
      platformInfo
      userInfo
      metadata
      segments
      availableSegments
      expiryDate
    }
  }
`;

/**
 * __useMyApexLegendStatQuery__
 *
 * To run a query within a React component, call `useMyApexLegendStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyApexLegendStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyApexLegendStatQuery({
 *   variables: {
 *      plateform: // value for 'plateform'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMyApexLegendStatQuery(
  baseOptions?: Apollo.QueryHookOptions<MyApexLegendStatQuery, MyApexLegendStatQueryVariables>
) {
  return Apollo.useQuery<MyApexLegendStatQuery, MyApexLegendStatQueryVariables>(
    MyApexLegendStatDocument,
    baseOptions
  );
}
export function useMyApexLegendStatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyApexLegendStatQuery, MyApexLegendStatQueryVariables>
) {
  return Apollo.useLazyQuery<MyApexLegendStatQuery, MyApexLegendStatQueryVariables>(
    MyApexLegendStatDocument,
    baseOptions
  );
}
export type MyApexLegendStatQueryHookResult = ReturnType<typeof useMyApexLegendStatQuery>;
export type MyApexLegendStatLazyQueryHookResult = ReturnType<typeof useMyApexLegendStatLazyQuery>;
export type MyApexLegendStatQueryResult = Apollo.QueryResult<
  MyApexLegendStatQuery,
  MyApexLegendStatQueryVariables
>;
export const MyApexLegendMatchHistoryDocument = gql`
  query MyApexLegendMatchHistory($plateform: String, $userId: ID) {
    apexMatchHistoryBrokenBySession(plateform: $plateform, userId: $userId) {
      matches
      metadata
      stats
      playlists
    }
  }
`;

/**
 * __useMyApexLegendMatchHistoryQuery__
 *
 * To run a query within a React component, call `useMyApexLegendMatchHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyApexLegendMatchHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyApexLegendMatchHistoryQuery({
 *   variables: {
 *      plateform: // value for 'plateform'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMyApexLegendMatchHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyApexLegendMatchHistoryQuery,
    MyApexLegendMatchHistoryQueryVariables
  >
) {
  return Apollo.useQuery<MyApexLegendMatchHistoryQuery, MyApexLegendMatchHistoryQueryVariables>(
    MyApexLegendMatchHistoryDocument,
    baseOptions
  );
}
export function useMyApexLegendMatchHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyApexLegendMatchHistoryQuery,
    MyApexLegendMatchHistoryQueryVariables
  >
) {
  return Apollo.useLazyQuery<MyApexLegendMatchHistoryQuery, MyApexLegendMatchHistoryQueryVariables>(
    MyApexLegendMatchHistoryDocument,
    baseOptions
  );
}
export type MyApexLegendMatchHistoryQueryHookResult = ReturnType<
  typeof useMyApexLegendMatchHistoryQuery
>;
export type MyApexLegendMatchHistoryLazyQueryHookResult = ReturnType<
  typeof useMyApexLegendMatchHistoryLazyQuery
>;
export type MyApexLegendMatchHistoryQueryResult = Apollo.QueryResult<
  MyApexLegendMatchHistoryQuery,
  MyApexLegendMatchHistoryQueryVariables
>;
export const MyFortniteBrStatDocument = gql`
  query MyFortniteBRStat($platform: String, $userId: ID) {
    myForniteBRStat(platform: $platform, userId: $userId) {
      account
      battlePass
      image
      stats
    }
  }
`;

/**
 * __useMyFortniteBrStatQuery__
 *
 * To run a query within a React component, call `useMyFortniteBrStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyFortniteBrStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyFortniteBrStatQuery({
 *   variables: {
 *      platform: // value for 'platform'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMyFortniteBrStatQuery(
  baseOptions?: Apollo.QueryHookOptions<MyFortniteBrStatQuery, MyFortniteBrStatQueryVariables>
) {
  return Apollo.useQuery<MyFortniteBrStatQuery, MyFortniteBrStatQueryVariables>(
    MyFortniteBrStatDocument,
    baseOptions
  );
}
export function useMyFortniteBrStatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyFortniteBrStatQuery, MyFortniteBrStatQueryVariables>
) {
  return Apollo.useLazyQuery<MyFortniteBrStatQuery, MyFortniteBrStatQueryVariables>(
    MyFortniteBrStatDocument,
    baseOptions
  );
}
export type MyFortniteBrStatQueryHookResult = ReturnType<typeof useMyFortniteBrStatQuery>;
export type MyFortniteBrStatLazyQueryHookResult = ReturnType<typeof useMyFortniteBrStatLazyQuery>;
export type MyFortniteBrStatQueryResult = Apollo.QueryResult<
  MyFortniteBrStatQuery,
  MyFortniteBrStatQueryVariables
>;
export const MyFortniteBrRecentMatchesDocument = gql`
  query MyFortniteBRRecentMatches($platform: String, $userId: ID) {
    myForniteBRRecentMatches(platform: $platform, userId: $userId) {
      account
      name
      max_results
      matches {
        platform
        date
        mode
        readable_name
        kills
        matchesplayed
        minutesplayed
        playersoutlived
        score
        placetop1
        placetop3
        placetop5
        placetop6
        placetop10
        placetop12
        placetop25
      }
    }
  }
`;

/**
 * __useMyFortniteBrRecentMatchesQuery__
 *
 * To run a query within a React component, call `useMyFortniteBrRecentMatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyFortniteBrRecentMatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyFortniteBrRecentMatchesQuery({
 *   variables: {
 *      platform: // value for 'platform'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMyFortniteBrRecentMatchesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyFortniteBrRecentMatchesQuery,
    MyFortniteBrRecentMatchesQueryVariables
  >
) {
  return Apollo.useQuery<MyFortniteBrRecentMatchesQuery, MyFortniteBrRecentMatchesQueryVariables>(
    MyFortniteBrRecentMatchesDocument,
    baseOptions
  );
}
export function useMyFortniteBrRecentMatchesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyFortniteBrRecentMatchesQuery,
    MyFortniteBrRecentMatchesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    MyFortniteBrRecentMatchesQuery,
    MyFortniteBrRecentMatchesQueryVariables
  >(MyFortniteBrRecentMatchesDocument, baseOptions);
}
export type MyFortniteBrRecentMatchesQueryHookResult = ReturnType<
  typeof useMyFortniteBrRecentMatchesQuery
>;
export type MyFortniteBrRecentMatchesLazyQueryHookResult = ReturnType<
  typeof useMyFortniteBrRecentMatchesLazyQuery
>;
export type MyFortniteBrRecentMatchesQueryResult = Apollo.QueryResult<
  MyFortniteBrRecentMatchesQuery,
  MyFortniteBrRecentMatchesQueryVariables
>;
export const Myr6StatsDocument = gql`
  query Myr6Stats($platform: String, $userId: ID) {
    myr6Stats(platform: $platform, userId: $userId) {
      id
      pvp
      pve
    }
  }
`;

/**
 * __useMyr6StatsQuery__
 *
 * To run a query within a React component, call `useMyr6StatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyr6StatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyr6StatsQuery({
 *   variables: {
 *      platform: // value for 'platform'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMyr6StatsQuery(
  baseOptions?: Apollo.QueryHookOptions<Myr6StatsQuery, Myr6StatsQueryVariables>
) {
  return Apollo.useQuery<Myr6StatsQuery, Myr6StatsQueryVariables>(Myr6StatsDocument, baseOptions);
}
export function useMyr6StatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Myr6StatsQuery, Myr6StatsQueryVariables>
) {
  return Apollo.useLazyQuery<Myr6StatsQuery, Myr6StatsQueryVariables>(
    Myr6StatsDocument,
    baseOptions
  );
}
export type Myr6StatsQueryHookResult = ReturnType<typeof useMyr6StatsQuery>;
export type Myr6StatsLazyQueryHookResult = ReturnType<typeof useMyr6StatsLazyQuery>;
export type Myr6StatsQueryResult = Apollo.QueryResult<Myr6StatsQuery, Myr6StatsQueryVariables>;
export const Fut21PlayerStatDocument = gql`
  query Fut21PlayerStat($page: Int) {
    fut21PlayerStat(page: $page) {
      ...FutPlayerInfo
    }
  }
  ${FutPlayerInfoFragmentDoc}
`;

/**
 * __useFut21PlayerStatQuery__
 *
 * To run a query within a React component, call `useFut21PlayerStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useFut21PlayerStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFut21PlayerStatQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFut21PlayerStatQuery(
  baseOptions?: Apollo.QueryHookOptions<Fut21PlayerStatQuery, Fut21PlayerStatQueryVariables>
) {
  return Apollo.useQuery<Fut21PlayerStatQuery, Fut21PlayerStatQueryVariables>(
    Fut21PlayerStatDocument,
    baseOptions
  );
}
export function useFut21PlayerStatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Fut21PlayerStatQuery, Fut21PlayerStatQueryVariables>
) {
  return Apollo.useLazyQuery<Fut21PlayerStatQuery, Fut21PlayerStatQueryVariables>(
    Fut21PlayerStatDocument,
    baseOptions
  );
}
export type Fut21PlayerStatQueryHookResult = ReturnType<typeof useFut21PlayerStatQuery>;
export type Fut21PlayerStatLazyQueryHookResult = ReturnType<typeof useFut21PlayerStatLazyQuery>;
export type Fut21PlayerStatQueryResult = Apollo.QueryResult<
  Fut21PlayerStatQuery,
  Fut21PlayerStatQueryVariables
>;
export const AllLeaderboardpointsDocument = gql`
  query AllLeaderboardpoints(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: LeaderboardpointFilter
  ) {
    allLeaderboardpoints(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...LeaderboardpointInfos
    }
  }
  ${LeaderboardpointInfosFragmentDoc}
`;

/**
 * __useAllLeaderboardpointsQuery__
 *
 * To run a query within a React component, call `useAllLeaderboardpointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLeaderboardpointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLeaderboardpointsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllLeaderboardpointsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllLeaderboardpointsQuery,
    AllLeaderboardpointsQueryVariables
  >
) {
  return Apollo.useQuery<AllLeaderboardpointsQuery, AllLeaderboardpointsQueryVariables>(
    AllLeaderboardpointsDocument,
    baseOptions
  );
}
export function useAllLeaderboardpointsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllLeaderboardpointsQuery,
    AllLeaderboardpointsQueryVariables
  >
) {
  return Apollo.useLazyQuery<AllLeaderboardpointsQuery, AllLeaderboardpointsQueryVariables>(
    AllLeaderboardpointsDocument,
    baseOptions
  );
}
export type AllLeaderboardpointsQueryHookResult = ReturnType<typeof useAllLeaderboardpointsQuery>;
export type AllLeaderboardpointsLazyQueryHookResult = ReturnType<
  typeof useAllLeaderboardpointsLazyQuery
>;
export type AllLeaderboardpointsQueryResult = Apollo.QueryResult<
  AllLeaderboardpointsQuery,
  AllLeaderboardpointsQueryVariables
>;
export const CurrentLeaderboardDocument = gql`
  query CurrentLeaderboard($limit: Int, $type: LEADERBOARD_TYPE) {
    currentLeaderboard(limit: $limit, type: $type) {
      user {
        ...UserBasicInfos
      }
      point
      eventCount
    }
  }
  ${UserBasicInfosFragmentDoc}
`;

/**
 * __useCurrentLeaderboardQuery__
 *
 * To run a query within a React component, call `useCurrentLeaderboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentLeaderboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentLeaderboardQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCurrentLeaderboardQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentLeaderboardQuery, CurrentLeaderboardQueryVariables>
) {
  return Apollo.useQuery<CurrentLeaderboardQuery, CurrentLeaderboardQueryVariables>(
    CurrentLeaderboardDocument,
    baseOptions
  );
}
export function useCurrentLeaderboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentLeaderboardQuery,
    CurrentLeaderboardQueryVariables
  >
) {
  return Apollo.useLazyQuery<CurrentLeaderboardQuery, CurrentLeaderboardQueryVariables>(
    CurrentLeaderboardDocument,
    baseOptions
  );
}
export type CurrentLeaderboardQueryHookResult = ReturnType<typeof useCurrentLeaderboardQuery>;
export type CurrentLeaderboardLazyQueryHookResult = ReturnType<
  typeof useCurrentLeaderboardLazyQuery
>;
export type CurrentLeaderboardQueryResult = Apollo.QueryResult<
  CurrentLeaderboardQuery,
  CurrentLeaderboardQueryVariables
>;
export const ProvisionalClassificationDocument = gql`
  query ProvisionalClassification($limit: Int) {
    rankingUserPoint(limit: $limit) {
      ...UserBasicInfos
    }
  }
  ${UserBasicInfosFragmentDoc}
`;

/**
 * __useProvisionalClassificationQuery__
 *
 * To run a query within a React component, call `useProvisionalClassificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useProvisionalClassificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProvisionalClassificationQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useProvisionalClassificationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProvisionalClassificationQuery,
    ProvisionalClassificationQueryVariables
  >
) {
  return Apollo.useQuery<ProvisionalClassificationQuery, ProvisionalClassificationQueryVariables>(
    ProvisionalClassificationDocument,
    baseOptions
  );
}
export function useProvisionalClassificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProvisionalClassificationQuery,
    ProvisionalClassificationQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ProvisionalClassificationQuery,
    ProvisionalClassificationQueryVariables
  >(ProvisionalClassificationDocument, baseOptions);
}
export type ProvisionalClassificationQueryHookResult = ReturnType<
  typeof useProvisionalClassificationQuery
>;
export type ProvisionalClassificationLazyQueryHookResult = ReturnType<
  typeof useProvisionalClassificationLazyQuery
>;
export type ProvisionalClassificationQueryResult = Apollo.QueryResult<
  ProvisionalClassificationQuery,
  ProvisionalClassificationQueryVariables
>;
export const JoinTeamToLeagueDocument = gql`
  mutation JoinTeamToLeague($leagueId: ID!, $teamId: ID!) {
    joinTeamToLeague(leagueId: $leagueId, teamId: $teamId) {
      id
      user {
        id
      }
      team {
        id
      }
      league {
        id
      }
      leagueTotalPoint
    }
  }
`;
export type JoinTeamToLeagueMutationFn = Apollo.MutationFunction<
  JoinTeamToLeagueMutation,
  JoinTeamToLeagueMutationVariables
>;

/**
 * __useJoinTeamToLeagueMutation__
 *
 * To run a mutation, you first call `useJoinTeamToLeagueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinTeamToLeagueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinTeamToLeagueMutation, { data, loading, error }] = useJoinTeamToLeagueMutation({
 *   variables: {
 *      leagueId: // value for 'leagueId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useJoinTeamToLeagueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinTeamToLeagueMutation,
    JoinTeamToLeagueMutationVariables
  >
) {
  return Apollo.useMutation<JoinTeamToLeagueMutation, JoinTeamToLeagueMutationVariables>(
    JoinTeamToLeagueDocument,
    baseOptions
  );
}
export type JoinTeamToLeagueMutationHookResult = ReturnType<typeof useJoinTeamToLeagueMutation>;
export type JoinTeamToLeagueMutationResult = Apollo.MutationResult<JoinTeamToLeagueMutation>;
export type JoinTeamToLeagueMutationOptions = Apollo.BaseMutationOptions<
  JoinTeamToLeagueMutation,
  JoinTeamToLeagueMutationVariables
>;
export const JoinMeToLeagueDocument = gql`
  mutation JoinMeToLeague($leagueId: ID!) {
    joinMeToLeague(leagueId: $leagueId) {
      id
      user {
        id
      }
      team {
        id
      }
      league {
        id
      }
      leagueTotalPoint
    }
  }
`;
export type JoinMeToLeagueMutationFn = Apollo.MutationFunction<
  JoinMeToLeagueMutation,
  JoinMeToLeagueMutationVariables
>;

/**
 * __useJoinMeToLeagueMutation__
 *
 * To run a mutation, you first call `useJoinMeToLeagueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinMeToLeagueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinMeToLeagueMutation, { data, loading, error }] = useJoinMeToLeagueMutation({
 *   variables: {
 *      leagueId: // value for 'leagueId'
 *   },
 * });
 */
export function useJoinMeToLeagueMutation(
  baseOptions?: Apollo.MutationHookOptions<JoinMeToLeagueMutation, JoinMeToLeagueMutationVariables>
) {
  return Apollo.useMutation<JoinMeToLeagueMutation, JoinMeToLeagueMutationVariables>(
    JoinMeToLeagueDocument,
    baseOptions
  );
}
export type JoinMeToLeagueMutationHookResult = ReturnType<typeof useJoinMeToLeagueMutation>;
export type JoinMeToLeagueMutationResult = Apollo.MutationResult<JoinMeToLeagueMutation>;
export type JoinMeToLeagueMutationOptions = Apollo.BaseMutationOptions<
  JoinMeToLeagueMutation,
  JoinMeToLeagueMutationVariables
>;
export const AllLeaguesDocument = gql`
  query AllLeagues(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: LeagueFilter
  ) {
    allLeagues(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...LeagueCompleteInfos
    }
  }
  ${LeagueCompleteInfosFragmentDoc}
`;

/**
 * __useAllLeaguesQuery__
 *
 * To run a query within a React component, call `useAllLeaguesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLeaguesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLeaguesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllLeaguesQuery(
  baseOptions?: Apollo.QueryHookOptions<AllLeaguesQuery, AllLeaguesQueryVariables>
) {
  return Apollo.useQuery<AllLeaguesQuery, AllLeaguesQueryVariables>(
    AllLeaguesDocument,
    baseOptions
  );
}
export function useAllLeaguesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllLeaguesQuery, AllLeaguesQueryVariables>
) {
  return Apollo.useLazyQuery<AllLeaguesQuery, AllLeaguesQueryVariables>(
    AllLeaguesDocument,
    baseOptions
  );
}
export type AllLeaguesQueryHookResult = ReturnType<typeof useAllLeaguesQuery>;
export type AllLeaguesLazyQueryHookResult = ReturnType<typeof useAllLeaguesLazyQuery>;
export type AllLeaguesQueryResult = Apollo.QueryResult<AllLeaguesQuery, AllLeaguesQueryVariables>;
export const ActiveLeaguesDocument = gql`
  query ActiveLeagues {
    activeLeagues {
      id
      title
      isActive
      startedAt
      entryCost
      format
      createdAt
      description
      awards {
        value
      }
      gamePlateform {
        id
        name
        logo
      }
      game {
        id
        name
      }
      isMeAlreadyParticipant
    }
  }
`;

/**
 * __useActiveLeaguesQuery__
 *
 * To run a query within a React component, call `useActiveLeaguesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveLeaguesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveLeaguesQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveLeaguesQuery(
  baseOptions?: Apollo.QueryHookOptions<ActiveLeaguesQuery, ActiveLeaguesQueryVariables>
) {
  return Apollo.useQuery<ActiveLeaguesQuery, ActiveLeaguesQueryVariables>(
    ActiveLeaguesDocument,
    baseOptions
  );
}
export function useActiveLeaguesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ActiveLeaguesQuery, ActiveLeaguesQueryVariables>
) {
  return Apollo.useLazyQuery<ActiveLeaguesQuery, ActiveLeaguesQueryVariables>(
    ActiveLeaguesDocument,
    baseOptions
  );
}
export type ActiveLeaguesQueryHookResult = ReturnType<typeof useActiveLeaguesQuery>;
export type ActiveLeaguesLazyQueryHookResult = ReturnType<typeof useActiveLeaguesLazyQuery>;
export type ActiveLeaguesQueryResult = Apollo.QueryResult<
  ActiveLeaguesQuery,
  ActiveLeaguesQueryVariables
>;
export const LeagueDocument = gql`
  query League($id: ID!) {
    League(id: $id) {
      ...LeagueCompleteInfos
    }
  }
  ${LeagueCompleteInfosFragmentDoc}
`;

/**
 * __useLeagueQuery__
 *
 * To run a query within a React component, call `useLeagueQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeagueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeagueQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLeagueQuery(
  baseOptions: Apollo.QueryHookOptions<LeagueQuery, LeagueQueryVariables>
) {
  return Apollo.useQuery<LeagueQuery, LeagueQueryVariables>(LeagueDocument, baseOptions);
}
export function useLeagueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LeagueQuery, LeagueQueryVariables>
) {
  return Apollo.useLazyQuery<LeagueQuery, LeagueQueryVariables>(LeagueDocument, baseOptions);
}
export type LeagueQueryHookResult = ReturnType<typeof useLeagueQuery>;
export type LeagueLazyQueryHookResult = ReturnType<typeof useLeagueLazyQuery>;
export type LeagueQueryResult = Apollo.QueryResult<LeagueQuery, LeagueQueryVariables>;
export const GetGameLeagueDocument = gql`
  query GetGameLeague($id: ID!) {
    League(id: $id) {
      id
      title
      description
      game {
        id
        name
        mainJacket
        logo
      }
      gamePlateform {
        id
        name
        logo
      }
      isMeAlreadyParticipant
    }
  }
`;

/**
 * __useGetGameLeagueQuery__
 *
 * To run a query within a React component, call `useGetGameLeagueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameLeagueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameLeagueQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGameLeagueQuery(
  baseOptions: Apollo.QueryHookOptions<GetGameLeagueQuery, GetGameLeagueQueryVariables>
) {
  return Apollo.useQuery<GetGameLeagueQuery, GetGameLeagueQueryVariables>(
    GetGameLeagueDocument,
    baseOptions
  );
}
export function useGetGameLeagueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGameLeagueQuery, GetGameLeagueQueryVariables>
) {
  return Apollo.useLazyQuery<GetGameLeagueQuery, GetGameLeagueQueryVariables>(
    GetGameLeagueDocument,
    baseOptions
  );
}
export type GetGameLeagueQueryHookResult = ReturnType<typeof useGetGameLeagueQuery>;
export type GetGameLeagueLazyQueryHookResult = ReturnType<typeof useGetGameLeagueLazyQuery>;
export type GetGameLeagueQueryResult = Apollo.QueryResult<
  GetGameLeagueQuery,
  GetGameLeagueQueryVariables
>;
export const GetLeagueGameDocument = gql`
  query GetLeagueGame($id: ID!) {
    Game(id: $id) {
      ...GameInfos
      leagues {
        ...LeagueInfos
      }
    }
  }
  ${GameInfosFragmentDoc}
  ${LeagueInfosFragmentDoc}
`;

/**
 * __useGetLeagueGameQuery__
 *
 * To run a query within a React component, call `useGetLeagueGameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeagueGameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeagueGameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLeagueGameQuery(
  baseOptions: Apollo.QueryHookOptions<GetLeagueGameQuery, GetLeagueGameQueryVariables>
) {
  return Apollo.useQuery<GetLeagueGameQuery, GetLeagueGameQueryVariables>(
    GetLeagueGameDocument,
    baseOptions
  );
}
export function useGetLeagueGameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLeagueGameQuery, GetLeagueGameQueryVariables>
) {
  return Apollo.useLazyQuery<GetLeagueGameQuery, GetLeagueGameQueryVariables>(
    GetLeagueGameDocument,
    baseOptions
  );
}
export type GetLeagueGameQueryHookResult = ReturnType<typeof useGetLeagueGameQuery>;
export type GetLeagueGameLazyQueryHookResult = ReturnType<typeof useGetLeagueGameLazyQuery>;
export type GetLeagueGameQueryResult = Apollo.QueryResult<
  GetLeagueGameQuery,
  GetLeagueGameQueryVariables
>;
export const GetGeneralInfoLeagueDocument = gql`
  query GetGeneralInfoLeague($id: ID!) {
    League(id: $id) {
      id
      title
      description
      startedAt
      createdAt
      organiser {
        id
        name
      }
      game {
        id
        logo
      }
      format
      maxParticipants
      minParticipants
      isActive
      gamePlateform {
        id
        name
        logo
      }
      awards {
        id
        value
        startRank
        endRank
      }
      entryCost
      isMeAlreadyParticipant
    }
  }
`;

/**
 * __useGetGeneralInfoLeagueQuery__
 *
 * To run a query within a React component, call `useGetGeneralInfoLeagueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralInfoLeagueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralInfoLeagueQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGeneralInfoLeagueQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGeneralInfoLeagueQuery,
    GetGeneralInfoLeagueQueryVariables
  >
) {
  return Apollo.useQuery<GetGeneralInfoLeagueQuery, GetGeneralInfoLeagueQueryVariables>(
    GetGeneralInfoLeagueDocument,
    baseOptions
  );
}
export function useGetGeneralInfoLeagueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGeneralInfoLeagueQuery,
    GetGeneralInfoLeagueQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetGeneralInfoLeagueQuery, GetGeneralInfoLeagueQueryVariables>(
    GetGeneralInfoLeagueDocument,
    baseOptions
  );
}
export type GetGeneralInfoLeagueQueryHookResult = ReturnType<typeof useGetGeneralInfoLeagueQuery>;
export type GetGeneralInfoLeagueLazyQueryHookResult = ReturnType<
  typeof useGetGeneralInfoLeagueLazyQuery
>;
export type GetGeneralInfoLeagueQueryResult = Apollo.QueryResult<
  GetGeneralInfoLeagueQuery,
  GetGeneralInfoLeagueQueryVariables
>;
export const GetBracketLeagueDocument = gql`
  query GetBracketLeague($id: ID!) {
    League(id: $id) {
      id
      title
      description
      startedAt
      matches {
        ...TournamentBracketInfos
        participant1 {
          id
          user {
            ...UserBasicInfos
          }
          team {
            ...TeamBasicInfos
          }
        }
        participant2 {
          id
          user {
            ...UserBasicInfos
          }
          team {
            ...TeamBasicInfos
          }
        }
        winner {
          id
          user {
            id
          }
          team {
            id
          }
        }
      }
    }
  }
  ${TournamentBracketInfosFragmentDoc}
  ${UserBasicInfosFragmentDoc}
  ${TeamBasicInfosFragmentDoc}
`;

/**
 * __useGetBracketLeagueQuery__
 *
 * To run a query within a React component, call `useGetBracketLeagueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBracketLeagueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBracketLeagueQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBracketLeagueQuery(
  baseOptions: Apollo.QueryHookOptions<GetBracketLeagueQuery, GetBracketLeagueQueryVariables>
) {
  return Apollo.useQuery<GetBracketLeagueQuery, GetBracketLeagueQueryVariables>(
    GetBracketLeagueDocument,
    baseOptions
  );
}
export function useGetBracketLeagueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBracketLeagueQuery, GetBracketLeagueQueryVariables>
) {
  return Apollo.useLazyQuery<GetBracketLeagueQuery, GetBracketLeagueQueryVariables>(
    GetBracketLeagueDocument,
    baseOptions
  );
}
export type GetBracketLeagueQueryHookResult = ReturnType<typeof useGetBracketLeagueQuery>;
export type GetBracketLeagueLazyQueryHookResult = ReturnType<typeof useGetBracketLeagueLazyQuery>;
export type GetBracketLeagueQueryResult = Apollo.QueryResult<
  GetBracketLeagueQuery,
  GetBracketLeagueQueryVariables
>;
export const GetBracketClassmentDocument = gql`
  query GetBracketClassment($id: ID!) {
    League(id: $id) {
      id
      title
      description
      startedAt
      participants {
        id
        user {
          ...UserBasicInfos
        }
        team {
          ...TeamBasicInfos
        }
        createdAt
        brackets {
          ...TournamentBracketInfos
          winner {
            id
            user {
              id
            }
            team {
              id
            }
          }
        }
        leagueTotalPoint
      }
    }
  }
  ${UserBasicInfosFragmentDoc}
  ${TeamBasicInfosFragmentDoc}
  ${TournamentBracketInfosFragmentDoc}
`;

/**
 * __useGetBracketClassmentQuery__
 *
 * To run a query within a React component, call `useGetBracketClassmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBracketClassmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBracketClassmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBracketClassmentQuery(
  baseOptions: Apollo.QueryHookOptions<GetBracketClassmentQuery, GetBracketClassmentQueryVariables>
) {
  return Apollo.useQuery<GetBracketClassmentQuery, GetBracketClassmentQueryVariables>(
    GetBracketClassmentDocument,
    baseOptions
  );
}
export function useGetBracketClassmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBracketClassmentQuery,
    GetBracketClassmentQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetBracketClassmentQuery, GetBracketClassmentQueryVariables>(
    GetBracketClassmentDocument,
    baseOptions
  );
}
export type GetBracketClassmentQueryHookResult = ReturnType<typeof useGetBracketClassmentQuery>;
export type GetBracketClassmentLazyQueryHookResult = ReturnType<
  typeof useGetBracketClassmentLazyQuery
>;
export type GetBracketClassmentQueryResult = Apollo.QueryResult<
  GetBracketClassmentQuery,
  GetBracketClassmentQueryVariables
>;
export const GetParticipantsLeagueDocument = gql`
  query GetParticipantsLeague($id: ID!) {
    League(id: $id) {
      id
      title
      description
      participants {
        id
        user {
          ...UserBasicInfos
        }
        team {
          ...TeamBasicInfos
        }
      }
      isMeAlreadyParticipant
    }
  }
  ${UserBasicInfosFragmentDoc}
  ${TeamBasicInfosFragmentDoc}
`;

/**
 * __useGetParticipantsLeagueQuery__
 *
 * To run a query within a React component, call `useGetParticipantsLeagueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipantsLeagueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipantsLeagueQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetParticipantsLeagueQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetParticipantsLeagueQuery,
    GetParticipantsLeagueQueryVariables
  >
) {
  return Apollo.useQuery<GetParticipantsLeagueQuery, GetParticipantsLeagueQueryVariables>(
    GetParticipantsLeagueDocument,
    baseOptions
  );
}
export function useGetParticipantsLeagueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParticipantsLeagueQuery,
    GetParticipantsLeagueQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetParticipantsLeagueQuery, GetParticipantsLeagueQueryVariables>(
    GetParticipantsLeagueDocument,
    baseOptions
  );
}
export type GetParticipantsLeagueQueryHookResult = ReturnType<typeof useGetParticipantsLeagueQuery>;
export type GetParticipantsLeagueLazyQueryHookResult = ReturnType<
  typeof useGetParticipantsLeagueLazyQuery
>;
export type GetParticipantsLeagueQueryResult = Apollo.QueryResult<
  GetParticipantsLeagueQuery,
  GetParticipantsLeagueQueryVariables
>;
export const GetRulesLeagueDocument = gql`
  query GetRulesLeague($id: ID!) {
    League(id: $id) {
      id
      title
      description
      rules {
        ...LeagueRuleInfos
      }
    }
  }
  ${LeagueRuleInfosFragmentDoc}
`;

/**
 * __useGetRulesLeagueQuery__
 *
 * To run a query within a React component, call `useGetRulesLeagueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRulesLeagueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRulesLeagueQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRulesLeagueQuery(
  baseOptions: Apollo.QueryHookOptions<GetRulesLeagueQuery, GetRulesLeagueQueryVariables>
) {
  return Apollo.useQuery<GetRulesLeagueQuery, GetRulesLeagueQueryVariables>(
    GetRulesLeagueDocument,
    baseOptions
  );
}
export function useGetRulesLeagueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRulesLeagueQuery, GetRulesLeagueQueryVariables>
) {
  return Apollo.useLazyQuery<GetRulesLeagueQuery, GetRulesLeagueQueryVariables>(
    GetRulesLeagueDocument,
    baseOptions
  );
}
export type GetRulesLeagueQueryHookResult = ReturnType<typeof useGetRulesLeagueQuery>;
export type GetRulesLeagueLazyQueryHookResult = ReturnType<typeof useGetRulesLeagueLazyQuery>;
export type GetRulesLeagueQueryResult = Apollo.QueryResult<
  GetRulesLeagueQuery,
  GetRulesLeagueQueryVariables
>;
export const AddMessageThreadDocument = gql`
  mutation AddMessageThread($input: AddMessageInput!) {
    addMessage(input: $input) {
      ...MessageInfos
      author {
        ...UserInfos
      }
    }
  }
  ${MessageInfosFragmentDoc}
  ${UserInfosFragmentDoc}
`;
export type AddMessageThreadMutationFn = Apollo.MutationFunction<
  AddMessageThreadMutation,
  AddMessageThreadMutationVariables
>;

/**
 * __useAddMessageThreadMutation__
 *
 * To run a mutation, you first call `useAddMessageThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMessageThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMessageThreadMutation, { data, loading, error }] = useAddMessageThreadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMessageThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMessageThreadMutation,
    AddMessageThreadMutationVariables
  >
) {
  return Apollo.useMutation<AddMessageThreadMutation, AddMessageThreadMutationVariables>(
    AddMessageThreadDocument,
    baseOptions
  );
}
export type AddMessageThreadMutationHookResult = ReturnType<typeof useAddMessageThreadMutation>;
export type AddMessageThreadMutationResult = Apollo.MutationResult<AddMessageThreadMutation>;
export type AddMessageThreadMutationOptions = Apollo.BaseMutationOptions<
  AddMessageThreadMutation,
  AddMessageThreadMutationVariables
>;
export const EditMessageThreadDocument = gql`
  mutation EditMessageThread($input: EditMessageInput!) {
    editMessage(input: $input) {
      ...MessageInfos
    }
  }
  ${MessageInfosFragmentDoc}
`;
export type EditMessageThreadMutationFn = Apollo.MutationFunction<
  EditMessageThreadMutation,
  EditMessageThreadMutationVariables
>;

/**
 * __useEditMessageThreadMutation__
 *
 * To run a mutation, you first call `useEditMessageThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMessageThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMessageThreadMutation, { data, loading, error }] = useEditMessageThreadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMessageThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditMessageThreadMutation,
    EditMessageThreadMutationVariables
  >
) {
  return Apollo.useMutation<EditMessageThreadMutation, EditMessageThreadMutationVariables>(
    EditMessageThreadDocument,
    baseOptions
  );
}
export type EditMessageThreadMutationHookResult = ReturnType<typeof useEditMessageThreadMutation>;
export type EditMessageThreadMutationResult = Apollo.MutationResult<EditMessageThreadMutation>;
export type EditMessageThreadMutationOptions = Apollo.BaseMutationOptions<
  EditMessageThreadMutation,
  EditMessageThreadMutationVariables
>;
export const DeleteMessageThreadDocument = gql`
  mutation DeleteMessageThread($input: DeleteMessageInput!) {
    deleteMessage(input: $input) {
      ...MessageInfos
    }
  }
  ${MessageInfosFragmentDoc}
`;
export type DeleteMessageThreadMutationFn = Apollo.MutationFunction<
  DeleteMessageThreadMutation,
  DeleteMessageThreadMutationVariables
>;

/**
 * __useDeleteMessageThreadMutation__
 *
 * To run a mutation, you first call `useDeleteMessageThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageThreadMutation, { data, loading, error }] = useDeleteMessageThreadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMessageThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMessageThreadMutation,
    DeleteMessageThreadMutationVariables
  >
) {
  return Apollo.useMutation<DeleteMessageThreadMutation, DeleteMessageThreadMutationVariables>(
    DeleteMessageThreadDocument,
    baseOptions
  );
}
export type DeleteMessageThreadMutationHookResult = ReturnType<
  typeof useDeleteMessageThreadMutation
>;
export type DeleteMessageThreadMutationResult = Apollo.MutationResult<DeleteMessageThreadMutation>;
export type DeleteMessageThreadMutationOptions = Apollo.BaseMutationOptions<
  DeleteMessageThreadMutation,
  DeleteMessageThreadMutationVariables
>;
export const SubscribeToMessageThreadAddedDocument = gql`
  subscription SubscribeToMessageThreadAdded($threadId: ID!) {
    messageThreadAdded(threadId: $threadId) {
      ...MessageInfos
    }
  }
  ${MessageInfosFragmentDoc}
`;

/**
 * __useSubscribeToMessageThreadAddedSubscription__
 *
 * To run a query within a React component, call `useSubscribeToMessageThreadAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToMessageThreadAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToMessageThreadAddedSubscription({
 *   variables: {
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useSubscribeToMessageThreadAddedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToMessageThreadAddedSubscription,
    SubscribeToMessageThreadAddedSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToMessageThreadAddedSubscription,
    SubscribeToMessageThreadAddedSubscriptionVariables
  >(SubscribeToMessageThreadAddedDocument, baseOptions);
}
export type SubscribeToMessageThreadAddedSubscriptionHookResult = ReturnType<
  typeof useSubscribeToMessageThreadAddedSubscription
>;
export type SubscribeToMessageThreadAddedSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToMessageThreadAddedSubscription>;
export const SubscribeToMessageThreadEditDocument = gql`
  subscription SubscribeToMessageThreadEdit($threadId: ID!) {
    messageThreadEdit(threadId: $threadId) {
      ...MessageInfos
    }
  }
  ${MessageInfosFragmentDoc}
`;

/**
 * __useSubscribeToMessageThreadEditSubscription__
 *
 * To run a query within a React component, call `useSubscribeToMessageThreadEditSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToMessageThreadEditSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToMessageThreadEditSubscription({
 *   variables: {
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useSubscribeToMessageThreadEditSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToMessageThreadEditSubscription,
    SubscribeToMessageThreadEditSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToMessageThreadEditSubscription,
    SubscribeToMessageThreadEditSubscriptionVariables
  >(SubscribeToMessageThreadEditDocument, baseOptions);
}
export type SubscribeToMessageThreadEditSubscriptionHookResult = ReturnType<
  typeof useSubscribeToMessageThreadEditSubscription
>;
export type SubscribeToMessageThreadEditSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToMessageThreadEditSubscription>;
export const SubscribeToMessageThreadRemovedDocument = gql`
  subscription SubscribeToMessageThreadRemoved($threadId: ID!) {
    messageThreadRemoved(threadId: $threadId) {
      ...MessageInfos
    }
  }
  ${MessageInfosFragmentDoc}
`;

/**
 * __useSubscribeToMessageThreadRemovedSubscription__
 *
 * To run a query within a React component, call `useSubscribeToMessageThreadRemovedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToMessageThreadRemovedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToMessageThreadRemovedSubscription({
 *   variables: {
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useSubscribeToMessageThreadRemovedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToMessageThreadRemovedSubscription,
    SubscribeToMessageThreadRemovedSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToMessageThreadRemovedSubscription,
    SubscribeToMessageThreadRemovedSubscriptionVariables
  >(SubscribeToMessageThreadRemovedDocument, baseOptions);
}
export type SubscribeToMessageThreadRemovedSubscriptionHookResult = ReturnType<
  typeof useSubscribeToMessageThreadRemovedSubscription
>;
export type SubscribeToMessageThreadRemovedSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToMessageThreadRemovedSubscription>;
export const AllNotificationsDocument = gql`
  query AllNotifications(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: NotificationFilter
  ) {
    allNotifications(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...NotificationInfos
    }
  }
  ${NotificationInfosFragmentDoc}
`;

/**
 * __useAllNotificationsQuery__
 *
 * To run a query within a React component, call `useAllNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllNotificationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllNotificationsQuery, AllNotificationsQueryVariables>
) {
  return Apollo.useQuery<AllNotificationsQuery, AllNotificationsQueryVariables>(
    AllNotificationsDocument,
    baseOptions
  );
}
export function useAllNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllNotificationsQuery, AllNotificationsQueryVariables>
) {
  return Apollo.useLazyQuery<AllNotificationsQuery, AllNotificationsQueryVariables>(
    AllNotificationsDocument,
    baseOptions
  );
}
export type AllNotificationsQueryHookResult = ReturnType<typeof useAllNotificationsQuery>;
export type AllNotificationsLazyQueryHookResult = ReturnType<typeof useAllNotificationsLazyQuery>;
export type AllNotificationsQueryResult = Apollo.QueryResult<
  AllNotificationsQuery,
  AllNotificationsQueryVariables
>;
export const AllPagesDocument = gql`
  query AllPages {
    allPages {
      ...PageInfos
      contents {
        ...PageContentInfos
      }
    }
  }
  ${PageInfosFragmentDoc}
  ${PageContentInfosFragmentDoc}
`;

/**
 * __useAllPagesQuery__
 *
 * To run a query within a React component, call `useAllPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllPagesQuery(
  baseOptions?: Apollo.QueryHookOptions<AllPagesQuery, AllPagesQueryVariables>
) {
  return Apollo.useQuery<AllPagesQuery, AllPagesQueryVariables>(AllPagesDocument, baseOptions);
}
export function useAllPagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllPagesQuery, AllPagesQueryVariables>
) {
  return Apollo.useLazyQuery<AllPagesQuery, AllPagesQueryVariables>(AllPagesDocument, baseOptions);
}
export type AllPagesQueryHookResult = ReturnType<typeof useAllPagesQuery>;
export type AllPagesLazyQueryHookResult = ReturnType<typeof useAllPagesLazyQuery>;
export type AllPagesQueryResult = Apollo.QueryResult<AllPagesQuery, AllPagesQueryVariables>;
export const PageBySlugDocument = gql`
  query PageBySlug($slug: String!) {
    pageBySlug(slug: $slug) {
      ...PageInfos
      contents {
        ...PageContentInfos
      }
    }
  }
  ${PageInfosFragmentDoc}
  ${PageContentInfosFragmentDoc}
`;

/**
 * __usePageBySlugQuery__
 *
 * To run a query within a React component, call `usePageBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePageBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<PageBySlugQuery, PageBySlugQueryVariables>
) {
  return Apollo.useQuery<PageBySlugQuery, PageBySlugQueryVariables>(
    PageBySlugDocument,
    baseOptions
  );
}
export function usePageBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PageBySlugQuery, PageBySlugQueryVariables>
) {
  return Apollo.useLazyQuery<PageBySlugQuery, PageBySlugQueryVariables>(
    PageBySlugDocument,
    baseOptions
  );
}
export type PageBySlugQueryHookResult = ReturnType<typeof usePageBySlugQuery>;
export type PageBySlugLazyQueryHookResult = ReturnType<typeof usePageBySlugLazyQuery>;
export type PageBySlugQueryResult = Apollo.QueryResult<PageBySlugQuery, PageBySlugQueryVariables>;
export const MakePaymentDocument = gql`
  mutation MakePayment(
    $paymentMethod: PAYMENT_METHOD
    $tournamentId: ID
    $wagerId: ID
    $leagueId: ID
    $object: PAYMENT_OBJECT
  ) {
    makePayment(
      paymentMethod: $paymentMethod
      tournamentId: $tournamentId
      wagerId: $wagerId
      leagueId: $leagueId
      object: $object
    ) {
      clientSecret
      order {
        ...OrderInfos
      }
      paymentMethod
    }
  }
  ${OrderInfosFragmentDoc}
`;
export type MakePaymentMutationFn = Apollo.MutationFunction<
  MakePaymentMutation,
  MakePaymentMutationVariables
>;

/**
 * __useMakePaymentMutation__
 *
 * To run a mutation, you first call `useMakePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makePaymentMutation, { data, loading, error }] = useMakePaymentMutation({
 *   variables: {
 *      paymentMethod: // value for 'paymentMethod'
 *      tournamentId: // value for 'tournamentId'
 *      wagerId: // value for 'wagerId'
 *      leagueId: // value for 'leagueId'
 *      object: // value for 'object'
 *   },
 * });
 */
export function useMakePaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<MakePaymentMutation, MakePaymentMutationVariables>
) {
  return Apollo.useMutation<MakePaymentMutation, MakePaymentMutationVariables>(
    MakePaymentDocument,
    baseOptions
  );
}
export type MakePaymentMutationHookResult = ReturnType<typeof useMakePaymentMutation>;
export type MakePaymentMutationResult = Apollo.MutationResult<MakePaymentMutation>;
export type MakePaymentMutationOptions = Apollo.BaseMutationOptions<
  MakePaymentMutation,
  MakePaymentMutationVariables
>;
export const CreateOrderDocument = gql`
  mutation CreateOrder(
    $amount: Float
    $userId: ID
    $tournamentId: ID
    $wagerId: ID
    $leagueId: ID
  ) {
    createOrder(
      amount: $amount
      userId: $userId
      tournamentId: $tournamentId
      wagerId: $wagerId
      leagueId: $leagueId
    ) {
      ...OrderInfos
    }
  }
  ${OrderInfosFragmentDoc}
`;
export type CreateOrderMutationFn = Apollo.MutationFunction<
  CreateOrderMutation,
  CreateOrderMutationVariables
>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      userId: // value for 'userId'
 *      tournamentId: // value for 'tournamentId'
 *      wagerId: // value for 'wagerId'
 *      leagueId: // value for 'leagueId'
 *   },
 * });
 */
export function useCreateOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>
) {
  return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(
    CreateOrderDocument,
    baseOptions
  );
}
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<
  CreateOrderMutation,
  CreateOrderMutationVariables
>;
export const DeleteOrderDocument = gql`
  mutation DeleteOrder($id: ID!) {
    deleteOrder(id: $id) {
      id
    }
  }
`;
export type DeleteOrderMutationFn = Apollo.MutationFunction<
  DeleteOrderMutation,
  DeleteOrderMutationVariables
>;

/**
 * __useDeleteOrderMutation__
 *
 * To run a mutation, you first call `useDeleteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderMutation, { data, loading, error }] = useDeleteOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteOrderMutation, DeleteOrderMutationVariables>
) {
  return Apollo.useMutation<DeleteOrderMutation, DeleteOrderMutationVariables>(
    DeleteOrderDocument,
    baseOptions
  );
}
export type DeleteOrderMutationHookResult = ReturnType<typeof useDeleteOrderMutation>;
export type DeleteOrderMutationResult = Apollo.MutationResult<DeleteOrderMutation>;
export type DeleteOrderMutationOptions = Apollo.BaseMutationOptions<
  DeleteOrderMutation,
  DeleteOrderMutationVariables
>;
export const ConfirmOrderPaymentDocument = gql`
  mutation ConfirmOrderPayment($orderId: ID!, $meta: JSON) {
    confirmOrderPayment(orderId: $orderId, meta: $meta) {
      ...OrderInfos
    }
  }
  ${OrderInfosFragmentDoc}
`;
export type ConfirmOrderPaymentMutationFn = Apollo.MutationFunction<
  ConfirmOrderPaymentMutation,
  ConfirmOrderPaymentMutationVariables
>;

/**
 * __useConfirmOrderPaymentMutation__
 *
 * To run a mutation, you first call `useConfirmOrderPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmOrderPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmOrderPaymentMutation, { data, loading, error }] = useConfirmOrderPaymentMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      meta: // value for 'meta'
 *   },
 * });
 */
export function useConfirmOrderPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmOrderPaymentMutation,
    ConfirmOrderPaymentMutationVariables
  >
) {
  return Apollo.useMutation<ConfirmOrderPaymentMutation, ConfirmOrderPaymentMutationVariables>(
    ConfirmOrderPaymentDocument,
    baseOptions
  );
}
export type ConfirmOrderPaymentMutationHookResult = ReturnType<
  typeof useConfirmOrderPaymentMutation
>;
export type ConfirmOrderPaymentMutationResult = Apollo.MutationResult<ConfirmOrderPaymentMutation>;
export type ConfirmOrderPaymentMutationOptions = Apollo.BaseMutationOptions<
  ConfirmOrderPaymentMutation,
  ConfirmOrderPaymentMutationVariables
>;
export const ReportUserActionDocument = gql`
  mutation ReportUserAction($input: ReportUserActionInput!) {
    reportUserAction(input: $input) {
      id
    }
  }
`;
export type ReportUserActionMutationFn = Apollo.MutationFunction<
  ReportUserActionMutation,
  ReportUserActionMutationVariables
>;

/**
 * __useReportUserActionMutation__
 *
 * To run a mutation, you first call `useReportUserActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportUserActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportUserActionMutation, { data, loading, error }] = useReportUserActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportUserActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReportUserActionMutation,
    ReportUserActionMutationVariables
  >
) {
  return Apollo.useMutation<ReportUserActionMutation, ReportUserActionMutationVariables>(
    ReportUserActionDocument,
    baseOptions
  );
}
export type ReportUserActionMutationHookResult = ReturnType<typeof useReportUserActionMutation>;
export type ReportUserActionMutationResult = Apollo.MutationResult<ReportUserActionMutation>;
export type ReportUserActionMutationOptions = Apollo.BaseMutationOptions<
  ReportUserActionMutation,
  ReportUserActionMutationVariables
>;
export const CurrentSaisonDocument = gql`
  query CurrentSaison {
    currentSaison {
      ...SaisonInfo
    }
  }
  ${SaisonInfoFragmentDoc}
`;

/**
 * __useCurrentSaisonQuery__
 *
 * To run a query within a React component, call `useCurrentSaisonQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentSaisonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentSaisonQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentSaisonQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentSaisonQuery, CurrentSaisonQueryVariables>
) {
  return Apollo.useQuery<CurrentSaisonQuery, CurrentSaisonQueryVariables>(
    CurrentSaisonDocument,
    baseOptions
  );
}
export function useCurrentSaisonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentSaisonQuery, CurrentSaisonQueryVariables>
) {
  return Apollo.useLazyQuery<CurrentSaisonQuery, CurrentSaisonQueryVariables>(
    CurrentSaisonDocument,
    baseOptions
  );
}
export type CurrentSaisonQueryHookResult = ReturnType<typeof useCurrentSaisonQuery>;
export type CurrentSaisonLazyQueryHookResult = ReturnType<typeof useCurrentSaisonLazyQuery>;
export type CurrentSaisonQueryResult = Apollo.QueryResult<
  CurrentSaisonQuery,
  CurrentSaisonQueryVariables
>;
export const ThisMonthTournamentCountDocument = gql`
  query ThisMonthTournamentCount {
    thisMonthTournamentCount
  }
`;

/**
 * __useThisMonthTournamentCountQuery__
 *
 * To run a query within a React component, call `useThisMonthTournamentCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useThisMonthTournamentCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThisMonthTournamentCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useThisMonthTournamentCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ThisMonthTournamentCountQuery,
    ThisMonthTournamentCountQueryVariables
  >
) {
  return Apollo.useQuery<ThisMonthTournamentCountQuery, ThisMonthTournamentCountQueryVariables>(
    ThisMonthTournamentCountDocument,
    baseOptions
  );
}
export function useThisMonthTournamentCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ThisMonthTournamentCountQuery,
    ThisMonthTournamentCountQueryVariables
  >
) {
  return Apollo.useLazyQuery<ThisMonthTournamentCountQuery, ThisMonthTournamentCountQueryVariables>(
    ThisMonthTournamentCountDocument,
    baseOptions
  );
}
export type ThisMonthTournamentCountQueryHookResult = ReturnType<
  typeof useThisMonthTournamentCountQuery
>;
export type ThisMonthTournamentCountLazyQueryHookResult = ReturnType<
  typeof useThisMonthTournamentCountLazyQuery
>;
export type ThisMonthTournamentCountQueryResult = Apollo.QueryResult<
  ThisMonthTournamentCountQuery,
  ThisMonthTournamentCountQueryVariables
>;
export const CreateUserSocialAccountDocument = gql`
  mutation CreateUserSocialAccount(
    $socialAccountId: ID!
    $userId: ID
    $username: String!
    $email: String!
  ) {
    createUsersocialaccount(
      socialAccountId: $socialAccountId
      userId: $userId
      username: $username
      email: $email
    ) {
      ...UserSocialAccountInfos
    }
  }
  ${UserSocialAccountInfosFragmentDoc}
`;
export type CreateUserSocialAccountMutationFn = Apollo.MutationFunction<
  CreateUserSocialAccountMutation,
  CreateUserSocialAccountMutationVariables
>;

/**
 * __useCreateUserSocialAccountMutation__
 *
 * To run a mutation, you first call `useCreateUserSocialAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserSocialAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserSocialAccountMutation, { data, loading, error }] = useCreateUserSocialAccountMutation({
 *   variables: {
 *      socialAccountId: // value for 'socialAccountId'
 *      userId: // value for 'userId'
 *      username: // value for 'username'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateUserSocialAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserSocialAccountMutation,
    CreateUserSocialAccountMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateUserSocialAccountMutation,
    CreateUserSocialAccountMutationVariables
  >(CreateUserSocialAccountDocument, baseOptions);
}
export type CreateUserSocialAccountMutationHookResult = ReturnType<
  typeof useCreateUserSocialAccountMutation
>;
export type CreateUserSocialAccountMutationResult =
  Apollo.MutationResult<CreateUserSocialAccountMutation>;
export type CreateUserSocialAccountMutationOptions = Apollo.BaseMutationOptions<
  CreateUserSocialAccountMutation,
  CreateUserSocialAccountMutationVariables
>;
export const DeleteUsersocialaccountDocument = gql`
  mutation DeleteUsersocialaccount($id: ID!) {
    deleteUsersocialaccount(id: $id) {
      ...UserSocialAccountInfos
    }
  }
  ${UserSocialAccountInfosFragmentDoc}
`;
export type DeleteUsersocialaccountMutationFn = Apollo.MutationFunction<
  DeleteUsersocialaccountMutation,
  DeleteUsersocialaccountMutationVariables
>;

/**
 * __useDeleteUsersocialaccountMutation__
 *
 * To run a mutation, you first call `useDeleteUsersocialaccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUsersocialaccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUsersocialaccountMutation, { data, loading, error }] = useDeleteUsersocialaccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUsersocialaccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUsersocialaccountMutation,
    DeleteUsersocialaccountMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteUsersocialaccountMutation,
    DeleteUsersocialaccountMutationVariables
  >(DeleteUsersocialaccountDocument, baseOptions);
}
export type DeleteUsersocialaccountMutationHookResult = ReturnType<
  typeof useDeleteUsersocialaccountMutation
>;
export type DeleteUsersocialaccountMutationResult =
  Apollo.MutationResult<DeleteUsersocialaccountMutation>;
export type DeleteUsersocialaccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteUsersocialaccountMutation,
  DeleteUsersocialaccountMutationVariables
>;
export const AllSocialaccountsDocument = gql`
  query AllSocialaccounts {
    allSocialaccounts {
      ...SocialAccountInfos
    }
  }
  ${SocialAccountInfosFragmentDoc}
`;

/**
 * __useAllSocialaccountsQuery__
 *
 * To run a query within a React component, call `useAllSocialaccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSocialaccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSocialaccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllSocialaccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllSocialaccountsQuery, AllSocialaccountsQueryVariables>
) {
  return Apollo.useQuery<AllSocialaccountsQuery, AllSocialaccountsQueryVariables>(
    AllSocialaccountsDocument,
    baseOptions
  );
}
export function useAllSocialaccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllSocialaccountsQuery, AllSocialaccountsQueryVariables>
) {
  return Apollo.useLazyQuery<AllSocialaccountsQuery, AllSocialaccountsQueryVariables>(
    AllSocialaccountsDocument,
    baseOptions
  );
}
export type AllSocialaccountsQueryHookResult = ReturnType<typeof useAllSocialaccountsQuery>;
export type AllSocialaccountsLazyQueryHookResult = ReturnType<typeof useAllSocialaccountsLazyQuery>;
export type AllSocialaccountsQueryResult = Apollo.QueryResult<
  AllSocialaccountsQuery,
  AllSocialaccountsQueryVariables
>;
export const FindSocialAccountByLabelDocument = gql`
  query FindSocialAccountByLabel($label: String!) {
    findSocialAccountByLabel(label: $label) {
      ...SocialAccountInfos
    }
  }
  ${SocialAccountInfosFragmentDoc}
`;

/**
 * __useFindSocialAccountByLabelQuery__
 *
 * To run a query within a React component, call `useFindSocialAccountByLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSocialAccountByLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSocialAccountByLabelQuery({
 *   variables: {
 *      label: // value for 'label'
 *   },
 * });
 */
export function useFindSocialAccountByLabelQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindSocialAccountByLabelQuery,
    FindSocialAccountByLabelQueryVariables
  >
) {
  return Apollo.useQuery<FindSocialAccountByLabelQuery, FindSocialAccountByLabelQueryVariables>(
    FindSocialAccountByLabelDocument,
    baseOptions
  );
}
export function useFindSocialAccountByLabelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindSocialAccountByLabelQuery,
    FindSocialAccountByLabelQueryVariables
  >
) {
  return Apollo.useLazyQuery<FindSocialAccountByLabelQuery, FindSocialAccountByLabelQueryVariables>(
    FindSocialAccountByLabelDocument,
    baseOptions
  );
}
export type FindSocialAccountByLabelQueryHookResult = ReturnType<
  typeof useFindSocialAccountByLabelQuery
>;
export type FindSocialAccountByLabelLazyQueryHookResult = ReturnType<
  typeof useFindSocialAccountByLabelLazyQuery
>;
export type FindSocialAccountByLabelQueryResult = Apollo.QueryResult<
  FindSocialAccountByLabelQuery,
  FindSocialAccountByLabelQueryVariables
>;
export const FindUserSocialAccountByLabelDocument = gql`
  query FindUserSocialAccountByLabel($label: String!) {
    findUserSocialAccountByLabel(label: $label) {
      ...UserSocialAccountInfos
    }
  }
  ${UserSocialAccountInfosFragmentDoc}
`;

/**
 * __useFindUserSocialAccountByLabelQuery__
 *
 * To run a query within a React component, call `useFindUserSocialAccountByLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserSocialAccountByLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserSocialAccountByLabelQuery({
 *   variables: {
 *      label: // value for 'label'
 *   },
 * });
 */
export function useFindUserSocialAccountByLabelQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindUserSocialAccountByLabelQuery,
    FindUserSocialAccountByLabelQueryVariables
  >
) {
  return Apollo.useQuery<
    FindUserSocialAccountByLabelQuery,
    FindUserSocialAccountByLabelQueryVariables
  >(FindUserSocialAccountByLabelDocument, baseOptions);
}
export function useFindUserSocialAccountByLabelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindUserSocialAccountByLabelQuery,
    FindUserSocialAccountByLabelQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    FindUserSocialAccountByLabelQuery,
    FindUserSocialAccountByLabelQueryVariables
  >(FindUserSocialAccountByLabelDocument, baseOptions);
}
export type FindUserSocialAccountByLabelQueryHookResult = ReturnType<
  typeof useFindUserSocialAccountByLabelQuery
>;
export type FindUserSocialAccountByLabelLazyQueryHookResult = ReturnType<
  typeof useFindUserSocialAccountByLabelLazyQuery
>;
export type FindUserSocialAccountByLabelQueryResult = Apollo.QueryResult<
  FindUserSocialAccountByLabelQuery,
  FindUserSocialAccountByLabelQueryVariables
>;
export const HasUserSocialAccountDocument = gql`
  query HasUserSocialAccount($socialAccountId: ID!) {
    hasUserSocialAccount(socialAccountId: $socialAccountId)
  }
`;

/**
 * __useHasUserSocialAccountQuery__
 *
 * To run a query within a React component, call `useHasUserSocialAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasUserSocialAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasUserSocialAccountQuery({
 *   variables: {
 *      socialAccountId: // value for 'socialAccountId'
 *   },
 * });
 */
export function useHasUserSocialAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    HasUserSocialAccountQuery,
    HasUserSocialAccountQueryVariables
  >
) {
  return Apollo.useQuery<HasUserSocialAccountQuery, HasUserSocialAccountQueryVariables>(
    HasUserSocialAccountDocument,
    baseOptions
  );
}
export function useHasUserSocialAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HasUserSocialAccountQuery,
    HasUserSocialAccountQueryVariables
  >
) {
  return Apollo.useLazyQuery<HasUserSocialAccountQuery, HasUserSocialAccountQueryVariables>(
    HasUserSocialAccountDocument,
    baseOptions
  );
}
export type HasUserSocialAccountQueryHookResult = ReturnType<typeof useHasUserSocialAccountQuery>;
export type HasUserSocialAccountLazyQueryHookResult = ReturnType<
  typeof useHasUserSocialAccountLazyQuery
>;
export type HasUserSocialAccountQueryResult = Apollo.QueryResult<
  HasUserSocialAccountQuery,
  HasUserSocialAccountQueryVariables
>;
export const SubscribeToPermiumMutationDocument = gql`
  mutation SubscribeToPermiumMutation($input: SubscribeToPremiumInput!) {
    subscribeToPremium(input: $input) {
      ...UserInfos
      currentSubsciption {
        ...SubscriptionInfos
      }
    }
  }
  ${UserInfosFragmentDoc}
  ${SubscriptionInfosFragmentDoc}
`;
export type SubscribeToPermiumMutationMutationFn = Apollo.MutationFunction<
  SubscribeToPermiumMutationMutation,
  SubscribeToPermiumMutationMutationVariables
>;

/**
 * __useSubscribeToPermiumMutationMutation__
 *
 * To run a mutation, you first call `useSubscribeToPermiumMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToPermiumMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeToPermiumMutationMutation, { data, loading, error }] = useSubscribeToPermiumMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribeToPermiumMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubscribeToPermiumMutationMutation,
    SubscribeToPermiumMutationMutationVariables
  >
) {
  return Apollo.useMutation<
    SubscribeToPermiumMutationMutation,
    SubscribeToPermiumMutationMutationVariables
  >(SubscribeToPermiumMutationDocument, baseOptions);
}
export type SubscribeToPermiumMutationMutationHookResult = ReturnType<
  typeof useSubscribeToPermiumMutationMutation
>;
export type SubscribeToPermiumMutationMutationResult =
  Apollo.MutationResult<SubscribeToPermiumMutationMutation>;
export type SubscribeToPermiumMutationMutationOptions = Apollo.BaseMutationOptions<
  SubscribeToPermiumMutationMutation,
  SubscribeToPermiumMutationMutationVariables
>;
export const ActiveCouponMutationDocument = gql`
  mutation ActiveCouponMutation($input: ActiveCouponInput!) {
    activeCoupon(input: $input) {
      ...UserInfos
      currentSubsciption {
        ...SubscriptionInfos
      }
    }
  }
  ${UserInfosFragmentDoc}
  ${SubscriptionInfosFragmentDoc}
`;
export type ActiveCouponMutationMutationFn = Apollo.MutationFunction<
  ActiveCouponMutationMutation,
  ActiveCouponMutationMutationVariables
>;

/**
 * __useActiveCouponMutationMutation__
 *
 * To run a mutation, you first call `useActiveCouponMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActiveCouponMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activeCouponMutationMutation, { data, loading, error }] = useActiveCouponMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActiveCouponMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActiveCouponMutationMutation,
    ActiveCouponMutationMutationVariables
  >
) {
  return Apollo.useMutation<ActiveCouponMutationMutation, ActiveCouponMutationMutationVariables>(
    ActiveCouponMutationDocument,
    baseOptions
  );
}
export type ActiveCouponMutationMutationHookResult = ReturnType<
  typeof useActiveCouponMutationMutation
>;
export type ActiveCouponMutationMutationResult =
  Apollo.MutationResult<ActiveCouponMutationMutation>;
export type ActiveCouponMutationMutationOptions = Apollo.BaseMutationOptions<
  ActiveCouponMutationMutation,
  ActiveCouponMutationMutationVariables
>;
export const CurrentSubscriptionUserDocument = gql`
  query CurrentSubscriptionUser {
    me {
      id
      endPremium
      currentSubsciption {
        ...SubscriptionInfos
      }
    }
  }
  ${SubscriptionInfosFragmentDoc}
`;

/**
 * __useCurrentSubscriptionUserQuery__
 *
 * To run a query within a React component, call `useCurrentSubscriptionUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentSubscriptionUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentSubscriptionUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentSubscriptionUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentSubscriptionUserQuery,
    CurrentSubscriptionUserQueryVariables
  >
) {
  return Apollo.useQuery<CurrentSubscriptionUserQuery, CurrentSubscriptionUserQueryVariables>(
    CurrentSubscriptionUserDocument,
    baseOptions
  );
}
export function useCurrentSubscriptionUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentSubscriptionUserQuery,
    CurrentSubscriptionUserQueryVariables
  >
) {
  return Apollo.useLazyQuery<CurrentSubscriptionUserQuery, CurrentSubscriptionUserQueryVariables>(
    CurrentSubscriptionUserDocument,
    baseOptions
  );
}
export type CurrentSubscriptionUserQueryHookResult = ReturnType<
  typeof useCurrentSubscriptionUserQuery
>;
export type CurrentSubscriptionUserLazyQueryHookResult = ReturnType<
  typeof useCurrentSubscriptionUserLazyQuery
>;
export type CurrentSubscriptionUserQueryResult = Apollo.QueryResult<
  CurrentSubscriptionUserQuery,
  CurrentSubscriptionUserQueryVariables
>;
export const GetAllSubscriptionDocument = gql`
  query GetAllSubscription {
    subscriptions {
      ...SubscriptionInfos
    }
  }
  ${SubscriptionInfosFragmentDoc}
`;

/**
 * __useGetAllSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetAllSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllSubscriptionQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllSubscriptionQuery, GetAllSubscriptionQueryVariables>
) {
  return Apollo.useQuery<GetAllSubscriptionQuery, GetAllSubscriptionQueryVariables>(
    GetAllSubscriptionDocument,
    baseOptions
  );
}
export function useGetAllSubscriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllSubscriptionQuery,
    GetAllSubscriptionQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetAllSubscriptionQuery, GetAllSubscriptionQueryVariables>(
    GetAllSubscriptionDocument,
    baseOptions
  );
}
export type GetAllSubscriptionQueryHookResult = ReturnType<typeof useGetAllSubscriptionQuery>;
export type GetAllSubscriptionLazyQueryHookResult = ReturnType<
  typeof useGetAllSubscriptionLazyQuery
>;
export type GetAllSubscriptionQueryResult = Apollo.QueryResult<
  GetAllSubscriptionQuery,
  GetAllSubscriptionQueryVariables
>;
export const CreateTeamDocument = gql`
  mutation CreateTeam(
    $name: String!
    $tag: String
    $description: String
    $logo: String
    $banner: String
  ) {
    createTeam(name: $name, tag: $tag, description: $description, logo: $logo, banner: $banner) {
      ...TeamInfos
    }
  }
  ${TeamInfosFragmentDoc}
`;
export type CreateTeamMutationFn = Apollo.MutationFunction<
  CreateTeamMutation,
  CreateTeamMutationVariables
>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      name: // value for 'name'
 *      tag: // value for 'tag'
 *      description: // value for 'description'
 *      logo: // value for 'logo'
 *      banner: // value for 'banner'
 *   },
 * });
 */
export function useCreateTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTeamMutation, CreateTeamMutationVariables>
) {
  return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(
    CreateTeamDocument,
    baseOptions
  );
}
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<
  CreateTeamMutation,
  CreateTeamMutationVariables
>;
export const DeleteTeamDocument = gql`
  mutation DeleteTeam($id: ID!) {
    deleteTeam(id: $id) {
      id
    }
  }
`;
export type DeleteTeamMutationFn = Apollo.MutationFunction<
  DeleteTeamMutation,
  DeleteTeamMutationVariables
>;

/**
 * __useDeleteTeamMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMutation, { data, loading, error }] = useDeleteTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTeamMutation, DeleteTeamMutationVariables>
) {
  return Apollo.useMutation<DeleteTeamMutation, DeleteTeamMutationVariables>(
    DeleteTeamDocument,
    baseOptions
  );
}
export type DeleteTeamMutationHookResult = ReturnType<typeof useDeleteTeamMutation>;
export type DeleteTeamMutationResult = Apollo.MutationResult<DeleteTeamMutation>;
export type DeleteTeamMutationOptions = Apollo.BaseMutationOptions<
  DeleteTeamMutation,
  DeleteTeamMutationVariables
>;
export const UpdateTeamDocument = gql`
  mutation UpdateTeam(
    $id: ID!
    $name: String
    $tag: String
    $description: String
    $logo: String
    $banner: String
  ) {
    updateTeam(
      id: $id
      name: $name
      tag: $tag
      description: $description
      logo: $logo
      banner: $banner
    ) {
      ...TeamInfos
    }
  }
  ${TeamInfosFragmentDoc}
`;
export type UpdateTeamMutationFn = Apollo.MutationFunction<
  UpdateTeamMutation,
  UpdateTeamMutationVariables
>;

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      tag: // value for 'tag'
 *      description: // value for 'description'
 *      logo: // value for 'logo'
 *      banner: // value for 'banner'
 *   },
 * });
 */
export function useUpdateTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTeamMutation, UpdateTeamMutationVariables>
) {
  return Apollo.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(
    UpdateTeamDocument,
    baseOptions
  );
}
export type UpdateTeamMutationHookResult = ReturnType<typeof useUpdateTeamMutation>;
export type UpdateTeamMutationResult = Apollo.MutationResult<UpdateTeamMutation>;
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeamMutation,
  UpdateTeamMutationVariables
>;
export const GetTeamsDocument = gql`
  query GetTeams {
    me {
      id
      joinedTeams {
        id
        role
        team {
          ...TeamBasicInfos
          owner {
            id
            user {
              ...UserBasicInfos
            }
          }
        }
      }
    }
  }
  ${TeamBasicInfosFragmentDoc}
  ${UserBasicInfosFragmentDoc}
`;

/**
 * __useGetTeamsQuery__
 *
 * To run a query within a React component, call `useGetTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeamsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTeamsQuery, GetTeamsQueryVariables>
) {
  return Apollo.useQuery<GetTeamsQuery, GetTeamsQueryVariables>(GetTeamsDocument, baseOptions);
}
export function useGetTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTeamsQuery, GetTeamsQueryVariables>
) {
  return Apollo.useLazyQuery<GetTeamsQuery, GetTeamsQueryVariables>(GetTeamsDocument, baseOptions);
}
export type GetTeamsQueryHookResult = ReturnType<typeof useGetTeamsQuery>;
export type GetTeamsLazyQueryHookResult = ReturnType<typeof useGetTeamsLazyQuery>;
export type GetTeamsQueryResult = Apollo.QueryResult<GetTeamsQuery, GetTeamsQueryVariables>;
export const GetTeamDocument = gql`
  query GetTeam($id: ID!) {
    Team(id: $id) {
      ...TeamInfos
    }
  }
  ${TeamInfosFragmentDoc}
`;

/**
 * __useGetTeamQuery__
 *
 * To run a query within a React component, call `useGetTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTeamQuery(
  baseOptions: Apollo.QueryHookOptions<GetTeamQuery, GetTeamQueryVariables>
) {
  return Apollo.useQuery<GetTeamQuery, GetTeamQueryVariables>(GetTeamDocument, baseOptions);
}
export function useGetTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTeamQuery, GetTeamQueryVariables>
) {
  return Apollo.useLazyQuery<GetTeamQuery, GetTeamQueryVariables>(GetTeamDocument, baseOptions);
}
export type GetTeamQueryHookResult = ReturnType<typeof useGetTeamQuery>;
export type GetTeamLazyQueryHookResult = ReturnType<typeof useGetTeamLazyQuery>;
export type GetTeamQueryResult = Apollo.QueryResult<GetTeamQuery, GetTeamQueryVariables>;
export const ListTeamsNotInTournamentDocument = gql`
  query ListTeamsNotInTournament($filter: TeamsTournamentFilterInput!) {
    teamsTournament(filter: $filter) {
      results {
        ...TeamInfos
      }
      total
    }
  }
  ${TeamInfosFragmentDoc}
`;

/**
 * __useListTeamsNotInTournamentQuery__
 *
 * To run a query within a React component, call `useListTeamsNotInTournamentQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTeamsNotInTournamentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTeamsNotInTournamentQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListTeamsNotInTournamentQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListTeamsNotInTournamentQuery,
    ListTeamsNotInTournamentQueryVariables
  >
) {
  return Apollo.useQuery<ListTeamsNotInTournamentQuery, ListTeamsNotInTournamentQueryVariables>(
    ListTeamsNotInTournamentDocument,
    baseOptions
  );
}
export function useListTeamsNotInTournamentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListTeamsNotInTournamentQuery,
    ListTeamsNotInTournamentQueryVariables
  >
) {
  return Apollo.useLazyQuery<ListTeamsNotInTournamentQuery, ListTeamsNotInTournamentQueryVariables>(
    ListTeamsNotInTournamentDocument,
    baseOptions
  );
}
export type ListTeamsNotInTournamentQueryHookResult = ReturnType<
  typeof useListTeamsNotInTournamentQuery
>;
export type ListTeamsNotInTournamentLazyQueryHookResult = ReturnType<
  typeof useListTeamsNotInTournamentLazyQuery
>;
export type ListTeamsNotInTournamentQueryResult = Apollo.QueryResult<
  ListTeamsNotInTournamentQuery,
  ListTeamsNotInTournamentQueryVariables
>;
export const GetTeamDiscordDocument = gql`
  query GetTeamDiscord($id: ID!) {
    Team(id: $id) {
      id
      discordChannelId
      discordVocalChannelId
    }
  }
`;

/**
 * __useGetTeamDiscordQuery__
 *
 * To run a query within a React component, call `useGetTeamDiscordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamDiscordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamDiscordQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTeamDiscordQuery(
  baseOptions: Apollo.QueryHookOptions<GetTeamDiscordQuery, GetTeamDiscordQueryVariables>
) {
  return Apollo.useQuery<GetTeamDiscordQuery, GetTeamDiscordQueryVariables>(
    GetTeamDiscordDocument,
    baseOptions
  );
}
export function useGetTeamDiscordLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTeamDiscordQuery, GetTeamDiscordQueryVariables>
) {
  return Apollo.useLazyQuery<GetTeamDiscordQuery, GetTeamDiscordQueryVariables>(
    GetTeamDiscordDocument,
    baseOptions
  );
}
export type GetTeamDiscordQueryHookResult = ReturnType<typeof useGetTeamDiscordQuery>;
export type GetTeamDiscordLazyQueryHookResult = ReturnType<typeof useGetTeamDiscordLazyQuery>;
export type GetTeamDiscordQueryResult = Apollo.QueryResult<
  GetTeamDiscordQuery,
  GetTeamDiscordQueryVariables
>;
export const InviteUserToTeamDocument = gql`
  mutation InviteUserToTeam($teamId: ID!, $userId: ID) {
    inviteUserToTeam(teamId: $teamId, userId: $userId) {
      id
    }
  }
`;
export type InviteUserToTeamMutationFn = Apollo.MutationFunction<
  InviteUserToTeamMutation,
  InviteUserToTeamMutationVariables
>;

/**
 * __useInviteUserToTeamMutation__
 *
 * To run a mutation, you first call `useInviteUserToTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserToTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserToTeamMutation, { data, loading, error }] = useInviteUserToTeamMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useInviteUserToTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteUserToTeamMutation,
    InviteUserToTeamMutationVariables
  >
) {
  return Apollo.useMutation<InviteUserToTeamMutation, InviteUserToTeamMutationVariables>(
    InviteUserToTeamDocument,
    baseOptions
  );
}
export type InviteUserToTeamMutationHookResult = ReturnType<typeof useInviteUserToTeamMutation>;
export type InviteUserToTeamMutationResult = Apollo.MutationResult<InviteUserToTeamMutation>;
export type InviteUserToTeamMutationOptions = Apollo.BaseMutationOptions<
  InviteUserToTeamMutation,
  InviteUserToTeamMutationVariables
>;
export const DeleteTeamMemberDocument = gql`
  mutation DeleteTeamMember($id: ID!) {
    deleteTeamMember(id: $id) {
      n
      ok
    }
  }
`;
export type DeleteTeamMemberMutationFn = Apollo.MutationFunction<
  DeleteTeamMemberMutation,
  DeleteTeamMemberMutationVariables
>;

/**
 * __useDeleteTeamMemberMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMemberMutation, { data, loading, error }] = useDeleteTeamMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTeamMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTeamMemberMutation,
    DeleteTeamMemberMutationVariables
  >
) {
  return Apollo.useMutation<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables>(
    DeleteTeamMemberDocument,
    baseOptions
  );
}
export type DeleteTeamMemberMutationHookResult = ReturnType<typeof useDeleteTeamMemberMutation>;
export type DeleteTeamMemberMutationResult = Apollo.MutationResult<DeleteTeamMemberMutation>;
export type DeleteTeamMemberMutationOptions = Apollo.BaseMutationOptions<
  DeleteTeamMemberMutation,
  DeleteTeamMemberMutationVariables
>;
export const AllUsersNotInTeamDocument = gql`
  query AllUsersNotInTeam($teamId: ID, $q: String) {
    allUsersNotInTeam(teamId: $teamId, q: $q) {
      ...UserInfos
    }
  }
  ${UserInfosFragmentDoc}
`;

/**
 * __useAllUsersNotInTeamQuery__
 *
 * To run a query within a React component, call `useAllUsersNotInTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersNotInTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersNotInTeamQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      q: // value for 'q'
 *   },
 * });
 */
export function useAllUsersNotInTeamQuery(
  baseOptions?: Apollo.QueryHookOptions<AllUsersNotInTeamQuery, AllUsersNotInTeamQueryVariables>
) {
  return Apollo.useQuery<AllUsersNotInTeamQuery, AllUsersNotInTeamQueryVariables>(
    AllUsersNotInTeamDocument,
    baseOptions
  );
}
export function useAllUsersNotInTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllUsersNotInTeamQuery, AllUsersNotInTeamQueryVariables>
) {
  return Apollo.useLazyQuery<AllUsersNotInTeamQuery, AllUsersNotInTeamQueryVariables>(
    AllUsersNotInTeamDocument,
    baseOptions
  );
}
export type AllUsersNotInTeamQueryHookResult = ReturnType<typeof useAllUsersNotInTeamQuery>;
export type AllUsersNotInTeamLazyQueryHookResult = ReturnType<typeof useAllUsersNotInTeamLazyQuery>;
export type AllUsersNotInTeamQueryResult = Apollo.QueryResult<
  AllUsersNotInTeamQuery,
  AllUsersNotInTeamQueryVariables
>;
export const GetCustomTeamMembersDocument = gql`
  query GetCustomTeamMembers($limit: Int, $ownerIn: Boolean) {
    getCustomTeamMembers(limit: $limit, ownerIn: $ownerIn) {
      ...TeamMemberInfos
    }
  }
  ${TeamMemberInfosFragmentDoc}
`;

/**
 * __useGetCustomTeamMembersQuery__
 *
 * To run a query within a React component, call `useGetCustomTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomTeamMembersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      ownerIn: // value for 'ownerIn'
 *   },
 * });
 */
export function useGetCustomTeamMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCustomTeamMembersQuery,
    GetCustomTeamMembersQueryVariables
  >
) {
  return Apollo.useQuery<GetCustomTeamMembersQuery, GetCustomTeamMembersQueryVariables>(
    GetCustomTeamMembersDocument,
    baseOptions
  );
}
export function useGetCustomTeamMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomTeamMembersQuery,
    GetCustomTeamMembersQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetCustomTeamMembersQuery, GetCustomTeamMembersQueryVariables>(
    GetCustomTeamMembersDocument,
    baseOptions
  );
}
export type GetCustomTeamMembersQueryHookResult = ReturnType<typeof useGetCustomTeamMembersQuery>;
export type GetCustomTeamMembersLazyQueryHookResult = ReturnType<
  typeof useGetCustomTeamMembersLazyQuery
>;
export type GetCustomTeamMembersQueryResult = Apollo.QueryResult<
  GetCustomTeamMembersQuery,
  GetCustomTeamMembersQueryVariables
>;
export const GetVersusTeamMemberDocument = gql`
  query GetVersusTeamMember($limit: Int) {
    getVersusTeamMember(limit: $limit) {
      ...TeamMemberInfos
    }
  }
  ${TeamMemberInfosFragmentDoc}
`;

/**
 * __useGetVersusTeamMemberQuery__
 *
 * To run a query within a React component, call `useGetVersusTeamMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVersusTeamMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVersusTeamMemberQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetVersusTeamMemberQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVersusTeamMemberQuery, GetVersusTeamMemberQueryVariables>
) {
  return Apollo.useQuery<GetVersusTeamMemberQuery, GetVersusTeamMemberQueryVariables>(
    GetVersusTeamMemberDocument,
    baseOptions
  );
}
export function useGetVersusTeamMemberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVersusTeamMemberQuery,
    GetVersusTeamMemberQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetVersusTeamMemberQuery, GetVersusTeamMemberQueryVariables>(
    GetVersusTeamMemberDocument,
    baseOptions
  );
}
export type GetVersusTeamMemberQueryHookResult = ReturnType<typeof useGetVersusTeamMemberQuery>;
export type GetVersusTeamMemberLazyQueryHookResult = ReturnType<
  typeof useGetVersusTeamMemberLazyQuery
>;
export type GetVersusTeamMemberQueryResult = Apollo.QueryResult<
  GetVersusTeamMemberQuery,
  GetVersusTeamMemberQueryVariables
>;
export const CreateTextContentDocument = gql`
  mutation CreateTextContent(
    $contentFr: String
    $contentEn: String
    $section: String
    $textType: TEXT_TYPE
  ) {
    createTextContent(
      contentFr: $contentFr
      contentEn: $contentEn
      section: $section
      textType: $textType
    ) {
      ...TextContentInfos
    }
  }
  ${TextContentInfosFragmentDoc}
`;
export type CreateTextContentMutationFn = Apollo.MutationFunction<
  CreateTextContentMutation,
  CreateTextContentMutationVariables
>;

/**
 * __useCreateTextContentMutation__
 *
 * To run a mutation, you first call `useCreateTextContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTextContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTextContentMutation, { data, loading, error }] = useCreateTextContentMutation({
 *   variables: {
 *      contentFr: // value for 'contentFr'
 *      contentEn: // value for 'contentEn'
 *      section: // value for 'section'
 *      textType: // value for 'textType'
 *   },
 * });
 */
export function useCreateTextContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTextContentMutation,
    CreateTextContentMutationVariables
  >
) {
  return Apollo.useMutation<CreateTextContentMutation, CreateTextContentMutationVariables>(
    CreateTextContentDocument,
    baseOptions
  );
}
export type CreateTextContentMutationHookResult = ReturnType<typeof useCreateTextContentMutation>;
export type CreateTextContentMutationResult = Apollo.MutationResult<CreateTextContentMutation>;
export type CreateTextContentMutationOptions = Apollo.BaseMutationOptions<
  CreateTextContentMutation,
  CreateTextContentMutationVariables
>;
export const UpdateTextContentDocument = gql`
  mutation UpdateTextContent(
    $id: ID!
    $contentFr: String
    $contentEn: String
    $section: String
    $textType: TEXT_TYPE
  ) {
    updateTextContent(
      id: $id
      contentFr: $contentFr
      contentEn: $contentEn
      section: $section
      textType: $textType
    ) {
      ...TextContentInfos
    }
  }
  ${TextContentInfosFragmentDoc}
`;
export type UpdateTextContentMutationFn = Apollo.MutationFunction<
  UpdateTextContentMutation,
  UpdateTextContentMutationVariables
>;

/**
 * __useUpdateTextContentMutation__
 *
 * To run a mutation, you first call `useUpdateTextContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTextContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTextContentMutation, { data, loading, error }] = useUpdateTextContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      contentFr: // value for 'contentFr'
 *      contentEn: // value for 'contentEn'
 *      section: // value for 'section'
 *      textType: // value for 'textType'
 *   },
 * });
 */
export function useUpdateTextContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTextContentMutation,
    UpdateTextContentMutationVariables
  >
) {
  return Apollo.useMutation<UpdateTextContentMutation, UpdateTextContentMutationVariables>(
    UpdateTextContentDocument,
    baseOptions
  );
}
export type UpdateTextContentMutationHookResult = ReturnType<typeof useUpdateTextContentMutation>;
export type UpdateTextContentMutationResult = Apollo.MutationResult<UpdateTextContentMutation>;
export type UpdateTextContentMutationOptions = Apollo.BaseMutationOptions<
  UpdateTextContentMutation,
  UpdateTextContentMutationVariables
>;
export const DeleteTextContentDocument = gql`
  mutation DeleteTextContent($id: ID!) {
    deleteTextContent(id: $id) {
      ...TextContentInfos
    }
  }
  ${TextContentInfosFragmentDoc}
`;
export type DeleteTextContentMutationFn = Apollo.MutationFunction<
  DeleteTextContentMutation,
  DeleteTextContentMutationVariables
>;

/**
 * __useDeleteTextContentMutation__
 *
 * To run a mutation, you first call `useDeleteTextContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTextContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTextContentMutation, { data, loading, error }] = useDeleteTextContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTextContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTextContentMutation,
    DeleteTextContentMutationVariables
  >
) {
  return Apollo.useMutation<DeleteTextContentMutation, DeleteTextContentMutationVariables>(
    DeleteTextContentDocument,
    baseOptions
  );
}
export type DeleteTextContentMutationHookResult = ReturnType<typeof useDeleteTextContentMutation>;
export type DeleteTextContentMutationResult = Apollo.MutationResult<DeleteTextContentMutation>;
export type DeleteTextContentMutationOptions = Apollo.BaseMutationOptions<
  DeleteTextContentMutation,
  DeleteTextContentMutationVariables
>;
export const GetTextContentDocument = gql`
  query GetTextContent($section: String, $textType: TEXT_TYPE, $index: Int) {
    getTextContent(section: $section, textType: $textType, index: $index) {
      id
      contentFr
      contentEn
      index
      section
      textType
      removed
      createdAt
    }
  }
`;

/**
 * __useGetTextContentQuery__
 *
 * To run a query within a React component, call `useGetTextContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTextContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTextContentQuery({
 *   variables: {
 *      section: // value for 'section'
 *      textType: // value for 'textType'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useGetTextContentQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTextContentQuery, GetTextContentQueryVariables>
) {
  return Apollo.useQuery<GetTextContentQuery, GetTextContentQueryVariables>(
    GetTextContentDocument,
    baseOptions
  );
}
export function useGetTextContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTextContentQuery, GetTextContentQueryVariables>
) {
  return Apollo.useLazyQuery<GetTextContentQuery, GetTextContentQueryVariables>(
    GetTextContentDocument,
    baseOptions
  );
}
export type GetTextContentQueryHookResult = ReturnType<typeof useGetTextContentQuery>;
export type GetTextContentLazyQueryHookResult = ReturnType<typeof useGetTextContentLazyQuery>;
export type GetTextContentQueryResult = Apollo.QueryResult<
  GetTextContentQuery,
  GetTextContentQueryVariables
>;
export const ListPrivateThreadDocument = gql`
  query ListPrivateThread($filter: AllThreadFilter!) {
    allPrivateThread(filter: $filter) {
      results {
        ...ThreadInfos
        user {
          ...UserInfos
        }
      }
      total
    }
  }
  ${ThreadInfosFragmentDoc}
  ${UserInfosFragmentDoc}
`;

/**
 * __useListPrivateThreadQuery__
 *
 * To run a query within a React component, call `useListPrivateThreadQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPrivateThreadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPrivateThreadQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListPrivateThreadQuery(
  baseOptions: Apollo.QueryHookOptions<ListPrivateThreadQuery, ListPrivateThreadQueryVariables>
) {
  return Apollo.useQuery<ListPrivateThreadQuery, ListPrivateThreadQueryVariables>(
    ListPrivateThreadDocument,
    baseOptions
  );
}
export function useListPrivateThreadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListPrivateThreadQuery, ListPrivateThreadQueryVariables>
) {
  return Apollo.useLazyQuery<ListPrivateThreadQuery, ListPrivateThreadQueryVariables>(
    ListPrivateThreadDocument,
    baseOptions
  );
}
export type ListPrivateThreadQueryHookResult = ReturnType<typeof useListPrivateThreadQuery>;
export type ListPrivateThreadLazyQueryHookResult = ReturnType<typeof useListPrivateThreadLazyQuery>;
export type ListPrivateThreadQueryResult = Apollo.QueryResult<
  ListPrivateThreadQuery,
  ListPrivateThreadQueryVariables
>;
export const ThreadDetailDocument = gql`
  query ThreadDetail($threadId: ID!, $filter: Filter) {
    thread(threadId: $threadId) {
      ...ThreadInfos
      user {
        ...UserInfos
      }
      messages(filter: $filter) {
        ...MessageInfos
        author {
          ...UserInfos
        }
      }
    }
  }
  ${ThreadInfosFragmentDoc}
  ${UserInfosFragmentDoc}
  ${MessageInfosFragmentDoc}
`;

/**
 * __useThreadDetailQuery__
 *
 * To run a query within a React component, call `useThreadDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreadDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreadDetailQuery({
 *   variables: {
 *      threadId: // value for 'threadId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useThreadDetailQuery(
  baseOptions: Apollo.QueryHookOptions<ThreadDetailQuery, ThreadDetailQueryVariables>
) {
  return Apollo.useQuery<ThreadDetailQuery, ThreadDetailQueryVariables>(
    ThreadDetailDocument,
    baseOptions
  );
}
export function useThreadDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ThreadDetailQuery, ThreadDetailQueryVariables>
) {
  return Apollo.useLazyQuery<ThreadDetailQuery, ThreadDetailQueryVariables>(
    ThreadDetailDocument,
    baseOptions
  );
}
export type ThreadDetailQueryHookResult = ReturnType<typeof useThreadDetailQuery>;
export type ThreadDetailLazyQueryHookResult = ReturnType<typeof useThreadDetailLazyQuery>;
export type ThreadDetailQueryResult = Apollo.QueryResult<
  ThreadDetailQuery,
  ThreadDetailQueryVariables
>;
export const GetPrivateThreadUserDocument = gql`
  query GetPrivateThreadUser($input: GetPrivateThreadUserInput!) {
    getPrivateThreadUser(input: $input) {
      ...ThreadInfos
    }
  }
  ${ThreadInfosFragmentDoc}
`;

/**
 * __useGetPrivateThreadUserQuery__
 *
 * To run a query within a React component, call `useGetPrivateThreadUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrivateThreadUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrivateThreadUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPrivateThreadUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPrivateThreadUserQuery,
    GetPrivateThreadUserQueryVariables
  >
) {
  return Apollo.useQuery<GetPrivateThreadUserQuery, GetPrivateThreadUserQueryVariables>(
    GetPrivateThreadUserDocument,
    baseOptions
  );
}
export function useGetPrivateThreadUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPrivateThreadUserQuery,
    GetPrivateThreadUserQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetPrivateThreadUserQuery, GetPrivateThreadUserQueryVariables>(
    GetPrivateThreadUserDocument,
    baseOptions
  );
}
export type GetPrivateThreadUserQueryHookResult = ReturnType<typeof useGetPrivateThreadUserQuery>;
export type GetPrivateThreadUserLazyQueryHookResult = ReturnType<
  typeof useGetPrivateThreadUserLazyQuery
>;
export type GetPrivateThreadUserQueryResult = Apollo.QueryResult<
  GetPrivateThreadUserQuery,
  GetPrivateThreadUserQueryVariables
>;
export const UpdateTournamentDocument = gql`
  mutation UpdateTournament($input: UpdateTournamentInput!) {
    updateTournament(input: $input) {
      ...TournamentInfos
    }
  }
  ${TournamentInfosFragmentDoc}
`;
export type UpdateTournamentMutationFn = Apollo.MutationFunction<
  UpdateTournamentMutation,
  UpdateTournamentMutationVariables
>;

/**
 * __useUpdateTournamentMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentMutation, { data, loading, error }] = useUpdateTournamentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTournamentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTournamentMutation,
    UpdateTournamentMutationVariables
  >
) {
  return Apollo.useMutation<UpdateTournamentMutation, UpdateTournamentMutationVariables>(
    UpdateTournamentDocument,
    baseOptions
  );
}
export type UpdateTournamentMutationHookResult = ReturnType<typeof useUpdateTournamentMutation>;
export type UpdateTournamentMutationResult = Apollo.MutationResult<UpdateTournamentMutation>;
export type UpdateTournamentMutationOptions = Apollo.BaseMutationOptions<
  UpdateTournamentMutation,
  UpdateTournamentMutationVariables
>;
export const JoinUserToTournamentDocument = gql`
  mutation JoinUserToTournament($tournamentId: ID!) {
    joinUserToTournament(tournamentId: $tournamentId) {
      id
      user {
        ...UserInfos
      }
      team {
        ...TeamInfos
      }
    }
  }
  ${UserInfosFragmentDoc}
  ${TeamInfosFragmentDoc}
`;
export type JoinUserToTournamentMutationFn = Apollo.MutationFunction<
  JoinUserToTournamentMutation,
  JoinUserToTournamentMutationVariables
>;

/**
 * __useJoinUserToTournamentMutation__
 *
 * To run a mutation, you first call `useJoinUserToTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinUserToTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinUserToTournamentMutation, { data, loading, error }] = useJoinUserToTournamentMutation({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useJoinUserToTournamentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinUserToTournamentMutation,
    JoinUserToTournamentMutationVariables
  >
) {
  return Apollo.useMutation<JoinUserToTournamentMutation, JoinUserToTournamentMutationVariables>(
    JoinUserToTournamentDocument,
    baseOptions
  );
}
export type JoinUserToTournamentMutationHookResult = ReturnType<
  typeof useJoinUserToTournamentMutation
>;
export type JoinUserToTournamentMutationResult =
  Apollo.MutationResult<JoinUserToTournamentMutation>;
export type JoinUserToTournamentMutationOptions = Apollo.BaseMutationOptions<
  JoinUserToTournamentMutation,
  JoinUserToTournamentMutationVariables
>;
export const JoinTeamToTournamentDocument = gql`
  mutation JoinTeamToTournament($tournamentId: ID!, $teamId: ID!) {
    joinTeamToTournament(tournamentId: $tournamentId, teamId: $teamId) {
      id
      user {
        ...UserInfos
      }
      team {
        ...TeamInfos
      }
    }
  }
  ${UserInfosFragmentDoc}
  ${TeamInfosFragmentDoc}
`;
export type JoinTeamToTournamentMutationFn = Apollo.MutationFunction<
  JoinTeamToTournamentMutation,
  JoinTeamToTournamentMutationVariables
>;

/**
 * __useJoinTeamToTournamentMutation__
 *
 * To run a mutation, you first call `useJoinTeamToTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinTeamToTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinTeamToTournamentMutation, { data, loading, error }] = useJoinTeamToTournamentMutation({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useJoinTeamToTournamentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinTeamToTournamentMutation,
    JoinTeamToTournamentMutationVariables
  >
) {
  return Apollo.useMutation<JoinTeamToTournamentMutation, JoinTeamToTournamentMutationVariables>(
    JoinTeamToTournamentDocument,
    baseOptions
  );
}
export type JoinTeamToTournamentMutationHookResult = ReturnType<
  typeof useJoinTeamToTournamentMutation
>;
export type JoinTeamToTournamentMutationResult =
  Apollo.MutationResult<JoinTeamToTournamentMutation>;
export type JoinTeamToTournamentMutationOptions = Apollo.BaseMutationOptions<
  JoinTeamToTournamentMutation,
  JoinTeamToTournamentMutationVariables
>;
export const GetTournamentsDocument = gql`
  query GetTournaments(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: TournamentFilter
  ) {
    allTournaments(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...TournamentInfos
      awards {
        ...TournamentAwardInfo
      }
    }
  }
  ${TournamentInfosFragmentDoc}
  ${TournamentAwardInfoFragmentDoc}
`;

/**
 * __useGetTournamentsQuery__
 *
 * To run a query within a React component, call `useGetTournamentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTournamentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTournamentsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetTournamentsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTournamentsQuery, GetTournamentsQueryVariables>
) {
  return Apollo.useQuery<GetTournamentsQuery, GetTournamentsQueryVariables>(
    GetTournamentsDocument,
    baseOptions
  );
}
export function useGetTournamentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTournamentsQuery, GetTournamentsQueryVariables>
) {
  return Apollo.useLazyQuery<GetTournamentsQuery, GetTournamentsQueryVariables>(
    GetTournamentsDocument,
    baseOptions
  );
}
export type GetTournamentsQueryHookResult = ReturnType<typeof useGetTournamentsQuery>;
export type GetTournamentsLazyQueryHookResult = ReturnType<typeof useGetTournamentsLazyQuery>;
export type GetTournamentsQueryResult = Apollo.QueryResult<
  GetTournamentsQuery,
  GetTournamentsQueryVariables
>;
export const AllTournamentsFeaturedDocument = gql`
  query AllTournamentsFeatured(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: TournamentFilter
  ) {
    allTournamentsFeatured(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...TournamentInfos
      awards {
        ...TournamentAwardInfo
      }
      participants {
        id
        point
        user {
          ...UserBasicInfos
        }
        team {
          ...TeamBasicInfos
        }
      }
    }
  }
  ${TournamentInfosFragmentDoc}
  ${TournamentAwardInfoFragmentDoc}
  ${UserBasicInfosFragmentDoc}
  ${TeamBasicInfosFragmentDoc}
`;

/**
 * __useAllTournamentsFeaturedQuery__
 *
 * To run a query within a React component, call `useAllTournamentsFeaturedQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentsFeaturedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentsFeaturedQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllTournamentsFeaturedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllTournamentsFeaturedQuery,
    AllTournamentsFeaturedQueryVariables
  >
) {
  return Apollo.useQuery<AllTournamentsFeaturedQuery, AllTournamentsFeaturedQueryVariables>(
    AllTournamentsFeaturedDocument,
    baseOptions
  );
}
export function useAllTournamentsFeaturedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllTournamentsFeaturedQuery,
    AllTournamentsFeaturedQueryVariables
  >
) {
  return Apollo.useLazyQuery<AllTournamentsFeaturedQuery, AllTournamentsFeaturedQueryVariables>(
    AllTournamentsFeaturedDocument,
    baseOptions
  );
}
export type AllTournamentsFeaturedQueryHookResult = ReturnType<
  typeof useAllTournamentsFeaturedQuery
>;
export type AllTournamentsFeaturedLazyQueryHookResult = ReturnType<
  typeof useAllTournamentsFeaturedLazyQuery
>;
export type AllTournamentsFeaturedQueryResult = Apollo.QueryResult<
  AllTournamentsFeaturedQuery,
  AllTournamentsFeaturedQueryVariables
>;
export const AllTournamentsDocument = gql`
  query AllTournaments {
    allTournaments {
      id
      title
      isActive
      startedAt
      createdAt
      entryCost
      totalAwardValue
      rewardsTotal
      format {
        title
        type
      }
      description
      awards {
        value
      }
      gamePlateform {
        id
        name
        logo
      }
      gamemode {
        id
        title
        teamSize
        type
      }
      organiser {
        id
        name
      }
      entryType
      maxParticipants
      participants {
        id
        point
        user {
          id
          avatar
          username
          point
        }
        team {
          id
          logo
          name
          point
        }
      }
      game {
        id
        name
        logo
        twitchGameId
        mainJacket
        landingPageJacket
      }
      isMeAlreadyParticipant
    }
  }
`;

/**
 * __useAllTournamentsQuery__
 *
 * To run a query within a React component, call `useAllTournamentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllTournamentsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllTournamentsQuery, AllTournamentsQueryVariables>
) {
  return Apollo.useQuery<AllTournamentsQuery, AllTournamentsQueryVariables>(
    AllTournamentsDocument,
    baseOptions
  );
}
export function useAllTournamentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllTournamentsQuery, AllTournamentsQueryVariables>
) {
  return Apollo.useLazyQuery<AllTournamentsQuery, AllTournamentsQueryVariables>(
    AllTournamentsDocument,
    baseOptions
  );
}
export type AllTournamentsQueryHookResult = ReturnType<typeof useAllTournamentsQuery>;
export type AllTournamentsLazyQueryHookResult = ReturnType<typeof useAllTournamentsLazyQuery>;
export type AllTournamentsQueryResult = Apollo.QueryResult<
  AllTournamentsQuery,
  AllTournamentsQueryVariables
>;
export const GetActiveTournamentsDocument = gql`
  query GetActiveTournaments {
    activeTournaments {
      id
      title
      isActive
      startedAt
      createdAt
      entryCost
      rewardsTotal
      format {
        title
      }
      description
      awards {
        value
      }
      gamePlateform {
        id
        name
        logo
      }
      gamemode {
        id
        title
        teamSize
        type
      }
      maxParticipants
      participants {
        id
      }
      game {
        id
        name
        mainJacket
        logo
        twitchGameId
      }
      isMeAlreadyParticipant
    }
  }
`;

/**
 * __useGetActiveTournamentsQuery__
 *
 * To run a query within a React component, call `useGetActiveTournamentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveTournamentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveTournamentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveTournamentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActiveTournamentsQuery,
    GetActiveTournamentsQueryVariables
  >
) {
  return Apollo.useQuery<GetActiveTournamentsQuery, GetActiveTournamentsQueryVariables>(
    GetActiveTournamentsDocument,
    baseOptions
  );
}
export function useGetActiveTournamentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveTournamentsQuery,
    GetActiveTournamentsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetActiveTournamentsQuery, GetActiveTournamentsQueryVariables>(
    GetActiveTournamentsDocument,
    baseOptions
  );
}
export type GetActiveTournamentsQueryHookResult = ReturnType<typeof useGetActiveTournamentsQuery>;
export type GetActiveTournamentsLazyQueryHookResult = ReturnType<
  typeof useGetActiveTournamentsLazyQuery
>;
export type GetActiveTournamentsQueryResult = Apollo.QueryResult<
  GetActiveTournamentsQuery,
  GetActiveTournamentsQueryVariables
>;
export const GetTournamentDocument = gql`
  query GetTournament($id: ID!) {
    Tournament(id: $id) {
      ...TournamentInfos
      participants {
        id
        point
        user {
          ...UserBasicInfos
        }
        team {
          ...TeamBasicInfos
        }
      }
      rules {
        ...TournamentRuleInfos
      }
      awards {
        ...TournamentAwardInfo
      }
      brackets {
        ...TournamentBracketInfos
        participant1 {
          id
          user {
            ...UserBasicInfos
          }
          team {
            ...TeamBasicInfos
          }
        }
        participant2 {
          id
          user {
            ...UserBasicInfos
          }
          team {
            ...TeamBasicInfos
          }
        }
        winner {
          id
          user {
            ...UserBasicInfos
          }
          team {
            ...TeamBasicInfos
          }
        }
      }
    }
  }
  ${TournamentInfosFragmentDoc}
  ${UserBasicInfosFragmentDoc}
  ${TeamBasicInfosFragmentDoc}
  ${TournamentRuleInfosFragmentDoc}
  ${TournamentAwardInfoFragmentDoc}
  ${TournamentBracketInfosFragmentDoc}
`;

/**
 * __useGetTournamentQuery__
 *
 * To run a query within a React component, call `useGetTournamentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTournamentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTournamentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTournamentQuery(
  baseOptions: Apollo.QueryHookOptions<GetTournamentQuery, GetTournamentQueryVariables>
) {
  return Apollo.useQuery<GetTournamentQuery, GetTournamentQueryVariables>(
    GetTournamentDocument,
    baseOptions
  );
}
export function useGetTournamentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTournamentQuery, GetTournamentQueryVariables>
) {
  return Apollo.useLazyQuery<GetTournamentQuery, GetTournamentQueryVariables>(
    GetTournamentDocument,
    baseOptions
  );
}
export type GetTournamentQueryHookResult = ReturnType<typeof useGetTournamentQuery>;
export type GetTournamentLazyQueryHookResult = ReturnType<typeof useGetTournamentLazyQuery>;
export type GetTournamentQueryResult = Apollo.QueryResult<
  GetTournamentQuery,
  GetTournamentQueryVariables
>;
export const GetLastTenTournamentsDocument = gql`
  query GetLastTenTournaments {
    findLastTenTournaments {
      results {
        ...TournamentInfos
      }
      total
    }
  }
  ${TournamentInfosFragmentDoc}
`;

/**
 * __useGetLastTenTournamentsQuery__
 *
 * To run a query within a React component, call `useGetLastTenTournamentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastTenTournamentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastTenTournamentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLastTenTournamentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLastTenTournamentsQuery,
    GetLastTenTournamentsQueryVariables
  >
) {
  return Apollo.useQuery<GetLastTenTournamentsQuery, GetLastTenTournamentsQueryVariables>(
    GetLastTenTournamentsDocument,
    baseOptions
  );
}
export function useGetLastTenTournamentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLastTenTournamentsQuery,
    GetLastTenTournamentsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetLastTenTournamentsQuery, GetLastTenTournamentsQueryVariables>(
    GetLastTenTournamentsDocument,
    baseOptions
  );
}
export type GetLastTenTournamentsQueryHookResult = ReturnType<typeof useGetLastTenTournamentsQuery>;
export type GetLastTenTournamentsLazyQueryHookResult = ReturnType<
  typeof useGetLastTenTournamentsLazyQuery
>;
export type GetLastTenTournamentsQueryResult = Apollo.QueryResult<
  GetLastTenTournamentsQuery,
  GetLastTenTournamentsQueryVariables
>;
export const GetGeneralInfoTournamentDocument = gql`
  query GetGeneralInfoTournament($id: ID!) {
    Tournament(id: $id) {
      id
      title
      description
      discordChannelId
      startedAt
      createdAt
      isSignupEnd
      organiser {
        id
        name
      }
      game {
        id
        logo
      }
      format {
        id
        title
        type
      }
      maxParticipants
      minParticipants
      isActive
      status
      gamePlateform {
        id
        name
        logo
      }
      awards {
        id
        value
        startRank
        endRank
      }
      rounds {
        id
        roundNumber
        roundDate
      }
      region {
        id
        name
      }
      gameserver {
        id
        name
        country {
          id
          code
        }
      }
      map {
        id
        name
        thumbnail
      }
      audiochat {
        id
        name
      }
      myRanking {
        id
        rank
      }
      entryType
      descordUrl
      signupStartedAt
      signupEndAt
      totalAwardValue
      rewardsTotal
      entryCost
      signupAt
      isMeAlreadyParticipant
    }
  }
`;

/**
 * __useGetGeneralInfoTournamentQuery__
 *
 * To run a query within a React component, call `useGetGeneralInfoTournamentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralInfoTournamentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralInfoTournamentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGeneralInfoTournamentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGeneralInfoTournamentQuery,
    GetGeneralInfoTournamentQueryVariables
  >
) {
  return Apollo.useQuery<GetGeneralInfoTournamentQuery, GetGeneralInfoTournamentQueryVariables>(
    GetGeneralInfoTournamentDocument,
    baseOptions
  );
}
export function useGetGeneralInfoTournamentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGeneralInfoTournamentQuery,
    GetGeneralInfoTournamentQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetGeneralInfoTournamentQuery, GetGeneralInfoTournamentQueryVariables>(
    GetGeneralInfoTournamentDocument,
    baseOptions
  );
}
export type GetGeneralInfoTournamentQueryHookResult = ReturnType<
  typeof useGetGeneralInfoTournamentQuery
>;
export type GetGeneralInfoTournamentLazyQueryHookResult = ReturnType<
  typeof useGetGeneralInfoTournamentLazyQuery
>;
export type GetGeneralInfoTournamentQueryResult = Apollo.QueryResult<
  GetGeneralInfoTournamentQuery,
  GetGeneralInfoTournamentQueryVariables
>;
export const GetWinnerTournamentDocument = gql`
  query GetWinnerTournament($id: ID!) {
    Tournament(id: $id) {
      id
      awards {
        id
        value
        startRank
        endRank
        winnerParticipants {
          id
          user {
            ...UserBasicInfos
          }
          team {
            ...TeamBasicInfos
          }
        }
      }
    }
  }
  ${UserBasicInfosFragmentDoc}
  ${TeamBasicInfosFragmentDoc}
`;

/**
 * __useGetWinnerTournamentQuery__
 *
 * To run a query within a React component, call `useGetWinnerTournamentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWinnerTournamentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWinnerTournamentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWinnerTournamentQuery(
  baseOptions: Apollo.QueryHookOptions<GetWinnerTournamentQuery, GetWinnerTournamentQueryVariables>
) {
  return Apollo.useQuery<GetWinnerTournamentQuery, GetWinnerTournamentQueryVariables>(
    GetWinnerTournamentDocument,
    baseOptions
  );
}
export function useGetWinnerTournamentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWinnerTournamentQuery,
    GetWinnerTournamentQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetWinnerTournamentQuery, GetWinnerTournamentQueryVariables>(
    GetWinnerTournamentDocument,
    baseOptions
  );
}
export type GetWinnerTournamentQueryHookResult = ReturnType<typeof useGetWinnerTournamentQuery>;
export type GetWinnerTournamentLazyQueryHookResult = ReturnType<
  typeof useGetWinnerTournamentLazyQuery
>;
export type GetWinnerTournamentQueryResult = Apollo.QueryResult<
  GetWinnerTournamentQuery,
  GetWinnerTournamentQueryVariables
>;
export const GetBracketTournamentDocument = gql`
  query GetBracketTournament($id: ID!) {
    Tournament(id: $id) {
      id
      title
      description
      status
      startedAt
      maxParticipants
      participants {
        id
      }
      brackets {
        ...TournamentBracketInfos
        participant1 {
          id
          user {
            ...UserBasicInfos
          }
          team {
            ...TeamBasicInfos
          }
        }
        participant2 {
          id
          user {
            ...UserBasicInfos
          }
          team {
            ...TeamBasicInfos
          }
        }
        winner {
          id
          user {
            id
          }
          team {
            id
          }
        }
      }
    }
  }
  ${TournamentBracketInfosFragmentDoc}
  ${UserBasicInfosFragmentDoc}
  ${TeamBasicInfosFragmentDoc}
`;

/**
 * __useGetBracketTournamentQuery__
 *
 * To run a query within a React component, call `useGetBracketTournamentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBracketTournamentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBracketTournamentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBracketTournamentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBracketTournamentQuery,
    GetBracketTournamentQueryVariables
  >
) {
  return Apollo.useQuery<GetBracketTournamentQuery, GetBracketTournamentQueryVariables>(
    GetBracketTournamentDocument,
    baseOptions
  );
}
export function useGetBracketTournamentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBracketTournamentQuery,
    GetBracketTournamentQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetBracketTournamentQuery, GetBracketTournamentQueryVariables>(
    GetBracketTournamentDocument,
    baseOptions
  );
}
export type GetBracketTournamentQueryHookResult = ReturnType<typeof useGetBracketTournamentQuery>;
export type GetBracketTournamentLazyQueryHookResult = ReturnType<
  typeof useGetBracketTournamentLazyQuery
>;
export type GetBracketTournamentQueryResult = Apollo.QueryResult<
  GetBracketTournamentQuery,
  GetBracketTournamentQueryVariables
>;
export const GetGameTournamentDocument = gql`
  query GetGameTournament($id: ID!) {
    Tournament(id: $id) {
      id
      title
      description
      discordChannelId
      organiser {
        id
        logo
      }
      gamemode {
        id
        title
        teamSize
        type
      }
      startedAt
      endAt
      signupStartedAt
      signupEndAt
      descordUrl
      game {
        id
        name
        mainJacket
        logo
        twitchGameId
      }
      participants {
        id
      }
      gamePlateform {
        id
        name
        logo
      }
      gameserver {
        id
        country {
          id
          code
        }
      }
      isMeAlreadyParticipant
    }
  }
`;

/**
 * __useGetGameTournamentQuery__
 *
 * To run a query within a React component, call `useGetGameTournamentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameTournamentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameTournamentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGameTournamentQuery(
  baseOptions: Apollo.QueryHookOptions<GetGameTournamentQuery, GetGameTournamentQueryVariables>
) {
  return Apollo.useQuery<GetGameTournamentQuery, GetGameTournamentQueryVariables>(
    GetGameTournamentDocument,
    baseOptions
  );
}
export function useGetGameTournamentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGameTournamentQuery, GetGameTournamentQueryVariables>
) {
  return Apollo.useLazyQuery<GetGameTournamentQuery, GetGameTournamentQueryVariables>(
    GetGameTournamentDocument,
    baseOptions
  );
}
export type GetGameTournamentQueryHookResult = ReturnType<typeof useGetGameTournamentQuery>;
export type GetGameTournamentLazyQueryHookResult = ReturnType<typeof useGetGameTournamentLazyQuery>;
export type GetGameTournamentQueryResult = Apollo.QueryResult<
  GetGameTournamentQuery,
  GetGameTournamentQueryVariables
>;
export const GetParticipantsTournamentDocument = gql`
  query GetParticipantsTournament($id: ID!) {
    Tournament(id: $id) {
      id
      title
      description
      maxParticipants
      status
      signupEndAt
      entryCost
      format {
        id
        type
      }
      participants {
        id
        point
        user {
          ...UserBasicInfos
        }
        team {
          ...TeamBasicInfos
        }
      }
      isMeAlreadyParticipant
    }
  }
  ${UserBasicInfosFragmentDoc}
  ${TeamBasicInfosFragmentDoc}
`;

/**
 * __useGetParticipantsTournamentQuery__
 *
 * To run a query within a React component, call `useGetParticipantsTournamentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipantsTournamentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipantsTournamentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetParticipantsTournamentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetParticipantsTournamentQuery,
    GetParticipantsTournamentQueryVariables
  >
) {
  return Apollo.useQuery<GetParticipantsTournamentQuery, GetParticipantsTournamentQueryVariables>(
    GetParticipantsTournamentDocument,
    baseOptions
  );
}
export function useGetParticipantsTournamentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParticipantsTournamentQuery,
    GetParticipantsTournamentQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetParticipantsTournamentQuery,
    GetParticipantsTournamentQueryVariables
  >(GetParticipantsTournamentDocument, baseOptions);
}
export type GetParticipantsTournamentQueryHookResult = ReturnType<
  typeof useGetParticipantsTournamentQuery
>;
export type GetParticipantsTournamentLazyQueryHookResult = ReturnType<
  typeof useGetParticipantsTournamentLazyQuery
>;
export type GetParticipantsTournamentQueryResult = Apollo.QueryResult<
  GetParticipantsTournamentQuery,
  GetParticipantsTournamentQueryVariables
>;
export const GetRulesTournamentDocument = gql`
  query GetRulesTournament($id: ID!) {
    Tournament(id: $id) {
      id
      title
      description
      rules {
        ...TournamentRuleInfos
      }
    }
  }
  ${TournamentRuleInfosFragmentDoc}
`;

/**
 * __useGetRulesTournamentQuery__
 *
 * To run a query within a React component, call `useGetRulesTournamentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRulesTournamentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRulesTournamentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRulesTournamentQuery(
  baseOptions: Apollo.QueryHookOptions<GetRulesTournamentQuery, GetRulesTournamentQueryVariables>
) {
  return Apollo.useQuery<GetRulesTournamentQuery, GetRulesTournamentQueryVariables>(
    GetRulesTournamentDocument,
    baseOptions
  );
}
export function useGetRulesTournamentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRulesTournamentQuery,
    GetRulesTournamentQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetRulesTournamentQuery, GetRulesTournamentQueryVariables>(
    GetRulesTournamentDocument,
    baseOptions
  );
}
export type GetRulesTournamentQueryHookResult = ReturnType<typeof useGetRulesTournamentQuery>;
export type GetRulesTournamentLazyQueryHookResult = ReturnType<
  typeof useGetRulesTournamentLazyQuery
>;
export type GetRulesTournamentQueryResult = Apollo.QueryResult<
  GetRulesTournamentQuery,
  GetRulesTournamentQueryVariables
>;
export const GetTournamentDiscordDocument = gql`
  query GetTournamentDiscord($id: ID!) {
    Tournament(id: $id) {
      id
      discordChannelId
    }
  }
`;

/**
 * __useGetTournamentDiscordQuery__
 *
 * To run a query within a React component, call `useGetTournamentDiscordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTournamentDiscordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTournamentDiscordQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTournamentDiscordQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTournamentDiscordQuery,
    GetTournamentDiscordQueryVariables
  >
) {
  return Apollo.useQuery<GetTournamentDiscordQuery, GetTournamentDiscordQueryVariables>(
    GetTournamentDiscordDocument,
    baseOptions
  );
}
export function useGetTournamentDiscordLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTournamentDiscordQuery,
    GetTournamentDiscordQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetTournamentDiscordQuery, GetTournamentDiscordQueryVariables>(
    GetTournamentDiscordDocument,
    baseOptions
  );
}
export type GetTournamentDiscordQueryHookResult = ReturnType<typeof useGetTournamentDiscordQuery>;
export type GetTournamentDiscordLazyQueryHookResult = ReturnType<
  typeof useGetTournamentDiscordLazyQuery
>;
export type GetTournamentDiscordQueryResult = Apollo.QueryResult<
  GetTournamentDiscordQuery,
  GetTournamentDiscordQueryVariables
>;
export const GetTournamentInfoDocument = gql`
  query GetTournamentInfo {
    tournamentInformation {
      totalTournamentCashPrize
      totalMonthTournament
      totalTournamentParticipant
      topGame {
        game {
          id
          name
          logo
          twitchGameId
        }
        percent
      }
    }
  }
`;

/**
 * __useGetTournamentInfoQuery__
 *
 * To run a query within a React component, call `useGetTournamentInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTournamentInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTournamentInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTournamentInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTournamentInfoQuery, GetTournamentInfoQueryVariables>
) {
  return Apollo.useQuery<GetTournamentInfoQuery, GetTournamentInfoQueryVariables>(
    GetTournamentInfoDocument,
    baseOptions
  );
}
export function useGetTournamentInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTournamentInfoQuery, GetTournamentInfoQueryVariables>
) {
  return Apollo.useLazyQuery<GetTournamentInfoQuery, GetTournamentInfoQueryVariables>(
    GetTournamentInfoDocument,
    baseOptions
  );
}
export type GetTournamentInfoQueryHookResult = ReturnType<typeof useGetTournamentInfoQuery>;
export type GetTournamentInfoLazyQueryHookResult = ReturnType<typeof useGetTournamentInfoLazyQuery>;
export type GetTournamentInfoQueryResult = Apollo.QueryResult<
  GetTournamentInfoQuery,
  GetTournamentInfoQueryVariables
>;
export const SubscribeToTournamentAddedDocument = gql`
  subscription SubscribeToTournamentAdded {
    tournamentAdded {
      ...TournamentInfos
    }
  }
  ${TournamentInfosFragmentDoc}
`;

/**
 * __useSubscribeToTournamentAddedSubscription__
 *
 * To run a query within a React component, call `useSubscribeToTournamentAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToTournamentAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToTournamentAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSubscribeToTournamentAddedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToTournamentAddedSubscription,
    SubscribeToTournamentAddedSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToTournamentAddedSubscription,
    SubscribeToTournamentAddedSubscriptionVariables
  >(SubscribeToTournamentAddedDocument, baseOptions);
}
export type SubscribeToTournamentAddedSubscriptionHookResult = ReturnType<
  typeof useSubscribeToTournamentAddedSubscription
>;
export type SubscribeToTournamentAddedSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToTournamentAddedSubscription>;
export const UserSubscribedInTournamentDocument = gql`
  subscription UserSubscribedInTournament($tournamentId: ID!) {
    userSubscribedInTournament(tournamentId: $tournamentId) {
      tournamentId
      user {
        ...UserInfos
      }
    }
  }
  ${UserInfosFragmentDoc}
`;

/**
 * __useUserSubscribedInTournamentSubscription__
 *
 * To run a query within a React component, call `useUserSubscribedInTournamentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscribedInTournamentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscribedInTournamentSubscription({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useUserSubscribedInTournamentSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    UserSubscribedInTournamentSubscription,
    UserSubscribedInTournamentSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    UserSubscribedInTournamentSubscription,
    UserSubscribedInTournamentSubscriptionVariables
  >(UserSubscribedInTournamentDocument, baseOptions);
}
export type UserSubscribedInTournamentSubscriptionHookResult = ReturnType<
  typeof useUserSubscribedInTournamentSubscription
>;
export type UserSubscribedInTournamentSubscriptionResult =
  Apollo.SubscriptionResult<UserSubscribedInTournamentSubscription>;
export const SubscribeToTournamentStartedDocument = gql`
  subscription SubscribeToTournamentStarted($id: ID!) {
    tournamentStarted(id: $id) {
      ...TournamentInfos
    }
  }
  ${TournamentInfosFragmentDoc}
`;

/**
 * __useSubscribeToTournamentStartedSubscription__
 *
 * To run a query within a React component, call `useSubscribeToTournamentStartedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToTournamentStartedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToTournamentStartedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubscribeToTournamentStartedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToTournamentStartedSubscription,
    SubscribeToTournamentStartedSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToTournamentStartedSubscription,
    SubscribeToTournamentStartedSubscriptionVariables
  >(SubscribeToTournamentStartedDocument, baseOptions);
}
export type SubscribeToTournamentStartedSubscriptionHookResult = ReturnType<
  typeof useSubscribeToTournamentStartedSubscription
>;
export type SubscribeToTournamentStartedSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToTournamentStartedSubscription>;
export const SubscribeToTournamentFinishedDocument = gql`
  subscription SubscribeToTournamentFinished($id: ID!) {
    tournamentFinished(id: $id) {
      id
    }
  }
`;

/**
 * __useSubscribeToTournamentFinishedSubscription__
 *
 * To run a query within a React component, call `useSubscribeToTournamentFinishedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToTournamentFinishedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToTournamentFinishedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubscribeToTournamentFinishedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToTournamentFinishedSubscription,
    SubscribeToTournamentFinishedSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToTournamentFinishedSubscription,
    SubscribeToTournamentFinishedSubscriptionVariables
  >(SubscribeToTournamentFinishedDocument, baseOptions);
}
export type SubscribeToTournamentFinishedSubscriptionHookResult = ReturnType<
  typeof useSubscribeToTournamentFinishedSubscription
>;
export type SubscribeToTournamentFinishedSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToTournamentFinishedSubscription>;
export const StartTournamentBracketDocument = gql`
  mutation startTournamentBracket($id: ID!, $startedAt: DateTime) {
    updateTournamentBracket(id: $id, startedAt: $startedAt) {
      id
    }
  }
`;
export type StartTournamentBracketMutationFn = Apollo.MutationFunction<
  StartTournamentBracketMutation,
  StartTournamentBracketMutationVariables
>;

/**
 * __useStartTournamentBracketMutation__
 *
 * To run a mutation, you first call `useStartTournamentBracketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartTournamentBracketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startTournamentBracketMutation, { data, loading, error }] = useStartTournamentBracketMutation({
 *   variables: {
 *      id: // value for 'id'
 *      startedAt: // value for 'startedAt'
 *   },
 * });
 */
export function useStartTournamentBracketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartTournamentBracketMutation,
    StartTournamentBracketMutationVariables
  >
) {
  return Apollo.useMutation<
    StartTournamentBracketMutation,
    StartTournamentBracketMutationVariables
  >(StartTournamentBracketDocument, baseOptions);
}
export type StartTournamentBracketMutationHookResult = ReturnType<
  typeof useStartTournamentBracketMutation
>;
export type StartTournamentBracketMutationResult =
  Apollo.MutationResult<StartTournamentBracketMutation>;
export type StartTournamentBracketMutationOptions = Apollo.BaseMutationOptions<
  StartTournamentBracketMutation,
  StartTournamentBracketMutationVariables
>;
export const SetTournamentBracketWinnerDocument = gql`
  mutation setTournamentBracketWinner($id: ID!, $winnerId: ID!) {
    updateTournamentBracket(id: $id, winnerId: $winnerId) {
      id
    }
  }
`;
export type SetTournamentBracketWinnerMutationFn = Apollo.MutationFunction<
  SetTournamentBracketWinnerMutation,
  SetTournamentBracketWinnerMutationVariables
>;

/**
 * __useSetTournamentBracketWinnerMutation__
 *
 * To run a mutation, you first call `useSetTournamentBracketWinnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTournamentBracketWinnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTournamentBracketWinnerMutation, { data, loading, error }] = useSetTournamentBracketWinnerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      winnerId: // value for 'winnerId'
 *   },
 * });
 */
export function useSetTournamentBracketWinnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetTournamentBracketWinnerMutation,
    SetTournamentBracketWinnerMutationVariables
  >
) {
  return Apollo.useMutation<
    SetTournamentBracketWinnerMutation,
    SetTournamentBracketWinnerMutationVariables
  >(SetTournamentBracketWinnerDocument, baseOptions);
}
export type SetTournamentBracketWinnerMutationHookResult = ReturnType<
  typeof useSetTournamentBracketWinnerMutation
>;
export type SetTournamentBracketWinnerMutationResult =
  Apollo.MutationResult<SetTournamentBracketWinnerMutation>;
export type SetTournamentBracketWinnerMutationOptions = Apollo.BaseMutationOptions<
  SetTournamentBracketWinnerMutation,
  SetTournamentBracketWinnerMutationVariables
>;
export const MarkTournamentBracketAsFinishedDocument = gql`
  mutation markTournamentBracketAsFinished($id: ID!, $isFinished: Boolean) {
    updateTournamentBracket(id: $id, isFinished: $isFinished) {
      id
    }
  }
`;
export type MarkTournamentBracketAsFinishedMutationFn = Apollo.MutationFunction<
  MarkTournamentBracketAsFinishedMutation,
  MarkTournamentBracketAsFinishedMutationVariables
>;

/**
 * __useMarkTournamentBracketAsFinishedMutation__
 *
 * To run a mutation, you first call `useMarkTournamentBracketAsFinishedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkTournamentBracketAsFinishedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markTournamentBracketAsFinishedMutation, { data, loading, error }] = useMarkTournamentBracketAsFinishedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isFinished: // value for 'isFinished'
 *   },
 * });
 */
export function useMarkTournamentBracketAsFinishedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkTournamentBracketAsFinishedMutation,
    MarkTournamentBracketAsFinishedMutationVariables
  >
) {
  return Apollo.useMutation<
    MarkTournamentBracketAsFinishedMutation,
    MarkTournamentBracketAsFinishedMutationVariables
  >(MarkTournamentBracketAsFinishedDocument, baseOptions);
}
export type MarkTournamentBracketAsFinishedMutationHookResult = ReturnType<
  typeof useMarkTournamentBracketAsFinishedMutation
>;
export type MarkTournamentBracketAsFinishedMutationResult =
  Apollo.MutationResult<MarkTournamentBracketAsFinishedMutation>;
export type MarkTournamentBracketAsFinishedMutationOptions = Apollo.BaseMutationOptions<
  MarkTournamentBracketAsFinishedMutation,
  MarkTournamentBracketAsFinishedMutationVariables
>;
export const UpdateMatchBracketDocument = gql`
  mutation UpdateMatchBracket(
    $id: ID!
    $startedAt: DateTime
    $winnerId: ID
    $pointParticipant1: Int
    $pointParticipant2: Int
    $isLive: Boolean
    $isFinished: Boolean
    $endedAt: DateTime
  ) {
    updateTournamentBracket(
      id: $id
      startedAt: $startedAt
      winnerId: $winnerId
      pointParticipant1: $pointParticipant1
      pointParticipant2: $pointParticipant2
      isLive: $isLive
      isFinished: $isFinished
      endedAt: $endedAt
    ) {
      id
      isLive
      isFinished
      pointParticipant1
      pointParticipant2
      startedAt
      endedAt
      winner {
        id
      }
    }
  }
`;
export type UpdateMatchBracketMutationFn = Apollo.MutationFunction<
  UpdateMatchBracketMutation,
  UpdateMatchBracketMutationVariables
>;

/**
 * __useUpdateMatchBracketMutation__
 *
 * To run a mutation, you first call `useUpdateMatchBracketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMatchBracketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMatchBracketMutation, { data, loading, error }] = useUpdateMatchBracketMutation({
 *   variables: {
 *      id: // value for 'id'
 *      startedAt: // value for 'startedAt'
 *      winnerId: // value for 'winnerId'
 *      pointParticipant1: // value for 'pointParticipant1'
 *      pointParticipant2: // value for 'pointParticipant2'
 *      isLive: // value for 'isLive'
 *      isFinished: // value for 'isFinished'
 *      endedAt: // value for 'endedAt'
 *   },
 * });
 */
export function useUpdateMatchBracketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMatchBracketMutation,
    UpdateMatchBracketMutationVariables
  >
) {
  return Apollo.useMutation<UpdateMatchBracketMutation, UpdateMatchBracketMutationVariables>(
    UpdateMatchBracketDocument,
    baseOptions
  );
}
export type UpdateMatchBracketMutationHookResult = ReturnType<typeof useUpdateMatchBracketMutation>;
export type UpdateMatchBracketMutationResult = Apollo.MutationResult<UpdateMatchBracketMutation>;
export type UpdateMatchBracketMutationOptions = Apollo.BaseMutationOptions<
  UpdateMatchBracketMutation,
  UpdateMatchBracketMutationVariables
>;
export const GetMatchDetailsDocument = gql`
  query getMatchDetails($id: ID!) {
    TournamentBracket(id: $id) {
      ...TournamentBracketCompleteInfos
    }
  }
  ${TournamentBracketCompleteInfosFragmentDoc}
`;

/**
 * __useGetMatchDetailsQuery__
 *
 * To run a query within a React component, call `useGetMatchDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMatchDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMatchDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMatchDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetMatchDetailsQuery, GetMatchDetailsQueryVariables>
) {
  return Apollo.useQuery<GetMatchDetailsQuery, GetMatchDetailsQueryVariables>(
    GetMatchDetailsDocument,
    baseOptions
  );
}
export function useGetMatchDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMatchDetailsQuery, GetMatchDetailsQueryVariables>
) {
  return Apollo.useLazyQuery<GetMatchDetailsQuery, GetMatchDetailsQueryVariables>(
    GetMatchDetailsDocument,
    baseOptions
  );
}
export type GetMatchDetailsQueryHookResult = ReturnType<typeof useGetMatchDetailsQuery>;
export type GetMatchDetailsLazyQueryHookResult = ReturnType<typeof useGetMatchDetailsLazyQuery>;
export type GetMatchDetailsQueryResult = Apollo.QueryResult<
  GetMatchDetailsQuery,
  GetMatchDetailsQueryVariables
>;
export const GetTournamentFormatsDocument = gql`
  query GetTournamentFormats {
    tournamentFormats {
      ...TournamentFormatInfos
    }
  }
  ${TournamentFormatInfosFragmentDoc}
`;

/**
 * __useGetTournamentFormatsQuery__
 *
 * To run a query within a React component, call `useGetTournamentFormatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTournamentFormatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTournamentFormatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTournamentFormatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTournamentFormatsQuery,
    GetTournamentFormatsQueryVariables
  >
) {
  return Apollo.useQuery<GetTournamentFormatsQuery, GetTournamentFormatsQueryVariables>(
    GetTournamentFormatsDocument,
    baseOptions
  );
}
export function useGetTournamentFormatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTournamentFormatsQuery,
    GetTournamentFormatsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetTournamentFormatsQuery, GetTournamentFormatsQueryVariables>(
    GetTournamentFormatsDocument,
    baseOptions
  );
}
export type GetTournamentFormatsQueryHookResult = ReturnType<typeof useGetTournamentFormatsQuery>;
export type GetTournamentFormatsLazyQueryHookResult = ReturnType<
  typeof useGetTournamentFormatsLazyQuery
>;
export type GetTournamentFormatsQueryResult = Apollo.QueryResult<
  GetTournamentFormatsQuery,
  GetTournamentFormatsQueryVariables
>;
export const AddTournamentGainDocument = gql`
  mutation AddTournamentGain($input: AddGainInput!) {
    addTournamentGain(input: $input) {
      id
    }
  }
`;
export type AddTournamentGainMutationFn = Apollo.MutationFunction<
  AddTournamentGainMutation,
  AddTournamentGainMutationVariables
>;

/**
 * __useAddTournamentGainMutation__
 *
 * To run a mutation, you first call `useAddTournamentGainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTournamentGainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTournamentGainMutation, { data, loading, error }] = useAddTournamentGainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTournamentGainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTournamentGainMutation,
    AddTournamentGainMutationVariables
  >
) {
  return Apollo.useMutation<AddTournamentGainMutation, AddTournamentGainMutationVariables>(
    AddTournamentGainDocument,
    baseOptions
  );
}
export type AddTournamentGainMutationHookResult = ReturnType<typeof useAddTournamentGainMutation>;
export type AddTournamentGainMutationResult = Apollo.MutationResult<AddTournamentGainMutation>;
export type AddTournamentGainMutationOptions = Apollo.BaseMutationOptions<
  AddTournamentGainMutation,
  AddTournamentGainMutationVariables
>;
export const EditTournamentGainDocument = gql`
  mutation EditTournamentGain($input: EditGainInput!) {
    editTournamentGain(input: $input) {
      ...TournamentGainInfos
    }
  }
  ${TournamentGainInfosFragmentDoc}
`;
export type EditTournamentGainMutationFn = Apollo.MutationFunction<
  EditTournamentGainMutation,
  EditTournamentGainMutationVariables
>;

/**
 * __useEditTournamentGainMutation__
 *
 * To run a mutation, you first call `useEditTournamentGainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTournamentGainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTournamentGainMutation, { data, loading, error }] = useEditTournamentGainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTournamentGainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditTournamentGainMutation,
    EditTournamentGainMutationVariables
  >
) {
  return Apollo.useMutation<EditTournamentGainMutation, EditTournamentGainMutationVariables>(
    EditTournamentGainDocument,
    baseOptions
  );
}
export type EditTournamentGainMutationHookResult = ReturnType<typeof useEditTournamentGainMutation>;
export type EditTournamentGainMutationResult = Apollo.MutationResult<EditTournamentGainMutation>;
export type EditTournamentGainMutationOptions = Apollo.BaseMutationOptions<
  EditTournamentGainMutation,
  EditTournamentGainMutationVariables
>;
export const DeleteTournamentGainDocument = gql`
  mutation DeleteTournamentGain($input: DeleteGainInput!) {
    deleteTournamentGain(input: $input) {
      ...TournamentGainInfos
    }
  }
  ${TournamentGainInfosFragmentDoc}
`;
export type DeleteTournamentGainMutationFn = Apollo.MutationFunction<
  DeleteTournamentGainMutation,
  DeleteTournamentGainMutationVariables
>;

/**
 * __useDeleteTournamentGainMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentGainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentGainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentGainMutation, { data, loading, error }] = useDeleteTournamentGainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTournamentGainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTournamentGainMutation,
    DeleteTournamentGainMutationVariables
  >
) {
  return Apollo.useMutation<DeleteTournamentGainMutation, DeleteTournamentGainMutationVariables>(
    DeleteTournamentGainDocument,
    baseOptions
  );
}
export type DeleteTournamentGainMutationHookResult = ReturnType<
  typeof useDeleteTournamentGainMutation
>;
export type DeleteTournamentGainMutationResult =
  Apollo.MutationResult<DeleteTournamentGainMutation>;
export type DeleteTournamentGainMutationOptions = Apollo.BaseMutationOptions<
  DeleteTournamentGainMutation,
  DeleteTournamentGainMutationVariables
>;
export const SetMatchPointDocument = gql`
  mutation SetMatchPoint($input: SetMatchPointInput!) {
    setMatchPoint(input: $input) {
      ...MatchInfos
    }
  }
  ${MatchInfosFragmentDoc}
`;
export type SetMatchPointMutationFn = Apollo.MutationFunction<
  SetMatchPointMutation,
  SetMatchPointMutationVariables
>;

/**
 * __useSetMatchPointMutation__
 *
 * To run a mutation, you first call `useSetMatchPointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMatchPointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMatchPointMutation, { data, loading, error }] = useSetMatchPointMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetMatchPointMutation(
  baseOptions?: Apollo.MutationHookOptions<SetMatchPointMutation, SetMatchPointMutationVariables>
) {
  return Apollo.useMutation<SetMatchPointMutation, SetMatchPointMutationVariables>(
    SetMatchPointDocument,
    baseOptions
  );
}
export type SetMatchPointMutationHookResult = ReturnType<typeof useSetMatchPointMutation>;
export type SetMatchPointMutationResult = Apollo.MutationResult<SetMatchPointMutation>;
export type SetMatchPointMutationOptions = Apollo.BaseMutationOptions<
  SetMatchPointMutation,
  SetMatchPointMutationVariables
>;
export const FinishMatchDocument = gql`
  mutation FinishMatch($input: FinishMatchInput!) {
    finishMatch(input: $input) {
      match {
        ...MatchInfos
      }
      nextMatch {
        ...MatchInfos
      }
      thirdOperatedMatch {
        ...MatchInfos
      }
    }
  }
  ${MatchInfosFragmentDoc}
`;
export type FinishMatchMutationFn = Apollo.MutationFunction<
  FinishMatchMutation,
  FinishMatchMutationVariables
>;

/**
 * __useFinishMatchMutation__
 *
 * To run a mutation, you first call `useFinishMatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishMatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishMatchMutation, { data, loading, error }] = useFinishMatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinishMatchMutation(
  baseOptions?: Apollo.MutationHookOptions<FinishMatchMutation, FinishMatchMutationVariables>
) {
  return Apollo.useMutation<FinishMatchMutation, FinishMatchMutationVariables>(
    FinishMatchDocument,
    baseOptions
  );
}
export type FinishMatchMutationHookResult = ReturnType<typeof useFinishMatchMutation>;
export type FinishMatchMutationResult = Apollo.MutationResult<FinishMatchMutation>;
export type FinishMatchMutationOptions = Apollo.BaseMutationOptions<
  FinishMatchMutation,
  FinishMatchMutationVariables
>;
export const SubscribeToMatchPointSetDocument = gql`
  subscription SubscribeToMatchPointSet($tournamentId: ID!) {
    matchPointSet(tournamentId: $tournamentId) {
      ...MatchInfos
    }
  }
  ${MatchInfosFragmentDoc}
`;

/**
 * __useSubscribeToMatchPointSetSubscription__
 *
 * To run a query within a React component, call `useSubscribeToMatchPointSetSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToMatchPointSetSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToMatchPointSetSubscription({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useSubscribeToMatchPointSetSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToMatchPointSetSubscription,
    SubscribeToMatchPointSetSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToMatchPointSetSubscription,
    SubscribeToMatchPointSetSubscriptionVariables
  >(SubscribeToMatchPointSetDocument, baseOptions);
}
export type SubscribeToMatchPointSetSubscriptionHookResult = ReturnType<
  typeof useSubscribeToMatchPointSetSubscription
>;
export type SubscribeToMatchPointSetSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToMatchPointSetSubscription>;
export const SubscribeToMatchFinishedDocument = gql`
  subscription SubscribeToMatchFinished($tournamentId: ID!) {
    matchFinished(tournamentId: $tournamentId) {
      match {
        ...MatchInfos
      }
      nextMatch {
        ...MatchInfos
      }
      thirdOperatedMatch {
        ...MatchInfos
      }
    }
  }
  ${MatchInfosFragmentDoc}
`;

/**
 * __useSubscribeToMatchFinishedSubscription__
 *
 * To run a query within a React component, call `useSubscribeToMatchFinishedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToMatchFinishedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToMatchFinishedSubscription({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useSubscribeToMatchFinishedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToMatchFinishedSubscription,
    SubscribeToMatchFinishedSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToMatchFinishedSubscription,
    SubscribeToMatchFinishedSubscriptionVariables
  >(SubscribeToMatchFinishedDocument, baseOptions);
}
export type SubscribeToMatchFinishedSubscriptionHookResult = ReturnType<
  typeof useSubscribeToMatchFinishedSubscription
>;
export type SubscribeToMatchFinishedSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToMatchFinishedSubscription>;
export const AddTournamentMessageTextDocument = gql`
  mutation AddTournamentMessageText($input: AddTournamentMessageInput!) {
    addTournamentMessage(input: $input) {
      ...TournamentMessageInfos
    }
  }
  ${TournamentMessageInfosFragmentDoc}
`;
export type AddTournamentMessageTextMutationFn = Apollo.MutationFunction<
  AddTournamentMessageTextMutation,
  AddTournamentMessageTextMutationVariables
>;

/**
 * __useAddTournamentMessageTextMutation__
 *
 * To run a mutation, you first call `useAddTournamentMessageTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTournamentMessageTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTournamentMessageTextMutation, { data, loading, error }] = useAddTournamentMessageTextMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTournamentMessageTextMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTournamentMessageTextMutation,
    AddTournamentMessageTextMutationVariables
  >
) {
  return Apollo.useMutation<
    AddTournamentMessageTextMutation,
    AddTournamentMessageTextMutationVariables
  >(AddTournamentMessageTextDocument, baseOptions);
}
export type AddTournamentMessageTextMutationHookResult = ReturnType<
  typeof useAddTournamentMessageTextMutation
>;
export type AddTournamentMessageTextMutationResult =
  Apollo.MutationResult<AddTournamentMessageTextMutation>;
export type AddTournamentMessageTextMutationOptions = Apollo.BaseMutationOptions<
  AddTournamentMessageTextMutation,
  AddTournamentMessageTextMutationVariables
>;
export const RemoveTournamentMessageDocument = gql`
  mutation RemoveTournamentMessage($id: ID!) {
    removeTournamentMessage(id: $id) {
      id
      tournamentId
      isRemoved
    }
  }
`;
export type RemoveTournamentMessageMutationFn = Apollo.MutationFunction<
  RemoveTournamentMessageMutation,
  RemoveTournamentMessageMutationVariables
>;

/**
 * __useRemoveTournamentMessageMutation__
 *
 * To run a mutation, you first call `useRemoveTournamentMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTournamentMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTournamentMessageMutation, { data, loading, error }] = useRemoveTournamentMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveTournamentMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveTournamentMessageMutation,
    RemoveTournamentMessageMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveTournamentMessageMutation,
    RemoveTournamentMessageMutationVariables
  >(RemoveTournamentMessageDocument, baseOptions);
}
export type RemoveTournamentMessageMutationHookResult = ReturnType<
  typeof useRemoveTournamentMessageMutation
>;
export type RemoveTournamentMessageMutationResult =
  Apollo.MutationResult<RemoveTournamentMessageMutation>;
export type RemoveTournamentMessageMutationOptions = Apollo.BaseMutationOptions<
  RemoveTournamentMessageMutation,
  RemoveTournamentMessageMutationVariables
>;
export const GetTournamentMessagesDocument = gql`
  query GetTournamentMessages($tournamentId: ID!, $filter: FilterTournamentMessageInput!) {
    Tournament(id: $tournamentId) {
      id
    }
  }
`;

/**
 * __useGetTournamentMessagesQuery__
 *
 * To run a query within a React component, call `useGetTournamentMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTournamentMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTournamentMessagesQuery({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetTournamentMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTournamentMessagesQuery,
    GetTournamentMessagesQueryVariables
  >
) {
  return Apollo.useQuery<GetTournamentMessagesQuery, GetTournamentMessagesQueryVariables>(
    GetTournamentMessagesDocument,
    baseOptions
  );
}
export function useGetTournamentMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTournamentMessagesQuery,
    GetTournamentMessagesQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetTournamentMessagesQuery, GetTournamentMessagesQueryVariables>(
    GetTournamentMessagesDocument,
    baseOptions
  );
}
export type GetTournamentMessagesQueryHookResult = ReturnType<typeof useGetTournamentMessagesQuery>;
export type GetTournamentMessagesLazyQueryHookResult = ReturnType<
  typeof useGetTournamentMessagesLazyQuery
>;
export type GetTournamentMessagesQueryResult = Apollo.QueryResult<
  GetTournamentMessagesQuery,
  GetTournamentMessagesQueryVariables
>;
export const SubscribeToTournamentMessageAddedDocument = gql`
  subscription SubscribeToTournamentMessageAdded($tournamentId: ID!) {
    tournamentMessageAdded(tournamentId: $tournamentId) {
      ...TournamentMessageInfos
    }
  }
  ${TournamentMessageInfosFragmentDoc}
`;

/**
 * __useSubscribeToTournamentMessageAddedSubscription__
 *
 * To run a query within a React component, call `useSubscribeToTournamentMessageAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToTournamentMessageAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToTournamentMessageAddedSubscription({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useSubscribeToTournamentMessageAddedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToTournamentMessageAddedSubscription,
    SubscribeToTournamentMessageAddedSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToTournamentMessageAddedSubscription,
    SubscribeToTournamentMessageAddedSubscriptionVariables
  >(SubscribeToTournamentMessageAddedDocument, baseOptions);
}
export type SubscribeToTournamentMessageAddedSubscriptionHookResult = ReturnType<
  typeof useSubscribeToTournamentMessageAddedSubscription
>;
export type SubscribeToTournamentMessageAddedSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToTournamentMessageAddedSubscription>;
export const SubscribeToTournamentMessageRemovedDocument = gql`
  subscription SubscribeToTournamentMessageRemoved($tournamentId: ID!) {
    tournamentMessageRemoved(tournamentId: $tournamentId) {
      id
      isRemoved
      tournamentId
    }
  }
`;

/**
 * __useSubscribeToTournamentMessageRemovedSubscription__
 *
 * To run a query within a React component, call `useSubscribeToTournamentMessageRemovedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToTournamentMessageRemovedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToTournamentMessageRemovedSubscription({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useSubscribeToTournamentMessageRemovedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToTournamentMessageRemovedSubscription,
    SubscribeToTournamentMessageRemovedSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToTournamentMessageRemovedSubscription,
    SubscribeToTournamentMessageRemovedSubscriptionVariables
  >(SubscribeToTournamentMessageRemovedDocument, baseOptions);
}
export type SubscribeToTournamentMessageRemovedSubscriptionHookResult = ReturnType<
  typeof useSubscribeToTournamentMessageRemovedSubscription
>;
export type SubscribeToTournamentMessageRemovedSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToTournamentMessageRemovedSubscription>;
export const TrainingsDocument = gql`
  query trainings {
    trainings {
      id
      trainingGameName
      logo
      jacketImage
      trainingPrograms {
        id
        programName
        programLevel
        programEstimateDuration
        progression
        programDescription
      }
    }
  }
`;

/**
 * __useTrainingsQuery__
 *
 * To run a query within a React component, call `useTrainingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrainingsQuery(
  baseOptions?: Apollo.QueryHookOptions<TrainingsQuery, TrainingsQueryVariables>
) {
  return Apollo.useQuery<TrainingsQuery, TrainingsQueryVariables>(TrainingsDocument, baseOptions);
}
export function useTrainingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TrainingsQuery, TrainingsQueryVariables>
) {
  return Apollo.useLazyQuery<TrainingsQuery, TrainingsQueryVariables>(
    TrainingsDocument,
    baseOptions
  );
}
export type TrainingsQueryHookResult = ReturnType<typeof useTrainingsQuery>;
export type TrainingsLazyQueryHookResult = ReturnType<typeof useTrainingsLazyQuery>;
export type TrainingsQueryResult = Apollo.QueryResult<TrainingsQuery, TrainingsQueryVariables>;
export const GetTrainingDetailDocument = gql`
  query getTrainingDetail($trainingProgramId: ID!) {
    trainingDetail(trainingProgramId: $trainingProgramId) {
      id
      trainingPrograms {
        id
        programName
        programLevel
        programEstimateDuration
        progression
        programDescription
      }
      parent
      advice
      about {
        basicIntro
        perks
        skills
      }
      system {
        description
        steps
        assignment
      }
    }
  }
`;

/**
 * __useGetTrainingDetailQuery__
 *
 * To run a query within a React component, call `useGetTrainingDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrainingDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrainingDetailQuery({
 *   variables: {
 *      trainingProgramId: // value for 'trainingProgramId'
 *   },
 * });
 */
export function useGetTrainingDetailQuery(
  baseOptions: Apollo.QueryHookOptions<GetTrainingDetailQuery, GetTrainingDetailQueryVariables>
) {
  return Apollo.useQuery<GetTrainingDetailQuery, GetTrainingDetailQueryVariables>(
    GetTrainingDetailDocument,
    baseOptions
  );
}
export function useGetTrainingDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTrainingDetailQuery, GetTrainingDetailQueryVariables>
) {
  return Apollo.useLazyQuery<GetTrainingDetailQuery, GetTrainingDetailQueryVariables>(
    GetTrainingDetailDocument,
    baseOptions
  );
}
export type GetTrainingDetailQueryHookResult = ReturnType<typeof useGetTrainingDetailQuery>;
export type GetTrainingDetailLazyQueryHookResult = ReturnType<typeof useGetTrainingDetailLazyQuery>;
export type GetTrainingDetailQueryResult = Apollo.QueryResult<
  GetTrainingDetailQuery,
  GetTrainingDetailQueryVariables
>;
export const AllUserTransactionsDocument = gql`
  query AllUserTransactions {
    me {
      id
      transactions {
        ...TransactionInfos
      }
    }
  }
  ${TransactionInfosFragmentDoc}
`;

/**
 * __useAllUserTransactionsQuery__
 *
 * To run a query within a React component, call `useAllUserTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserTransactionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllUserTransactionsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllUserTransactionsQuery, AllUserTransactionsQueryVariables>
) {
  return Apollo.useQuery<AllUserTransactionsQuery, AllUserTransactionsQueryVariables>(
    AllUserTransactionsDocument,
    baseOptions
  );
}
export function useAllUserTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllUserTransactionsQuery,
    AllUserTransactionsQueryVariables
  >
) {
  return Apollo.useLazyQuery<AllUserTransactionsQuery, AllUserTransactionsQueryVariables>(
    AllUserTransactionsDocument,
    baseOptions
  );
}
export type AllUserTransactionsQueryHookResult = ReturnType<typeof useAllUserTransactionsQuery>;
export type AllUserTransactionsLazyQueryHookResult = ReturnType<
  typeof useAllUserTransactionsLazyQuery
>;
export type AllUserTransactionsQueryResult = Apollo.QueryResult<
  AllUserTransactionsQuery,
  AllUserTransactionsQueryVariables
>;
export const AuthorizeTwichUserDocument = gql`
  mutation AuthorizeTwichUser($code: String!) {
    authorizeTwichUser(code: $code) {
      id
      twitchLogin
      twitchUserId
    }
  }
`;
export type AuthorizeTwichUserMutationFn = Apollo.MutationFunction<
  AuthorizeTwichUserMutation,
  AuthorizeTwichUserMutationVariables
>;

/**
 * __useAuthorizeTwichUserMutation__
 *
 * To run a mutation, you first call `useAuthorizeTwichUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorizeTwichUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorizeTwichUserMutation, { data, loading, error }] = useAuthorizeTwichUserMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useAuthorizeTwichUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthorizeTwichUserMutation,
    AuthorizeTwichUserMutationVariables
  >
) {
  return Apollo.useMutation<AuthorizeTwichUserMutation, AuthorizeTwichUserMutationVariables>(
    AuthorizeTwichUserDocument,
    baseOptions
  );
}
export type AuthorizeTwichUserMutationHookResult = ReturnType<typeof useAuthorizeTwichUserMutation>;
export type AuthorizeTwichUserMutationResult = Apollo.MutationResult<AuthorizeTwichUserMutation>;
export type AuthorizeTwichUserMutationOptions = Apollo.BaseMutationOptions<
  AuthorizeTwichUserMutation,
  AuthorizeTwichUserMutationVariables
>;
export const ActiveTwitchLiveDocument = gql`
  query ActiveTwitchLive($page: Int, $perPage: Int, $gameId: ID) {
    activeTwitchLive(page: $page, perPage: $perPage, gameId: $gameId) {
      ...TwitchLiveInfos
    }
  }
  ${TwitchLiveInfosFragmentDoc}
`;

/**
 * __useActiveTwitchLiveQuery__
 *
 * To run a query within a React component, call `useActiveTwitchLiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveTwitchLiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveTwitchLiveQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useActiveTwitchLiveQuery(
  baseOptions?: Apollo.QueryHookOptions<ActiveTwitchLiveQuery, ActiveTwitchLiveQueryVariables>
) {
  return Apollo.useQuery<ActiveTwitchLiveQuery, ActiveTwitchLiveQueryVariables>(
    ActiveTwitchLiveDocument,
    baseOptions
  );
}
export function useActiveTwitchLiveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ActiveTwitchLiveQuery, ActiveTwitchLiveQueryVariables>
) {
  return Apollo.useLazyQuery<ActiveTwitchLiveQuery, ActiveTwitchLiveQueryVariables>(
    ActiveTwitchLiveDocument,
    baseOptions
  );
}
export type ActiveTwitchLiveQueryHookResult = ReturnType<typeof useActiveTwitchLiveQuery>;
export type ActiveTwitchLiveLazyQueryHookResult = ReturnType<typeof useActiveTwitchLiveLazyQuery>;
export type ActiveTwitchLiveQueryResult = Apollo.QueryResult<
  ActiveTwitchLiveQuery,
  ActiveTwitchLiveQueryVariables
>;
export const EditProfilMutationDocument = gql`
  mutation EditProfilMutation(
    $id: ID!
    $email: String
    $username: String
    $birthDate: Date
    $avatar: String
    $country: String
    $language: String
    $firstname: String
    $lastname: String
    $isSignupFinish: Boolean
  ) {
    updateUser(
      id: $id
      email: $email
      username: $username
      birthDate: $birthDate
      avatar: $avatar
      country: $country
      language: $language
      firstname: $firstname
      lastname: $lastname
      isSignupFinish: $isSignupFinish
    ) {
      ...UserInfos
    }
  }
  ${UserInfosFragmentDoc}
`;
export type EditProfilMutationMutationFn = Apollo.MutationFunction<
  EditProfilMutationMutation,
  EditProfilMutationMutationVariables
>;

/**
 * __useEditProfilMutationMutation__
 *
 * To run a mutation, you first call `useEditProfilMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProfilMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProfilMutationMutation, { data, loading, error }] = useEditProfilMutationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      username: // value for 'username'
 *      birthDate: // value for 'birthDate'
 *      avatar: // value for 'avatar'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      isSignupFinish: // value for 'isSignupFinish'
 *   },
 * });
 */
export function useEditProfilMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditProfilMutationMutation,
    EditProfilMutationMutationVariables
  >
) {
  return Apollo.useMutation<EditProfilMutationMutation, EditProfilMutationMutationVariables>(
    EditProfilMutationDocument,
    baseOptions
  );
}
export type EditProfilMutationMutationHookResult = ReturnType<typeof useEditProfilMutationMutation>;
export type EditProfilMutationMutationResult = Apollo.MutationResult<EditProfilMutationMutation>;
export type EditProfilMutationMutationOptions = Apollo.BaseMutationOptions<
  EditProfilMutationMutation,
  EditProfilMutationMutationVariables
>;
export const CheckUserUidExistsDocument = gql`
  mutation CheckUserUidExists($uid: String!) {
    checkUserUidExists(uid: $uid)
  }
`;
export type CheckUserUidExistsMutationFn = Apollo.MutationFunction<
  CheckUserUidExistsMutation,
  CheckUserUidExistsMutationVariables
>;

/**
 * __useCheckUserUidExistsMutation__
 *
 * To run a mutation, you first call `useCheckUserUidExistsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckUserUidExistsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkUserUidExistsMutation, { data, loading, error }] = useCheckUserUidExistsMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useCheckUserUidExistsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckUserUidExistsMutation,
    CheckUserUidExistsMutationVariables
  >
) {
  return Apollo.useMutation<CheckUserUidExistsMutation, CheckUserUidExistsMutationVariables>(
    CheckUserUidExistsDocument,
    baseOptions
  );
}
export type CheckUserUidExistsMutationHookResult = ReturnType<typeof useCheckUserUidExistsMutation>;
export type CheckUserUidExistsMutationResult = Apollo.MutationResult<CheckUserUidExistsMutation>;
export type CheckUserUidExistsMutationOptions = Apollo.BaseMutationOptions<
  CheckUserUidExistsMutation,
  CheckUserUidExistsMutationVariables
>;
export const ForgotPasswordDocument = gql`
  mutation ForgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input)
  }
`;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForgotPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>
) {
  return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(
    ForgotPasswordDocument,
    baseOptions
  );
}
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;
export const CheckExpiredTokenDocument = gql`
  mutation CheckExpiredToken($input: CheckExpiredTokenInput!) {
    checkExpiredToken(input: $input)
  }
`;
export type CheckExpiredTokenMutationFn = Apollo.MutationFunction<
  CheckExpiredTokenMutation,
  CheckExpiredTokenMutationVariables
>;

/**
 * __useCheckExpiredTokenMutation__
 *
 * To run a mutation, you first call `useCheckExpiredTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckExpiredTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkExpiredTokenMutation, { data, loading, error }] = useCheckExpiredTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckExpiredTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckExpiredTokenMutation,
    CheckExpiredTokenMutationVariables
  >
) {
  return Apollo.useMutation<CheckExpiredTokenMutation, CheckExpiredTokenMutationVariables>(
    CheckExpiredTokenDocument,
    baseOptions
  );
}
export type CheckExpiredTokenMutationHookResult = ReturnType<typeof useCheckExpiredTokenMutation>;
export type CheckExpiredTokenMutationResult = Apollo.MutationResult<CheckExpiredTokenMutation>;
export type CheckExpiredTokenMutationOptions = Apollo.BaseMutationOptions<
  CheckExpiredTokenMutation,
  CheckExpiredTokenMutationVariables
>;
export const ChangePasswordDocument = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input)
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>
) {
  return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(
    ChangePasswordDocument,
    baseOptions
  );
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const CheckSocialAuthDocument = gql`
  mutation CheckSocialAuth($socialApp: SOCIAL_APP, $uid: String!) {
    checkSocialAuth(socialApp: $socialApp, uid: $uid)
  }
`;
export type CheckSocialAuthMutationFn = Apollo.MutationFunction<
  CheckSocialAuthMutation,
  CheckSocialAuthMutationVariables
>;

/**
 * __useCheckSocialAuthMutation__
 *
 * To run a mutation, you first call `useCheckSocialAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckSocialAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkSocialAuthMutation, { data, loading, error }] = useCheckSocialAuthMutation({
 *   variables: {
 *      socialApp: // value for 'socialApp'
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useCheckSocialAuthMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckSocialAuthMutation,
    CheckSocialAuthMutationVariables
  >
) {
  return Apollo.useMutation<CheckSocialAuthMutation, CheckSocialAuthMutationVariables>(
    CheckSocialAuthDocument,
    baseOptions
  );
}
export type CheckSocialAuthMutationHookResult = ReturnType<typeof useCheckSocialAuthMutation>;
export type CheckSocialAuthMutationResult = Apollo.MutationResult<CheckSocialAuthMutation>;
export type CheckSocialAuthMutationOptions = Apollo.BaseMutationOptions<
  CheckSocialAuthMutation,
  CheckSocialAuthMutationVariables
>;
export const AddUserToMyfriendsDocument = gql`
  mutation AddUserToMyfriends($userId: ID!) {
    addUserToMyfriends(userId: $userId) {
      user {
        id
        isMyfriend
      }
      chat {
        chatId
        accessKey
        title
      }
    }
  }
`;
export type AddUserToMyfriendsMutationFn = Apollo.MutationFunction<
  AddUserToMyfriendsMutation,
  AddUserToMyfriendsMutationVariables
>;

/**
 * __useAddUserToMyfriendsMutation__
 *
 * To run a mutation, you first call `useAddUserToMyfriendsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToMyfriendsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToMyfriendsMutation, { data, loading, error }] = useAddUserToMyfriendsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddUserToMyfriendsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserToMyfriendsMutation,
    AddUserToMyfriendsMutationVariables
  >
) {
  return Apollo.useMutation<AddUserToMyfriendsMutation, AddUserToMyfriendsMutationVariables>(
    AddUserToMyfriendsDocument,
    baseOptions
  );
}
export type AddUserToMyfriendsMutationHookResult = ReturnType<typeof useAddUserToMyfriendsMutation>;
export type AddUserToMyfriendsMutationResult = Apollo.MutationResult<AddUserToMyfriendsMutation>;
export type AddUserToMyfriendsMutationOptions = Apollo.BaseMutationOptions<
  AddUserToMyfriendsMutation,
  AddUserToMyfriendsMutationVariables
>;
export const UpdateUserMainGamePlateformDocument = gql`
  mutation UpdateUserMainGamePlateform($gamePlateformId: ID!) {
    updateUserMainGamePlateform(gamePlateformId: $gamePlateformId) {
      id
      myMainGamePlateform {
        id
        name
      }
    }
  }
`;
export type UpdateUserMainGamePlateformMutationFn = Apollo.MutationFunction<
  UpdateUserMainGamePlateformMutation,
  UpdateUserMainGamePlateformMutationVariables
>;

/**
 * __useUpdateUserMainGamePlateformMutation__
 *
 * To run a mutation, you first call `useUpdateUserMainGamePlateformMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMainGamePlateformMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMainGamePlateformMutation, { data, loading, error }] = useUpdateUserMainGamePlateformMutation({
 *   variables: {
 *      gamePlateformId: // value for 'gamePlateformId'
 *   },
 * });
 */
export function useUpdateUserMainGamePlateformMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMainGamePlateformMutation,
    UpdateUserMainGamePlateformMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateUserMainGamePlateformMutation,
    UpdateUserMainGamePlateformMutationVariables
  >(UpdateUserMainGamePlateformDocument, baseOptions);
}
export type UpdateUserMainGamePlateformMutationHookResult = ReturnType<
  typeof useUpdateUserMainGamePlateformMutation
>;
export type UpdateUserMainGamePlateformMutationResult =
  Apollo.MutationResult<UpdateUserMainGamePlateformMutation>;
export type UpdateUserMainGamePlateformMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMainGamePlateformMutation,
  UpdateUserMainGamePlateformMutationVariables
>;
export const CreateGamepartymemberDocument = gql`
  mutation CreateGamepartymember($partyId: ID!, $userId: ID) {
    createGamepartymember(partyId: $partyId, userId: $userId) {
      id
    }
  }
`;
export type CreateGamepartymemberMutationFn = Apollo.MutationFunction<
  CreateGamepartymemberMutation,
  CreateGamepartymemberMutationVariables
>;

/**
 * __useCreateGamepartymemberMutation__
 *
 * To run a mutation, you first call `useCreateGamepartymemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGamepartymemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGamepartymemberMutation, { data, loading, error }] = useCreateGamepartymemberMutation({
 *   variables: {
 *      partyId: // value for 'partyId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateGamepartymemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGamepartymemberMutation,
    CreateGamepartymemberMutationVariables
  >
) {
  return Apollo.useMutation<CreateGamepartymemberMutation, CreateGamepartymemberMutationVariables>(
    CreateGamepartymemberDocument,
    baseOptions
  );
}
export type CreateGamepartymemberMutationHookResult = ReturnType<
  typeof useCreateGamepartymemberMutation
>;
export type CreateGamepartymemberMutationResult =
  Apollo.MutationResult<CreateGamepartymemberMutation>;
export type CreateGamepartymemberMutationOptions = Apollo.BaseMutationOptions<
  CreateGamepartymemberMutation,
  CreateGamepartymemberMutationVariables
>;
export const CheckIfEmailIsAlreadyTakenDocument = gql`
  mutation CheckIfEmailIsAlreadyTaken($email: String!) {
    checkIfEmailIsAlreadyTaken(email: $email)
  }
`;
export type CheckIfEmailIsAlreadyTakenMutationFn = Apollo.MutationFunction<
  CheckIfEmailIsAlreadyTakenMutation,
  CheckIfEmailIsAlreadyTakenMutationVariables
>;

/**
 * __useCheckIfEmailIsAlreadyTakenMutation__
 *
 * To run a mutation, you first call `useCheckIfEmailIsAlreadyTakenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckIfEmailIsAlreadyTakenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkIfEmailIsAlreadyTakenMutation, { data, loading, error }] = useCheckIfEmailIsAlreadyTakenMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckIfEmailIsAlreadyTakenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckIfEmailIsAlreadyTakenMutation,
    CheckIfEmailIsAlreadyTakenMutationVariables
  >
) {
  return Apollo.useMutation<
    CheckIfEmailIsAlreadyTakenMutation,
    CheckIfEmailIsAlreadyTakenMutationVariables
  >(CheckIfEmailIsAlreadyTakenDocument, baseOptions);
}
export type CheckIfEmailIsAlreadyTakenMutationHookResult = ReturnType<
  typeof useCheckIfEmailIsAlreadyTakenMutation
>;
export type CheckIfEmailIsAlreadyTakenMutationResult =
  Apollo.MutationResult<CheckIfEmailIsAlreadyTakenMutation>;
export type CheckIfEmailIsAlreadyTakenMutationOptions = Apollo.BaseMutationOptions<
  CheckIfEmailIsAlreadyTakenMutation,
  CheckIfEmailIsAlreadyTakenMutationVariables
>;
export const CheckIfUsernameIsAlreadyTakenDocument = gql`
  mutation CheckIfUsernameIsAlreadyTaken($username: String!) {
    checkIfUsernameIsAlreadyTaken(username: $username)
  }
`;
export type CheckIfUsernameIsAlreadyTakenMutationFn = Apollo.MutationFunction<
  CheckIfUsernameIsAlreadyTakenMutation,
  CheckIfUsernameIsAlreadyTakenMutationVariables
>;

/**
 * __useCheckIfUsernameIsAlreadyTakenMutation__
 *
 * To run a mutation, you first call `useCheckIfUsernameIsAlreadyTakenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckIfUsernameIsAlreadyTakenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkIfUsernameIsAlreadyTakenMutation, { data, loading, error }] = useCheckIfUsernameIsAlreadyTakenMutation({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useCheckIfUsernameIsAlreadyTakenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckIfUsernameIsAlreadyTakenMutation,
    CheckIfUsernameIsAlreadyTakenMutationVariables
  >
) {
  return Apollo.useMutation<
    CheckIfUsernameIsAlreadyTakenMutation,
    CheckIfUsernameIsAlreadyTakenMutationVariables
  >(CheckIfUsernameIsAlreadyTakenDocument, baseOptions);
}
export type CheckIfUsernameIsAlreadyTakenMutationHookResult = ReturnType<
  typeof useCheckIfUsernameIsAlreadyTakenMutation
>;
export type CheckIfUsernameIsAlreadyTakenMutationResult =
  Apollo.MutationResult<CheckIfUsernameIsAlreadyTakenMutation>;
export type CheckIfUsernameIsAlreadyTakenMutationOptions = Apollo.BaseMutationOptions<
  CheckIfUsernameIsAlreadyTakenMutation,
  CheckIfUsernameIsAlreadyTakenMutationVariables
>;
export const SendWelcomeEmailDocument = gql`
  mutation SendWelcomeEmail($email: String!, $username: String!) {
    sendWelcomeEmail(email: $email, username: $username)
  }
`;
export type SendWelcomeEmailMutationFn = Apollo.MutationFunction<
  SendWelcomeEmailMutation,
  SendWelcomeEmailMutationVariables
>;

/**
 * __useSendWelcomeEmailMutation__
 *
 * To run a mutation, you first call `useSendWelcomeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendWelcomeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendWelcomeEmailMutation, { data, loading, error }] = useSendWelcomeEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useSendWelcomeEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendWelcomeEmailMutation,
    SendWelcomeEmailMutationVariables
  >
) {
  return Apollo.useMutation<SendWelcomeEmailMutation, SendWelcomeEmailMutationVariables>(
    SendWelcomeEmailDocument,
    baseOptions
  );
}
export type SendWelcomeEmailMutationHookResult = ReturnType<typeof useSendWelcomeEmailMutation>;
export type SendWelcomeEmailMutationResult = Apollo.MutationResult<SendWelcomeEmailMutation>;
export type SendWelcomeEmailMutationOptions = Apollo.BaseMutationOptions<
  SendWelcomeEmailMutation,
  SendWelcomeEmailMutationVariables
>;
export const EnableoAuth2Document = gql`
  mutation EnableoAuth2($id: ID!) {
    enableoAuth2(id: $id) {
      ...UserInfos
    }
  }
  ${UserInfosFragmentDoc}
`;
export type EnableoAuth2MutationFn = Apollo.MutationFunction<
  EnableoAuth2Mutation,
  EnableoAuth2MutationVariables
>;

/**
 * __useEnableoAuth2Mutation__
 *
 * To run a mutation, you first call `useEnableoAuth2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableoAuth2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableoAuth2Mutation, { data, loading, error }] = useEnableoAuth2Mutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnableoAuth2Mutation(
  baseOptions?: Apollo.MutationHookOptions<EnableoAuth2Mutation, EnableoAuth2MutationVariables>
) {
  return Apollo.useMutation<EnableoAuth2Mutation, EnableoAuth2MutationVariables>(
    EnableoAuth2Document,
    baseOptions
  );
}
export type EnableoAuth2MutationHookResult = ReturnType<typeof useEnableoAuth2Mutation>;
export type EnableoAuth2MutationResult = Apollo.MutationResult<EnableoAuth2Mutation>;
export type EnableoAuth2MutationOptions = Apollo.BaseMutationOptions<
  EnableoAuth2Mutation,
  EnableoAuth2MutationVariables
>;
export const DisableoAuth2Document = gql`
  mutation DisableoAuth2($id: ID!) {
    disableoAuth2(id: $id) {
      ...UserInfos
    }
  }
  ${UserInfosFragmentDoc}
`;
export type DisableoAuth2MutationFn = Apollo.MutationFunction<
  DisableoAuth2Mutation,
  DisableoAuth2MutationVariables
>;

/**
 * __useDisableoAuth2Mutation__
 *
 * To run a mutation, you first call `useDisableoAuth2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableoAuth2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableoAuth2Mutation, { data, loading, error }] = useDisableoAuth2Mutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisableoAuth2Mutation(
  baseOptions?: Apollo.MutationHookOptions<DisableoAuth2Mutation, DisableoAuth2MutationVariables>
) {
  return Apollo.useMutation<DisableoAuth2Mutation, DisableoAuth2MutationVariables>(
    DisableoAuth2Document,
    baseOptions
  );
}
export type DisableoAuth2MutationHookResult = ReturnType<typeof useDisableoAuth2Mutation>;
export type DisableoAuth2MutationResult = Apollo.MutationResult<DisableoAuth2Mutation>;
export type DisableoAuth2MutationOptions = Apollo.BaseMutationOptions<
  DisableoAuth2Mutation,
  DisableoAuth2MutationVariables
>;
export const CheckIfEmailAndPasswordMatchedDocument = gql`
  mutation CheckIfEmailAndPasswordMatched($input: LoginInput!) {
    checkIfEmailAndPasswordMatched(input: $input)
  }
`;
export type CheckIfEmailAndPasswordMatchedMutationFn = Apollo.MutationFunction<
  CheckIfEmailAndPasswordMatchedMutation,
  CheckIfEmailAndPasswordMatchedMutationVariables
>;

/**
 * __useCheckIfEmailAndPasswordMatchedMutation__
 *
 * To run a mutation, you first call `useCheckIfEmailAndPasswordMatchedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckIfEmailAndPasswordMatchedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkIfEmailAndPasswordMatchedMutation, { data, loading, error }] = useCheckIfEmailAndPasswordMatchedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckIfEmailAndPasswordMatchedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckIfEmailAndPasswordMatchedMutation,
    CheckIfEmailAndPasswordMatchedMutationVariables
  >
) {
  return Apollo.useMutation<
    CheckIfEmailAndPasswordMatchedMutation,
    CheckIfEmailAndPasswordMatchedMutationVariables
  >(CheckIfEmailAndPasswordMatchedDocument, baseOptions);
}
export type CheckIfEmailAndPasswordMatchedMutationHookResult = ReturnType<
  typeof useCheckIfEmailAndPasswordMatchedMutation
>;
export type CheckIfEmailAndPasswordMatchedMutationResult =
  Apollo.MutationResult<CheckIfEmailAndPasswordMatchedMutation>;
export type CheckIfEmailAndPasswordMatchedMutationOptions = Apollo.BaseMutationOptions<
  CheckIfEmailAndPasswordMatchedMutation,
  CheckIfEmailAndPasswordMatchedMutationVariables
>;
export const LoginOauth2Document = gql`
  mutation LoginOauth2($input: LoginOauth2Input!) {
    loginOauth2(input: $input)
  }
`;
export type LoginOauth2MutationFn = Apollo.MutationFunction<
  LoginOauth2Mutation,
  LoginOauth2MutationVariables
>;

/**
 * __useLoginOauth2Mutation__
 *
 * To run a mutation, you first call `useLoginOauth2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginOauth2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginOauth2Mutation, { data, loading, error }] = useLoginOauth2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginOauth2Mutation(
  baseOptions?: Apollo.MutationHookOptions<LoginOauth2Mutation, LoginOauth2MutationVariables>
) {
  return Apollo.useMutation<LoginOauth2Mutation, LoginOauth2MutationVariables>(
    LoginOauth2Document,
    baseOptions
  );
}
export type LoginOauth2MutationHookResult = ReturnType<typeof useLoginOauth2Mutation>;
export type LoginOauth2MutationResult = Apollo.MutationResult<LoginOauth2Mutation>;
export type LoginOauth2MutationOptions = Apollo.BaseMutationOptions<
  LoginOauth2Mutation,
  LoginOauth2MutationVariables
>;
export const CheckTokenOauth2Document = gql`
  mutation CheckTokenOauth2($input: CheckExpiredTokenInput!) {
    checkTokenOauth2(input: $input)
  }
`;
export type CheckTokenOauth2MutationFn = Apollo.MutationFunction<
  CheckTokenOauth2Mutation,
  CheckTokenOauth2MutationVariables
>;

/**
 * __useCheckTokenOauth2Mutation__
 *
 * To run a mutation, you first call `useCheckTokenOauth2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckTokenOauth2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkTokenOauth2Mutation, { data, loading, error }] = useCheckTokenOauth2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckTokenOauth2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckTokenOauth2Mutation,
    CheckTokenOauth2MutationVariables
  >
) {
  return Apollo.useMutation<CheckTokenOauth2Mutation, CheckTokenOauth2MutationVariables>(
    CheckTokenOauth2Document,
    baseOptions
  );
}
export type CheckTokenOauth2MutationHookResult = ReturnType<typeof useCheckTokenOauth2Mutation>;
export type CheckTokenOauth2MutationResult = Apollo.MutationResult<CheckTokenOauth2Mutation>;
export type CheckTokenOauth2MutationOptions = Apollo.BaseMutationOptions<
  CheckTokenOauth2Mutation,
  CheckTokenOauth2MutationVariables
>;
export const CreateAuthTokenDocument = gql`
  mutation CreateAuthToken($input: LoginInput!) {
    createAuthToken(input: $input)
  }
`;
export type CreateAuthTokenMutationFn = Apollo.MutationFunction<
  CreateAuthTokenMutation,
  CreateAuthTokenMutationVariables
>;

/**
 * __useCreateAuthTokenMutation__
 *
 * To run a mutation, you first call `useCreateAuthTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAuthTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAuthTokenMutation, { data, loading, error }] = useCreateAuthTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAuthTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAuthTokenMutation,
    CreateAuthTokenMutationVariables
  >
) {
  return Apollo.useMutation<CreateAuthTokenMutation, CreateAuthTokenMutationVariables>(
    CreateAuthTokenDocument,
    baseOptions
  );
}
export type CreateAuthTokenMutationHookResult = ReturnType<typeof useCreateAuthTokenMutation>;
export type CreateAuthTokenMutationResult = Apollo.MutationResult<CreateAuthTokenMutation>;
export type CreateAuthTokenMutationOptions = Apollo.BaseMutationOptions<
  CreateAuthTokenMutation,
  CreateAuthTokenMutationVariables
>;
export const CheckIfUserMatchInSocialAppDocument = gql`
  mutation CheckIfUserMatchInSocialApp(
    $socialApp: SOCIAL_APP!
    $email: String!
    $providerUid: String!
  ) {
    checkIfUserMatchInSocialApp(socialApp: $socialApp, email: $email, providerUid: $providerUid)
  }
`;
export type CheckIfUserMatchInSocialAppMutationFn = Apollo.MutationFunction<
  CheckIfUserMatchInSocialAppMutation,
  CheckIfUserMatchInSocialAppMutationVariables
>;

/**
 * __useCheckIfUserMatchInSocialAppMutation__
 *
 * To run a mutation, you first call `useCheckIfUserMatchInSocialAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckIfUserMatchInSocialAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkIfUserMatchInSocialAppMutation, { data, loading, error }] = useCheckIfUserMatchInSocialAppMutation({
 *   variables: {
 *      socialApp: // value for 'socialApp'
 *      email: // value for 'email'
 *      providerUid: // value for 'providerUid'
 *   },
 * });
 */
export function useCheckIfUserMatchInSocialAppMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckIfUserMatchInSocialAppMutation,
    CheckIfUserMatchInSocialAppMutationVariables
  >
) {
  return Apollo.useMutation<
    CheckIfUserMatchInSocialAppMutation,
    CheckIfUserMatchInSocialAppMutationVariables
  >(CheckIfUserMatchInSocialAppDocument, baseOptions);
}
export type CheckIfUserMatchInSocialAppMutationHookResult = ReturnType<
  typeof useCheckIfUserMatchInSocialAppMutation
>;
export type CheckIfUserMatchInSocialAppMutationResult =
  Apollo.MutationResult<CheckIfUserMatchInSocialAppMutation>;
export type CheckIfUserMatchInSocialAppMutationOptions = Apollo.BaseMutationOptions<
  CheckIfUserMatchInSocialAppMutation,
  CheckIfUserMatchInSocialAppMutationVariables
>;
export const LoginSocialWithoutUpdatingUserInfoDocument = gql`
  mutation LoginSocialWithoutUpdatingUserInfo($input: LoginSocialInput!) {
    loginSocialWithoutUpdatingUserInfo(input: $input) {
      ...LoginSocialInfos
    }
  }
  ${LoginSocialInfosFragmentDoc}
`;
export type LoginSocialWithoutUpdatingUserInfoMutationFn = Apollo.MutationFunction<
  LoginSocialWithoutUpdatingUserInfoMutation,
  LoginSocialWithoutUpdatingUserInfoMutationVariables
>;

/**
 * __useLoginSocialWithoutUpdatingUserInfoMutation__
 *
 * To run a mutation, you first call `useLoginSocialWithoutUpdatingUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginSocialWithoutUpdatingUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginSocialWithoutUpdatingUserInfoMutation, { data, loading, error }] = useLoginSocialWithoutUpdatingUserInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginSocialWithoutUpdatingUserInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginSocialWithoutUpdatingUserInfoMutation,
    LoginSocialWithoutUpdatingUserInfoMutationVariables
  >
) {
  return Apollo.useMutation<
    LoginSocialWithoutUpdatingUserInfoMutation,
    LoginSocialWithoutUpdatingUserInfoMutationVariables
  >(LoginSocialWithoutUpdatingUserInfoDocument, baseOptions);
}
export type LoginSocialWithoutUpdatingUserInfoMutationHookResult = ReturnType<
  typeof useLoginSocialWithoutUpdatingUserInfoMutation
>;
export type LoginSocialWithoutUpdatingUserInfoMutationResult =
  Apollo.MutationResult<LoginSocialWithoutUpdatingUserInfoMutation>;
export type LoginSocialWithoutUpdatingUserInfoMutationOptions = Apollo.BaseMutationOptions<
  LoginSocialWithoutUpdatingUserInfoMutation,
  LoginSocialWithoutUpdatingUserInfoMutationVariables
>;
export const MeDocument = gql`
  query Me {
    me {
      ...UserInfos
      chatEnginePublicSecret
    }
  }
  ${UserInfosFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UserDocument = gql`
  query User($id: ID!) {
    User(id: $id) {
      ...UserInfos
      chatEnginePublicSecret
    }
  }
  ${UserInfosFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>
) {
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const MeBasicDocument = gql`
  query MeBasic {
    me {
      ...UserBasicInfos
    }
  }
  ${UserBasicInfosFragmentDoc}
`;

/**
 * __useMeBasicQuery__
 *
 * To run a query within a React component, call `useMeBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeBasicQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeBasicQuery(
  baseOptions?: Apollo.QueryHookOptions<MeBasicQuery, MeBasicQueryVariables>
) {
  return Apollo.useQuery<MeBasicQuery, MeBasicQueryVariables>(MeBasicDocument, baseOptions);
}
export function useMeBasicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeBasicQuery, MeBasicQueryVariables>
) {
  return Apollo.useLazyQuery<MeBasicQuery, MeBasicQueryVariables>(MeBasicDocument, baseOptions);
}
export type MeBasicQueryHookResult = ReturnType<typeof useMeBasicQuery>;
export type MeBasicLazyQueryHookResult = ReturnType<typeof useMeBasicLazyQuery>;
export type MeBasicQueryResult = Apollo.QueryResult<MeBasicQuery, MeBasicQueryVariables>;
export const MyGamePartyInvitationDocument = gql`
  query MyGamePartyInvitation {
    me {
      id
      gamePartyInvitation {
        gameParty {
          id
          chat {
            id
            owner {
              id
              username
            }
          }
        }
      }
    }
  }
`;

/**
 * __useMyGamePartyInvitationQuery__
 *
 * To run a query within a React component, call `useMyGamePartyInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyGamePartyInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyGamePartyInvitationQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyGamePartyInvitationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyGamePartyInvitationQuery,
    MyGamePartyInvitationQueryVariables
  >
) {
  return Apollo.useQuery<MyGamePartyInvitationQuery, MyGamePartyInvitationQueryVariables>(
    MyGamePartyInvitationDocument,
    baseOptions
  );
}
export function useMyGamePartyInvitationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyGamePartyInvitationQuery,
    MyGamePartyInvitationQueryVariables
  >
) {
  return Apollo.useLazyQuery<MyGamePartyInvitationQuery, MyGamePartyInvitationQueryVariables>(
    MyGamePartyInvitationDocument,
    baseOptions
  );
}
export type MyGamePartyInvitationQueryHookResult = ReturnType<typeof useMyGamePartyInvitationQuery>;
export type MyGamePartyInvitationLazyQueryHookResult = ReturnType<
  typeof useMyGamePartyInvitationLazyQuery
>;
export type MyGamePartyInvitationQueryResult = Apollo.QueryResult<
  MyGamePartyInvitationQuery,
  MyGamePartyInvitationQueryVariables
>;
export const FindUserTokenDocument = gql`
  query FindUserToken($token: String!) {
    findUserToken(token: $token) {
      ...UserInfos
    }
  }
  ${UserInfosFragmentDoc}
`;

/**
 * __useFindUserTokenQuery__
 *
 * To run a query within a React component, call `useFindUserTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useFindUserTokenQuery(
  baseOptions: Apollo.QueryHookOptions<FindUserTokenQuery, FindUserTokenQueryVariables>
) {
  return Apollo.useQuery<FindUserTokenQuery, FindUserTokenQueryVariables>(
    FindUserTokenDocument,
    baseOptions
  );
}
export function useFindUserTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindUserTokenQuery, FindUserTokenQueryVariables>
) {
  return Apollo.useLazyQuery<FindUserTokenQuery, FindUserTokenQueryVariables>(
    FindUserTokenDocument,
    baseOptions
  );
}
export type FindUserTokenQueryHookResult = ReturnType<typeof useFindUserTokenQuery>;
export type FindUserTokenLazyQueryHookResult = ReturnType<typeof useFindUserTokenLazyQuery>;
export type FindUserTokenQueryResult = Apollo.QueryResult<
  FindUserTokenQuery,
  FindUserTokenQueryVariables
>;
export const ListUsersInfosDocument = gql`
  query ListUsersInfos($filter: UsersFilterInput!) {
    users(filter: $filter) {
      results {
        ...UserInfos
      }
      total
    }
  }
  ${UserInfosFragmentDoc}
`;

/**
 * __useListUsersInfosQuery__
 *
 * To run a query within a React component, call `useListUsersInfosQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersInfosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersInfosQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListUsersInfosQuery(
  baseOptions: Apollo.QueryHookOptions<ListUsersInfosQuery, ListUsersInfosQueryVariables>
) {
  return Apollo.useQuery<ListUsersInfosQuery, ListUsersInfosQueryVariables>(
    ListUsersInfosDocument,
    baseOptions
  );
}
export function useListUsersInfosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListUsersInfosQuery, ListUsersInfosQueryVariables>
) {
  return Apollo.useLazyQuery<ListUsersInfosQuery, ListUsersInfosQueryVariables>(
    ListUsersInfosDocument,
    baseOptions
  );
}
export type ListUsersInfosQueryHookResult = ReturnType<typeof useListUsersInfosQuery>;
export type ListUsersInfosLazyQueryHookResult = ReturnType<typeof useListUsersInfosLazyQuery>;
export type ListUsersInfosQueryResult = Apollo.QueryResult<
  ListUsersInfosQuery,
  ListUsersInfosQueryVariables
>;
export const ListUsersNotInTournamentDocument = gql`
  query ListUsersNotInTournament($filter: UsersTournamentFilterInput!) {
    usersTournament(filter: $filter) {
      results {
        ...UserInfos
      }
      total
    }
  }
  ${UserInfosFragmentDoc}
`;

/**
 * __useListUsersNotInTournamentQuery__
 *
 * To run a query within a React component, call `useListUsersNotInTournamentQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersNotInTournamentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersNotInTournamentQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListUsersNotInTournamentQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListUsersNotInTournamentQuery,
    ListUsersNotInTournamentQueryVariables
  >
) {
  return Apollo.useQuery<ListUsersNotInTournamentQuery, ListUsersNotInTournamentQueryVariables>(
    ListUsersNotInTournamentDocument,
    baseOptions
  );
}
export function useListUsersNotInTournamentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListUsersNotInTournamentQuery,
    ListUsersNotInTournamentQueryVariables
  >
) {
  return Apollo.useLazyQuery<ListUsersNotInTournamentQuery, ListUsersNotInTournamentQueryVariables>(
    ListUsersNotInTournamentDocument,
    baseOptions
  );
}
export type ListUsersNotInTournamentQueryHookResult = ReturnType<
  typeof useListUsersNotInTournamentQuery
>;
export type ListUsersNotInTournamentLazyQueryHookResult = ReturnType<
  typeof useListUsersNotInTournamentLazyQuery
>;
export type ListUsersNotInTournamentQueryResult = Apollo.QueryResult<
  ListUsersNotInTournamentQuery,
  ListUsersNotInTournamentQueryVariables
>;
export const UserFriendSuggestionsDocument = gql`
  query UserFriendSuggestions {
    userFriendsSuggestion {
      ...UserInfos
    }
  }
  ${UserInfosFragmentDoc}
`;

/**
 * __useUserFriendSuggestionsQuery__
 *
 * To run a query within a React component, call `useUserFriendSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFriendSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFriendSuggestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserFriendSuggestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserFriendSuggestionsQuery,
    UserFriendSuggestionsQueryVariables
  >
) {
  return Apollo.useQuery<UserFriendSuggestionsQuery, UserFriendSuggestionsQueryVariables>(
    UserFriendSuggestionsDocument,
    baseOptions
  );
}
export function useUserFriendSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserFriendSuggestionsQuery,
    UserFriendSuggestionsQueryVariables
  >
) {
  return Apollo.useLazyQuery<UserFriendSuggestionsQuery, UserFriendSuggestionsQueryVariables>(
    UserFriendSuggestionsDocument,
    baseOptions
  );
}
export type UserFriendSuggestionsQueryHookResult = ReturnType<typeof useUserFriendSuggestionsQuery>;
export type UserFriendSuggestionsLazyQueryHookResult = ReturnType<
  typeof useUserFriendSuggestionsLazyQuery
>;
export type UserFriendSuggestionsQueryResult = Apollo.QueryResult<
  UserFriendSuggestionsQuery,
  UserFriendSuggestionsQueryVariables
>;
export const OnlineFriendsDocument = gql`
  query OnlineFriends {
    onlineFriends {
      ...UserInfos
    }
  }
  ${UserInfosFragmentDoc}
`;

/**
 * __useOnlineFriendsQuery__
 *
 * To run a query within a React component, call `useOnlineFriendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnlineFriendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnlineFriendsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnlineFriendsQuery(
  baseOptions?: Apollo.QueryHookOptions<OnlineFriendsQuery, OnlineFriendsQueryVariables>
) {
  return Apollo.useQuery<OnlineFriendsQuery, OnlineFriendsQueryVariables>(
    OnlineFriendsDocument,
    baseOptions
  );
}
export function useOnlineFriendsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OnlineFriendsQuery, OnlineFriendsQueryVariables>
) {
  return Apollo.useLazyQuery<OnlineFriendsQuery, OnlineFriendsQueryVariables>(
    OnlineFriendsDocument,
    baseOptions
  );
}
export type OnlineFriendsQueryHookResult = ReturnType<typeof useOnlineFriendsQuery>;
export type OnlineFriendsLazyQueryHookResult = ReturnType<typeof useOnlineFriendsLazyQuery>;
export type OnlineFriendsQueryResult = Apollo.QueryResult<
  OnlineFriendsQuery,
  OnlineFriendsQueryVariables
>;
export const GetUsersByRandDocument = gql`
  query GetUsersByRand($limit: Int) {
    getUsersByRand(limit: $limit) {
      ...UserInfos
    }
  }
  ${UserInfosFragmentDoc}
`;

/**
 * __useGetUsersByRandQuery__
 *
 * To run a query within a React component, call `useGetUsersByRandQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByRandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByRandQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUsersByRandQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUsersByRandQuery, GetUsersByRandQueryVariables>
) {
  return Apollo.useQuery<GetUsersByRandQuery, GetUsersByRandQueryVariables>(
    GetUsersByRandDocument,
    baseOptions
  );
}
export function useGetUsersByRandLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUsersByRandQuery, GetUsersByRandQueryVariables>
) {
  return Apollo.useLazyQuery<GetUsersByRandQuery, GetUsersByRandQueryVariables>(
    GetUsersByRandDocument,
    baseOptions
  );
}
export type GetUsersByRandQueryHookResult = ReturnType<typeof useGetUsersByRandQuery>;
export type GetUsersByRandLazyQueryHookResult = ReturnType<typeof useGetUsersByRandLazyQuery>;
export type GetUsersByRandQueryResult = Apollo.QueryResult<
  GetUsersByRandQuery,
  GetUsersByRandQueryVariables
>;
export const GetUserTeamsDocument = gql`
  query GetUserTeams($userId: ID!) {
    User(id: $userId) {
      id
      joinedTeams {
        id
        role
        team {
          ...TeamBasicInfos
          owner {
            id
            user {
              ...UserBasicInfos
            }
          }
        }
      }
    }
  }
  ${TeamBasicInfosFragmentDoc}
  ${UserBasicInfosFragmentDoc}
`;

/**
 * __useGetUserTeamsQuery__
 *
 * To run a query within a React component, call `useGetUserTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTeamsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserTeamsQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserTeamsQuery, GetUserTeamsQueryVariables>
) {
  return Apollo.useQuery<GetUserTeamsQuery, GetUserTeamsQueryVariables>(
    GetUserTeamsDocument,
    baseOptions
  );
}
export function useGetUserTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserTeamsQuery, GetUserTeamsQueryVariables>
) {
  return Apollo.useLazyQuery<GetUserTeamsQuery, GetUserTeamsQueryVariables>(
    GetUserTeamsDocument,
    baseOptions
  );
}
export type GetUserTeamsQueryHookResult = ReturnType<typeof useGetUserTeamsQuery>;
export type GetUserTeamsLazyQueryHookResult = ReturnType<typeof useGetUserTeamsLazyQuery>;
export type GetUserTeamsQueryResult = Apollo.QueryResult<
  GetUserTeamsQuery,
  GetUserTeamsQueryVariables
>;
export const AllUserTournamentsDocument = gql`
  query AllUserTournaments($userId: ID!) {
    User(id: $userId) {
      id
      joinedParticipationTournaments {
        id
        tournament {
          id
          title
          isActive
          startedAt
          createdAt
          entryCost
          rewardsTotal
          totalAwardValue
          format {
            title
          }
          description
          awards {
            value
          }
          gamePlateform {
            id
            name
            logo
          }
          myRanking {
            id
            rank
          }
          gamemode {
            id
            title
            teamSize
            type
          }
          maxParticipants
          participants {
            id
          }
          game {
            id
            name
            mainJacket
          }
          isMeAlreadyParticipant
        }
      }
    }
  }
`;

/**
 * __useAllUserTournamentsQuery__
 *
 * To run a query within a React component, call `useAllUserTournamentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserTournamentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserTournamentsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAllUserTournamentsQuery(
  baseOptions: Apollo.QueryHookOptions<AllUserTournamentsQuery, AllUserTournamentsQueryVariables>
) {
  return Apollo.useQuery<AllUserTournamentsQuery, AllUserTournamentsQueryVariables>(
    AllUserTournamentsDocument,
    baseOptions
  );
}
export function useAllUserTournamentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllUserTournamentsQuery,
    AllUserTournamentsQueryVariables
  >
) {
  return Apollo.useLazyQuery<AllUserTournamentsQuery, AllUserTournamentsQueryVariables>(
    AllUserTournamentsDocument,
    baseOptions
  );
}
export type AllUserTournamentsQueryHookResult = ReturnType<typeof useAllUserTournamentsQuery>;
export type AllUserTournamentsLazyQueryHookResult = ReturnType<
  typeof useAllUserTournamentsLazyQuery
>;
export type AllUserTournamentsQueryResult = Apollo.QueryResult<
  AllUserTournamentsQuery,
  AllUserTournamentsQueryVariables
>;
export const AllUserWagersDocument = gql`
  query AllUserWagers($userId: ID!) {
    User(id: $userId) {
      id
      joinedWagers {
        id
        wager {
          id
          isMeAlreadyParticipant
          cashPrice
          format
          isActive
          participantNumber
          startedAt
          updatedAt
          title
          createdAt
          game {
            id
            name
          }
          plateform {
            id
            name
            logo
          }
          createdBy {
            id
            username
          }
          rule
          rule_en
          entryCost
        }
      }
    }
  }
`;

/**
 * __useAllUserWagersQuery__
 *
 * To run a query within a React component, call `useAllUserWagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserWagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserWagersQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAllUserWagersQuery(
  baseOptions: Apollo.QueryHookOptions<AllUserWagersQuery, AllUserWagersQueryVariables>
) {
  return Apollo.useQuery<AllUserWagersQuery, AllUserWagersQueryVariables>(
    AllUserWagersDocument,
    baseOptions
  );
}
export function useAllUserWagersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllUserWagersQuery, AllUserWagersQueryVariables>
) {
  return Apollo.useLazyQuery<AllUserWagersQuery, AllUserWagersQueryVariables>(
    AllUserWagersDocument,
    baseOptions
  );
}
export type AllUserWagersQueryHookResult = ReturnType<typeof useAllUserWagersQuery>;
export type AllUserWagersLazyQueryHookResult = ReturnType<typeof useAllUserWagersLazyQuery>;
export type AllUserWagersQueryResult = Apollo.QueryResult<
  AllUserWagersQuery,
  AllUserWagersQueryVariables
>;
export const MyUserSocialDocument = gql`
  query MyUserSocial {
    me {
      id
      socialAccounts {
        ...UserSocialAccountInfos
      }
    }
  }
  ${UserSocialAccountInfosFragmentDoc}
`;

/**
 * __useMyUserSocialQuery__
 *
 * To run a query within a React component, call `useMyUserSocialQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyUserSocialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyUserSocialQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyUserSocialQuery(
  baseOptions?: Apollo.QueryHookOptions<MyUserSocialQuery, MyUserSocialQueryVariables>
) {
  return Apollo.useQuery<MyUserSocialQuery, MyUserSocialQueryVariables>(
    MyUserSocialDocument,
    baseOptions
  );
}
export function useMyUserSocialLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyUserSocialQuery, MyUserSocialQueryVariables>
) {
  return Apollo.useLazyQuery<MyUserSocialQuery, MyUserSocialQueryVariables>(
    MyUserSocialDocument,
    baseOptions
  );
}
export type MyUserSocialQueryHookResult = ReturnType<typeof useMyUserSocialQuery>;
export type MyUserSocialLazyQueryHookResult = ReturnType<typeof useMyUserSocialLazyQuery>;
export type MyUserSocialQueryResult = Apollo.QueryResult<
  MyUserSocialQuery,
  MyUserSocialQueryVariables
>;
export const UserOnlineDocument = gql`
  subscription UserOnline {
    userOnline {
      ...UserInfos
      chatEnginePublicSecret
    }
  }
  ${UserInfosFragmentDoc}
`;

/**
 * __useUserOnlineSubscription__
 *
 * To run a query within a React component, call `useUserOnlineSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserOnlineSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOnlineSubscription({
 *   variables: {
 *   },
 * });
 */
export function useUserOnlineSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    UserOnlineSubscription,
    UserOnlineSubscriptionVariables
  >
) {
  return Apollo.useSubscription<UserOnlineSubscription, UserOnlineSubscriptionVariables>(
    UserOnlineDocument,
    baseOptions
  );
}
export type UserOnlineSubscriptionHookResult = ReturnType<typeof useUserOnlineSubscription>;
export type UserOnlineSubscriptionResult = Apollo.SubscriptionResult<UserOnlineSubscription>;
export const UserOfflineDocument = gql`
  subscription UserOffline {
    userOffline {
      ...UserInfos
      chatEnginePublicSecret
    }
  }
  ${UserInfosFragmentDoc}
`;

/**
 * __useUserOfflineSubscription__
 *
 * To run a query within a React component, call `useUserOfflineSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserOfflineSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOfflineSubscription({
 *   variables: {
 *   },
 * });
 */
export function useUserOfflineSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    UserOfflineSubscription,
    UserOfflineSubscriptionVariables
  >
) {
  return Apollo.useSubscription<UserOfflineSubscription, UserOfflineSubscriptionVariables>(
    UserOfflineDocument,
    baseOptions
  );
}
export type UserOfflineSubscriptionHookResult = ReturnType<typeof useUserOfflineSubscription>;
export type UserOfflineSubscriptionResult = Apollo.SubscriptionResult<UserOfflineSubscription>;
export const GamePartyInvitationDocument = gql`
  subscription GamePartyInvitation {
    newInvitationGameParty {
      id
      gameParty {
        id
        removed
        name
        members {
          id
        }
        chat {
          ...ChatGroupInfo
        }
      }
      gamePartyInvitation {
        user {
          id
        }
        gameParty {
          id
        }
      }
    }
  }
  ${ChatGroupInfoFragmentDoc}
`;

/**
 * __useGamePartyInvitationSubscription__
 *
 * To run a query within a React component, call `useGamePartyInvitationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGamePartyInvitationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGamePartyInvitationSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGamePartyInvitationSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GamePartyInvitationSubscription,
    GamePartyInvitationSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    GamePartyInvitationSubscription,
    GamePartyInvitationSubscriptionVariables
  >(GamePartyInvitationDocument, baseOptions);
}
export type GamePartyInvitationSubscriptionHookResult = ReturnType<
  typeof useGamePartyInvitationSubscription
>;
export type GamePartyInvitationSubscriptionResult =
  Apollo.SubscriptionResult<GamePartyInvitationSubscription>;
export const AddVideoDocument = gql`
  mutation AddVideo($input: AddVideoInput!) {
    addVideo(input: $input) {
      ...VideoInfos
    }
  }
  ${VideoInfosFragmentDoc}
`;
export type AddVideoMutationFn = Apollo.MutationFunction<
  AddVideoMutation,
  AddVideoMutationVariables
>;

/**
 * __useAddVideoMutation__
 *
 * To run a mutation, you first call `useAddVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVideoMutation, { data, loading, error }] = useAddVideoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVideoMutation(
  baseOptions?: Apollo.MutationHookOptions<AddVideoMutation, AddVideoMutationVariables>
) {
  return Apollo.useMutation<AddVideoMutation, AddVideoMutationVariables>(
    AddVideoDocument,
    baseOptions
  );
}
export type AddVideoMutationHookResult = ReturnType<typeof useAddVideoMutation>;
export type AddVideoMutationResult = Apollo.MutationResult<AddVideoMutation>;
export type AddVideoMutationOptions = Apollo.BaseMutationOptions<
  AddVideoMutation,
  AddVideoMutationVariables
>;
export const EditVideoDocument = gql`
  mutation EditVideo($input: EditVideoInput!) {
    editVideo(input: $input) {
      id
      title
      description
      imgPreview
      categoryId
    }
  }
`;
export type EditVideoMutationFn = Apollo.MutationFunction<
  EditVideoMutation,
  EditVideoMutationVariables
>;

/**
 * __useEditVideoMutation__
 *
 * To run a mutation, you first call `useEditVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVideoMutation, { data, loading, error }] = useEditVideoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditVideoMutation(
  baseOptions?: Apollo.MutationHookOptions<EditVideoMutation, EditVideoMutationVariables>
) {
  return Apollo.useMutation<EditVideoMutation, EditVideoMutationVariables>(
    EditVideoDocument,
    baseOptions
  );
}
export type EditVideoMutationHookResult = ReturnType<typeof useEditVideoMutation>;
export type EditVideoMutationResult = Apollo.MutationResult<EditVideoMutation>;
export type EditVideoMutationOptions = Apollo.BaseMutationOptions<
  EditVideoMutation,
  EditVideoMutationVariables
>;
export const DeleteVideoDocument = gql`
  mutation DeleteVideo($input: DeleteVideoInput!) {
    deleteVideo(input: $input) {
      id
      removed
    }
  }
`;
export type DeleteVideoMutationFn = Apollo.MutationFunction<
  DeleteVideoMutation,
  DeleteVideoMutationVariables
>;

/**
 * __useDeleteVideoMutation__
 *
 * To run a mutation, you first call `useDeleteVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVideoMutation, { data, loading, error }] = useDeleteVideoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVideoMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteVideoMutation, DeleteVideoMutationVariables>
) {
  return Apollo.useMutation<DeleteVideoMutation, DeleteVideoMutationVariables>(
    DeleteVideoDocument,
    baseOptions
  );
}
export type DeleteVideoMutationHookResult = ReturnType<typeof useDeleteVideoMutation>;
export type DeleteVideoMutationResult = Apollo.MutationResult<DeleteVideoMutation>;
export type DeleteVideoMutationOptions = Apollo.BaseMutationOptions<
  DeleteVideoMutation,
  DeleteVideoMutationVariables
>;
export const CreateVideoThumbnailDocument = gql`
  mutation CreateVideoThumbnail($input: CreateVideoThumbnailInput!) {
    createVideoThumbnail(input: $input) {
      id
      imgPreview
    }
  }
`;
export type CreateVideoThumbnailMutationFn = Apollo.MutationFunction<
  CreateVideoThumbnailMutation,
  CreateVideoThumbnailMutationVariables
>;

/**
 * __useCreateVideoThumbnailMutation__
 *
 * To run a mutation, you first call `useCreateVideoThumbnailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVideoThumbnailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVideoThumbnailMutation, { data, loading, error }] = useCreateVideoThumbnailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVideoThumbnailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVideoThumbnailMutation,
    CreateVideoThumbnailMutationVariables
  >
) {
  return Apollo.useMutation<CreateVideoThumbnailMutation, CreateVideoThumbnailMutationVariables>(
    CreateVideoThumbnailDocument,
    baseOptions
  );
}
export type CreateVideoThumbnailMutationHookResult = ReturnType<
  typeof useCreateVideoThumbnailMutation
>;
export type CreateVideoThumbnailMutationResult =
  Apollo.MutationResult<CreateVideoThumbnailMutation>;
export type CreateVideoThumbnailMutationOptions = Apollo.BaseMutationOptions<
  CreateVideoThumbnailMutation,
  CreateVideoThumbnailMutationVariables
>;
export const GetVideosDocument = gql`
  query GetVideos($filter: VideosFilterInput!) {
    videos(filter: $filter) {
      results {
        ...VideoInfos
      }
      total
    }
  }
  ${VideoInfosFragmentDoc}
`;

/**
 * __useGetVideosQuery__
 *
 * To run a query within a React component, call `useGetVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideosQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetVideosQuery(
  baseOptions: Apollo.QueryHookOptions<GetVideosQuery, GetVideosQueryVariables>
) {
  return Apollo.useQuery<GetVideosQuery, GetVideosQueryVariables>(GetVideosDocument, baseOptions);
}
export function useGetVideosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVideosQuery, GetVideosQueryVariables>
) {
  return Apollo.useLazyQuery<GetVideosQuery, GetVideosQueryVariables>(
    GetVideosDocument,
    baseOptions
  );
}
export type GetVideosQueryHookResult = ReturnType<typeof useGetVideosQuery>;
export type GetVideosLazyQueryHookResult = ReturnType<typeof useGetVideosLazyQuery>;
export type GetVideosQueryResult = Apollo.QueryResult<GetVideosQuery, GetVideosQueryVariables>;
export const GetVideoDocument = gql`
  query GetVideo($id: ID!) {
    video(id: $id) {
      ...VideoInfos
      author {
        ...UserInfos
      }
    }
  }
  ${VideoInfosFragmentDoc}
  ${UserInfosFragmentDoc}
`;

/**
 * __useGetVideoQuery__
 *
 * To run a query within a React component, call `useGetVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVideoQuery(
  baseOptions: Apollo.QueryHookOptions<GetVideoQuery, GetVideoQueryVariables>
) {
  return Apollo.useQuery<GetVideoQuery, GetVideoQueryVariables>(GetVideoDocument, baseOptions);
}
export function useGetVideoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVideoQuery, GetVideoQueryVariables>
) {
  return Apollo.useLazyQuery<GetVideoQuery, GetVideoQueryVariables>(GetVideoDocument, baseOptions);
}
export type GetVideoQueryHookResult = ReturnType<typeof useGetVideoQuery>;
export type GetVideoLazyQueryHookResult = ReturnType<typeof useGetVideoLazyQuery>;
export type GetVideoQueryResult = Apollo.QueryResult<GetVideoQuery, GetVideoQueryVariables>;
export const SubscribeToVideoStreamBeginDocument = gql`
  subscription SubscribeToVideoStreamBegin {
    videoStreamBegin {
      ...VideoInfos
    }
  }
  ${VideoInfosFragmentDoc}
`;

/**
 * __useSubscribeToVideoStreamBeginSubscription__
 *
 * To run a query within a React component, call `useSubscribeToVideoStreamBeginSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToVideoStreamBeginSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToVideoStreamBeginSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSubscribeToVideoStreamBeginSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToVideoStreamBeginSubscription,
    SubscribeToVideoStreamBeginSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToVideoStreamBeginSubscription,
    SubscribeToVideoStreamBeginSubscriptionVariables
  >(SubscribeToVideoStreamBeginDocument, baseOptions);
}
export type SubscribeToVideoStreamBeginSubscriptionHookResult = ReturnType<
  typeof useSubscribeToVideoStreamBeginSubscription
>;
export type SubscribeToVideoStreamBeginSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToVideoStreamBeginSubscription>;
export const SubscribeToVideoStreamFinishedDocument = gql`
  subscription SubscribeToVideoStreamFinished {
    videoStreamFinished {
      ...VideoInfos
    }
  }
  ${VideoInfosFragmentDoc}
`;

/**
 * __useSubscribeToVideoStreamFinishedSubscription__
 *
 * To run a query within a React component, call `useSubscribeToVideoStreamFinishedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToVideoStreamFinishedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToVideoStreamFinishedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSubscribeToVideoStreamFinishedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToVideoStreamFinishedSubscription,
    SubscribeToVideoStreamFinishedSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToVideoStreamFinishedSubscription,
    SubscribeToVideoStreamFinishedSubscriptionVariables
  >(SubscribeToVideoStreamFinishedDocument, baseOptions);
}
export type SubscribeToVideoStreamFinishedSubscriptionHookResult = ReturnType<
  typeof useSubscribeToVideoStreamFinishedSubscription
>;
export type SubscribeToVideoStreamFinishedSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToVideoStreamFinishedSubscription>;
export const GetVideoCategoriesDocument = gql`
  query GetVideoCategories {
    videoCategories {
      ...VideoCategoryInfos
    }
  }
  ${VideoCategoryInfosFragmentDoc}
`;

/**
 * __useGetVideoCategoriesQuery__
 *
 * To run a query within a React component, call `useGetVideoCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVideoCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVideoCategoriesQuery, GetVideoCategoriesQueryVariables>
) {
  return Apollo.useQuery<GetVideoCategoriesQuery, GetVideoCategoriesQueryVariables>(
    GetVideoCategoriesDocument,
    baseOptions
  );
}
export function useGetVideoCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVideoCategoriesQuery,
    GetVideoCategoriesQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetVideoCategoriesQuery, GetVideoCategoriesQueryVariables>(
    GetVideoCategoriesDocument,
    baseOptions
  );
}
export type GetVideoCategoriesQueryHookResult = ReturnType<typeof useGetVideoCategoriesQuery>;
export type GetVideoCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetVideoCategoriesLazyQuery
>;
export type GetVideoCategoriesQueryResult = Apollo.QueryResult<
  GetVideoCategoriesQuery,
  GetVideoCategoriesQueryVariables
>;
export const CreateWagerDocument = gql`
  mutation CreateWager(
    $gameId: ID
    $gamePlateformId: ID
    $format: WAGER_FORMAT
    $title: String
    $startedAt: DateTime
    $cashPrice: Float
    $rule: String
  ) {
    createWager(
      gameId: $gameId
      gamePlateformId: $gamePlateformId
      format: $format
      title: $title
      startedAt: $startedAt
      cashPrice: $cashPrice
      rule: $rule
    ) {
      ...WagerInfos
    }
  }
  ${WagerInfosFragmentDoc}
`;
export type CreateWagerMutationFn = Apollo.MutationFunction<
  CreateWagerMutation,
  CreateWagerMutationVariables
>;

/**
 * __useCreateWagerMutation__
 *
 * To run a mutation, you first call `useCreateWagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWagerMutation, { data, loading, error }] = useCreateWagerMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      gamePlateformId: // value for 'gamePlateformId'
 *      format: // value for 'format'
 *      title: // value for 'title'
 *      startedAt: // value for 'startedAt'
 *      cashPrice: // value for 'cashPrice'
 *      rule: // value for 'rule'
 *   },
 * });
 */
export function useCreateWagerMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateWagerMutation, CreateWagerMutationVariables>
) {
  return Apollo.useMutation<CreateWagerMutation, CreateWagerMutationVariables>(
    CreateWagerDocument,
    baseOptions
  );
}
export type CreateWagerMutationHookResult = ReturnType<typeof useCreateWagerMutation>;
export type CreateWagerMutationResult = Apollo.MutationResult<CreateWagerMutation>;
export type CreateWagerMutationOptions = Apollo.BaseMutationOptions<
  CreateWagerMutation,
  CreateWagerMutationVariables
>;
export const CreateWagerFullDocument = gql`
  mutation CreateWagerFull(
    $gameId: ID
    $gamePlateformId: ID
    $format: WAGER_FORMAT
    $title: String
    $startedAt: DateTime
    $cashPrice: Float
    $rule: String
    $entryCost: Float
    $gameformatId: ID
    $gameModeId: ID
    $entryType: TournamentEntryType
    $mapId: ID
    $gameServerId: ID
    $regionId: ID
    $audiochatId: ID
    $discordUrl: String
    $title_en: String
    $rule_en: String
  ) {
    createWager(
      gameId: $gameId
      gamePlateformId: $gamePlateformId
      format: $format
      title: $title
      startedAt: $startedAt
      cashPrice: $cashPrice
      rule: $rule
      entryCost: $entryCost
      gameformatId: $gameformatId
      gameModeId: $gameModeId
      entryType: $entryType
      mapId: $mapId
      gameServerId: $gameServerId
      regionId: $regionId
      audiochatId: $audiochatId
      discordUrl: $discordUrl
      title_en: $title_en
      rule_en: $rule_en
    ) {
      ...WagerInfos
    }
  }
  ${WagerInfosFragmentDoc}
`;
export type CreateWagerFullMutationFn = Apollo.MutationFunction<
  CreateWagerFullMutation,
  CreateWagerFullMutationVariables
>;

/**
 * __useCreateWagerFullMutation__
 *
 * To run a mutation, you first call `useCreateWagerFullMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWagerFullMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWagerFullMutation, { data, loading, error }] = useCreateWagerFullMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      gamePlateformId: // value for 'gamePlateformId'
 *      format: // value for 'format'
 *      title: // value for 'title'
 *      startedAt: // value for 'startedAt'
 *      cashPrice: // value for 'cashPrice'
 *      rule: // value for 'rule'
 *      entryCost: // value for 'entryCost'
 *      gameformatId: // value for 'gameformatId'
 *      gameModeId: // value for 'gameModeId'
 *      entryType: // value for 'entryType'
 *      mapId: // value for 'mapId'
 *      gameServerId: // value for 'gameServerId'
 *      regionId: // value for 'regionId'
 *      audiochatId: // value for 'audiochatId'
 *      discordUrl: // value for 'discordUrl'
 *      title_en: // value for 'title_en'
 *      rule_en: // value for 'rule_en'
 *   },
 * });
 */
export function useCreateWagerFullMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWagerFullMutation,
    CreateWagerFullMutationVariables
  >
) {
  return Apollo.useMutation<CreateWagerFullMutation, CreateWagerFullMutationVariables>(
    CreateWagerFullDocument,
    baseOptions
  );
}
export type CreateWagerFullMutationHookResult = ReturnType<typeof useCreateWagerFullMutation>;
export type CreateWagerFullMutationResult = Apollo.MutationResult<CreateWagerFullMutation>;
export type CreateWagerFullMutationOptions = Apollo.BaseMutationOptions<
  CreateWagerFullMutation,
  CreateWagerFullMutationVariables
>;
export const UpdateWagerDocument = gql`
  mutation UpdateWager($id: ID!, $title: String, $cashPrice: Float, $rule: String) {
    updateWager(id: $id, title: $title, cashPrice: $cashPrice, rule: $rule) {
      ...WagerInfos
    }
  }
  ${WagerInfosFragmentDoc}
`;
export type UpdateWagerMutationFn = Apollo.MutationFunction<
  UpdateWagerMutation,
  UpdateWagerMutationVariables
>;

/**
 * __useUpdateWagerMutation__
 *
 * To run a mutation, you first call `useUpdateWagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWagerMutation, { data, loading, error }] = useUpdateWagerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      cashPrice: // value for 'cashPrice'
 *      rule: // value for 'rule'
 *   },
 * });
 */
export function useUpdateWagerMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateWagerMutation, UpdateWagerMutationVariables>
) {
  return Apollo.useMutation<UpdateWagerMutation, UpdateWagerMutationVariables>(
    UpdateWagerDocument,
    baseOptions
  );
}
export type UpdateWagerMutationHookResult = ReturnType<typeof useUpdateWagerMutation>;
export type UpdateWagerMutationResult = Apollo.MutationResult<UpdateWagerMutation>;
export type UpdateWagerMutationOptions = Apollo.BaseMutationOptions<
  UpdateWagerMutation,
  UpdateWagerMutationVariables
>;
export const JoinTeamToWagerDocument = gql`
  mutation JoinTeamToWager($wagerId: ID!, $teamId: ID!) {
    joinTeamToWager(wagerId: $wagerId, teamId: $teamId) {
      id
      user {
        id
      }
      team {
        id
      }
      wager {
        id
      }
    }
  }
`;
export type JoinTeamToWagerMutationFn = Apollo.MutationFunction<
  JoinTeamToWagerMutation,
  JoinTeamToWagerMutationVariables
>;

/**
 * __useJoinTeamToWagerMutation__
 *
 * To run a mutation, you first call `useJoinTeamToWagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinTeamToWagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinTeamToWagerMutation, { data, loading, error }] = useJoinTeamToWagerMutation({
 *   variables: {
 *      wagerId: // value for 'wagerId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useJoinTeamToWagerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinTeamToWagerMutation,
    JoinTeamToWagerMutationVariables
  >
) {
  return Apollo.useMutation<JoinTeamToWagerMutation, JoinTeamToWagerMutationVariables>(
    JoinTeamToWagerDocument,
    baseOptions
  );
}
export type JoinTeamToWagerMutationHookResult = ReturnType<typeof useJoinTeamToWagerMutation>;
export type JoinTeamToWagerMutationResult = Apollo.MutationResult<JoinTeamToWagerMutation>;
export type JoinTeamToWagerMutationOptions = Apollo.BaseMutationOptions<
  JoinTeamToWagerMutation,
  JoinTeamToWagerMutationVariables
>;
export const JoinMeToWagerDocument = gql`
  mutation JoinMeToWager($wagerId: ID!) {
    joinMeToWager(wagerId: $wagerId) {
      id
      user {
        id
      }
      team {
        id
      }
      wager {
        id
      }
    }
  }
`;
export type JoinMeToWagerMutationFn = Apollo.MutationFunction<
  JoinMeToWagerMutation,
  JoinMeToWagerMutationVariables
>;

/**
 * __useJoinMeToWagerMutation__
 *
 * To run a mutation, you first call `useJoinMeToWagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinMeToWagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinMeToWagerMutation, { data, loading, error }] = useJoinMeToWagerMutation({
 *   variables: {
 *      wagerId: // value for 'wagerId'
 *   },
 * });
 */
export function useJoinMeToWagerMutation(
  baseOptions?: Apollo.MutationHookOptions<JoinMeToWagerMutation, JoinMeToWagerMutationVariables>
) {
  return Apollo.useMutation<JoinMeToWagerMutation, JoinMeToWagerMutationVariables>(
    JoinMeToWagerDocument,
    baseOptions
  );
}
export type JoinMeToWagerMutationHookResult = ReturnType<typeof useJoinMeToWagerMutation>;
export type JoinMeToWagerMutationResult = Apollo.MutationResult<JoinMeToWagerMutation>;
export type JoinMeToWagerMutationOptions = Apollo.BaseMutationOptions<
  JoinMeToWagerMutation,
  JoinMeToWagerMutationVariables
>;
export const AllWagersDocument = gql`
  query AllWagers(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: WagerFilter
  ) {
    allWagers(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      isMeAlreadyParticipant
      cashPrice
      format
      isActive
      participantNumber
      startedAt
      updatedAt
      title
      createdAt
      entryType
      game {
        id
        name
        logo
        twitchGameId
      }
      participants {
        id
        user {
          id
          username
          avatar
        }
        team {
          id
          logo
          name
        }
      }
      plateform {
        id
        name
        logo
      }
      gamemode {
        id
        title
        teamSize
        type
      }
      createdBy {
        id
        username
      }
      rule
      rule_en
      entryCost
    }
  }
`;

/**
 * __useAllWagersQuery__
 *
 * To run a query within a React component, call `useAllWagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllWagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllWagersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllWagersQuery(
  baseOptions?: Apollo.QueryHookOptions<AllWagersQuery, AllWagersQueryVariables>
) {
  return Apollo.useQuery<AllWagersQuery, AllWagersQueryVariables>(AllWagersDocument, baseOptions);
}
export function useAllWagersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllWagersQuery, AllWagersQueryVariables>
) {
  return Apollo.useLazyQuery<AllWagersQuery, AllWagersQueryVariables>(
    AllWagersDocument,
    baseOptions
  );
}
export type AllWagersQueryHookResult = ReturnType<typeof useAllWagersQuery>;
export type AllWagersLazyQueryHookResult = ReturnType<typeof useAllWagersLazyQuery>;
export type AllWagersQueryResult = Apollo.QueryResult<AllWagersQuery, AllWagersQueryVariables>;
export const ActiveWagersDocument = gql`
  query ActiveWagers {
    activeWagers {
      ...WagerInfos
      plateform {
        id
        name
        logo
      }
      game {
        id
        name
      }
    }
  }
  ${WagerInfosFragmentDoc}
`;

/**
 * __useActiveWagersQuery__
 *
 * To run a query within a React component, call `useActiveWagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveWagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveWagersQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveWagersQuery(
  baseOptions?: Apollo.QueryHookOptions<ActiveWagersQuery, ActiveWagersQueryVariables>
) {
  return Apollo.useQuery<ActiveWagersQuery, ActiveWagersQueryVariables>(
    ActiveWagersDocument,
    baseOptions
  );
}
export function useActiveWagersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ActiveWagersQuery, ActiveWagersQueryVariables>
) {
  return Apollo.useLazyQuery<ActiveWagersQuery, ActiveWagersQueryVariables>(
    ActiveWagersDocument,
    baseOptions
  );
}
export type ActiveWagersQueryHookResult = ReturnType<typeof useActiveWagersQuery>;
export type ActiveWagersLazyQueryHookResult = ReturnType<typeof useActiveWagersLazyQuery>;
export type ActiveWagersQueryResult = Apollo.QueryResult<
  ActiveWagersQuery,
  ActiveWagersQueryVariables
>;
export const WagerDocument = gql`
  query Wager($id: ID!) {
    Wager(id: $id) {
      ...WagerCompleteInfos
    }
  }
  ${WagerCompleteInfosFragmentDoc}
`;

/**
 * __useWagerQuery__
 *
 * To run a query within a React component, call `useWagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useWagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWagerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWagerQuery(
  baseOptions: Apollo.QueryHookOptions<WagerQuery, WagerQueryVariables>
) {
  return Apollo.useQuery<WagerQuery, WagerQueryVariables>(WagerDocument, baseOptions);
}
export function useWagerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WagerQuery, WagerQueryVariables>
) {
  return Apollo.useLazyQuery<WagerQuery, WagerQueryVariables>(WagerDocument, baseOptions);
}
export type WagerQueryHookResult = ReturnType<typeof useWagerQuery>;
export type WagerLazyQueryHookResult = ReturnType<typeof useWagerLazyQuery>;
export type WagerQueryResult = Apollo.QueryResult<WagerQuery, WagerQueryVariables>;
export const GetGameWagerDocument = gql`
  query GetGameWager($id: ID!) {
    Wager(id: $id) {
      id
      title
      game {
        id
        mainJacket
        logo
        twitchGameId
      }
      startedAt
      discordUrl
      plateform {
        id
        name
        logo
      }
      gamemode {
        id
        title
        teamSize
        type
      }
      participants {
        id
      }
      gameserver {
        id
        name
        country {
          id
          code
        }
      }
      participantNumber
      isMeAlreadyParticipant
      chatEngineId
      chatEngineAccessKey
    }
  }
`;

/**
 * __useGetGameWagerQuery__
 *
 * To run a query within a React component, call `useGetGameWagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameWagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameWagerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGameWagerQuery(
  baseOptions: Apollo.QueryHookOptions<GetGameWagerQuery, GetGameWagerQueryVariables>
) {
  return Apollo.useQuery<GetGameWagerQuery, GetGameWagerQueryVariables>(
    GetGameWagerDocument,
    baseOptions
  );
}
export function useGetGameWagerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGameWagerQuery, GetGameWagerQueryVariables>
) {
  return Apollo.useLazyQuery<GetGameWagerQuery, GetGameWagerQueryVariables>(
    GetGameWagerDocument,
    baseOptions
  );
}
export type GetGameWagerQueryHookResult = ReturnType<typeof useGetGameWagerQuery>;
export type GetGameWagerLazyQueryHookResult = ReturnType<typeof useGetGameWagerLazyQuery>;
export type GetGameWagerQueryResult = Apollo.QueryResult<
  GetGameWagerQuery,
  GetGameWagerQueryVariables
>;
export const GetGeneralInfoWagerDocument = gql`
  query GetGeneralInfoWager($id: ID!) {
    Wager(id: $id) {
      id
      isMeAlreadyParticipant
      signupAt
      cashPrice
      format
      isActive
      participantNumber
      startedAt
      updatedAt
      title
      createdAt
      game {
        id
        name
        logo
      }
      plateform {
        id
        name
        logo
      }
      rule
      rule_en
      entryCost
      entryType
      map {
        id
        name
      }
      gameformat {
        id
        name
      }
      gamemode {
        id
        title
        teamSize
        type
      }
      region {
        id
        name
      }
      audiochat {
        id
        name
      }
      gameserver {
        id
        name
      }
    }
  }
`;

/**
 * __useGetGeneralInfoWagerQuery__
 *
 * To run a query within a React component, call `useGetGeneralInfoWagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralInfoWagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralInfoWagerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGeneralInfoWagerQuery(
  baseOptions: Apollo.QueryHookOptions<GetGeneralInfoWagerQuery, GetGeneralInfoWagerQueryVariables>
) {
  return Apollo.useQuery<GetGeneralInfoWagerQuery, GetGeneralInfoWagerQueryVariables>(
    GetGeneralInfoWagerDocument,
    baseOptions
  );
}
export function useGetGeneralInfoWagerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGeneralInfoWagerQuery,
    GetGeneralInfoWagerQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetGeneralInfoWagerQuery, GetGeneralInfoWagerQueryVariables>(
    GetGeneralInfoWagerDocument,
    baseOptions
  );
}
export type GetGeneralInfoWagerQueryHookResult = ReturnType<typeof useGetGeneralInfoWagerQuery>;
export type GetGeneralInfoWagerLazyQueryHookResult = ReturnType<
  typeof useGetGeneralInfoWagerLazyQuery
>;
export type GetGeneralInfoWagerQueryResult = Apollo.QueryResult<
  GetGeneralInfoWagerQuery,
  GetGeneralInfoWagerQueryVariables
>;
export const GetWinnerWagerDocument = gql`
  query GetWinnerWager($id: ID!) {
    Wager(id: $id) {
      id
      winner {
        id
        user {
          id
          firstname
          lastname
          email
        }
        team {
          id
          name
        }
      }
      winnerId
    }
  }
`;

/**
 * __useGetWinnerWagerQuery__
 *
 * To run a query within a React component, call `useGetWinnerWagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWinnerWagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWinnerWagerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWinnerWagerQuery(
  baseOptions: Apollo.QueryHookOptions<GetWinnerWagerQuery, GetWinnerWagerQueryVariables>
) {
  return Apollo.useQuery<GetWinnerWagerQuery, GetWinnerWagerQueryVariables>(
    GetWinnerWagerDocument,
    baseOptions
  );
}
export function useGetWinnerWagerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWinnerWagerQuery, GetWinnerWagerQueryVariables>
) {
  return Apollo.useLazyQuery<GetWinnerWagerQuery, GetWinnerWagerQueryVariables>(
    GetWinnerWagerDocument,
    baseOptions
  );
}
export type GetWinnerWagerQueryHookResult = ReturnType<typeof useGetWinnerWagerQuery>;
export type GetWinnerWagerLazyQueryHookResult = ReturnType<typeof useGetWinnerWagerLazyQuery>;
export type GetWinnerWagerQueryResult = Apollo.QueryResult<
  GetWinnerWagerQuery,
  GetWinnerWagerQueryVariables
>;
export const GetRuleWagerDocument = gql`
  query GetRuleWager($id: ID!) {
    Wager(id: $id) {
      id
      title
      rule
      rule_en
    }
  }
`;

/**
 * __useGetRuleWagerQuery__
 *
 * To run a query within a React component, call `useGetRuleWagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRuleWagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRuleWagerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRuleWagerQuery(
  baseOptions: Apollo.QueryHookOptions<GetRuleWagerQuery, GetRuleWagerQueryVariables>
) {
  return Apollo.useQuery<GetRuleWagerQuery, GetRuleWagerQueryVariables>(
    GetRuleWagerDocument,
    baseOptions
  );
}
export function useGetRuleWagerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRuleWagerQuery, GetRuleWagerQueryVariables>
) {
  return Apollo.useLazyQuery<GetRuleWagerQuery, GetRuleWagerQueryVariables>(
    GetRuleWagerDocument,
    baseOptions
  );
}
export type GetRuleWagerQueryHookResult = ReturnType<typeof useGetRuleWagerQuery>;
export type GetRuleWagerLazyQueryHookResult = ReturnType<typeof useGetRuleWagerLazyQuery>;
export type GetRuleWagerQueryResult = Apollo.QueryResult<
  GetRuleWagerQuery,
  GetRuleWagerQueryVariables
>;
export const GetParticipantsWagerDocument = gql`
  query GetParticipantsWager($id: ID!) {
    Wager(id: $id) {
      id
      title
      isMeAlreadyParticipant
      participantNumber
      participants {
        id
        user {
          ...UserBasicInfos
        }
        team {
          ...TeamBasicInfos
        }
      }
      isMeAlreadyParticipant
    }
  }
  ${UserBasicInfosFragmentDoc}
  ${TeamBasicInfosFragmentDoc}
`;

/**
 * __useGetParticipantsWagerQuery__
 *
 * To run a query within a React component, call `useGetParticipantsWagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipantsWagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipantsWagerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetParticipantsWagerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetParticipantsWagerQuery,
    GetParticipantsWagerQueryVariables
  >
) {
  return Apollo.useQuery<GetParticipantsWagerQuery, GetParticipantsWagerQueryVariables>(
    GetParticipantsWagerDocument,
    baseOptions
  );
}
export function useGetParticipantsWagerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParticipantsWagerQuery,
    GetParticipantsWagerQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetParticipantsWagerQuery, GetParticipantsWagerQueryVariables>(
    GetParticipantsWagerDocument,
    baseOptions
  );
}
export type GetParticipantsWagerQueryHookResult = ReturnType<typeof useGetParticipantsWagerQuery>;
export type GetParticipantsWagerLazyQueryHookResult = ReturnType<
  typeof useGetParticipantsWagerLazyQuery
>;
export type GetParticipantsWagerQueryResult = Apollo.QueryResult<
  GetParticipantsWagerQuery,
  GetParticipantsWagerQueryVariables
>;
export const GetWagerDiscordChannelDocument = gql`
  query GetWagerDiscordChannel($id: ID!) {
    Wager(id: $id) {
      id
      discordChannelId
    }
  }
`;

/**
 * __useGetWagerDiscordChannelQuery__
 *
 * To run a query within a React component, call `useGetWagerDiscordChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWagerDiscordChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWagerDiscordChannelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWagerDiscordChannelQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWagerDiscordChannelQuery,
    GetWagerDiscordChannelQueryVariables
  >
) {
  return Apollo.useQuery<GetWagerDiscordChannelQuery, GetWagerDiscordChannelQueryVariables>(
    GetWagerDiscordChannelDocument,
    baseOptions
  );
}
export function useGetWagerDiscordChannelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWagerDiscordChannelQuery,
    GetWagerDiscordChannelQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetWagerDiscordChannelQuery, GetWagerDiscordChannelQueryVariables>(
    GetWagerDiscordChannelDocument,
    baseOptions
  );
}
export type GetWagerDiscordChannelQueryHookResult = ReturnType<
  typeof useGetWagerDiscordChannelQuery
>;
export type GetWagerDiscordChannelLazyQueryHookResult = ReturnType<
  typeof useGetWagerDiscordChannelLazyQuery
>;
export type GetWagerDiscordChannelQueryResult = Apollo.QueryResult<
  GetWagerDiscordChannelQuery,
  GetWagerDiscordChannelQueryVariables
>;
export const GetWagerInfoDocument = gql`
  query GetWagerInfo {
    wagerInformation {
      totalWagerCashPrize
      totalMonthWager
      totalWagerParticipant
      topGame {
        game {
          id
          name
          logo
          twitchGameId
        }
        percent
      }
    }
  }
`;

/**
 * __useGetWagerInfoQuery__
 *
 * To run a query within a React component, call `useGetWagerInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWagerInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWagerInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWagerInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<GetWagerInfoQuery, GetWagerInfoQueryVariables>
) {
  return Apollo.useQuery<GetWagerInfoQuery, GetWagerInfoQueryVariables>(
    GetWagerInfoDocument,
    baseOptions
  );
}
export function useGetWagerInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWagerInfoQuery, GetWagerInfoQueryVariables>
) {
  return Apollo.useLazyQuery<GetWagerInfoQuery, GetWagerInfoQueryVariables>(
    GetWagerInfoDocument,
    baseOptions
  );
}
export type GetWagerInfoQueryHookResult = ReturnType<typeof useGetWagerInfoQuery>;
export type GetWagerInfoLazyQueryHookResult = ReturnType<typeof useGetWagerInfoLazyQuery>;
export type GetWagerInfoQueryResult = Apollo.QueryResult<
  GetWagerInfoQuery,
  GetWagerInfoQueryVariables
>;
export const MakeWalletPaymentDocument = gql`
  mutation MakeWalletPayment($amount: Float, $jetton: Float) {
    makeWalletPayment(amount: $amount, jetton: $jetton) {
      clientSecret
      order {
        ...WalletOrderInfo
      }
    }
  }
  ${WalletOrderInfoFragmentDoc}
`;
export type MakeWalletPaymentMutationFn = Apollo.MutationFunction<
  MakeWalletPaymentMutation,
  MakeWalletPaymentMutationVariables
>;

/**
 * __useMakeWalletPaymentMutation__
 *
 * To run a mutation, you first call `useMakeWalletPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeWalletPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeWalletPaymentMutation, { data, loading, error }] = useMakeWalletPaymentMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      jetton: // value for 'jetton'
 *   },
 * });
 */
export function useMakeWalletPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakeWalletPaymentMutation,
    MakeWalletPaymentMutationVariables
  >
) {
  return Apollo.useMutation<MakeWalletPaymentMutation, MakeWalletPaymentMutationVariables>(
    MakeWalletPaymentDocument,
    baseOptions
  );
}
export type MakeWalletPaymentMutationHookResult = ReturnType<typeof useMakeWalletPaymentMutation>;
export type MakeWalletPaymentMutationResult = Apollo.MutationResult<MakeWalletPaymentMutation>;
export type MakeWalletPaymentMutationOptions = Apollo.BaseMutationOptions<
  MakeWalletPaymentMutation,
  MakeWalletPaymentMutationVariables
>;
export const ConfirmWalletOrderPaymentDocument = gql`
  mutation ConfirmWalletOrderPayment($walletOrderId: ID!, $meta: JSON) {
    confirmWalletOrderPayment(walletOrderId: $walletOrderId, meta: $meta) {
      ...WalletOrderInfo
    }
  }
  ${WalletOrderInfoFragmentDoc}
`;
export type ConfirmWalletOrderPaymentMutationFn = Apollo.MutationFunction<
  ConfirmWalletOrderPaymentMutation,
  ConfirmWalletOrderPaymentMutationVariables
>;

/**
 * __useConfirmWalletOrderPaymentMutation__
 *
 * To run a mutation, you first call `useConfirmWalletOrderPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmWalletOrderPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmWalletOrderPaymentMutation, { data, loading, error }] = useConfirmWalletOrderPaymentMutation({
 *   variables: {
 *      walletOrderId: // value for 'walletOrderId'
 *      meta: // value for 'meta'
 *   },
 * });
 */
export function useConfirmWalletOrderPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmWalletOrderPaymentMutation,
    ConfirmWalletOrderPaymentMutationVariables
  >
) {
  return Apollo.useMutation<
    ConfirmWalletOrderPaymentMutation,
    ConfirmWalletOrderPaymentMutationVariables
  >(ConfirmWalletOrderPaymentDocument, baseOptions);
}
export type ConfirmWalletOrderPaymentMutationHookResult = ReturnType<
  typeof useConfirmWalletOrderPaymentMutation
>;
export type ConfirmWalletOrderPaymentMutationResult =
  Apollo.MutationResult<ConfirmWalletOrderPaymentMutation>;
export type ConfirmWalletOrderPaymentMutationOptions = Apollo.BaseMutationOptions<
  ConfirmWalletOrderPaymentMutation,
  ConfirmWalletOrderPaymentMutationVariables
>;
export const AllWalletsDocument = gql`
  query AllWallets(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: WalletFilter
  ) {
    allWallets(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...WalletInfo
    }
  }
  ${WalletInfoFragmentDoc}
`;

/**
 * __useAllWalletsQuery__
 *
 * To run a query within a React component, call `useAllWalletsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllWalletsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllWalletsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllWalletsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllWalletsQuery, AllWalletsQueryVariables>
) {
  return Apollo.useQuery<AllWalletsQuery, AllWalletsQueryVariables>(
    AllWalletsDocument,
    baseOptions
  );
}
export function useAllWalletsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllWalletsQuery, AllWalletsQueryVariables>
) {
  return Apollo.useLazyQuery<AllWalletsQuery, AllWalletsQueryVariables>(
    AllWalletsDocument,
    baseOptions
  );
}
export type AllWalletsQueryHookResult = ReturnType<typeof useAllWalletsQuery>;
export type AllWalletsLazyQueryHookResult = ReturnType<typeof useAllWalletsLazyQuery>;
export type AllWalletsQueryResult = Apollo.QueryResult<AllWalletsQuery, AllWalletsQueryVariables>;
export const AllWallettransactionsDocument = gql`
  query AllWallettransactions(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: WallettransactionFilter
  ) {
    allWallettransactions(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      ...WallettransactionInfo
    }
  }
  ${WallettransactionInfoFragmentDoc}
`;

/**
 * __useAllWallettransactionsQuery__
 *
 * To run a query within a React component, call `useAllWallettransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllWallettransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllWallettransactionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllWallettransactionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllWallettransactionsQuery,
    AllWallettransactionsQueryVariables
  >
) {
  return Apollo.useQuery<AllWallettransactionsQuery, AllWallettransactionsQueryVariables>(
    AllWallettransactionsDocument,
    baseOptions
  );
}
export function useAllWallettransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllWallettransactionsQuery,
    AllWallettransactionsQueryVariables
  >
) {
  return Apollo.useLazyQuery<AllWallettransactionsQuery, AllWallettransactionsQueryVariables>(
    AllWallettransactionsDocument,
    baseOptions
  );
}
export type AllWallettransactionsQueryHookResult = ReturnType<typeof useAllWallettransactionsQuery>;
export type AllWallettransactionsLazyQueryHookResult = ReturnType<
  typeof useAllWallettransactionsLazyQuery
>;
export type AllWallettransactionsQueryResult = Apollo.QueryResult<
  AllWallettransactionsQuery,
  AllWallettransactionsQueryVariables
>;
export const MyWalletTransactionDocument = gql`
  query MyWalletTransaction {
    me {
      id
      totalWallet
      wallet {
        ...WalletInfo
      }
    }
  }
  ${WalletInfoFragmentDoc}
`;

/**
 * __useMyWalletTransactionQuery__
 *
 * To run a query within a React component, call `useMyWalletTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyWalletTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyWalletTransactionQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyWalletTransactionQuery(
  baseOptions?: Apollo.QueryHookOptions<MyWalletTransactionQuery, MyWalletTransactionQueryVariables>
) {
  return Apollo.useQuery<MyWalletTransactionQuery, MyWalletTransactionQueryVariables>(
    MyWalletTransactionDocument,
    baseOptions
  );
}
export function useMyWalletTransactionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyWalletTransactionQuery,
    MyWalletTransactionQueryVariables
  >
) {
  return Apollo.useLazyQuery<MyWalletTransactionQuery, MyWalletTransactionQueryVariables>(
    MyWalletTransactionDocument,
    baseOptions
  );
}
export type MyWalletTransactionQueryHookResult = ReturnType<typeof useMyWalletTransactionQuery>;
export type MyWalletTransactionLazyQueryHookResult = ReturnType<
  typeof useMyWalletTransactionLazyQuery
>;
export type MyWalletTransactionQueryResult = Apollo.QueryResult<
  MyWalletTransactionQuery,
  MyWalletTransactionQueryVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    MatchOpponent: ['MatchUserOpponent', 'MatchTeamOpponent'],
    ReportActionContent: ['Tournament', 'TournamentMessage'],
    TournamentMessageContent: ['MessageFile', 'MessageText'],
    TournamentParticipants: ['ListUser', 'ListTeam'],
    TournamentWinners: ['TournamentUserWinners', 'TournamentTeamWinners'],
  },
};
export default result;
