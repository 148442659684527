import React, { FC, memo, Suspense, useEffect } from 'react';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';
import { useMeQuery } from '../../generated/graphql';
import AuthServices from '../../services/AuthServices';
import Loading from '../Loading';

type AccessType = 'private' | 'public' | 'authentication';

export interface ProtectedLazyRouteProps extends RouteProps {
  noAccessRedirection?: string;
  fallbackComponent?: FC;
  access?: AccessType;
}

const defaultFallback = <Loading isFullHeight={true} />;

const getNoAccessDefaultPath = (access: AccessType): string | undefined => {
  const isConnected = Boolean(AuthServices.getAccessToken());
  switch (access) {
    case 'authentication':
      return isConnected ? '/app' : undefined;
    case 'private':
      return isConnected ? undefined : '/login';
    case 'public':
      return undefined;
    default:
      return isConnected ? '/app' : '/';
  }
};

const ProtectedLazyRoute: FC<ProtectedLazyRouteProps> = memo(props => {
  let redirectionPath = '';
  const { data } = useMeQuery();
  const history = useHistory();
  const { noAccessRedirection, component, fallbackComponent, access: accessProps } = props;
  const FallbackComponent = fallbackComponent || defaultFallback;
  const access: AccessType = accessProps || 'private';

  const pathToRedirect = getNoAccessDefaultPath(access);
  useEffect(() => {
    if (
      history.location.pathname !== '/login' &&
      history.location.pathname !== '/signup' &&
      data?.me?.isSignupFinish === false
    )
      history.push('/signup');
  }, [data?.me?.isSignupFinish, history.location.pathname]);

  if (pathToRedirect) {
    redirectionPath = noAccessRedirection || pathToRedirect;
  }
  if (redirectionPath === '') {
    return (
      <Suspense fallback={FallbackComponent}>
        <Route {...props} component={component as any} />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={FallbackComponent}>
      <Redirect to={{ pathname: redirectionPath }} />
    </Suspense>
  );
});

export default ProtectedLazyRoute;
