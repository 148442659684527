import { ApolloProvider as ApolloProviderClient } from '@apollo/client';
import React, { FC, useContext } from 'react';
import { apolloClient } from '../config/apolloClient';
import { AuthContext } from './AuthProvider';

const ApolloProvider: FC = ({ children }) => {
  const { token } = useContext(AuthContext);

  return <ApolloProviderClient client={apolloClient(token)}>{children}</ApolloProviderClient>;
};
export default ApolloProvider;
