import { gql } from '@apollo/client';

export const TEAM_MEMBER_FRAGMENT = gql`
  fragment TeamMemberInfos on TeamMember {
    id
    role
    createdAt
    user {
      id
      isMe
      firstname
      lastname
      email
      avatar
      username
      isAdmin
      phoneNumber
      displayName
    }
    team {
      id
      name
      description
      logo
      banner
      createdAt
    }
  }
`;
