import { Link, LinkProps } from '@material-ui/core';
import classnames from 'classnames';
import React, { FC, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import useStyles from './style';

interface LinkCustomProps {
  onClick?: () => void;
}
const AnchorLink: FC<LinkProps & LinkCustomProps> = props => {
  const cls = useStyles();
  const { href, children, onClick: onClickParam, color, className: classNameProp } = props;
  const history = useHistory();
  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    if (onClickParam) {
      onClickParam();
    }
    history.push(href || '/');
  };

  return (
    <Link
      {...props}
      onClick={handleClick}
      className={classnames(classNameProp, !color ? cls.whiteColor : '')}>
      {children}
    </Link>
  );
};

export default AnchorLink;
