import { Grid, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import LoadingButton from '../../common/LoadingButton';
import Typography from '../../common/Typography';
import { globalSnackbarVar } from '../../config/reactiveVars';
import { useSendInvitationMobileMutation } from '../../generated/graphql';
import PublicLayout from '../PublicLayout';
import useStyles from './style';

const expoLink = 'exp://exp.host/@thoussein/gmrtl2?release-channel=default';
const InvitationMobile = () => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [send, { loading }] = useSendInvitationMobileMutation();
  const handleSubmit = () => {
    if (email.length) {
      send({
        variables: {
          email: [email],
        },
      }).then(() => {
        globalSnackbarVar({
          message: 'Invitation envoyé',
          open: true,
          type: 'SUCCESS',
          duration: 3000,
        });
        setEmail('');
      });
    }
  };
  return (
    <PublicLayout>
      <Grid
        container={true}
        alignContent="center"
        alignItems="center"
        className={classes.container}>
        <Typography variant="h4">Scanner le code QR ci-dessous</Typography>
        <div className={classes.qrContainer}>
          <QRCode value={expoLink} />
        </div>
        <Typography variant="h6">{`${'Pour IOS, veillez entrer votre email pour recevoir une invitation'}`}</Typography>
        <div className={classes.formContainer}>
          <TextField
            name="email"
            className={classes.input}
            placeholder="Ton email"
            onChange={e => {
              setEmail(e.target.value);
            }}
          />
          <LoadingButton
            loading={loading}
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}>
            Envoyer
          </LoadingButton>
        </div>
      </Grid>
    </PublicLayout>
  );
};

export default InvitationMobile;
