import { gql } from '@apollo/client';
import { USER_BASIC_FRAGMENT } from '../user/fragment';
import { CHAT_GROUP_FRAGMENT } from './fragment';

export const MY_ALL_CHAT_GROUP = gql`
  query MyAllChatGroup {
    me {
      id
      chats {
        ...ChatGroupInfo
      }
    }
  }
  ${CHAT_GROUP_FRAGMENT}
`;

export const MY_GAME_PARTY = gql`
  query MyGameParty {
    me {
      id
      gameParty {
        id
        removed
        name
        members {
          id
        }
        chat {
          ...ChatGroupInfo
        }
      }
    }
  }
  ${CHAT_GROUP_FRAGMENT}
`;

export const CHAT_MEMBER = gql`
  query Chat($id: ID!) {
    Chat(id: $id) {
      ...ChatGroupInfo
      members {
        id
        removed
        user {
          ...UserBasicInfos
        }
      }
    }
  }
  ${CHAT_GROUP_FRAGMENT}
  ${USER_BASIC_FRAGMENT}
`;
