import { Social_App } from "../generated/graphql";

const ACCESS_TOKEN = 'ACCESS_TOKEN';
const AUTH_NAME = 'AUTH_NAME';
const TOKEN_DEFAULT = 'TOKEN_DEFAULT';

const authService = {
  clearToken: () => localStorage.clear(),
  isAuthenticated: (): boolean => !!localStorage.getItem(ACCESS_TOKEN),
  setAccessToken: (token: string) => localStorage.setItem(ACCESS_TOKEN, token),
  getAccessToken: (): string | null => localStorage.getItem(ACCESS_TOKEN),
  setAuthName: (authName: Social_App | 'GG') => localStorage.setItem(AUTH_NAME, authName),
  getAuthName: (): Social_App | 'GG' => localStorage.getItem(AUTH_NAME) as (Social_App | 'GG'),
  setTokenDefault: (tokenDefault: string) => localStorage.setItem(TOKEN_DEFAULT, tokenDefault),
  getTokenDefault: (): string => localStorage.getItem(TOKEN_DEFAULT) as string,
  signOut: () => {
    localStorage.clear();
  },
};

export default authService;
