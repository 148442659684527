import { ApolloClient, createHttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { fieldPaginationFn, withIsRemovedPaginationFn } from '../services/apolloUtils';
import { getUserLanguage } from '../services/languageUtil';
import config from './config';

export const apolloClient = (token?: string) => {
  const authLink = setContext((_, { headers }) => {
    const language = getUserLanguage();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        // Make fr as a default language
        language: language || 'fr',
        'Cross-Origin-Resource-Policy': 'cross-origin'
      },
    };
  });

  const httpLink = createHttpLink({
    uri: config.servers.graphqlUrl,
  });

  const wsLink = new WebSocketLink({
    uri: config.servers.graphqlSubscriptionUrl,
    options: {
      reconnect: true,
      connectionParams: {
        authToken: token,
      },
    },
  });

  const simplePagination = fieldPaginationFn();

  const cache: InMemoryCache & {
    readQueryClone: any;
  } = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          users: simplePagination,
          videos: simplePagination,
        },
      },
      User: {
        keyFields: ['id'],
        fields: {
          games: simplePagination,
          teams: simplePagination,
        },
      },
      Tournament: {
        keyFields: ['id'],
        fields: {
          messages: withIsRemovedPaginationFn(false, 'up'),
        },
      },
    },
  }) as any;

  cache.readQueryClone = cache.readQuery;

  cache.readQuery = (...args) => {
    try {
      return cache.readQueryClone(...args);
    } catch (err) {
      return undefined;
    }
  };

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    authLink.concat(httpLink)
  );

  return new ApolloClient({
    link: splitLink,
    cache,
  });
};
