import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classnames from 'classnames';
import React, { FC } from 'react';
import { useMeQuery } from '../../../generated/graphql';
import useStyles from './styles';

interface IProps {
  isPopoverOpen?: boolean;
}
const UserProfileButton: FC<IProps> = ({ isPopoverOpen }) => {
  const classes = useStyles();
  // const [open, setOpen] = useState(false);
  const { data } = useMeQuery();
  // const progression = useMemo(() => {
  //   if (data?.me) {
  //     const allKeys = Object.keys(data.me);
  //     const allExistKey = allKeys.filter(k => data.me[k]);
  //     return Math.ceil((allExistKey.length * 100) / allKeys.length);
  //   }
  //   return 0;
  // }, [data?.me]);
  const name = data?.me.username ? data.me.username : 'GamerTag';
  const logo =
    data?.me.avatar ||
    'https://robohash.org/5ffd1f9f9c1b6bfafb05bba498c6bb44?set=set3&bgset=&size=400x400';
  return (
    <div className={classnames(classes.container, classes.center)}>
      <img
        alt="Avatar"
        src={logo}
        className={classnames(classes.avatar, classes.center, classes.border)}
      />
      <div>
        <div className={classes.userName}>{name.trim()}</div>
        {/* <LinearProgress className={classes.progress} value={progression} variant="determinate" /> */}
      </div>
      {!isPopoverOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}

      {/* <div className={classnames(classes.menu, classes.center, classes.border)}>
        <div>
          <div className={classes.gamerTag}>{name.trim()}</div>
          <div className={classnames(classes.icons, classes.center)}>
            <PSLogo />
            <CountryFlag isoCode={language} />
            <MenuIcon />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default UserProfileButton;
