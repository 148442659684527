import { createStyles } from '@material-ui/core/styles';

export const textStyles = createStyles({
  ellipsis: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
