import { useMeQuery } from '../generated/graphql';

const useLanguage = () => {
  const { data } = useMeQuery({
    fetchPolicy: 'cache-first',
  });
  const getField = (field: string) => {
    return data?.me.language === 'en' ? `${field}_en` : field;
  };
  return {
    local: data?.me?.language === 'en' ? 'en' : 'fr',
    getField,
  } as {
    local: 'en' | 'fr';
    getField: (v: string) => string;
  };
};
export default useLanguage;
