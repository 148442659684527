import { Button, TextField, Typography } from '@material-ui/core';
import React, { SyntheticEvent, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Snackbar from '../../../common/Snackbar/Snackbar';
import { usePageBySlugQuery } from '../../../generated/graphql';
import useLanguage from '../../../hooks/useLanguage';
import { AuthContext } from '../../../provider/AuthProvider';
import LayoutContainer from '../LayoutContainer';
import { validateEmail } from '../Lib/utils';
import styles from './styles';

const JoinSection = () => {
  const cls = styles();
  const { token } = useContext(AuthContext);
  const { local } = useLanguage();
  const { data: pageData } = usePageBySlugQuery({
    variables: {
      slug: 'landing-page',
    },
  });
  const landingPageInfo = pageData?.pageBySlug;
  const joinMessage = (landingPageInfo?.contents || []).find(
    e => e?.label === 'join-community-description'
  );

  const intl = useIntl();
  const history = useHistory();
  const [email, setEmail] = useState<string>('');
  const [snackbarOpened, setSnackbarOpened] = useState<boolean>(false);
  const yourEmail = intl.formatMessage({ id: 'your-email', defaultMessage: 'Ton email' });

  const handleChange = (e: any) => {
    e?.preventDefault();
    setEmail(e?.target?.value ?? '');
  };

  const handleClickButton = () => {
    if (!validateEmail(email)) {
      setSnackbarOpened(true);
    } else {
      history.push(`/signup/${email}`);
    }
  };

  const handleCloseSnackbar = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackbarOpened(false);
  };
  const index = local === 'en' ? 'value_en' : 'value';
  if (token?.length) return <div className={cls.h40} />;
  return (
    <section id="join-us" className={cls.joinUs}>
      <Snackbar
        message="Email invalide"
        open={snackbarOpened}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        type="ERROR"
      />
      <LayoutContainer>
        <div className={cls.inner}>
          <Typography variant="h2" align="center">
            {joinMessage ? (
              joinMessage[index]
            ) : (
              <FormattedMessage id="join-community" defaultMessage="" />
            )}
          </Typography>
          <div className={cls.textAlignCenter}>
            <TextField
              placeholder={yourEmail}
              variant="standard"
              id="email"
              name="email"
              value={email}
              onChange={handleChange}
              className={cls.input}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleClickButton();
              }}>
              <FormattedMessage id="free-register" defaultMessage="Créer ton compte gratuit" />
            </Button>
          </div>
        </div>
      </LayoutContainer>
    </section>
  );
};

export default JoinSection;
