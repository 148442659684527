import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import LoadingButton from '../../common/LoadingButton';
import {
  useCreateGamepartymemberMutation,
  useMyGamePartyInvitationQuery,
} from '../../generated/graphql';
import { MY_GAME_PARTY } from '../../gql/chat/query';
import useStyles from './style';

const InvitationGameParty = () => {
  const classes = useStyles();
  const { data } = useMyGamePartyInvitationQuery();
  const [isOpen, setIsOpen] = useState(false);
  const [count, setCount] = useState(30);

  const [acceptInvitation, { loading }] = useCreateGamepartymemberMutation();

  useEffect(() => {
    if (data?.me?.gamePartyInvitation?.gameParty?.id) {
      setIsOpen(true);
      setTimeout(() => {
        setIsOpen(false);
      }, 30000);
      setInterval(() => {
        setCount(value => value - 1);
      }, 1000);
    }
  }, [data?.me?.gamePartyInvitation?.gameParty?.id]);
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleSubmit = () => {
    if (data?.me?.id && data?.me?.gamePartyInvitation?.gameParty?.id) {
      acceptInvitation({
        variables: {
          partyId: data?.me?.gamePartyInvitation?.gameParty?.id,
          userId: data?.me?.id,
        },
        refetchQueries: [
          {
            query: MY_GAME_PARTY,
          },
        ],
      }).then(() => handleClose());
    }
  };
  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      classes={{ paper: classes.mainWrapper, root: classes.root }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={isOpen}>
      <DialogTitle className={classes.textAlignCenter} id="join-dialog-title">
        <FormattedMessage id="invite-to-group" />
      </DialogTitle>
      <DialogContent>
        <Box alignContent="center" justifyContent="center" alignItems="center">
          <b>
            <FormattedMessage
              id="user-invite-to-group"
              values={{
                name: `${data?.me?.gamePartyInvitation?.gameParty?.chat?.owner?.username}`,
              }}
            />
          </b>
          <br />
          <span>
            <FormattedMessage id="click-to-join" />
          </span>
          <br />
          <span>
            <FormattedMessage id="rest-time" />
            {` ${count}`}
          </span>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          alignContent="center"
          justifyContent="center"
          alignItems="center"
          className={classes.actionContainer}>
          <Button type="button" onClick={handleClose} variant="contained" color="default">
            <FormattedMessage id="all-refuse" />
          </Button>
          <LoadingButton
            loading={loading}
            type="button"
            onClick={handleSubmit}
            variant="contained"
            color="primary">
            <FormattedMessage id="all-accept" />
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default InvitationGameParty;
