import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'hidden',
  },
  mainWrapper: {
    backgroundColor: '#444',
    color: '#FFF',
    '& .MuiDialogContent-root': {
      overflowX: 'hidden',
    },
    '& .MuiDialogActions-root': {
      justifyContent: 'center',
    },
  },
  choiceItem: {
    marginBottom: 16,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'linear-gradient(-60deg, rgba(226,244,255,0.3) 5%,rgba(84, 107, 115, 0.3) 95%)',
    borderRadius: 4,
    color: ' #c6d4df',
    fontWeight: 'normal',
    fontSize: '10px',
    height: 80,
    '& .right': {
      marginRight: 10,
      whiteSpace: 'nowrap',
      textAlign: 'right',
      '& .action': {
        height: 'fit-content',
        verticalAlign: 'bottom',
        display: 'inline-block',
        backgroundColor: '#000000',
        padding: '2px 2px 2px 0px',
        borderRadius: 2,
        whiteSpace: 'nowrap',
        fontSize: 0,
        '& .price': {
          position: 'relative',
          display: 'inline-block',
          marginLeft: 2,
          verticalAlign: 'middle',
          backgroundColor: '#000000',
          fontSize: 13,
          paddingTop: 1,
          paddingLeft: 12,
          paddingRight: 12,
          height: 24,
        },
        '& .button': {
          marginLeft: 2,
          width: 'fit-content',
        },
      },
    },
    '& .left': {
      display: 'flex',
      flexDirection: 'column',
      '& h1': {},
      '& span': {
        fontSize: 13,
        marginTop: -13,
      },
    },
  },
  label: {
    width: '40%',
  },
  selectWrapper: {
    width: '100%',
  },
  amountWrapper: {
    width: '100%',
    color: theme.color.thunderbird,
    fontWeight: 'bold',
  },
  select: {
    width: '100%',
    backgroundColor: 'transparent!important',
    color: 'white!important',
  },
  menuItem: {
    backgroundColor: 'black!important',
    color: 'white!important',
  },
  paymentContainer: {
    '& .MuiFormControl-root': {
      marginRight: theme.spacing(1),
    },
    '& .MuiInputBase-input': {
      color: 'white',
      opacity: 1,
    },
    '& .MuiFormLabel-root': {
      color: 'white',
      width: '300px',
    },
    '& .MuiSelect-icon': {
      color: 'white',
    },
  },
  inlineFlex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  divider: {
    border: '1px solid white',
    marginTop: 4,
    marginBottom: 4,
  },
  selectItem: {
    backgroundColor: 'white!important',
    '&:hover': {
      backgroundColor: 'white!important',
    },
  },
  img: {},
  actionContainer: {
    '& .MuiButtonBase-root': {
      marginLeft: 15,
    },
  },
  textAlignCenter: {
    textAlign: 'center',
  }
}));

export default useStyles;
