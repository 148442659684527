import { motion } from 'framer-motion';
import React, { FC } from 'react';

const Path = (props: any) => (
  <motion.path fill="transparent" stroke="white" strokeWidth="2" strokeLinecap="round" {...props} />
);

interface IMenuToggleButton {
  opened: boolean;
}

export const MenuToggleButton: FC<IMenuToggleButton> = ({ opened }) => (
  <motion.div initial={false} animate={opened ? 'opened' : 'closed'}>
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        variants={{
          closed: { d: 'M 2 2.5 L 20 2.5' },
          opened: { d: 'M 3 16.5 L 17 2.5' },
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          opened: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: 'M 2 16.346 L 20 16.346' },
          opened: { d: 'M 3 2.5 L 17 16.346' },
        }}
      />
    </svg>
  </motion.div>
);
