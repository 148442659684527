import { gql } from '@apollo/client';

export const PAGE_FRAGMENT = gql`
  fragment PageInfos on Page {
    id
    slug
    pageContentIds
    title
  }
`;

export const PAGE_CONTENT_FRAGMENT = gql`
  fragment PageContentInfos on PageContent {
    id
    label
    value
    value_en
  }
`;
