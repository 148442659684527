import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export default (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <path d="M23.1 12.5v.5c0 4.9-3.7 10.5-10.5 10.5-2 0-4-.6-5.6-1.6.3 0 .6.1.9.1 1.7 0 3.3-.5 4.6-1.6-1.6 0-3-1.1-3.4-2.6.2 0 .5.1.7.1.3 0 .7 0 1-.1-1.8-.5-3-2-3-3.8.5.3 1.1.4 1.7.5-1.6-1.1-2.1-3.2-1.1-4.9 1.9 2.3 4.6 3.7 7.6 3.8-.1-.3-.1-.6-.1-.8 0-2 1.6-3.7 3.7-3.7 1 0 2 .4 2.7 1.2.8-.2 1.6-.5 2.3-.9-.3.9-.8 1.6-1.6 2 .7-.1 1.4-.3 2.1-.6-.6.7-1.2 1.4-2 1.9zM15.8 0C7 0-.2 7.2-.2 16s7.2 16 16 16 16-7.2 16-16-7.1-16-16-16z" />
    </SvgIcon>
  );
};
