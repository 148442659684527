import './Loading.scss';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// import CircularProgress from '@material-ui/core/CircularProgress';
import classnames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';
import logoLoading from '../../Assets/v3/loading2.gif';

interface LoaderProps {
  isFullHeight?: boolean;
}

const useStyles = makeStyles(theme => ({
  mxh50: {
    maxHeight: 50,
  },
  mxw50: {
    maxWidth: 50,
  },
}));

const Loader: FC<LoaderProps & HTMLAttributes<HTMLDivElement>> = props => {
  const { className, isFullHeight: isFullHeightProp, ...otherProps } = props;
  const classes = useStyles();
  const isFullHeight = isFullHeightProp || true;
  return (
    <div
      {...otherProps}
      className={classnames('page-loader', isFullHeight && 'full-height', className)}>
      {/* <CircularProgress /> */}
      <img className={classnames(classes.mxh50, classes.mxw50)} src={logoLoading} alt="loading" />
    </div>
  );
};

export default Loader;
