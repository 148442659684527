import { SnackbarContent } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Snack, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import Close from '@material-ui/icons/Close';
import classnames from 'classnames';
import React, { FC, ReactNode } from 'react';
import useStyles from './styles';

interface SnackbarProps {
  message: ReactNode;
  open: boolean;
  onClose: () => void;
  autoHideDuration: number;
  anchorOrigin?: SnackbarOrigin;
  type: 'ERROR' | 'SUCCESS' | 'DEFAULT';
}

const Snackbar: FC<SnackbarProps> = props => {
  const classes = useStyles();
  const { message, autoHideDuration, open, onClose, anchorOrigin, type } = props;

  const action = [
    <IconButton
      className={classes.iconButton}
      key="close"
      aria-label="Close"
      color="inherit"
      onClick={onClose}>
      <Close className={classes.close} />
    </IconButton>,
  ];
  return (
    <Snack
      onClose={onClose}
      anchorOrigin={{
        vertical: anchorOrigin?.vertical || 'top',
        horizontal: anchorOrigin?.horizontal || 'right',
      }}
      autoHideDuration={autoHideDuration}
      open={open}
      message={message}
      action={action}>
      <SnackbarContent
        className={classnames(
          type === 'ERROR' && classes.errorMessage,
          type === 'SUCCESS' && classes.successMessage
        )}
        message={message}
      />
    </Snack>
  );
};

export default Snackbar;
