import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import React, { FC } from 'react';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    color: 'red',
    textAlign: 'center',
    marginTop: 10,
  },
}));

const InputError: FC<{ message: string }> = props => {
  const classes = useStyles();
  const { message } = props;
  return <div className={classnames(classes.container, 'inputError')}>{message}</div>;
};

export default InputError;
