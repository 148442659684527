import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  language: {
    marginRight: 20,
    '& .MuiButtonBase-root': {
      marginRight: 5,
      marginLeft: 5,
    },
  },
  footer: {
    display: 'flex',
    width: `calc(100% - ${theme.spacing(10)}px)`,
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: '1px solid #3b3b3b',
    height: 100,
    color: 'white',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: 'transparent',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '@media(max-width: 767px)': {
      '& h2': {
        fontSize: '2rem !important',
      },
    },
    '& a': {
      color: '#fff',
      fontSize: 13,
      fontWeight: 500,
      marginLeft: 5,
      marginRight: 5,
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
        marginRight: 3,
      },
      transition: theme.transitions.create('color', {
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    '& > a:last-of-type': {
      flex: '0 0 100%',
      marginTop: theme.spacing(2),
      marginLeft: 'auto',
      fontFamily: '"Blender Pro", sans-serif',
      textAlign: 'center',
    },
    '@media(min-width: 751px)': {
      '& > a:last-of-type': {
        flex: '0 0 auto',
        marginTop: 0,
      },
    },
  },
  logo: {
    width: 125,
  },
  social: {
    width: 'fit-content',
    '& > a:not(:last-of-type)': {
      marginRight: theme.spacing(2),
    },
    '& svg': {
      fontSize: 40,
    },
    '& a:first-of-type:hover': { color: '#3b5998' },
    '& a:nth-of-type(2):hover': { color: '#de1885' },
    '& a:nth-of-type(3):hover': { color: '#55acee' },
    '& a:nth-of-type(4):hover': { color: '#ff0000' },
    '@media(min-width: 751px)': {
      justifyContent: 'center',
    },
    '@media(min-width:501px)': {
      '& > a:not(:last-of-type)': {
        marginRight: theme.spacing(6.25),
      },
    },
  },
  right: {
    display: 'flex',
    alignItems: 'center',
  },
  copyRight: {
    fontSize: 15.5,
    fontFamily: 'Ubuntu',
    color: '#FFFFFF',
    opacity: 0.5,
    fontWeight: 500,
    marginLeft: 20,
  },
  item: {
    fontSize: 15.5,
    fontFamily: 'Ubuntu',
    color: '#FFFFFF',
    fontWeight: 500,
  },
  logoContainer: {
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
  },
  ml10: {
    marginLeft: 10,
  },
  mr10: {
    marginRight: 10,
  }
}));
