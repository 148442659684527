import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React, { FC } from 'react';
import FR from '../../../common/Icons/FR';
import GB from '../../../common/Icons/GB';
import { useEditProfilMutationMutation, useMeQuery } from '../../../generated/graphql';
import { GET_ALL_PAGES } from '../../../gql/page/query';
import { setUserLanguage } from '../../../services/languageUtil';
import useStyles from './style';

interface LangMenuProps {
  anchorRef: any;
  handleClose: () => void;
  className?: string;
}

const LangMenu: FC<LangMenuProps> = ({ anchorRef, className, handleClose }) => {
  const classes = useStyles();
  const { data: dataMe } = useMeQuery();
  const [editProfilMutation] = useEditProfilMutationMutation();

  const handleChangeLanguage = (lang: string) => () => {
    if (dataMe && dataMe.me) {
      editProfilMutation({
        variables: {
          id: dataMe?.me?.id || '',
          language: lang,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateUser: {
            ...dataMe.me,
            language: lang,
          },
        },
        refetchQueries: [
          {
            query: GET_ALL_PAGES,
          },
        ],
      }).then(() => {
        setUserLanguage(lang);
      });
      handleClose();
    }
  };

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorRef}
      keepMounted={true}
      open={Boolean(anchorRef)}
      className={className}
      classes={{
        paper: classes.menuContainer,
      }}
      onClose={handleClose}>
      <MenuItem onClick={handleChangeLanguage('fr')}>
        <FR />
      </MenuItem>
      <MenuItem onClick={handleChangeLanguage('en')}>
        <GB />
      </MenuItem>
    </Menu>
  );
};

export default LangMenu;
