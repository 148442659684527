import './Loading.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import classnames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';

interface LoaderProps {
  isFullHeight?: boolean;
}

const Loader: FC<LoaderProps & HTMLAttributes<HTMLDivElement>> = props => {
  const { className, isFullHeight, ...otherProps } = props;
  return (
    <div
      {...otherProps}
      className={classnames('page-loader', isFullHeight && 'full-height', className)}>
      <CircularProgress />
    </div>
  );
};

export default Loader;
