import { makeVar } from '@apollo/client';
import { SnackbarOrigin } from '@material-ui/core/Snackbar';
import { MessageDescriptor } from 'react-intl';
import {
  ChatGroupInfoFragment,
  ChatMemberInfoFragment,
  UserInfosFragment,
} from '../generated/graphql';

export const globalSnackbarVar = makeVar<{
  open: boolean;
  duration?: number;
  message: string | MessageDescriptor;
  type: 'ERROR' | 'SUCCESS' | 'DEFAULT';
  anchor?: SnackbarOrigin;
}>({
  open: false,
  duration: 2000,
  message: '',
  type: 'DEFAULT',
  anchor: {
    horizontal: 'right',
    vertical: 'top',
  },
});

export const modalDeleteVideoVar = makeVar<{
  isOpen: boolean;
  videoId: string | undefined;
  redirectLink: string;
}>({
  isOpen: false,
  videoId: undefined,
  redirectLink: '',
});

export const modalChatVar = makeVar<{
  isOpen: boolean;
  loading: boolean;
  chatEngineId?: number;
  chatAccessKey?: string;
  title?: string;
  noChat: boolean;
}>({
  isOpen: false,
  loading: false,
  chatEngineId: undefined,
  chatAccessKey: undefined,
  title: undefined,
  noChat: false,
});

export const modalCreateChatVar = makeVar<{
  isOpen: boolean;
  chatId?: string;
  loading: boolean;
  chatEngineId?: number;
  gameParty?: string;
  memberList: ChatMemberInfoFragment[];
}>({
  isOpen: false,
  chatId: undefined,
  loading: false,
  gameParty: undefined,
  chatEngineId: undefined,
  memberList: [],
});

interface ChatEnginInterface {
  chatEngineId?: number;
  chatEngineAccessKey?: string;
  chatEngineTitle?: string;
  userId?: string;
  chatId?: string;
  user?: UserInfosFragment;
  chat?: ChatGroupInfoFragment;
  gamePartyId?: string;
}
export const chatEnginDataVar = makeVar<{
  isOpen: boolean;
  loading: boolean;
  noChat: boolean;
  selectedChat?: ChatEnginInterface;
  chatList: ChatEnginInterface[];
}>({
  isOpen: false,
  loading: false,
  noChat: false,
  selectedChat: undefined,
  chatList: [],
});

export const indexChatUser = (userId: string) => {
  return chatEnginDataVar().chatList.findIndex(i => i && i.userId && i.userId === userId);
};

export const indexChatGroup = (chatId: string) => {
  return chatEnginDataVar().chatList.findIndex(i => i && i.chatId && i.chatId === chatId);
};
export const appLanguage = makeVar<'fr' | 'en'>('fr');
