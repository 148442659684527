import { gql } from '@apollo/client';

export const USER_BASIC_FRAGMENT = gql`
  fragment UserBasicInfos on User {
    id
    isMe
    firstname
    lastname
    email
    avatar
    username
    isAdmin
    phoneNumber
    displayName
    totalWallet
    isOnline
    point
    isSignupFinish
    twitchUserId
    googleUserId
    facebookUserId
    twitterUserId
    discordUserId
    microsoftUserId
    description
    status
    myMainGamePlateform {
      id
      name
    }
  }
`;

export const USER_FRAGMENT = gql`
  fragment UserInfos on User {
    id
    isMe
    firstname
    lastname
    birthDate
    email
    avatar
    coverPhoto
    username
    isBanned
    language
    country
    devise
    oauth2
    createdAt
    updatedAt
    isAdmin
    phoneNumber
    uid
    displayName
    receiveCommunication
    isUsedTrial
    isPremium
    endPremium
    getStreamToken
    isMyfriend
    twitchLogin
    twitchUserId
    googleUserId
    facebookUserId
    twitterUserId
    discordUserId
    microsoftUserId
    totalWallet
    chatEngineId
    chatEngineSecret
    isOnline
    isSignupFinish
    description
    status
    myMainGamePlateform {
      id
      name
    }
  }
`;
