import { gql } from '@apollo/client';
import { USER_BASIC_FRAGMENT } from '../user/fragment';

export const CHAT_GROUP_FRAGMENT = gql`
  fragment ChatGroupInfo on Chat {
    id
    name
    chatengineId
    owner {
      id
    }
    chatEngineAccessKey
    createdAt
    members {
      id
      removed
      user {
        id
      }
    }
  }
`;
export const CHAT_MEMBER_GROUP_FRAGMENT = gql`
  fragment ChatMemberInfo on Chatmember {
    id
    chat {
      ...ChatGroupInfo
    }
    user {
      ...UserBasicInfos
    }
    removed
  }
  ${USER_BASIC_FRAGMENT}
  ${CHAT_GROUP_FRAGMENT}
`;

export const GAME_PARTY_FRAGMENT = gql`
  fragment GamePartyFragment on Gameparty {
    id
    name
    chat {
      ...ChatGroupInfo
    }
    members {
      id
    }
    removed
  }
  ${USER_BASIC_FRAGMENT}
  ${CHAT_GROUP_FRAGMENT}
`;
