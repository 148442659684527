import { useReactiveVar } from '@apollo/client/react/hooks/useReactiveVar';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Snackbar } from '../../common/Snackbar';
import { globalSnackbarVar } from '../../config/reactiveVars';

const GlobalSnackbar: FC = () => {
  const snackbar = useReactiveVar(globalSnackbarVar);

  const { formatMessage } = useIntl();

  const { open, message, duration, type, anchor } = snackbar;
  const closeSnackBar = () => {
    globalSnackbarVar({
      ...snackbar,
      open: false,
    });
  };

  const messageText = typeof message === 'string' ? message : formatMessage(message);

  return (
    <Snackbar
      onClose={closeSnackBar}
      message={messageText}
      open={open}
      anchorOrigin={anchor}
      type={type}
      autoHideDuration={duration || 3000}
    />
  );
};

export default GlobalSnackbar;
