import { Box, Button } from '@material-ui/core';
import classnames from 'classnames';
import React, { MouseEventHandler, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ReactComponent as GC } from '../../../Assets/v3/icon_gc.svg';
import { ReactComponent as GG } from '../../../Assets/v3/icon_gg.svg';
import { useMyWalletTransactionQuery } from '../../../generated/graphql';
import { AuthContext } from '../../../provider/AuthProvider';
// import FR from '../../../common/Icons/FR';
// import GB from '../../../common/Icons/GB';
// import { useGetTeamsQuery, useMeQuery } from '../../../generated/graphql';
// import UserGroupButton from '../../LandingPage/UserGroupButton/UserGroupButton';
import UserProfileButton from '../../LandingPage/UserProfileButton/UserProfileButton';
import LangMenu from '../LangMenu';
import { useMenu } from '../useMenu';
import MenuProfilPopover from './MenuProfilPopover';
// import NotificationIcon from './Notification';
// import OnlineUser from './OnlineUser';
import styles from './style';

const pathWithoutAppBar = ['login', 'signup', 'forgot-password'];
const AppBarRight = () => {
  const menu = useMenu().open;
  const classes = styles({ open: menu });

  const { isUserConnected } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const [anchorLangEl, setAnchorLangEl] = useState<any>(null);
  // const { data: dataMe } = useMeQuery();
  const { data: dataMe } = useMyWalletTransactionQuery();
  const handleClick: MouseEventHandler = event => {
    setAnchorEl(event.currentTarget);
  };
  const history = useHistory();
  // const locale = dataMe?.me?.language || 'fr';

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleClickLang: MouseEventHandler = e => {
  //   setAnchorLangEl(e.currentTarget);
  // };

  const handleCloseLang = () => {
    setAnchorLangEl(null);
  };

  const notShowAppBar = pathWithoutAppBar.some(item => history.location.pathname.includes(item));
  if (notShowAppBar) return <div />;
  return isUserConnected ? (
    <div className={classes.container}>
      <div className={classes.walletItem}>
        <GC />
        <span>{Intl.NumberFormat('fr-FR').format(dataMe?.me?.totalWallet || 0)}</span>
      </div>
      <div className={classes.walletItem}>
        <GG />
        <span>{Intl.NumberFormat('fr-FR').format(dataMe?.me?.totalWallet || 0)}</span>
      </div>
      {/* <div className={classes.onlineUserContainer}>
        <OnlineUser />
      </div> */}
      {/* <div>
        <IconButton size="small" onClick={handleClickLang}>
          {locale === 'fr' ? <FR /> : <GB />}
        </IconButton>
        <LangMenu anchorRef={anchorLangEl} handleClose={handleCloseLang} />
        <UserGroupButton
          onClick={() => history.push('/app/settings/team')}
          badgeNumber={teamData?.me.joinedTeams?.length || 0}
        />
      </div> */}
      {/* <NotificationIcon /> */}
      <Box className={classnames(classes.division, classes.profil)} mr={10} onClick={handleClick}>
        <UserProfileButton isPopoverOpen={Boolean(anchorEl)} />
      </Box>
      <MenuProfilPopover anchorRef={anchorEl} handleClose={handleClose} />
      <LangMenu anchorRef={anchorLangEl} handleClose={handleCloseLang} />
    </div>
  ) : (
    <div className={classes.flex}>
      <Button
        variant="contained"
        onClick={() => history.push('/login')}
        color="primary"
        size="small"
        className={classes.btn}>
        <FormattedMessage id="all-login" defaultMessage="Se connecter" />
      </Button>
      <Button
        variant="outlined"
        onClick={() => history.push('/signup')}
        color="inherit"
        size="small"
        className={classes.btn}>
        <FormattedMessage id="sign-up" defaultMessage="Sign up" />
      </Button>
    </div>
  );
};

export default AppBarRight;
