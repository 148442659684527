import { gql } from '@apollo/client';

export const SOCIAL_ACCOUNT_FRAGMENT = gql`
  fragment SocialAccountInfos on Socialaccount {
    id
    name
    label
    logo
  }
`;
export const USER_SOCIAL_ACCOUNT_FRAGMENT = gql`
  fragment UserSocialAccountInfos on Usersocialaccount {
    id
    socialAccount {
      ...SocialAccountInfos
    }
    email
    username
  }
  ${SOCIAL_ACCOUNT_FRAGMENT}
`;
