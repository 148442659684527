import { BaseSubscriptionOptions, DataProxy } from '@apollo/client';
import { uniqBy } from 'lodash';
import {
  OnlineFriendsQuery,
  OnlineFriendsQueryVariables,
  UserOfflineSubscription,
  UserOnlineSubscription,
} from '../../generated/graphql';
import { USER_FRIEND_ONLINE } from '../../gql/user/query';

export const updateCacheAfterOnlineUser: BaseSubscriptionOptions<UserOnlineSubscription>['onSubscriptionData'] =
  ({ client: { cache }, subscriptionData }) => {
    if (subscriptionData.data?.userOnline) {
      const queryVariable: DataProxy.Query<OnlineFriendsQueryVariables, OnlineFriendsQuery> = {
        query: USER_FRIEND_ONLINE,
      };
      const oldData = cache.readQuery(queryVariable);
      if (oldData) {
        cache.writeQuery({
          ...queryVariable,
          data: {
            __typename: 'Query',
            onlineFriends: uniqBy(
              [...(oldData.onlineFriends || []), subscriptionData.data?.userOnline],
              'id'
            ),
          },
        });
      }
    }
  };
export const updateCacheAfterOfflineUser: BaseSubscriptionOptions<UserOfflineSubscription>['onSubscriptionData'] =
  ({ client: { cache }, subscriptionData }) => {
    if (subscriptionData.data?.userOffline) {
      const queryVariable: DataProxy.Query<OnlineFriendsQueryVariables, OnlineFriendsQuery> = {
        query: USER_FRIEND_ONLINE,
      };
      const oldData = cache.readQuery(queryVariable);
      if (oldData) {
        cache.writeQuery({
          ...queryVariable,
          data: {
            __typename: 'Query',
            onlineFriends: (oldData.onlineFriends || []).filter(
              e => e.id && e.id !== subscriptionData.data.userOffline.id
            ),
          },
        });
      }
    }
  };
