import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';

const styles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    '@media(min-width: 1920px)': {
      maxWidth: 1750,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));

const LayoutContainer = (props: any) => {
  const { children, className } = props;
  const cls = styles();

  return <div className={classNames(cls.container, className)}>{children}</div>;
};

export default LayoutContainer;
