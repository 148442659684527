export const color = {
  ripeLemon: '#f2cf1c',
  white: '#FFFFFF',
  periwinkleGray: '#BEC8E7',
  codGray: '#08090B',
  mineShaft: '#212121',
  black: '#000000',
  resolutionBlue: '#001970',
  parsley: '#1b5e20',
  paprika: '#8c0032',
  gold: '#ffd700',
  westCoast: '#614e1a',
  champagne: '#FAF0C5',
  red: '#FF0000',
  scorpion: '#606060',
  thunderbird: '#FDFDFD',
  camarone: '#216025',
  woodsmoke: '#161616',
};
