import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    minWidth: '3rem',
    minHeight: '3rem',
  },
  center: {
    display: 'flex',
    // flexDirection: 'column',
    alignItems: 'center',
  },
  border: {
    border: '1px solid #262626',
  },
  avatar: {
    left: 0,
    marginRight: 15,
    height: 45,
    width: 45,
    color: 'white',
    backgroundColor: 'transparent',
  },
  progress: {
    boxSizing: 'border-box',
    height: 9,
    width: 109,
    border: '0.5px solid #979797',
    borderRadius: 2,
    backgroundColor: 'transparent',
    '& .MuiLinearProgress-bar': {
      height: 5,
      borderRadius: 1,
      backgroundColor: '#B12203',
    },
  },
  userName: {
    fontFamily: 'Blender Pro',
    textTransform: 'uppercase',
    color: 'white!important',
    fontSize: 16,
    fontWeight: 'bold',
    maxWidth: 109,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
  },
  menu: {
    minWidth: '9rem',
    minHeight: '2.8rem',
    borderRadius: '1.4rem',
    paddingLeft: '3rem',
    paddingRight: '1rem',
    backgroundColor: '#1f1f1f',
    cursor: 'pointer',
  },
  gamerTag: {
    color: 'white',
    fontSize: 16,
    fontFamily: theme.fontFamily.secondary,
  },
  icons: {
    '& > *': {
      width: '0.8rem',
      height: '0.8rem',
      fontSize: '1.5rem',
      color: 'white',
    },
  },
}));
