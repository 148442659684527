import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export default (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <circle cx="16.2" cy="16" r="3.1" />
      <path d="M21.1 12.2c-.6 0-1.1-.5-1.1-1.1 0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1zm-4.9 8.5c-2.6 0-4.7-2.1-4.7-4.7s2.1-4.7 4.7-4.7 4.7 2.1 4.7 4.7-2.1 4.7-4.7 4.7zm7.1-10.1c-.3-.8-.9-1.4-1.7-1.7-.5-.2-1.1-.3-1.7-.3-1 0-1.3-.1-3.7-.1s-2.7 0-3.7.1c-.6 0-1.2.1-1.7.3-.4.1-.8.3-1.1.6-.3.3-.5.7-.7 1.1-.2.5-.3 1.1-.3 1.7 0 1-.1 1.3-.1 3.7s0 2.7.1 3.7c0 .6.1 1.2.3 1.7.3.8.9 1.4 1.7 1.7.5.2 1.1.3 1.7.3 1 0 1.3.1 3.7.1s2.7 0 3.7-.1c.6 0 1.2-.1 1.7-.3.8-.3 1.4-.9 1.7-1.7.2-.5.3-1.1.3-1.7 0-1 .1-1.3.1-3.7s0-2.7-.1-3.7c.2-.6 0-1.2-.2-1.7z" />
      <path d="M25.3 19.8c0 .8-.2 1.5-.4 2.2-.5 1.2-1.5 2.2-2.7 2.7-.7.3-1.5.4-2.2.4-1 0-1.3.1-3.8.1s-2.8 0-3.8-.1c-.8 0-1.5-.2-2.2-.4-.6-.2-1.2-.6-1.6-1-.5-.5-.8-1-1.1-1.6-.3-.7-.4-1.5-.4-2.3-.1-1-.1-1.3-.1-3.8s0-2.8.1-3.8c0-.8.2-1.5.4-2.2.2-.6.6-1.2 1.1-1.6.5-.5 1-.8 1.6-1.1.7-.3 1.5-.4 2.2-.4 1 0 1.3-.1 3.8-.1s2.8 0 3.8.1c.8 0 1.5.2 2.2.4 1.2.5 2.2 1.4 2.7 2.7.3.7.4 1.5.4 2.2 0 1 .1 1.3.1 3.8s0 2.8-.1 3.8zM16.2 0C7.4 0 .2 7.2.2 16s7.2 16 16 16 16-7.2 16-16S25 0 16.2 0z" />
    </SvgIcon>
  );
};
