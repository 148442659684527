import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  button: {
    borderRadius: 9,
    height: 32,
    padding: '4px 22px',
    fontFamily: theme.fontFamily.secondary,
  },
  danger: {
    color: theme.color.white,
    backgroundColor: theme.color.red,
  },
  defaultButton: {
    color: 'gray',
  }
}));
