import Button, { ButtonProps } from '@material-ui/core/Button';
import React, { FC } from 'react';
import DefaultLoading from '../DefaultLoading';
import useStyle from './style';

interface LoadingButtonProps {
  loading?: boolean;
}

const LoadingButton: FC<LoadingButtonProps & ButtonProps> = props => {
  const { children, loading, onClick, ...otherProps } = props;
  const classes = useStyle();
  return (
    <Button {...otherProps} onClick={loading ? undefined : onClick}>
      {loading ? <DefaultLoading className={classes.defaultButton} /> : children}
    </Button>
  );
};

export default LoadingButton;
