import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme: Theme) => ({
  joinUs: {
    marginBottom: 15,
    color: 'white',
    backgroundColor: theme.palette.primary.light,
    '@media(min-width: 992px)': {
      height: 340,
    },
  },
  inner: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    textAlign: 'center',
    '& h1': {
      marginBottom: 80,
    },
  },
  input: {
    minWidth: '100%',
    marginBottom: 30,
    '@media(min-width: 768px)': {
      minWidth: 'auto',
      marginRight: 60,
    },
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  h40: {
    height: 40
  }
}));
