import React from 'react';
import { IntlProvider } from 'react-intl';
import Loading from './common/Loading';
import { GlobalSnackbar } from './components/GlobalSnackbar';
import InvitationGameParty from './components/InvitationGameParty';
import SubscribeComponent from './components/SubscribeComponent';
import { useMeQuery } from './generated/graphql';
import localeData from './locales/data.json';
import { Router } from './router';

function MainApp() {
  const { data, loading } = useMeQuery();
  const locale = data?.me?.language || 'fr';
  if (loading) {
    return <Loading />;
  }
  return (
    <IntlProvider locale={locale} messages={locale === 'fr' ? localeData.fr : localeData.en}>
      <SubscribeComponent />
      <InvitationGameParty />
      <GlobalSnackbar />
      <Router />
    </IntlProvider>
  );
}

export default MainApp;
