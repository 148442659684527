import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import AnchorLink from '../../../common/AnchorLink/AnchorLink';
import { useMyWalletTransactionQuery } from '../../../generated/graphql';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  menuContainer: {
    backgroundColor: '#131313',
    minWidth: 220,
    top: '80px!important',
    color: 'white',
    '& .MuiMenuItem-root': {
      paddingTop: '12px!important',
    },
    '& .MuiDivider-root': {
      backgroundColor: 'white',
    },
    '& .MuiList-root': {
      paddingBottom: '0px!important',
    },
  },
  logOutMenu: {
    backgroundColor: 'white',
    paddingBottom: '8px!important',
    color: 'black',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  textWhite: {
    color: 'white',
  },
}));
interface MenuProfilPopoverProps {
  anchorRef: any;
  handleClose: () => void;
  className?: string;
  isLandingPage?: boolean;
}
const MenuProfilPopover: FC<MenuProfilPopoverProps> = props => {
  const classes = useStyles();
  const history = useHistory();
  const { data: myWallet } = useMyWalletTransactionQuery();

  const { anchorRef, handleClose, className, isLandingPage } = props;
  const handleLogOut = () => {
    history.push('/logout');
  };
  useEffect(() => {
    handleClose();
  }, [history.location.pathname]);
  const menuItemListNoLandingPage = [
    // <MenuItem onClick={handleClose} key="menu-1">
    //   <AnchorLink href="/app/settings/leaderboard" underline="none" style={{ color: 'white' }}>
    //     <FormattedMessage id="user-leaderBoard" defaultMessage="LeaderBoard (Nouveau)" />
    //   </AnchorLink>
    // </MenuItem>,
    <Divider key="divider-1" />,
    <MenuItem key="menu-2">
      <AnchorLink
        href={`/app/profile/${myWallet?.me?.id}`}
        underline="none"
        className={classes.textWhite}>
        <FormattedMessage id="user-profile" defaultMessage="Profile (Nouveau)" />
      </AnchorLink>
    </MenuItem>,
    <Divider key="divider-2" />,
    <MenuItem key="menu-0">
      <AnchorLink className={classes.textWhite} href="/app/settings/wallet" underline="none">
        <FormattedMessage id="user-wallet" defaultMessage="Cagnotte (Nouveau)" />
        {` (${myWallet?.me?.totalWallet || 0} G-Coin)`}
      </AnchorLink>
    </MenuItem>,
    <Divider key="divider-3" />,
    <MenuItem key="menu-3">
      <AnchorLink className={classes.textWhite} href="/app/settings" underline="none">
        <FormattedMessage id="user-settings" defaultMessage="Settings" />
      </AnchorLink>
    </MenuItem>,
    <Divider key="divider-4" />,
  ];

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorRef}
      keepMounted={true}
      open={Boolean(anchorRef)}
      className={className}
      classes={{
        paper: classes.menuContainer,
      }}
      onClose={handleClose}>
      {!isLandingPage ? (
        menuItemListNoLandingPage.map(menu => menu)
      ) : (
        <>
          <MenuItem>
            <AnchorLink className={classes.textWhite} href="/app" underline="none">
              <FormattedMessage id="all-dashboard" defaultMessage="Dashboard" />
            </AnchorLink>
          </MenuItem>
          <Divider />
        </>
      )}
      <MenuItem onClick={handleLogOut} className={classes.logOutMenu}>
        <FormattedMessage id="user-logout" defaultMessage="Logout" />
      </MenuItem>
    </Menu>
  );
};
export default MenuProfilPopover;
