import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  menuContainer: {
    backgroundColor: '#131313',
    minWidth: 50,
    top: '80px!important',
    color: 'white',
    '& .MuiMenuItem-root': {
      paddingTop: '12px!important',
    },
    '& .MuiDivider-root': {
      backgroundColor: 'white',
    },
    '& .MuiList-root': {
      paddingBottom: '0px!important',
    },
  },
}));

export default useStyles;
