import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  onlineUserContainer: {
    marginRight: 10,
    display: 'flex',
  },
  walletItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 15,
    '& span': {
      fontFamily: 'Blender Pro',
      fontSize: 14,
      fontWeight: 'bold',
      marginLeft: 5,
    },
  },
  appbar: {
    flexDirection: 'row!important' as 'row',
    alignItems: 'center',
    padding: theme.spacing(0, 3),
    color: '#fff',
    backgroundColor: theme.color.black,
    height: 90,
    '&>div': {
      marginLeft: 'auto',
      display: 'inline-flex',
      alignItems: 'center',
    },
  },
  bell: {
    marginRight: theme.spacing(3),
  },
  division: {
    display: 'inline-flex',
    marginLeft: 15,
    alignItems: 'center',
  },
  profil: {
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      marginRight: '0px!important',
    },
  },
  logo: {
    flex: '0 0 96px',
    margin: theme.spacing(0, 3),
    textAlign: 'center',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      flexBasis: '145px',
    },
    '&::before': {
      content: "''",
      position: 'absolute',
      left: '-80%',
      top: 0,
      width: '100%',
      height: '100%',
      background: `linear-gradient(-70deg, rgba(255,255,255,0) 0%, rgba(206,205,212,0) 41%, rgba(255,255,255,0.8) 46%, rgba(255,255,255,0.8) 50%, rgba(219,219,219,0) 58%, rgba(255,255,255,0) 92%)`,
    },
    '&:hover::before': {
      left: '100%',
      transitionProperty: 'left, top, opacity',
      transitionDuration: '1s',
      transitionTimingFunction: 'ease',
    },
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  container: {
    display: 'flex',
  },
  btn: {
    width: 166,
    marginLeft: 5,
    marginRight: 5,
  },
  flex: {
    display: 'flex',
  },
}));
