import { Link } from '@material-ui/core';
import classnames from 'classnames';
import React from 'react';
import CustomLink from '../../../common/CustomLink';
// import FR from '../../../common/Icons/FR';
// import GB from '../../../common/Icons/GB';
// import { Logo } from '../../../common/Logo';
// import { useEditProfilMutationMutation, useMeQuery } from '../../../generated/graphql';
// import { GET_ALL_PAGES } from '../../../gql/page/query';
// import { setUserLanguage } from '../../../services/languageUtil';
// import Facebook from '../Icons/Facebook';
import Instagram from '../Icons/Instagram';
import Twitter from '../Icons/Twitter';
import Youtube from '../Icons/Youtube';
import style from './styles';

const Footer = () => {
  const cls = style();
  // const { data: dataMe } = useMeQuery();

  // const [editProfilMutation] = useEditProfilMutationMutation();
  // const handleClickLang = (lang: string) => () => {
  //   if (dataMe && dataMe.me) {
  //     editProfilMutation({
  //       variables: {
  //         id: dataMe?.me?.id || '',
  //         language: lang,
  //       },
  //       optimisticResponse: {
  //         __typename: 'Mutation',
  //         updateUser: {
  //           ...dataMe.me,
  //           language: lang,
  //         },
  //       },
  //       refetchQueries: [
  //         {
  //           query: GET_ALL_PAGES,
  //         },
  //       ],
  //     }).then(() => {
  //       setUserLanguage(lang);
  //     });
  //   }
  // };

  return (
    <div className={cls.footer}>
      <div className={cls.social}>
        <Link underline="none" href="https://www.twitter.com" target="_blank">
          <Twitter />
        </Link>
        {/* <Link underline="none" href="https://www.facebook.com" target="_blank">
          <Facebook />
        </Link> */}
        <Link underline="none" href="https://www.instagram.com" target="_blank">
          <Instagram />
        </Link>

        <Link underline="none" href="https://www.youtube.com" target="_blank">
          <Youtube />
        </Link>
      </div>
      <div className={cls.right}>
        {/* {dataMe?.me && (
          <div className={cls.language}>
            <IconButton
              size="small"
              disabled={dataMe?.me?.language === 'fr'}
              onClick={handleClickLang('fr')}>
              <FR />
            </IconButton>
            <IconButton
              size="small"
              disabled={dataMe?.me?.language === 'en'}
              onClick={handleClickLang('en')}>
              <GB />
            </IconButton>
          </div>
        )} */}
        <CustomLink underline="hover" href="/" className={cls.item}>
          Politique de confidentialité
        </CustomLink>
        <span className={classnames(cls.ml10, cls.mr10)}>-</span>
        <CustomLink className={cls.item} underline="hover" href="/legal">
          Mentions Légales
        </CustomLink>
        <div className={cls.logoContainer}>
          {/* <CustomLink>
            <Logo size="medium" />
          </CustomLink> */}
          <div className={cls.copyRight}>{`Website © ${new Date().getFullYear()} GOGRIND`}</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
