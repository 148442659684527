import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import classnames from 'classnames';
import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import InputError from '../../../common/InputError';
import { globalSnackbarVar } from '../../../config/reactiveVars';
import {
  useCheckTokenOauth2Mutation,
  useCreateAuthTokenMutation,
  useLoginOauth2Mutation,
} from '../../../generated/graphql';
import { AuthContext } from '../../../provider/AuthProvider';
import { getGraphqlError } from '../../../services/ErrorHandler';
import CustomAppBar from '../../LandingPage/CustomAppBar/CustomAppBar';
import Footer from '../../LandingPage/Footer';
import useStyle from './style';

export interface FormStateInterface {
  email: string;
  password: string;
  code: string[];
}

export interface ErrorStateInterface {
  email?: string;
  password?: string;
  credentialsInvalid?: string;
  code?: string;
}

const Login: FC = () => {
  const classes = useStyle();
  const history = useHistory();
  const [isSuccess, setIsSuccess] = useState(false);

  const [state, setState] = useState<FormStateInterface>({
    email: '',
    password: '',
    code: ['', '', '', '', '', ''],
  });

  const params = useLocation();
  useEffect(() => {
    // secure page login oauth 2
    if (params?.state) {
      const { email: emailParams, password: passwordParams, fromPage } = params?.state as any;
      if (fromPage === '/login' && Boolean(emailParams && Boolean(passwordParams))) {
        setState({
          email: emailParams,
          password: passwordParams,
          code: ['', '', '', '', '', ''],
        });
      } else {
        history.push('/login');
      }
    } else {
      history.push('/login');
    }
  }, []);

  const { setToken } = useContext(AuthContext);
  const [errors, setErrors] = useState<ErrorStateInterface>({});
  const [mutationLoginOauth, { loading: loadingLoginOauth2 }] = useLoginOauth2Mutation();
  const [mutationCheckTokenOauth2, { loading: loadingCheckTokenOauth2 }] =
    useCheckTokenOauth2Mutation();
  const [mutationCreateAuthToken, { loading: loadinCreateAuthToken }] =
    useCreateAuthTokenMutation();
  const { formatMessage } = useIntl();

  const displayAlertWhenEmailSent = () => {
    globalSnackbarVar({
      type: 'DEFAULT',
      message: `Email bien envoyé à ${state.email}`,
      open: true,
      anchor: {
        horizontal: 'right',
        vertical: 'top',
      },
      duration: 2000,
    });
  };

  const catchingErrorLoginOauth2 = (error: any) => {
    const tempErrors: ErrorStateInterface = {};
    const { stringCode } = getGraphqlError(error);
    if (stringCode === 'INVALID_EMAIl') {
      tempErrors.email = formatMessage({
        id: 'error-invalid-email',
        defaultMessage: 'Votre e-mail est invalide',
      });
    } else if (stringCode === 'EMPTY_PASSWORD') {
      tempErrors.password = formatMessage({
        id: 'error-empty-password',
        defaultMessage: 'Mot de passe est requis',
      });
    } else if (stringCode === 'INCORRECT_EMAIL_OR_PASSWORD') {
      tempErrors.credentialsInvalid = formatMessage({
        id: 'error-match-email-password',
        defaultMessage: 'email ou password incorrect',
      });
    } else if (stringCode.includes('CODE_NOT_MATCH')) {
      tempErrors.code = formatMessage({
        id: 'error-code-not-match',
        defaultMessage: 'Entrer votre code de connexion',
      });
    } else if (stringCode.includes('CODE_EXPIRED')) {
      tempErrors.code = formatMessage({
        id: 'error-code-expired',
        defaultMessage: 'Entrer votre code de connexion',
      });
    } else {
      throw error;
    }
    return tempErrors;
  };

  const handleReSendCode = () => {
    // loginOAuth2
    return mutationLoginOauth({
      variables: {
        input: {
          email: state.email,
        },
      },
    })
      .then(response => {
        if (response) displayAlertWhenEmailSent();
      })
      .catch(errorLoginOauth2 => {
        throw errorLoginOauth2;
      });
  };

  const handleLoginOauth2Enabled = () => {
    const { email, password } = state;
    return mutationCheckTokenOauth2({
      variables: {
        input: {
          code: +state.code.join(''),
          email,
        },
      },
    })
      .then(isOk => {
        if (isOk)
          mutationCreateAuthToken({
            variables: {
              input: {
                email,
                password,
              },
            },
          })
            .then(response => {
              setToken(response?.data?.createAuthToken);
              setIsSuccess(true);
            })
            .catch(error => {
              throw error;
            });
      })
      .catch((error: any) => {
        const tempErrors = catchingErrorLoginOauth2(error);
        setErrors(tempErrors);
      });
  };

  const handleOnclikLogin = () => {
    return handleLoginOauth2Enabled();
  };

  if (isSuccess) {
    return <Redirect to="/app/news" />;
  }

  // const loginDisabled = !state.email || !state.password || loadingLogin;
  const registerText = `Pas encore inscrit ?`;
  return (
    <>
      <CustomAppBar />
      <div className={classes.container}>
        <div className={classes.containerLogin}>
          <div className={classes.formContainer}>
            <div className={classes.flexCenterX}>
              <div className={classes.title}>{'Rejoins GOGRIND !'.toUpperCase()}</div>
            </div>
            <div className={classes.card}>
              <div className={classes.form}>
                <>
                  <p>
                    <FormattedMessage
                      id="sent-code-title"
                      defaultMessage="Veuillez recopiez le code à 6 chiffres dans votre boîte email."
                    />
                  </p>
                  <span className={classes.label}>Code</span>
                  <div className={classes.codContainer}>
                    {state.code.map((i, index) => (
                      <TextField
                        key={index.toString()}
                        type="text"
                        name="code"
                        value={i}
                        margin="none"
                        onPaste={e => {
                          const text = e.clipboardData.getData('Text');
                          if (text.length === 6 && !isNaN(+text)) {
                            setState({
                              ...state,
                              code: text.split(''),
                            });
                          }
                        }}
                        variant="outlined"
                        onChange={e => {
                          const codeC = state.code;
                          if (
                            (codeC[index].length === 0 || e.target.value.length === 0) &&
                            (e.target.value === '' || !isNaN(+e.target.value))
                          ) {
                            codeC[index] = e.target.value;
                            setState({
                              ...state,
                              code: codeC,
                            });
                          }
                        }}
                      />
                    ))}
                  </div>
                  {errors.code && (
                    <InputError
                      message={
                        errors.code ||
                        formatMessage({
                          id: errors.code,
                          defaultMessage: 'Error is occured',
                        })
                      }
                    />
                  )}
                  <div>
                    <Button
                      onClick={handleReSendCode}
                      className={classes.resendCode}
                      variant="text">
                      {formatMessage({
                        id: 'resend-token',
                        defaultMessage: 'Renvoyer le code',
                      })}
                    </Button>
                  </div>
                  <div>
                    <Button
                      className={classnames(
                        classes.login,
                        state.code.length && classes.btnGradientEnabled
                      )}
                      variant="contained"
                      onClick={handleOnclikLogin}>
                      {`${formatMessage({
                        id: loadinCreateAuthToken ? 'checking-code' : 'check-code',
                        defaultMessage: 'Vérifier',
                      })}${loadinCreateAuthToken ? `...` : ``}`}
                    </Button>
                  </div>
                </>
              </div>
            </div>
            <Button
              onClick={() => history.push('/signup')}
              className={classnames(classes.notYetAMember, classes.w100)}
              variant="outlined">
              {registerText} <b className={classes.ml5}>Creer ton compte</b>
            </Button>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Login;
