import { ThemeProvider } from '@material-ui/styles';
import React, { FC } from 'react';
import theme from '../../Theme/myTheme/landing-theme';
import CustomAppBar from '../LandingPage/CustomAppBar/CustomAppBar';
import Footer from '../LandingPage/Footer';
import JoinSection from '../LandingPage/JoinSection';
import useStyles from './styles';

const PublicLayout: FC = ({ children }) => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <CustomAppBar />
      <main className={classes.main}>
        {children}
        <JoinSection />
        <Footer />
      </main>
    </ThemeProvider>
  );
};

export default PublicLayout;
