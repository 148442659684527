import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme: Theme) => ({
  message: {
    padding: '0',
    display: 'block',
    maxWidth: '89%',
  },
  close: {
    width: '11px',
    height: '11px',
  },
  iconButton: {
    width: '24px',
    height: '24px',
    padding: '0',
  },
  icon: {
    width: '38px',
    height: '38px',
    display: 'block',
    left: '15px',
    position: 'absolute',
    marginTop: '-39px',
    fontSize: '20px',
    backgroundColor: 'white',
    padding: '9px',
    borderRadius: '50%',
    maxWidth: '38px',
  },
  warningMessage: {},
  errorMessage: {
    backgroundColor: theme.color.red,
    color: theme.color.white,
  },
  successMessage: {
    backgroundColor: theme.color.camarone,
    color: theme.color.white,
  },
}));
