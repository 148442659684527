import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
    container: {
        // background: `url(${Background}) left / cover no-repeat`,
        backgroundColor: theme.color.codGray,
        height: '100vh',
        zIndex: 2,
        '&::before': {
            zIndex: -1,
            content: `''`,
            position: 'absolute',
            width: '100%',
            height: '100vh',
            backgroundColor: theme.color.codGray,
        },
    },
    containerLogin: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 100px)',
    },

    OR: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 50,
        [theme.breakpoints.down('xl')]: {
            padding: 10,
        },
        [theme.breakpoints.down('xs')]: {
            padding: 10,
        },
        '& .top-divider': {
            transform: 'rotate(90deg) translateX(-64px)',
            width: 130,
            backgroundColor: '#202020',
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        '& .bottom-divider': {
            transform: 'rotate(90deg) translateX(64px)',
            width: 130,
            backgroundColor: '#202020',
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
    },
    card: {
        // backgroundColor: '#060606',
        padding: '70px 50px 70px 50px',
        // borderRadius: 25,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            padding: 20,
        },
    },
    formContainer: {
        zIndex: 4,
        width: 'fit-content',
        margin: 'auto',
    },
    form: {
        marginLeft: 20,
        marginRight: 20,
        width: 405,
        height: 405,
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 35,
        paddingRight: 35,
        backgroundColor: '#1A1E24',
        display: 'flex',
        clipPath:
            'polygon(0% 15%, 0 0, 15% 0%, 85% 0%, 100% 0, 100% 15%, 100% 85%, 92% 93%, 85% 100%, 15% 100%, 0 100%, 0% 85%)',
        flexDirection: 'column',
        '& .MuiInputBase-root ': {
            backgroundColor: 'transparent!important',
            border: '1px solid #FFFFFf!important',
            color: 'white!important',
            '& fieldset': {
                border: 'none',
            },
        },
        '& .MuiOutlinedInput-input:-webkit-autofill': {
            backgroundColor: 'transparent!important',
            border: '1px solid #FFFFFf!important',
        },
        '& .MuiInputBase-input': {
            padding: '10px 10px!important',
        },
        '& .MuiFormControl-root': {
            width: 'calc(100% - 5px)',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: 0,
        },
    },
    backButton: {
        width: 100,
        color: '#FDFDFD',
        '& span': {
            fontFamily: 'Noto Sans',
            fontSize: 13,
            color: '#FDFDFD',
            marginLeft: 10,
        },
    },
    socialLogin: {
        width: 405,
        height: 405,
        marginLeft: 20,
        marginRight: 20,
        paddingTop: 30,
        paddingBottom: 30,
        backgroundColor: '#F4F4F4',
        clipPath:
            'polygon(0% 15%, 0 0, 15% 0%, 85% 0%, 100% 0, 100% 15%, 100% 85%, 92% 93%, 85% 100%, 15% 100%, 0 100%, 0% 85%)',
        [theme.breakpoints.down('xs')]: {
            marginTop: 40,
        },
    },
    title: {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: 50,
        color: 'white',
        textTransform: 'uppercase',
    },

    login: {
        width: '50%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 25,
        // backgroundColor: '#171616',
        display: 'flex',
        textTransform: 'uppercase',
        '& .MuiButton-label': {
            color: 'black',
            textTransform: 'uppercase',
        },
    },

    btnGradientEnabled: {
        background: theme.color.thunderbird,
        '& span': {
            color: `${theme.color.black} !important`,
        },
    },
    subTitle: {
        textAlign: 'center',
        fontSize: 18,
        marginBottom: 10,
        fontFamily: 'Noto Sans',
    },
    notYetAMember: {
        width: '50%',
        border: '1px solid white',
        display: 'flex',
        marginTop: 40,
        height: 50,
        marginLeft: 'auto',
        marginRight: 'auto',
        '& .MuiButton-label': {
            color: 'white',
            textTransform: 'uppercase',
        },
    },

    socialForms: {
        marginTop: 70,
    },

    forgotPassword: {
        width: 'fit-content',
        alignSelf: 'flex-end',
        textTransform: 'none',
        boxShadow: 'none',
        '& .MuiButton-label': {
            color: '#747474',
            textTransform: 'none',
            textDecoration: 'underline',
        },
    },
    resendCode: {
        width: 'fit-content',
        alignSelf: 'flex-end',
        textTransform: 'none',
        boxShadow: 'none',
        '& .MuiButton-label': {
            color: '#747474',
            textTransform: 'none',
            textDecoration: 'underline',
        },
    },
    plusOrangeIcon: {
        position: 'absolute',
        top: '29%',
        left: '25%',
    },

    plusRedIcon: {
        position: 'absolute',
        bottom: '44%',
        right: '25%',
    },

    twoLineIcon: {
        position: 'absolute',
        bottom: '10%',
        left: '14%',
    },

    triangleIcon: {
        position: 'absolute',
        bottom: '33%',
        left: '32%',
        opacity: 0.3,
    },

    squareIcon: {
        position: 'absolute',
        bottom: '29%',
        left: '49%',
        opacity: 0.3,
    },

    circleLineIcon: {
        position: 'absolute',
        bottom: '13%',
        right: '14%',
    },
    label: {
        width: 150,
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: 'bold',
        marginTop: 10,
        marginBottom: 10,
    },
    codContainer: {
        display: 'flex',
        flexDirection: 'row',
        '& .MuiInputBase-input': {
            textAlign: 'center',
        },
    },
    w100: {
        width: '100%',
    },
    ml5: {
        marginLeft: 5
    },
    flexCenterX: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    }
}));
