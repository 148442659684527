import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import { boxShadows } from './boxShadows';
import { color } from './color';
import { containerStyles } from './containerStyles';
import { dynamicStyles } from './dynamicStyles';
import { fontFamily } from './fontFamily';
import { textStyles } from './textStyles';

const myTheme = (isDarkMode?: boolean) =>
  createMuiTheme({
    isDarkMode,
    textStyles,
    containerStyles,
    dynamicStyles,
    boxShadows,
    color,
    fontFamily,
    palette: {
      primary: {
        main: color.thunderbird,
        dark: '#FDFDFD',
        light: '#2c2c2c',
      },
      secondary: {
        main: color.camarone,
        dark: color.camarone,
        light: color.parsley,
      },
    },
    typography: {
      fontFamily: [fontFamily.main].join(','),
      fontSize: 16,
      htmlFontSize: 16,
      h1: {
        fontFamily: fontFamily.secondary,
        fontSize: '2.1875rem',
        fontWeight: 700,
        textTransform: 'uppercase',
      },
      h2: {
        fontFamily: fontFamily.secondary,
        fontSize: '1.5625rem',
        fontWeight: 700,
        textTransform: 'uppercase',
      },
      h3: {
        fontFamily: fontFamily.secondary,
        fontSize: '1.25rem',
        fontWeight: 700,
        textTransform: 'uppercase',
      },
      h4: {
        fontFamily: fontFamily.secondary,
        fontSize: '1.125rem',
        fontWeight: 700,
        textTransform: 'uppercase',
      },
      subtitle1: {
        fontFamily: fontFamily.main,
        fontSize: '1rem',
        fontWeight: 700,
        lineHeight: 1.25,
        textTransform: 'uppercase',
      },
      body1: {
        fontFamily: fontFamily.main,
        fontSize: '1rem',
      },
      body2: {
        fontFamily: fontFamily.main,
        fontSize: '0.875rem',
      },
      caption: {
        fontFamily: fontFamily.main,
        fontSize: '0.75rem',
      },
    },
    overrides: {
      MuiDivider: {
        root: {
          backgroundColor: 'white',
        },
      },
      MuiPaper: {
        root: {
          backgroundColor: 'transparent',
        },
        elevation2: {
          boxShadow: '0 4px 4px rgba(0,0,0,.25)',
        },
      },
      MuiTextField: {
        root: {
          marginBottom: 16,
        },
      },
      MuiFilledInput: {
        root: {
          backgroundColor: color.white,
          borderRadius: '0px!important',
        },
        input: {
          padding: '15.5px 14px',
          '&::placeholder': {
            color: color.periwinkleGray,
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: '0px!important',
        },
        input: {
          padding: '15.5px 14px',
          '&::placeholder': {
            color: color.periwinkleGray,
          },
        },
        notchedOutline: {
          borderColor: color.white,
        },
      },
      MuiSelect: {
        iconOutlined: {
          color: color.white,
        },
      },
      MuiButton: {
        root: {
          fontFamily: 'Blender Pro',
          height: 40,
          textTransform: 'uppercase',
          fontSize: 16,
          fontWeight: 400,
          borderRadius: 0,
          // boxShadow: 'inset 0 -2px 1px 0 rgba(0,0,0,0.5)!important',
        },
        text: {
          fontWeight: 700,
        },
        contained: {
          fontWeight: 600,
        },
        containedPrimary: {
          background: color.thunderbird,
        },
      },
      MuiTooltip: {
        popper: {
          zIndex: 1000,
        },
      },
    },
  });

export default myTheme;
