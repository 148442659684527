import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import { boxShadows } from './boxShadows';
import { color } from './color';
import { containerStyles } from './containerStyles';
import { dynamicStyles } from './dynamicStyles';
import { fontFamily } from './fontFamily';
import { textStyles } from './textStyles';

export default createMuiTheme({
  isDarkMode: false,
  textStyles,
  containerStyles,
  dynamicStyles,
  boxShadows,
  color,
  fontFamily: {
    main: '"Futura PT", sans-serif',
    secondary: fontFamily.secondary,
  },
  palette: {
    primary: {
      main: color.thunderbird,
      dark: '#FDFDFD',
      light: '#2c2c2c',
    },
    secondary: {
      main: color.camarone,
      dark: color.camarone,
      light: color.parsley,
    },
    background: {
      paper: '#2f2f2f',
    },
  },
  typography: {
    fontFamily: [fontFamily.main].join(','),
    fontSize: 16,
    h1: {
      fontFamily: fontFamily.secondary,
      fontSize: '2.1875rem',
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    h2: {
      fontFamily: fontFamily.secondary,
      fontSize: '3.25rem',
      fontWeight: 400,
      marginBottom: '1.25em',
    },
    h3: {
      fontFamily: fontFamily.secondary,
      fontSize: '1.25rem',
      marginBottom: '0.5em',
    },
    body1: {
      fontFamily: '"Futura PT", sans-serif',
      fontSize: '1rem',
      fontWeight: 400,
    },
    body2: {
      fontFamily: '"Futura PT", sans-serif',
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    caption: {
      fontFamily: '"Futura PT", sans-serif',
      fontSize: '0.75rem',
      fontWeight: 400,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontFamily: 'Blender Pro',
        paddingLeft: 60,
        paddingRight: 60,
        height: 40,
        fontSize: 16,
        fontWeight: 400,
        borderRadius: 0,
        boxShadow: 'inset 0 -2px 1px 0 rgba(0,0,0,0.5)!important',
      },
      sizeLarge: {
        padding: '6px 24px',
        fontSize: '1.25rem',
        borderRadius: 12,
      },
    },
    MuiInput: {
      root: {
        '@media(min-width:576px)': {
          minWidth: 400,
        },
      },
      underline: {
        '&::before, &&::after': {
          borderColor: '#fff',
        },
        '& input, & input::placeholder': {
          paddingBottom: 4,
          fontSize: '1.125rem',
          color: '#fff',
          opacity: 1,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: '#f44336',
      },
    },
  },
});
