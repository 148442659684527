import { ApolloError } from '@apollo/client';

export interface GraphqlErrorInterface {
  message: string;
  stringCode: string;
}

export const getGraphqlError = (err: ApolloError): GraphqlErrorInterface => {
  if (!err) {
    return {
      stringCode: 'INTERNAL_SERVER_ERROR',
      message: 'Internal server error',
    };
  }

  if (err.networkError) {
    return {
      stringCode: 'NETWORK_ERROR',
      message: 'Not found',
    };
  }

  if (err.message) {
    return {
      stringCode: err.message,
      message: err.message,
    };
  }

  return {
    stringCode: 'INTERNAL_SERVER_ERROR',
    message: 'Internal server error',
  };
};
