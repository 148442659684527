/* eslint-disable jsx-a11y/alt-text */
import { AppBar, Drawer, Hidden, IconButton, Toolbar } from '@material-ui/core';
import classnames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Logo } from '../../../common/Logo';
import AppBarRight from '../../Layout/AppBarDashboard/AppBarRight';
import CustomNav from '../CustomNav/CustomNav';
import { MenuToggleButton } from './MenuToggleButton';
import useStyles from './styles';

const CustomAppBar: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [drawerOpened, setDrawerOpened] = useState(false);

  useEffect(() => {
    setDrawerOpened(false);
  }, [history.location.pathname]);
  const toggleDrawer = () => {
    setDrawerOpened((v) => !v);
  };
  return (
    <>
      <AppBar position="fixed" elevation={0} className={classes.appbar}>
        <Toolbar
          classes={{
            root: classes.toolbar,
          }}
        >
          <div
            className={classnames(
              classes.logo,
              classes.center,
              history.location.pathname.includes('/login') ? classes.mAuto : classes.mNone,
            )}
            onClick={() => history.push('/')}
          >
            <Logo size="large" />
          </div>

          <>
            <Hidden mdDown={true}>
              <CustomNav orientation="horizontal" />
              <AppBarRight />
            </Hidden>
            <Hidden lgUp={true}>
              <div className={classes.grow} />
              <IconButton color="inherit" aria-label="Open drawer" onClick={() => toggleDrawer()}>
                <MenuToggleButton opened={drawerOpened} />
              </IconButton>
            </Hidden>
          </>
        </Toolbar>
      </AppBar>
      <Hidden lgUp={true}>
        <Drawer
          container={window.document.body ?? undefined}
          variant="temporary"
          anchor="right"
          open={drawerOpened}
          onClose={() => toggleDrawer()}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <IconButton color="inherit" onClick={() => toggleDrawer()} className={classes.btnToggle}>
            <MenuToggleButton opened={drawerOpened} />
          </IconButton>
          <AppBarRight />
          <CustomNav />
        </Drawer>
      </Hidden>
    </>
  );
};

export default CustomAppBar;
