import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  appbar: {
    color: 'white',
    backgroundColor: '#060606',
    // borderBottom: `3px solid ${theme.color.thunderbird}`,
    zIndex: 1300,
    [theme.breakpoints.up('lg')]: {
      borderBottomWidth: '5px',
    },
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  grow: {
    flexGrow: 1,
  },
  logo: {
    flex: '0 0 96px',
    minWidth: 130,
    textAlign: 'center',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      flexBasis: '145px',
    },
    '&::before': {
      content: "''",
      position: 'absolute',
      left: '-80%',
      top: 0,
      width: '100%',
      height: '100%',
      background: `linear-gradient(-70deg, rgba(255,255,255,0) 0%, rgba(206,205,212,0) 41%, rgba(255,255,255,0.8) 46%, rgba(255,255,255,0.8) 50%, rgba(219,219,219,0) 58%, rgba(255,255,255,0) 92%)`,
    },
    '&:hover::before': {
      left: '100%',
      transitionProperty: 'left, top, opacity',
      transitionDuration: '1s',
      transitionTimingFunction: 'ease',
    },
  },
  toolbar: {
    height: '72px!important',
    backgroundColor: '#060606',
    display: 'flex',
    justifyContent: 'space-between',
  },
  drawerPaper: {
    width: '65%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    backgroundColor: '#131313',
    '&::before': {
      content: '""',
      display: 'block',
    },
  },
  btnToggle: {
    width: 'fit-content',
  },
  mAuto: {
    margin: 'auto',
  },
  mNone: {
    margin: 'none',
  }
}));
