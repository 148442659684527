import { MenuItem, MenuList, useMediaQuery } from '@material-ui/core';
import clsx from 'classnames';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { AuthContext } from '../../../provider/AuthProvider';
import useStyles from './styles';

interface ICustomNav {
  orientation?: 'horizontal' | 'vertical';
}

interface IItem {
  title: string;
  to: string;
  isActive: boolean;
}

const pathWithoutAppBar = ['login', 'signup', 'forgot-password'];
const CustomNav: FC<ICustomNav> = (props) => {
  const { orientation = 'vertical' } = props;
  const {
    location: { pathname },
  } = useHistory();
  const classes = useStyles();
  const intl = useIntl();
  const { token } = useContext(AuthContext);
  const items: IItem[] = [
    {
      title: intl.formatMessage({ id: 'home' }),
      to: '/app',
      isActive: pathname === '' || pathname === '/app',
    },
    {
      title: intl.formatMessage({ id: 'all-tournaments' }),
      to: '/app/tournament',
      isActive: pathname.includes('/app/tournament'),
    },
    {
      title: 'Wagers',
      to: '/app/wager',
      isActive: pathname.includes('/app/wager'),
    },
    {
      title: intl.formatMessage({ id: 'all-community' }),
      to: '/app/news',
      isActive: pathname.includes('/app/news'),
    },
    {
      title: intl.formatMessage({ id: 'all-assistance' }),
      to: '/app/assistance',
      isActive: pathname.includes('/app/assistance'),
    },
    {
      title: intl.formatMessage({ id: 'all-leagues' }),
      to: '/app/league',
      isActive: pathname.includes('/app/league/list'),
    },
  ];

  const notShowAppBar = pathWithoutAppBar.some((item) => pathname.includes(item));
  if (pathname === '/signup')
    return <div className={classes.title}>{`${'Création de compte'}`}</div>;
  return !notShowAppBar && token ? (
    <nav className={classes.nav}>
      <MenuList className={orientation === 'horizontal' ? classes.horizontal : classes.vertical}>
        {items.map((item) => (
          <MenuItem
            key={item.title}
            component={Link}
            to={item.to}
            className={clsx(classes.item, item.isActive && classes.itemActive)}
          >
            {item.title}
          </MenuItem>
        ))}
      </MenuList>
    </nav>
  ) : (
    <div />
  );
};

export default CustomNav;
