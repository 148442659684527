import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  layout: {
    position: 'relative',
    height: '100vh',
    display: 'flex',
    width: '100%',
    overflow: 'hidden',
  },
  content: {
    flex: 1,
    // maxWidth: 'calc(100% - 205px)',
    height: '100%',
    marginTop: 72,
    position: 'relative',
    overflow: 'hidden',

    backgroundColor: theme.color.codGray,
    '&>div': {
      height: 'calc(100vh - 70px)',
      color: theme.color.white,
      overflow: 'auto',
    },
  },
  main: {
    marginTop: 72,
    minHeight: 'calc(100vh - 175px)',
    marginBottom: 25,
    color: 'white',
  },
  mainWithPadding: {
    color: 'white',
    padding: theme.spacing(4, 3),
    '@media(min-width: 1750px)': {
      padding: '30px 170px 10px 170px',
    },
  },
  containerFooter: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
  },
}));
