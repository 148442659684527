import { createStyles } from '@material-ui/core/styles';

export const containerStyles = createStyles({
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  flexGrid: {
    display: 'grid',
  },
});
