import { createStyles } from '@material-ui/core/styles';

export const dynamicStyles = createStyles({
  borderTextField: {
    '& .MuiInputBase-root ': {
      backgroundColor: 'transparent!important',
      border: '1px solid #FFFFFf!important',
      color: 'white!important',
      '& fieldset': {
        border: 'none',
      },
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      backgroundColor: 'transparent!important',
      border: '1px solid #FFFFFf!important',
    },
    '& .MuiInputBase-input': {
      padding: '10px 10px!important',
    },
  },
});
