import { Grid } from '@material-ui/core';
import queryString from 'querystring';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import SmallLoading from '../../common/SmallLoading';
import { globalSnackbarVar } from '../../config/reactiveVars';
import { useLoginBattlenetMutation } from '../../generated/graphql';
import authService from '../../services/AuthServices';
import PublicLayout from '../PublicLayout';
import useStyle from './styles';

export interface FormStateInterface {
  email: string;
  password: string;
}

/**
 * 
 * http://localhost:3000/connect/twitch/?code=e9ftcuwu4g715rfzrvehkxvf99w07e&scope=user%3Aedit+user%3Aread%3Aemail

 */
export interface ErrorStateInterface {
  email?: string;
  password?: string;
  credentialsInvalid?: string;
}
interface QueryStringInterface {
  access_token?: string;
  scope?: string;
  state?: string;
  code?: string;
  token_type?: string;
}
const BattlenetLoginPage: FC = () => {
  const classes = useStyle();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const {
    location: { search },
  } = history;

  const [isStarted, setIsStarted] = useState(false);
  const parsed: QueryStringInterface = queryString.parse(search.replace('?', ''));

  const [mutationBattlentLogin, { data }] = useLoginBattlenetMutation();
  useEffect(() => {
    if (!isStarted && parsed.code && !data) {
      setIsStarted(true);
      mutationBattlentLogin({
        variables: {
          code: parsed.code,
        },
      })
        .then(i => {
          if (i.data.loginBattlenet) {
            globalSnackbarVar({
              message: formatMessage({
                id: 'link-xbox-success',
              }),
              open: true,
              type: 'SUCCESS',
              duration: 3000,
            });

            const token = i.data.loginBattlenet;
            authService.setAccessToken(token);
            history.push('/app/news');
            window.location.reload();
          } else {
            globalSnackbarVar({
              message: formatMessage({
                id: 'all-error-occured',
              }),
              open: true,
              type: 'ERROR',
              duration: 3000,
            });
          }
          // setTimeout(() => history.push('/app/news'), 4000);
        })
        .catch(() => {
          globalSnackbarVar({
            message: formatMessage({
              id: 'all-error-occured',
            }),
            open: true,
            type: 'ERROR',
            duration: 3000,
          });
          setTimeout(() => history.push('/app/profile'), 4000);
        });
    }
  }, [isStarted, parsed, data, mutationBattlentLogin, history, formatMessage]);
  return (
    <PublicLayout>
      <Grid
        container={true}
        alignContent="center"
        alignItems="center"
        className={classes.container}>
        <Grid item={true} alignContent="center" alignItems="center">
          <p className={classes.text}>
            <FormattedMessage
              id="link-xbox-loading"
              defaultMessage="Logging in with your current xbox account, please wait"
            />
          </p>
          <SmallLoading />
        </Grid>
      </Grid>
    </PublicLayout>
  );
};

export default BattlenetLoginPage;
