import { Typography as Typo, TypographyProps } from '@material-ui/core';
import React, { FC } from 'react';

interface CustomTypographyProps {
  weight?: number | 'normal';
  htmlColor?: string;
}

const Typography: FC<TypographyProps & CustomTypographyProps> = props => {
  const { children, weight = undefined, htmlColor = undefined, style, ...rest } = props;
  return (
    <Typo {...rest} style={{ fontWeight: weight, color: htmlColor, ...style }}>
      {children}
    </Typo>
  );
};

export default Typography;
