import { gql } from '@apollo/client';
import { TEAM_MEMBER_FRAGMENT } from '../teamMember/fragment';

export const TEAM_BASIC_FRAGMENT = gql`
  fragment TeamBasicInfos on Team {
    id
    name
    description
    logo
    banner
    createdAt
    country
    point
    members {
      id
    }
  }
`;

export const TEAM_INFOS_FRAGMENT = gql`
  fragment TeamInfos on Team {
    id
    name
    description
    logo
    banner
    createdAt
    owner {
      ...TeamMemberInfos
    }
    members {
      ...TeamMemberInfos
    }
  }
  ${TEAM_MEMBER_FRAGMENT}
`;
