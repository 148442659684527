import { Link, LinkProps } from '@material-ui/core';
import React, { FC, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';

const CustomLink: FC<LinkProps> = props => {
  const { href, children } = props;
  const history = useHistory();
  const handleClick = (e: MouseEvent) => {
    e.preventDefault();

    history.push(href || '/');
  };

  return (
    <Link {...props} onClick={handleClick}>
      {children}
    </Link>
  );
};

export default CustomLink;
