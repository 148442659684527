import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  nav: {
    flex: 1,
    textAlign: 'left',
  },
  horizontal: {
    '& > *': {
      display: 'inline-block',
      // padding: '10px 35px',
    },
  },
  vertical: {
    '& > *': {
      padding: theme.spacing(2, 4),
    },
  },
  item: {
    color: '#6C6C6C',
    fontSize: 15,
    fontWeight: 'bold',
    fontFamily: 'Blender Pro',
    textTransform: 'uppercase',
    lineHeight: 3,
    borderLeft: `1px solid #262626`,
    borderRight: `1px solid #262626`,
  },
  itemActive: {
    borderBottom: `4px solid ${theme.color.thunderbird}`,
    color: 'white',
  },
  title: {
    fontFamily: 'Blender Pro',
    fontSize: 22,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
}));
