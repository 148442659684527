import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedLazyRoute } from '../common/ProtectedLazyRoute';
import LoginOauth2 from '../components/Authentication/LoginOauth2';
import BattlenetLoginPage from '../components/BattlenetLoginPage/BattlenetLoginPage';
import CustomChannel from '../components/CustomChannel';
import InvitationMobile from '../components/InvitationMobile';

const Login = lazy(() => import('../components/Authentication/Login'));
const TwitchAuth = lazy(() => import('../components/TwitchAuth'));
const TwitchLogin = lazy(() => import('../components/TwitchLogin/TwitchLogin'));
const DiscordLogin = lazy(() => import('../components/DiscordLogin/DiscordLogin'));
const TwitterLogin = lazy(() => import('../components/TwitterLogin/TwitterLogin'));
const DiscordLink = lazy(() => import('../components/DiscordLink'));
const XboxLogin = lazy(() => import('../components/MicrosoftLoginPage/MicrosoftLoginPage'));
const MicrosoftLink = lazy(() => import('../components/MicrosoftLink'));

const Forgot = lazy(() => import('../components/Authentication/Forgot'));
const SignupChoice = lazy(() => import('../components/Authentication/SignupChoice'));
const LandingPage = lazy(() => import('../components/LandingPage'));
const GameAllDetails = lazy(() => import('../components/LandingPage/GameAllDetails'));
const Logout = lazy(() => import('../components/Authentication/Logout'));
const Dashboard = lazy(() => import('../components/Dashboard'));
const NoticeLegal = lazy(() => import('../components/LandingPage/Footer/NoticeLegal'));

const Router = () => {
  return (
    <Switch>
      {/* <Redirect from="/app" to="/app/news" exact={true} /> */}
      <ProtectedLazyRoute
        path="/signup/:email?"
        access="public"
        exact={true}
        component={SignupChoice}
      />
      {/* <ProtectedLazyRoute
        path="/custom-channel/:channelId"
        access="public"
        exact={true}
        component={CustomChannel}
      /> */}
      <ProtectedLazyRoute path="/login" exact={true} access="authentication" component={Login} />
      <ProtectedLazyRoute
        path="/login/oauth2"
        exact={true}
        access="authentication"
        component={LoginOauth2}
      />
      <ProtectedLazyRoute
        path="/forgot-password"
        exact={true}
        access="authentication"
        component={Forgot}
      />
      <ProtectedLazyRoute path="/logout" exact={true} component={Logout} />
      <ProtectedLazyRoute path="/app" component={Dashboard} />
      <ProtectedLazyRoute path="/" exact={true} component={LandingPage} access="public" />
      <ProtectedLazyRoute
        path="/game/:gameId/view"
        exact={true}
        component={GameAllDetails}
        access="public"
      />
      <ProtectedLazyRoute path="/legal" exact={true} component={NoticeLegal} access="public" />
      <ProtectedLazyRoute path="/connect/twitch" component={TwitchAuth} />
      <ProtectedLazyRoute access="public" path="/twitch/login" component={TwitchLogin} />
      <ProtectedLazyRoute access="public" path="/discord/login" component={DiscordLogin} />
      <ProtectedLazyRoute access="public" path="/discord/link" component={DiscordLink} />
      <ProtectedLazyRoute access="public" path="/twitter/login" component={TwitterLogin} />
      <ProtectedLazyRoute access="public" path="/auth/xbox" component={XboxLogin} />
      <ProtectedLazyRoute access="public" path="/auth/link/xbox" component={MicrosoftLink} />
      <ProtectedLazyRoute access="public" path="/auth/battlenet" component={BattlenetLoginPage} />
      <ProtectedLazyRoute access="public" path="/mobile" component={InvitationMobile} />
    </Switch>
  );
};

export default Router;
