import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 100,
  },
  text: {
    color: 'white',
    marginBottom: 50,
  },
}));
