import React, { createContext, FC, useEffect, useState } from 'react';
import { Social_App } from '../generated/graphql';
import authService from '../services/AuthServices';

interface AuthContextType {
  authName: 'GG' | Social_App;
  userNameInAuth: string;
  tokenDefault: string;
  token: string;
  setToken?: (token: string) => void;
  setAuthName?: (authName: 'GG' | Social_App) => void;
  clearToken?: () => void;
  isUserConnected: boolean;
}

export const AuthContext = createContext<AuthContextType>({
  authName: undefined,
  userNameInAuth: undefined,
  token: '',
  tokenDefault: undefined,
  setToken: undefined,
  setAuthName: undefined,
  clearToken: undefined,
  isUserConnected: false,
});

const generatorId = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const AuthProvider: FC = ({ children }) => {
  const [authName, setAuthName] = useState<'GG' | Social_App>(authService.getAuthName());
  const [userNameInAuth, setUserNameInAuth] = useState<string>(undefined);
  const [token, setToken] = useState<string | undefined>(authService.getAccessToken());
  const [tokenDefault, setTokenDefault] = useState<string | undefined>(
    authService.getTokenDefault(),
  );
  useEffect(() => {
    const newToken = authService.getAccessToken();
    if (!token && newToken) setToken(newToken);
    const newAuthName = authService.getAuthName();
    if (!authName && newAuthName) setAuthName(newAuthName);
  }, []);
  const clearToken = () => {
    authService.signOut();
    authService.clearToken();
    setToken(undefined);
  };
  const setTokenValue = (t?: string) => {
    if (t) {
      // localStorage
      authService.setAccessToken(t);
      // authProvider
      setToken(t);
      // defaultToken
      setTokenDefaultValue();
    }
  };
  const setAuthNameValue = (an?: Social_App | 'GG') => {
    if (an) {
      // localStorage
      authService.setAuthName(an);
      // authProvider
      setAuthName(an);
    }
  };

  const setTokenDefaultValue = () => {
    const tdv = generatorId(20);
    // localStorage
    authService.setTokenDefault(tdv);

    // authProvider
    setTokenDefault(tdv);
  };

  return (
    <AuthContext.Provider
      value={{
        authName,
        tokenDefault,
        userNameInAuth,
        token,
        setToken: setTokenValue,
        setAuthName: setAuthNameValue,
        clearToken,
        isUserConnected: Boolean(token),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthProvider;
