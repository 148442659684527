const config = {
  servers: {
    graphqlUrl: process.env.REACT_APP_GRAPHQL_URL as string,

    graphqlSubscriptionUrl: process.env.REACT_APP_GRAPHQL_SUBSCRIPTION_URL as string,

    apiUrl: process.env.REACT_APP_REST_API_URL as string,

    stream: {
      host: process.env.REACT_APP_STREAM_SERVER_URL as string,
      port: +(process.env.REACT_APP_STREAM_SERVER_PORT as string),
      protocol: 'wss',
    },
  },
  secure: '',
  firebase: {
    authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,

    apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  },
  clientUploadPassToken: process.env.CLIENT_UPLOAD_PASS_TOKEN || '',
  twitch: {
    clientId: process.env.REACT_APP_TWITCH_CLIENT_ID,
    redirectUrl:
      process.env.REACT_APP_TWITCH_REDIRECT_URL || 'http://localhost:3000/connect/twitch',
    redirectLoginUrl:
      process.env.REACT_APP_TWITCH_LOGIN_REDIRECT_URL || 'http://localhost:3000/twitch/login',
  },
  isDev: process.env.REACT_APP_NODE_ENV === 'development',
  stripe: {
    apiKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || 'pk_test_nON1Ib4Cqv5OM8GXipGDuCjO',
  },
  chatEngine: {
    projectId:
      process.env.REACT_APP_CHAT_ENGINE_PROJECTID || '60b07842-d521-45fa-9e8b-d497b5d579df',
  },
  discord: {
    clientId: process.env.REACT_APP_DISCORD_CLIENT_ID,
    loginClientId: process.env.REACT_APP_DISCORD_LOGIN_CLIENT_ID,
    redirectUrl:
      process.env.REACT_APP_REDIRECT_DISCORD_AUTH || 'https://front.gmrtl2.com/discord/login',
    linkRedirectUrl:
      process.env.REACT_APP_REDIRECT_LINK_DISCORD_AUTH || 'https://front.gmrtl2.com/discord/link',
    serverId: process.env.REACT_APP_DISCORD_SERVER_ID,
    widgetServerId: process.env.REACT_APP_DISCORD_WIDGET_SERVER_ID || '896128425327206420',
    generalWidgetChannelId: process.env.REACT_APP_GENERAL_WIDGET_CHANNEL_ID || '896128425327206423',
    generalChannelId: process.env.REACT_APP_DISCORD_GENERAL_CHANNEL_ID,
  },
  microsoft: {
    MICROSOFT_CLIENT_ID:
      process.env.REACT_APP_MICROSOFT_CLIENT_ID || '220f428b-bf21-4e4e-b188-84a16c4c2cbd',
    MICROSOFT_LOGIN_REDIRECT_URL:
      process.env.REACT_APP_MICROSOFT_LOGIN_REDIRECT_URL || 'https://front.gmrtl2.com/auth/xbox',
    MICROSOFT_LINK_REDIRECT_URL:
      process.env.REACT_APP_MICROSOFT_LINK_REDIRECT_URL || 'https://front.gmrtl2.com/auth/link/xbox',
  },
  battlenet: {
    clientId: process.env.REACT_APP_BATTLENET_CLIENT_ID || 'ff23530a5fe2457ea89a5e4bf425f5ba',
    redirectUrl:
      process.env.REACT_APP_BATTLENET_REDIRECT_URL || 'http://localhost:3000/auth/battlenet',
  },
  socket: {
    url: process.env.REACT_APP_SOCKET_URL || 'ws://localhost:3120',
    protocol: process.env.REACT_APP_WEBSOCKET_PROTOCOL,
    host: process.env.REACT_APP_WEBSOCKET_HOST,
  }
};

export default config;
