import { gql } from '@apollo/client';
import { PAGE_CONTENT_FRAGMENT, PAGE_FRAGMENT } from './fragment';

export const GET_ALL_PAGES = gql`
  query AllPages {
    allPages {
      ...PageInfos
      contents {
        ...PageContentInfos
      }
    }
  }
  ${PAGE_FRAGMENT}
  ${PAGE_CONTENT_FRAGMENT}
`;
export const GET_PAGE_BY_SLUG = gql`
  query PageBySlug($slug: String!) {
    pageBySlug(slug: $slug) {
      ...PageInfos
      contents {
        ...PageContentInfos
      }
    }
  }
  ${PAGE_FRAGMENT}
  ${PAGE_CONTENT_FRAGMENT}
`;
