import React from 'react';
import {
  useGamePartyInvitationSubscription,
  useUserOfflineSubscription,
  useUserOnlineSubscription,
} from '../../generated/graphql';
import { updateCacheAfterOfflineUser, updateCacheAfterOnlineUser } from './subscribeUtils';

const SubscribeComponent = () => {
  useUserOnlineSubscription({
    onSubscriptionData: updateCacheAfterOnlineUser,
  });
  useUserOfflineSubscription({
    onSubscriptionData: updateCacheAfterOfflineUser,
  });
  useGamePartyInvitationSubscription();
  return <div />;
};

export default SubscribeComponent;
