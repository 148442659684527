import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export default (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <path d="M14.1 19l5.2-3-5.2-3v6z" />
      <path d="M26.1 16c0 1.6-.1 3.2-.4 4.8-.2.9-.9 1.5-1.8 1.8-1.5.4-7.8.4-7.8.4s-6.2 0-7.8-.4c-.9-.2-1.5-.9-1.8-1.8-.3-1.6-.4-3.2-.4-4.8 0-1.6.1-3.2.4-4.8.2-.9.9-1.5 1.8-1.8C9.9 9 16.1 9 16.1 9s6.3 0 7.8.4c.9.2 1.5.9 1.8 1.8.3 1.6.4 3.2.4 4.8zm-10-16C7.3 0 .1 7.2.1 16s7.2 16 16 16 16-7.2 16-16S25 0 16.1 0z" />
    </SvgIcon>
  );
};
