import { gql } from '@apollo/client';
import { USER_SOCIAL_ACCOUNT_FRAGMENT } from '../socialAccount/fragment';
import { TEAM_BASIC_FRAGMENT } from '../team/fragment';
import { USER_BASIC_FRAGMENT, USER_FRAGMENT } from './fragment';

export const ME_QUERY = gql`
  query Me {
    me {
      ...UserInfos
      chatEnginePublicSecret
    }
  }
  ${USER_FRAGMENT}
`;

export const USER_QUERY = gql`
  query User($id: ID!) {
    User(id: $id) {
      ...UserInfos
      chatEnginePublicSecret
    }
  }
  ${USER_FRAGMENT}
`;
export const ME_BASIC_QUERY = gql`
  query MeBasic {
    me {
      ...UserBasicInfos
    }
  }
  ${USER_BASIC_FRAGMENT}
`;
export const MY_GAME_PARTY_INVITATION = gql`
  query MyGamePartyInvitation {
    me {
      id
      gamePartyInvitation {
        gameParty {
          id
          chat {
            id
            owner {
              id
              username
            }
          }
        }
      }
    }
  }
`;

export const FIND_USER_TOKEN = gql`
  query FindUserToken($token: String!){
    findUserToken(token: $token){
      ...UserInfos
    }
  }
  ${USER_FRAGMENT}
`;

export const LIST_USERS_INFOS = gql`
  query ListUsersInfos($filter: UsersFilterInput!) {
    users(filter: $filter) {
      results {
        ...UserInfos
      }
      total
    }
  }
  ${USER_FRAGMENT}
`;

export const LIST_USERS_NOT_IN_TOURNAMENT = gql`
  query ListUsersNotInTournament($filter: UsersTournamentFilterInput!) {
    usersTournament(filter: $filter) {
      results {
        ...UserInfos
      }
      total
    }
  }
  ${USER_FRAGMENT}
`;

export const USER_FRIEND_SUGGESTIONS = gql`
  query UserFriendSuggestions {
    userFriendsSuggestion {
      ...UserInfos
    }
  }
  ${USER_FRAGMENT}
`;
export const USER_FRIEND_ONLINE = gql`
  query OnlineFriends {
    onlineFriends {
      ...UserInfos
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_USERS_BY_RAND = gql`
  query GetUsersByRand($limit: Int) {
    getUsersByRand(limit: $limit) {
      ...UserInfos
    }
  }
  ${USER_FRAGMENT}
`;


export const GET_USER_TEAMS = gql`
  query GetUserTeams($userId: ID!) {
    User(id: $userId) {
      id
      joinedTeams {
        id
        role
        team {
          ...TeamBasicInfos
          owner {
            id
            user {
              ...UserBasicInfos
            }
          }
        }
      }
    }
  }
  ${TEAM_BASIC_FRAGMENT}
  ${USER_BASIC_FRAGMENT}
`;

export const ALL_USER_TOURNAMENTS = gql`
  query AllUserTournaments($userId: ID!) {
    User(id: $userId) {
      id
      joinedParticipationTournaments {
        id
        tournament {
          id
          title
          isActive
          startedAt
          createdAt
          entryCost
          rewardsTotal
          totalAwardValue
          format {
            title
          }
          description
          awards {
            value
          }
          gamePlateform {
            id
            name
            logo
          }
          myRanking {
            id
            rank
          }
          gamemode {
            id
            title
            teamSize
            type
          }
          maxParticipants
          participants {
            id
          }
          game {
            id
            name
            mainJacket
          }
          isMeAlreadyParticipant
        }
      }
    }
  }
`;
export const ALL_USER_WAGERS = gql`
  query AllUserWagers($userId: ID!) {
    User(id: $userId) {
      id
      joinedWagers {
        id
        wager {
          id
          isMeAlreadyParticipant
          cashPrice
          format
          isActive
          participantNumber
          startedAt
          updatedAt
          title
          createdAt
          game {
            id
            name
          }
          plateform {
            id
            name
            logo
          }
          createdBy {
            id
            username
          }
          rule
          rule_en
          entryCost
        }
      }
    }
  }
`;

export const MY_USER_SOCIAL = gql`
  query MyUserSocial {
    me {
      id
      socialAccounts {
        ...UserSocialAccountInfos
      }
    }
  }
  ${USER_SOCIAL_ACCOUNT_FRAGMENT}
`;
